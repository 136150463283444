import React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { userService } from '../../../../core/services'
import * as constants from '../../../../static/constants'
import { Button, InputText } from '../../../atoms'
import { Form } from '../../../molecules'

const FIELDS = {
  reset_password_token: 'reset_password_token',
  password: 'password',
  password_confirmation: 'password_confirmation',
}

interface IState {
  errors: any
}

interface IProps {
  reset_password_token: string
}

class UserPasswordsEdit extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
    }
  }

  public handleUpdateForm = (errors, hasNoError) => {
    this.setState({
      errors: {
        ...this.state.errors,
        ...errors,
      },
    })
  }

  public handleSubmit = async (initialValues, values) => {
    const { redirectUrl } = await userService.resetPassword({ user: values })
    location.href = redirectUrl
  }

  public render() {
    return (
      <UserPasswordsEditWrapper>
        <Panel>
          <Form
            fields={FIELDS}
            handleSubmit={this.handleSubmit}
            handleUpdateForm={this.handleUpdateForm}
          >
            <FormItem>
              <input
                type="hidden"
                name="reset_password_token"
                value={this.props.reset_password_token}
              />
            </FormItem>
            <FormItem>
              <InputText
                required={true}
                password={true}
                name="password"
                defaultValue=""
                label={I18n.t('settings.accounts.new_password')}
                placeholder={I18n.t('settings.accounts.new_password')}
                error={this.state.errors.password}
              />
            </FormItem>
            <FormItem>
              <InputText
                required={true}
                password={true}
                name="password_confirmation"
                defaultValue=""
                label={I18n.t('settings.accounts.new_password_confirmation')}
                placeholder={I18n.t('settings.accounts.new_password_confirmation')}
                error={this.state.errors.password}
              />
            </FormItem>
            <Buttons>
              {this.state.errors.login && <span className="error">{this.state.errors.login}</span>}
              <Button primary={true} backgroundColor={'#FF923E'}>
                {I18n.t('generic.send')}
              </Button>
            </Buttons>
          </Form>
        </Panel>
      </UserPasswordsEditWrapper>
    )
  }
}

const UserPasswordsEditWrapper = styled.main`
  padding-top: 36px;

  .error {
    color: ${constants.DANGER_COLOR};
    display: block;
    margin-bottom: 12px;
  }
`

const Buttons = styled.div`
  margin-top: 24px;
  text-align: center;

  > p {
    display: block;
    margin: 16px auto 0;

    a {
      color: var(${constants.THEME_COLOR_VARIABLE_NAME});
      text-decoration: underline;
    }
  }

  .Button {
    display: block;
    margin: 0 auto;
    width: 160px;
    height: 40px;
    font-weight: bold;
    font-size: 16px;
  }
`

const Panel = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  max-width: 600px;
  margin: 0 auto;
  padding: 24px;
`

const FormItem = styled.div`
  & + & {
    margin-top: 16px;
  }
`

export default UserPasswordsEdit

import ClassNames from 'classnames'
import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { IPost } from '../../../../core/interfaces'
import { STATE_CODES } from '../../../../static/stateCodes'
import { setNativeValue } from '../../../../utils/form'
import injectGoogleMaps from '../../../../utils/injectGoogleMaps'
import { Button, InputText, Select } from '../../../atoms'
import { Form } from '../../../molecules'

const ADDRESS_FIELDS = {
  zipcode: 'zipcode',
  state: 'state',
  city: 'city',
  street1: 'street1',
  street2: 'street2',
  latitude: 'latitude',
  longitude: 'longitude',
}

interface IErrors {
  [key: string]: string | null
}

interface IProps {
  google: any
  post: IPost
  isProcessing: boolean
  handleFormSubmit(initialValues: any, values: any): void
}

const Address: React.FC<IProps> = props => {
  const [map, setMap] = React.useState(null)
  const [marker, setMarker] = React.useState(null)
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [errors, setErrors] = React.useState<IErrors>({})

  React.useEffect(() => {
    if (props.google) {
      const { latitude, longitude } = props.post

      if (!latitude || !longitude) {
        return
      }

      createMap(new props.google.maps.LatLng(latitude, longitude))
    }
  }, [props.google])

  const createMap = async coordinate => {
    const Options = {
      zoom: 15,
      center: coordinate,
      mapTypeId: 'roadmap',
    }
    const initializedMap = new props.google.maps.Map(document.getElementById('map'), Options)
    const defaultMarker = new props.google.maps.Marker({
      position: coordinate,
      title: props.post.name,
      map: initializedMap,
    })

    setMarker(defaultMarker)
    setMap(initializedMap)
  }

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }

  const getLongName = (addressComponents, type) => {
    let value = null

    addressComponents.some(component => {
      if (component.types.includes(type)) {
        value = component.long_name
        return true
      }
    })

    return value
  }

  const updateGeoCode = address => {
    if (!props.google) {
      return
    }

    const geocoder = new props.google.maps.Geocoder()
    geocoder.geocode({ address }, (results, status) => {
      if (status !== 'OK') {
        return
      }
      const addressComponents = results[0].address_components
      const geocodeValues = {
        country: getLongName(addressComponents, 'country'),
        state: getLongName(addressComponents, 'administrative_area_level_1'),
        city: getLongName(addressComponents, 'locality'),
        street1: getLongName(addressComponents, 'sublocality'),
        latitude: results[0].geometry.location.lat(),
        longitude: results[0].geometry.location.lng(),
      }
      const updateAdressFieflds = ['state', 'city', 'street2', 'latitude', 'longitude']

      updateAdressFieflds.forEach(field => {
        if (geocodeValues[field]) {
          const formElement = document.getElementById(field)
          setNativeValue(formElement, geocodeValues[field], true)
        }
      })
    })
  }

  const handleZipcode = e => {
    if (e.target.value.length === 7) {
      updateGeoCode(e.target.value)
    }
  }

  const getFullAddress = () => {
    const adressFieflds = ['state', 'city', 'street2', 'street1']
    let address = ''
    adressFieflds.forEach(field => {
      const formElement: any = document.getElementById(field)
      address += ` ${formElement.value}`
    })

    return address
  }

  const updateGeometry = async () => {
    if (!props.google) {
      return
    }

    const geocoder = new props.google.maps.Geocoder()

    geocoder.geocode({ address: getFullAddress() }, async (results, status) => {
      if (status !== 'OK') {
        return
      }
      const geocodeValues = {
        latitude: results[0].geometry.location.lat(),
        longitude: results[0].geometry.location.lng(),
      }
      const updateAdressFieflds = ['latitude', 'longitude']

      updateAdressFieflds.forEach(field => {
        if (geocodeValues[field]) {
          const formElement = document.getElementById(field)
          setNativeValue(formElement, geocodeValues[field], true)
        }
      })

      const LatLng = new props.google.maps.LatLng(geocodeValues.latitude, geocodeValues.longitude)

      if (!map) {
        createMap(LatLng)
      } else {
        marker.setPosition(LatLng)
        map.panTo(LatLng)
      }
    })
  }

  const stateOptions = [
    { value: '', label: '-' },
    ...STATE_CODES.map(stateName => ({
      value: stateName,
      label: stateName,
    })),
  ]

  return (
    <>
      <h2 className="Editor_Title">{I18n.t('post.input_address')}</h2>
      <Form
        fields={ADDRESS_FIELDS}
        handleUpdateForm={handleUpdateForm}
        handleSubmit={props.handleFormSubmit}
      >
        <ul>
          <FormItem>
            <InputText
              required={true}
              name="zipcode"
              label={I18n.t('generic.zipcode')}
              defaultValue={props.post.zipcode}
              error={errors.zipcode}
              onChangeHandler={handleZipcode}
            />
          </FormItem>
          <FormItem>
            <Select
              required={true}
              name="state"
              label={I18n.t('generic.prefectures')}
              options={stateOptions}
              defaultValue={props.post.state}
              error={errors.state}
            />
          </FormItem>
          <FormItem>
            <InputText
              required={true}
              name="city"
              label={I18n.t('generic.city_ku_mura')}
              defaultValue={props.post.city}
              error={errors.city}
              onBlurHandler={updateGeometry}
            />
          </FormItem>
          <FormItem>
            <InputText
              required={true}
              name="street1"
              label={I18n.t('generic.address_other')}
              defaultValue={props.post.street1}
              error={errors.street1}
              onBlurHandler={updateGeometry}
            />
          </FormItem>
          <FormItem>
            <InputText
              required={false}
              name="street2"
              label={I18n.t('generic.address_building_name')}
              defaultValue={props.post.street2}
              error={errors.street2}
              onBlurHandler={updateGeometry}
            />
          </FormItem>

          <div style={{ display: 'none' }}>
            <FormItem>
              <InputText
                required={true}
                name="latitude"
                label="latitude"
                defaultValue={String(props.post.latitude)}
                error={errors.latitude}
              />
            </FormItem>
            <FormItem>
              <InputText
                required={true}
                name="longitude"
                label="longitude"
                defaultValue={String(props.post.longitude)}
                error={errors.longitude}
              />
            </FormItem>
          </div>
        </ul>
        <div
          id="map"
          className={ClassNames('PostNew_Map', {
            disabled: !map,
          })}
        />
        <div className="Editor_Footer">
          <Button primary={true} disabled={!isSubmitEnabled || props.isProcessing}>
            {I18n.t('generic.update')}
          </Button>
        </div>
      </Form>
    </>
  )
}

const FormItem = styled.div`
  & + & {
    margin-top: 16px;
  }

  .FormItem_Title {
    font-size: 15px;
    font-weight: bold;
  }
`

export default injectGoogleMaps(Address)

import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import { IJsonResponse, IPost, IUser } from '../../../core/interfaces'
import { postService, userService } from '../../../core/services'
import * as constants from '../../../static/constants'
import { PostList } from '../../organisms'

interface IProps {
  user: IJsonResponse
  posts: {
    posts: IJsonResponse
  }
}

const Show: React.FC<IProps> = props => {
  const { user: initialUser } = userService.getUserFromJson(props.user)
  const [user] = React.useState<IUser>(initialUser)
  const { posts: initialPosts, pagination } = postService.getPostsFromJson(props.posts.posts)
  const [posts, setPosts] = React.useState<IPost[]>(initialPosts)
  const [meta, setMeta] = React.useState(pagination)

  const toggleLike = (postId, like) => {
    setPosts(currentPosts => {
      return currentPosts.map(postItem => ({
        ...postItem,
        user_liked: postItem.id === postId ? like : postItem.user_liked,
      }))
    })
  }

  const getPostsByPage = async page => {
    location.href = `${location.pathname}?page=${page}`
  }

  return (
    <ShowWrapper>
      <div className="Show_Inner">
        <ProfilePanel user={user} />
        <PostList
          title={count => I18n.t('post.users_post', { username: user.username })}
          noPostsTitle={I18n.t('post.users_post_none', { username: user.username })}
          getPostsByPage={getPostsByPage}
          isSignedIn={true}
          favorite={false}
          posts={posts}
          toggleLike={toggleLike}
          pagination={meta}
        />
      </div>
    </ShowWrapper>
  )
}

const ShowWrapper = styled.div`
  height: calc(100vh - ${constants.HEADER_HEIGHT}px);
  background-color: #f6f8f9;
  overflow: scroll;

  .Show_Inner {
    display: flex;
    max-width: 1200px;
    margin: auto;
    padding: 24px 0;
    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      display: block;
    }
  }

  .PostList {
    flex: 1;
  }
`

interface IProfilePanelProps {
  user: IUser
}

const ProfilePanel: React.FC<IProfilePanelProps> = props => {
  return (
    <ProfilePanelWrapper>
      <div className="ProfilePanel_Panel">
        <div className="ProfilePanel_Header">
          <div className="ProfilePanel_Avator">
            <img src={props.user.avatar_url} alt="" />
          </div>
          <div className="ProfilePanel_UserInfo">
            <div className="ProfilePanel_UserName">{props.user.username}</div>
            <span className="ProfilePanel_UserCreatedAt">
              {I18n.t('avatar.avatar_created_at', { created_at: props.user.created_at })}
            </span>
            <p className="ProfilePanel_UserBio">{props.user.bio}</p>
          </div>
        </div>
      </div>
    </ProfilePanelWrapper>
  )
}

const ProfilePanelWrapper = styled.div`
  .ProfilePanel_Panel {
    width: 360px;
    padding: 24px 16px;
    border-radius: 4px;
    margin: 0 0 0 24px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      width: auto;
      margin: 0 24px 0;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      margin: 0 12px 0;
    }
  }

  .ProfilePanel_Header {
    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      display: flex;
      align-items: center;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      display: block;
    }
  }

  .ProfilePanel_UserInfo {
    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      flex: 1;
      margin-left: 16px;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      margin: 0;
    }
  }

  .ProfilePanel_Avator {
    width: 96px;
    height: 96px;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      margin: 0;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      margin: auto;
    }

    img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }

  .ProfilePanel_UserName {
    font-size: 18px;
    font-weight: bold;
    margin-top: 8px;
    text-align: center;
    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      text-align: left;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      text-align: center;
    }
  }

  .ProfilePanel_UserCreatedAt {
    display: block;
    color: #999;
    font-size: 12px;
    margin-top: 8px;
    text-align: center;
    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      text-align: left;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      text-align: center;
    }
  }

  .ProfilePanel_UserBio {
    margin-top: 24px;
  }
`

export default Show

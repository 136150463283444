import ClassNames from 'classnames'
import Carousel from 're-carousel'
import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'
import SearchPanel from './SearchPanel'

const TopCarousel: React.FC = props => {
  return (
    <TopCarouselWrapper>
      <section className="section top-hero slideshow-container">
        <Carousel loop={true} auto={true}>
          <div className="picture1" />
          <div className="picture2" />
          <div className="picture3" />
          <div className="picture4" />
          <div className="picture5" />
          <div className="picture6" />
          <div className="picture7" />
        </Carousel>
        <div className="">
          <SearchPanel />
        </div>
      </section>
    </TopCarouselWrapper>
  )
}

const TopCarouselWrapper = styled.div`
  .slideshow-container {
    height: 668px;
    padding: 0;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      height: 240px;
    }
  }

  .picture1 {
    background-image: url('/images/top/top-cover2.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 668px;
    background-position: 50% 35%;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      background-position: 50% 35%;
      height: 240px;
    }
  }
  .picture2 {
    background-image: url('/images/top/top-cover.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 668px;
    background-position: 50% 0%;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      background-position: 50% 0%;
      height: 240px;
    }
  }
  .picture3 {
    background-image: url('/images/top/bottom-cover-1.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 668px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      height: 240px;
    }
  }
  .picture4 {
    background-image: url('/images/top/top-cover3.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 668px;
    background-position: 50% 10%;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      height: 240px;
    }
  }
  .picture5 {
    background-image: url('/images/top/pixta_26646767_L.jpeg');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 668px;
    background-position: 50% 10%;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      background-position: 50% 0%;
      height: 240px;
    }
  }
  .picture6 {
    background-image: url('/images/top/pixta_35512020_L.jpeg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50% 10%;
    height: 668px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      height: 240px;
    }
  }
  .picture7 {
    background-image: url('/images/top/pixta_36224838_L.jpeg');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 668px;
    background-position: 50% 10%;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      height: 240px;
    }
  }
`

export default TopCarousel

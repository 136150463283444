import { AxiosInstance } from 'axios'
import IJsonApiSerializer from 'core/interfaces/IJsonApiSerializer'

class AdminTagService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public async create(tag) {
    const { data } = await this.httpClient.post('/admin/api/tags/', { tag })
    const createdTag = this.serializer.parseResourceData(data.tag, data.tag.data)

    return { createdTag, flash: data.flash }
  }

  public async update(tag) {
    const { data } = await this.httpClient.patch(`/admin/api/tags/${tag.id}`, { tag })
    const updatedTag = this.serializer.parseResourceData(data.tag, data.tag.data)

    return { updatedTag, flash: data.flash }
  }

  public async delete(id) {
    const { data } = await this.httpClient.delete(`/admin/api/tags/${id}`)
    return { flash: data.flash }
  }
}

export default AdminTagService

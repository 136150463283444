import arrayMove from 'array-move'
import ClassNames from 'classnames'
import { IPost, IPostImage, IWindow } from 'core/interfaces'
import { cloneDeep } from 'lodash'
import * as React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { postImageService } from '../../../../core/services'
import * as constants from '../../../../static/constants'
import { DropZoneUploader, Spinner } from '../../../atoms'

interface IWindowEx extends IWindow {
  addEventListener: any
  removeEventListener: any
}

declare var window: IWindowEx

interface IProps {
  post: IPost
  images: IPostImage[]
  updateImages(images: IPostImage[]): void
}

const useMousePosition = () => {
  const [position, setPosition] = React.useState({ x: 0, y: 0 })

  React.useEffect(() => {
    const setFromEvent = e => setPosition({ x: e.clientX, y: e.clientY })
    window.addEventListener('mousemove', setFromEvent)

    return () => {
      window.removeEventListener('mousemove', setFromEvent)
    }
  }, [])

  return position
}

const PostImages: React.FC<IProps> = props => {
  const [loading, setLoading] = React.useState(false)
  const [images, setImages] = React.useState<IPostImage[]>(props.post.post_images)
  const position = useMousePosition()

  const deletePostImage = React.useCallback(
    async id => {
      const nextImages = images.filter(image => image.id !== id)
      const { flash } = await postImageService.delete(id)
      setImages(nextImages)
      props.updateImages(nextImages)
      window.flashMessages.addMessage({ text: flash.message, type: 'success' })
    },
    [images]
  )

  const updateImage = React.useCallback(
    async (id, values) => {
      const { flash } = await postImageService.update(id, values)
      window.flashMessages.addMessage({ text: flash.message, type: 'success' })

      const nextImages = images.map(updatedImage =>
        updatedImage.id === id
          ? {
              ...updatedImage,
              ...values,
            }
          : { ...updatedImage }
      )
      setImages(nextImages)
    },
    [images]
  )

  let uploadedImages = []
  const uploadImage = React.useCallback(
    async image => {
      const fileReader = new FileReader()
      setLoading(true)
      const { postImage, flash } = await postImageService.create(props.post, { file: image })
      setLoading(false)
      window.flashMessages.addMessage({ text: flash.message, type: 'success' })

      fileReader.onloadend = () => {
        const uploadedImage = {
          id: postImage.id,
          image_url: postImage.image_url,
          description: '',
        }

        uploadedImages.push(uploadedImage)
        const nextImages = [...images, ...uploadedImages]

        setImages(nextImages)
        props.updateImages(nextImages)
      }
      fileReader.readAsDataURL(image)
    },
    [images]
  )

  const updateImages = React.useCallback(
    async addedImages => {
      uploadedImages = []
      let response = null
      for (const image of addedImages) {
        response = await uploadImage(image)
      }

      if (response) {
        window.flashMessages.addMessage({ text: response.data.flash.message, type: 'success' })
      }
    },
    [images]
  )

  const [hoverIndex, setHoverIndex] = React.useState(null)
  const [dragStartIndex, setDragStartIndex] = React.useState(null)

  const dragstart = React.useCallback(
    (event, index) => {
      setDragStartIndex(index)
      setHoverIndex(index)
    },
    [dragStartIndex]
  )

  const onDrag = React.useCallback(
    index => {
      if (dragStartIndex === null) {
        return
      }
      setHoverIndex(index)
    },
    [dragStartIndex]
  )

  const dragend = React.useCallback(async () => {
    if (dragStartIndex === null) {
      return
    }
    const dragImageId = images[dragStartIndex].id
    const dropPosition = hoverIndex + 1
    const nextImages: IPostImage[] = arrayMove(cloneDeep(images), dragStartIndex, hoverIndex)

    setImages(nextImages)
    setHoverIndex(null)
    setDragStartIndex(null)

    const { flash } = await postImageService.update(dragImageId, { position: dropPosition })
    window.flashMessages.addMessage({ text: flash.message, type: 'success' })
    window.removeEventListener('mouseup', dragend)
  }, [images, dragStartIndex, hoverIndex])

  React.useEffect(() => {
    window.addEventListener('mouseup', dragend)
    return () => window.removeEventListener('mouseup', dragend)
  }, [images, dragStartIndex, hoverIndex])

  return (
    <ImageUpload className={ClassNames({ hasNoImage: images.length === 0 })}>
      <Images
        images={images}
        dragStartIndex={dragStartIndex}
        hoverIndex={hoverIndex}
        dragstart={dragstart}
        onDrag={onDrag}
        deletePostImage={deletePostImage}
        updateImage={updateImage}
      />
      {hoverIndex !== null && (
        <DragItem
          positionX={position.x}
          positionY={position.y}
          dragImage={images[dragStartIndex]}
        />
      )}
      {loading ? (
        <Loading>
          <Spinner />
        </Loading>
      ) : (
        <DropZoneUploader onDrop={updateImages} />
      )}
    </ImageUpload>
  )
}

interface IDragItemProps {
  positionX: number
  positionY: number
  dragImage: IPostImage
}

const DragItem = React.memo<IDragItemProps>(({ positionX, positionY, dragImage }) => (
  <div className="ImageUpload_Cell dragging" style={{ top: positionY, left: positionX }}>
    <div className="ImageUpload_Image">
      <img src={dragImage.image_url} draggable={false} />
    </div>
  </div>
))

interface IImageParams {
  description: string
}

interface IImagesProps {
  images: any
  dragStartIndex: number
  hoverIndex: number
  dragstart(event: any, index: number): void
  onDrag(index: number): void
  deletePostImage(id: number): void
  updateImage(id: number, params: IImageParams): void
}

const Images = React.memo<IImagesProps>(
  ({ images, dragStartIndex, hoverIndex, dragstart, onDrag, deletePostImage, updateImage }) => {
    const clonedImages = cloneDeep(images)
    const onDescriptionUpdate = (image, description) => {
      const isEmpty = image.description === null && description === ''
      const isSame = image.description === description
      if (isEmpty || isSame) {
        return
      }
      updateImage(image.id, { description })
    }

    if (hoverIndex !== null) {
      clonedImages.splice(dragStartIndex, 1)
      clonedImages.splice(hoverIndex, 0, null)
    }

    return clonedImages.map((image, index) =>
      image === null ? (
        <DropIndicator key="dropIndicator" />
      ) : (
        <div
          key={index}
          className={ClassNames('ImageUpload_Cell', { main: index === 0 })}
          onMouseDown={event => dragstart(event, index)}
          onMouseEnter={() => onDrag(index)}
        >
          <div className="ImageUpload_Image">
            {/* {index === 0 && <span>メイン画像</span>} */}
            <img src={image.image_url} draggable={false} />
            <div className="ImageUpload_Footer" onMouseDown={event => event.stopPropagation()}>
              <span onClick={() => deletePostImage(image.id)}>{I18n.t('generic.delete')}</span>
            </div>
          </div>
          <TextareaAutosize
            placeholder={I18n.t('generic.caption')}
            defaultValue={image.description}
            onMouseDown={event => event.stopPropagation()}
            onBlur={(event: any) => onDescriptionUpdate(image, event.target.value)}
          />
        </div>
      )
    )
  }
)

const Loading = styled.div`
  width: calc(33.33% - 32px);
  height: 240px;
  margin: 24px 16px 0;
  border-radius: 3px;
  background-color: #f4f4f4;
`

const DropIndicator = styled.div`
  width: calc(33.33% - 32px);
  margin: 24px 16px 0;
  border: dashed 2px var(${constants.THEME_COLOR_VARIABLE_NAME});
  border-radius: 3px;
  background-color: rgba(74, 144, 226, 0.2);

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    width: calc(50% - 32px);
  }

  @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
    width: calc(100% - 32px);
  }
`

const ImageUpload = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  overflow: hidden;
  -webkit-user-select: none;

  .DropZoneUploader {
    width: calc(33.33% - 32px);
    margin: 24px 16px 0;
  }

  &.hasNoImage {
    .DropZoneUploader {
      width: 100%;
    }
  }

  .DropZoneUploader {
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: calc(50% - 32px);
    }

    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      width: calc(100% - 32px);
    }
  }

  .ImageUpload_Cell {
    width: calc(33.33% - 32px);
    margin: 24px 16px 0;

    &.dragging {
      width: 200px;
      height: 200px;
      opacity: 0.8;
      position: fixed;
      background-color: #fff;

      .ImageUpload_Image {
        height: inherit;
      }
    }

    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: calc(50% - 32px);
    }

    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      width: calc(100% - 32px);
    }

    // &.main {
    //   width: calc(100% - 32px);
    // }

    > textarea {
      padding: 0;
      height: 32px;
      resize: none;
      border: none;
      line-height: 1.5;

      &:focus {
        border: none;
      }
    }
  }

  .ImageUpload_Image {
    position: relative;
    width: 100%;
    height: 240px;
    border-radius: 4px;
    background-color: #f4f4f4;
    overflow: hidden;

    > span {
      position: absolute;
      top: 8px;
      right: 8px;
      padding: 4px 8px;
      color: #fff;
      background-color: var(${constants.THEME_COLOR_VARIABLE_NAME});
      border-radius: 3px;
      font-size: 12px;
    }

    > img {
      width: 100%;
      height: inherit;
      object-fit: contain;
    }

    &:hover {
      .ImageUpload_Footer {
        transform: translateY(0);
      }
    }
  }

  .ImageUpload_Footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: 32px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0 12px;
    color: #fff;
    font-size: 14px;
    transform: translateY(100%);
    transition: transform 0.2s ease;

    > span {
      cursor: pointer;

      &:hover {
        opacity: 0.75;
      }
    }
  }
`

export default PostImages

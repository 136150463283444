export const APP_NAME = 'JET HAIR SALON'
export const APP_COLOR = '#4A90E2'
export const APP_COLOR_LIGHT = '#68a5ed'
export const SECONDARY_COLOR = '#FF9B4E'
export const BLACK_COLOR = '#344351'
export const TEXT_COLOR = '#212529'
export const BORDER_COLOR = '#EAEDEF'
export const DANGER_COLOR = '#F66'
export const SUCCESS_COLOR = '#29C30E'
export const THEME_COLOR_VARIABLE_NAME = '--theme-color'
export const HEADER_HEIGHT = 68
export const TOPPAGE_HEADER_HEIGHT = 68
export const BREAKPOINT_DESKTOP = 1088
export const BREAKPOINT_TABLET_LARGE = 960
export const BREAKPOINT_TABLET_SMALL = 720
export const BREAKPOINT_TABLET_MOBILE = 600
export const MAX_TEXT_COUNT = 100
export const MAX_TEXTAREA_COUNT = 5000
export const DEFAULT_PRICE = 5000
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_DISPLAY_FORMAT = 'YYYY/M/D'
export const DAYS_OF_THE_WEEK = ['日', '月', '火', '水', '木', '金', '土']

export const COLORS = {
  White: '#ffffff',
  Primary: '#13B7B7',
  PrimaryDark: '#009999',
  PrimaryLighter: '#4DB8B8',
  PrimaryLightest: '#99D6D6', // primary dark * 40%
  Point1: '#F3CC42',
  Point2: '#F97777',
  Text: '#555555',
  Background: '#F5F5F5',
  Border: '#E0E0E0',
  BorderLight: '#F3F3F3',
  Danger: '#F66',
  Success: '#29C30E',
  Placeholder: '#bbbbbb',
  ButtonFaintWhite: 'rgba(255,255,255, .2)',
  ButtonDisabledBg: '#eeeeee',
  Dummy: '#FF0000',
  Link: '#4a90e2',
  SaturdayBg: '#F1FFFF',
  Saturday: '#4a90e2',
  SundayBg: '#FFF1F6',
  Sunday: '#F97777',
  TableGray: '#F8F8F8',
} as const

import { Pagination } from 'components/atoms'
import AdminTable from 'components/atoms/AdminTable'
import I18n from 'core/i18n'
import { IContact, IJsonResponse } from 'core/interfaces'
import * as React from 'react'
import { utilService } from '../../../../core/services'
import AdminLayoutIndex from '../_layouts/index'

interface IProps {
  contacts: {
    contacts: IJsonResponse
  }
}

const Index: React.FC<IProps> = props => {
  const { data: initialContacts, pagination } = utilService.getDataFromJson(props.contacts.contacts)
  const [contacts, setContacts] = React.useState<IContact[]>(initialContacts)

  const onChangePageHandler = React.useCallback(page => {
    location.href = `${location.pathname}?page=${page}`
  }, [])

  return (
    <AdminLayoutIndex
      model="contact"
      main={
        <AdminTable
          body={
            <>
              <h1 className="EditTable_Title">
                <span>{I18n.t('nav.admins.contact')}</span>
              </h1>
              <div className="EditTable_Scroller">
                <div className="EditTable_Sizer">
                  <table>
                    <thead>
                      <tr>
                        <th>{I18n.t('generic.id')}</th>
                        <th>{I18n.t('generic.name')}</th>
                        <th>{I18n.t('generic.email')}</th>
                        <th>{I18n.t('contact.subject')}</th>
                        <th>{I18n.t('contact.body')}</th>
                        <th>{I18n.t('contact.status')}</th>
                        <th>{I18n.t('contact.body')}</th>
                        <th>{I18n.t('contact.user_id')}</th>
                        <th>{I18n.t('generic.created_at')}</th>
                        <th>{I18n.t('generic.updated_at')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts.map(contact => (
                        <tr key={contact.id}>
                          <td>{contact.id}</td>
                          <td>{contact.name}</td>
                          <td>{contact.email}</td>
                          <td>{contact.subject}</td>
                          <td>{contact.body}</td>
                          <td>{contact.status}</td>
                          <td>{contact.note}</td>
                          <td>{contact.user_id}</td>
                          <td>{contact.created_at}</td>
                          <td>{contact.updated_at}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {pagination && (
                <Pagination
                  onChangePageHandler={onChangePageHandler}
                  currentPage={pagination.current_page}
                  prevPage={pagination.prev_page}
                  nextPage={pagination.next_page}
                  totalPages={pagination.total_pages}
                  totalCount={pagination.total_count}
                />
              )}
            </>
          }
        />
      }
    />
  )
}

export default Index

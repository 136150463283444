import Loading from 'components/atoms/Loading'
import * as React from 'react'
import { IPost, IReservation, IUser, IWindow } from '../../../../core/interfaces'
import { postService } from '../../../../core/services'
import injectGoogleMaps from '../../../../utils/injectGoogleMaps'
import Complete from './Complete'
import Confirm from './Confirm'
import PostDetail from './PostDetail'
import TimeTables from './TimeTables'

declare var window: IWindow

interface IProps {
  current_user: IUser
  google: any
  params: {
    id: string
  }
  operation: boolean
}

const PostShow: React.FC<IProps> = props => {
  const [post, setPost] = React.useState<IPost>(null)
  const [reservation, setReservation] = React.useState(null)
  const [createdReservation, setCreatedReservation] = React.useState<IReservation>(null)
  const [currentPage, setCurrentPage] = React.useState(null)

  React.useEffect(() => {
    const f = async () => {
      const result = await postService.show(props.params.id)
      setPost(result.post)
      window.document.title = result.post.name
    }
    if (!post) {
      f()
    }
  }, [])

  return (
    <>
      {!post && <Loading />}
      {post && !reservation && (
        <PostDetail
          {...props}
          post={post}
          currentPage={currentPage}
          reservation={reservation}
          setReservation={setReservation}
          setCurrentPage={setCurrentPage}
          operation={props.operation}
        />
      )}
      {post && reservation && currentPage === 'option' && (
        <TimeTables
          post={post}
          reservation={reservation}
          setReservation={setReservation}
          setCurrentPage={setCurrentPage}
        />
      )}
      {post && reservation && currentPage === 'confirm' && (
        <Confirm
          post={post}
          reservation={reservation}
          setCreatedReservation={setCreatedReservation}
          setCurrentPage={setCurrentPage}
        />
      )}
      {post && reservation && currentPage === 'complete' && (
        <Complete post={post} createdReservation={createdReservation} />
      )}
    </>
  )
}

export default injectGoogleMaps(PostShow)

import ClassNames from 'classnames'
import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'

import Accordion from '../atoms/Accordion'

interface IProps {
  active: string
}

const TopAccordion: React.FC<IProps> = props => {
  return (
    <div>
      <Accordion
        title="詳しく見る"
        content='
<section class="section description" id="description">
  <div class="section-inner">
    <h1 class="section-title">
      訪問美容室でおしゃれを<span class="text-block">もっと楽しく！</span>
    </h1>
    <p class="section-sub-title">
      高齢や要介護、育児などで外出困難な方への<span class="text-block">訪問理美容サービスです</span>
    </p>
    <p class="section-paragraph">
      以下のような理由により外出が困難な方が<br />美容室の訪問サービスをご利用になれます。
    </p>
    <ul class="section-list">
      <li><span>1.</span> 本人が高齢または介護状態</li>
      <li><span>2.</span> 本人がお子様を育児中</li>
      <li><span>3.</span> 本人が病気・怪我の療養中(または入院中)</li>
      <li><span>4.</span> 本人が家族等の介護をしている</li>
    </ul>
  </div>
</section>'
      />
    </div>
  )
}

export default TopAccordion

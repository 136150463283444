import * as React from 'react'
import styled from 'styled-components'

interface IProps {
  youtubeUrls: []
}

const Youtube: React.FC<IProps> = props => {
  return (
    <YoutubeWrapper>
      <div className="embed-youtube">
        <ul className="youtube-gallery">
          {props.youtubeUrls &&
            props.youtubeUrls.map((url, index) => (
              <li className="youtube-gallery-item" key={'yt-' + index}>
                <iframe
                  width="560"
                  height="315"
                  src={url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                />
              </li>
            ))}
        </ul>
      </div>
    </YoutubeWrapper>
  )
}

const YoutubeWrapper = styled.div`
  .embed-youtube {
    max-width: 1182px;
    overflow-y: scroll;
  }
  .youtube-gallery {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow-x: auto;
  }
  .youtube-gallery-item {
    display: inline;
    margin-right: 10px;
  }
`
export default Youtube

import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../../core/i18n'
import { IOption, IPost, IWindow } from '../../../../../core/interfaces'
import { postOptionService, postService } from '../../../../../core/services'
import * as constants from '../../../../../static/constants'
import { Button, Spinner } from '../../../../atoms'
import OptionEditor from './OptionEditor'

declare var window: IWindow

interface IProps {
  post: IPost
}

const OptionIndex: React.FC<IProps> = props => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [option, setOption] = React.useState<IOption>(null)
  const [options, setOptions] = React.useState<IOption[]>(null)

  React.useEffect(() => {
    const f = async () => {
      const result = await postService.getOptions(props.post.id)
      setOptions(result.options)
      setIsLoading(false)
    }
    if (options === null) {
      f()
    }
  }, [options])

  const handleNew = () => {
    const newOption: IOption = {
      id: null,
      name: '',
      price: null,
      description: '',
      position: null,
    }
    setOption(newOption)
  }

  const handleEdit = async id => {
    const { option: selectedOption } = await postService.getOption(props.post.id, id)
    setOption(selectedOption)
  }

  const createOptionTemplate = async () => {
    const { flash } = await postOptionService.createTemplate(props.post.id)
    const { options: createdOptions } = await postService.getOptions(props.post.id)
    setOptions(createdOptions)
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
  }

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && option && (
        <OptionEditor
          post={props.post}
          option={option}
          handleSetOption={setOption}
          handleSetOptions={setOptions}
        />
      )}
      {!isLoading && !option && (
        <>
          <h2 className="Editor_Title">
            {I18n.t('post.option_title')}
            <Button small={true} handleClick={handleNew} right={true}>
              {options && options.length > 0
                ? I18n.t('post.menu.create_new')
                : '新しいメニューを独自に作成する'}
            </Button>
          </h2>
          <div className="EditTable_Scroller">
            {options && options.length > 0 ? (
              <List>
                <table>
                  <thead>
                    <tr>
                      <th>{I18n.t('generic.name')}</th>
                      <th>{I18n.t('generic.price')}</th>
                      <th>{I18n.t('generic.description')}</th>
                      <th>{I18n.t('generic.position')}</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {options.map(record => (
                      <tr key={record.id}>
                        <td>{record.name}</td>
                        <td>{record.price}</td>
                        <td>{record.description}</td>
                        <td>{record.position}</td>
                        <td className="right">
                          <Button>
                            <a
                              onClick={() => {
                                handleEdit(record.id)
                              }}
                            >
                              {I18n.t('generic.show_detail')}
                            </a>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </List>
            ) : (
              <Template>
                <div className="title">カット以外のメニューも追加できます</div>
                <div className="body">
                  メニューのひな形をボタンひとつで自動生成することが出来ます
                  <br />
                  <br />
                  {(props.post.category.name === '美容室' ||
                    props.post.category.name === '理容室') && (
                    <p>
                      「基本情報」からカテゴリーの「美容室・理容室」を選ぶ時に生成されるひな形はこのようなものです（イメージです）:
                      <img src="/images/menu_template.png" alt="menu_template" />
                      <br />
                    </p>
                  )}
                  {props.post.category.name === 'メイクアップアーティスト' && (
                    <p>
                      「基本情報」からカテゴリーの「メイクアップアーティスト」「を選ぶ時に生成されるひな形はこのようなものです（イメージです）：
                      <img src="/images/menu_template_makeup.png" alt="menu_template" />
                      <br />
                    </p>
                  )}
                  {props.post.category.name === 'ネイリスト' && (
                    <p>
                      「基本情報」からカテゴリーの「ネイリスト」を選ぶ時に生成されるひな形はこのようなものです（イメージです）：
                      <img src="/images/menu_template_nailist.png" alt="menu_template" />
                    </p>
                  )}
                </div>

                <Button small={true} handleClick={createOptionTemplate}>
                  メニューのひな形をつかう
                </Button>
              </Template>
            )}
          </div>
        </>
      )}
    </>
  )
}

const List = styled.div`
  table {
    width: 100%;
  }

  th,
  td {
    padding: 8px 12px;
    max-width: 320px;

    > a {
      color: var(${constants.THEME_COLOR_VARIABLE_NAME});
      text-decoration: underline;
    }
  }

  th {
    cursoor: pointer;

    &:hover {
      background-color: #f6f8f9;
    }
  }

  tbody {
    > tr {
      border-top: solid 1px ${constants.BORDER_COLOR};
    }
  }
  .right {
    text-align: right;
  }
`

const Template = styled.div`
  text-align: center;
  .title {
    padding: 10px;
    font-size: 30px;
    font-weight: bold;
  }

  .body {
    margin: 30px 100px;
  }

  img {
    width: 100%;
    height: auto;
    margin: 10px 0;
  }
  button {
    margin-bottom: 20px;
  }
`

export default OptionIndex

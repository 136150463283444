import ClassNames from 'classnames'
import { IUser } from 'core/interfaces'
import * as React from 'react'
import ClickOutside from 'react-click-outside'
import styled, { createGlobalStyle } from 'styled-components'
import I18n from '../../core/i18n'
import { messageService, notificationService, userService } from '../../core/services'
import * as constants from '../../static/constants'
import { IsDesktop, IsMobileOrTablet } from '../../utils/responsive'
import Button from '../atoms/Button'
import LocaleSelect from '../atoms/LocaleSelect'

interface IWindow {
  localePath: { [key: string]: string }
  location: any
}
declare var window: IWindow

interface IProps {
  isSignedIn: boolean
  user: IUser
  lang?: { [key: string]: string }
  top?: boolean
}

const MenuList = (
  props: IProps & {
    desktop?: boolean
    mobile?: boolean
  }
) => (
  <ul
    className={ClassNames('Profile_Menu', {
      desktop: props.desktop,
      mobile: props.mobile,
    })}
  >
    {props.isSignedIn ? (
      <>
        <IsMobileOrTablet>
          <li>
            <a href="/notifications/messages">{I18n.t('nav.header.messages')}</a>
          </li>
          <li>
            <a href="/notifications">{I18n.t('nav.header.notifications')}</a>
          </li>
        </IsMobileOrTablet>

        {!props.user.is_owner && (
          // {(props.user.is_admin || !props.user.is_owner) && (
          <>
            <li>
              <a href="/posts/search">{I18n.t('nav.header.search_post')}</a>
            </li>
            <li>
              <a href="/posts/favorites">{I18n.t('nav.header.favorites')}</a>
            </li>
          </>
        )}
        {props.user.is_owner && (
          // {(props.user.is_admin || props.user.is_owner) && (
          <>
            <li>
              <a href="/posts/new">{I18n.t('nav.header.create_post')}</a>
            </li>
            <li>
              <a href="/posts">{I18n.t('nav.header.posts')}</a>
            </li>
          </>
        )}

        <li>
          <a href="/reservations">{I18n.t('nav.header.reservations')}</a>
        </li>

        <li className="separate">
          <a href="/contacts/new">{I18n.t('nav.header.contacts')}</a>
        </li>

        <li>
          <a href="/settings/account">{I18n.t('nav.header.settings')}</a>
        </li>

        <li>
          <a rel="nofollow" data-method="delete" href="/users/sign_out">
            {I18n.t('nav.header.logout')}
          </a>
        </li>
        {props.user.is_admin && (
          <li>
            <a href="/admin/posts">{I18n.t('nav.header.admin')}</a>
          </li>
        )}
      </>
    ) : (
      <>
        <li>
          <a href="/users/sign_in">{I18n.t('nav.header.login')}</a>
        </li>
        <li>
          <a href="/users/sign_up">{I18n.t('nav.header.guest_signup')}</a>
        </li>
        <li>
          <a href="/owners/sign_up">{I18n.t('nav.header.owner_signup')}</a>
        </li>
        {props.lang && (
          <li className="separate">
            <LocaleSelect />
          </li>
        )}
      </>
    )}
  </ul>
)

const Avatar = props => (
  <div className="Avator">
    <span className="Avator_Name">{props.user.username}</span>
    <div className="Avator_Icon">
      <img src={props.user.avatar_url} />
    </div>
  </div>
)

const ENTER_KEYCODE = 13

interface INotification {
  id: number
  title: string
  created_at: string
  read_at: string
  url: string
}

const Header: React.FC<IProps> = props => {
  const [isBurgerOpen, setIsBurgerOpen] = React.useState(false)
  const [notifications, setNotifications] = React.useState<INotification[]>([])
  const [over10, setOver10] = React.useState(false)
  const [showNotifications, setShowNotifications] = React.useState(false)
  const [messages, setMessages] = React.useState<INotification[]>([])
  const [searchBoxIsOpen, setSearchBoxIsOpen] = React.useState(false)
  const { isSignedIn, user } = props

  const [showMessages, setShowMessages] = React.useState(false)

  const urlParams = new URLSearchParams(window.location.search)
  const defaultKeywords = urlParams.get('area') || ''

  const onSearchHandler = async event => {
    const keywords = event.target.value
    location.href = `/posts/search?area=${keywords}`
  }

  const onKeyDownHandler = event => {
    if (event.keyCode && event.keyCode === ENTER_KEYCODE) {
      onSearchHandler(event)
    }
  }

  const getMessages = async () => {
    const { messages: messagesData } = await messageService.get({ unread: true })
    setMessages(messagesData)
    setShowMessages(true)
  }

  const getNotifications = async () => {
    const { notifications: notificationsData } = await notificationService.get({ header: true })
    setNotifications(notificationsData)
    if (notificationsData.length > 9) {
      setOver10(true)
    }
    setShowNotifications(true)
  }

  const readNotification = async (id, url) => {
    await userService.readNotification(id)
    location.href = url
  }

  React.useEffect(() => {
    if (props.lang) {
      window.localePath = { ...props.lang }
    }
  }, [])

  return (
    <HeaderWrapper className={ClassNames('Header', { top: props.top })}>
      <GlobalStyle top={props.top} />
      <div className="Header_Inner">
        <div className="Header_Left">
          <div className="Header_Left__Icon" />
          <a className="Logo" href="/">
            <img src="/images/custom/logo_black.svg" alt="" />
          </a>
          {!props.top && (
            <IsDesktop>
              <Search className="Search">
                <input
                  name="search"
                  type="text"
                  placeholder={I18n.t('placeholder.post.search')}
                  defaultValue={defaultKeywords}
                  onBlur={onSearchHandler}
                  onKeyDown={onKeyDownHandler}
                />
                <i className="material-icons">search</i>
              </Search>
            </IsDesktop>
          )}
        </div>
        {isSignedIn ? (
          <div className="Header_Items">
            <IsDesktop>
              <div className="Header_Notifications">
                <ClickOutside onClickOutside={() => setShowMessages(false)}>
                  <div className="Header_NotificationsIcon" onClick={getMessages}>
                    <i className="material-icons">mail</i>
                    {props.user.unread_messages_count > 0 && (
                      <span>{props.user.unread_messages_count}</span>
                    )}
                  </div>
                  {showMessages && (
                    <ul className="Header_NotificationsList">
                      {messages.length > 0 ? (
                        <>
                          {messages.map(message => (
                            <a
                              key={message.id}
                              onClick={() => {
                                readNotification(message.id, message.url)
                              }}
                            >
                              <li>
                                <span style={{ fontWeight: message.read_at ? 'normal' : 'bold' }}>
                                  {message.title}
                                  <br />
                                  <span>
                                    {message.created_at}
                                    {I18n.t('generic.ago')}
                                  </span>
                                </span>
                              </li>
                            </a>
                          ))}
                        </>
                      ) : (
                        <a>
                          <li>
                            <span>{I18n.t('notifications.no_messages')}</span>
                          </li>
                        </a>
                      )}
                    </ul>
                  )}
                </ClickOutside>
              </div>

              <div className="Header_Notifications">
                <ClickOutside onClickOutside={() => setShowNotifications(false)}>
                  <div className="Header_NotificationsIcon" onClick={getNotifications}>
                    <i className="material-icons">notifications</i>
                    {props.user.unread_notifications_count > 0 && (
                      <span>{props.user.unread_notifications_count}</span>
                    )}
                  </div>
                  {showNotifications && (
                    <ul className="Header_NotificationsList">
                      {notifications.length > 0 ? (
                        <>
                          {over10 && (
                            <a href="/notifications">
                              <li>
                                <span>{I18n.t('generic.show_all')}</span>
                              </li>
                            </a>
                          )}
                          {notifications.map(notification => (
                            <a
                              key={notification.id}
                              onClick={() => {
                                readNotification(notification.id, notification.url)
                              }}
                            >
                              <li>
                                <span
                                  style={{ fontWeight: notification.read_at ? 'normal' : 'bold' }}
                                >
                                  {notification.title}
                                  <br />
                                  <span>
                                    {notification.created_at}
                                    {I18n.t('generic.ago')}
                                  </span>
                                </span>
                              </li>
                            </a>
                          ))}
                        </>
                      ) : (
                        <a>
                          <li>
                            <span>{I18n.t('notifications.no_notifications')}</span>
                          </li>
                        </a>
                      )}
                    </ul>
                  )}
                </ClickOutside>
              </div>
              <Profile>
                <Avatar user={user} />
                <MenuList user={props.user} desktop={true} isSignedIn={isSignedIn} />
              </Profile>
            </IsDesktop>
            <IsMobileOrTablet>
              {!props.top && (
                <div className="SearchToggle" onClick={() => setSearchBoxIsOpen(true)}>
                  <i className="material-icons">search</i>
                </div>
              )}
              <Burger
                className={ClassNames({ isOpen: isBurgerOpen })}
                onClick={() => setIsBurgerOpen(!isBurgerOpen)}
              >
                <span />
                <span />
                <span />
              </Burger>
              {isBurgerOpen && (
                <MobileMenu className="MobileMenu" top={props.top}>
                  <Avatar user={user} />
                  <MenuList user={props.user} mobile={true} isSignedIn={isSignedIn} />
                </MobileMenu>
              )}
              {searchBoxIsOpen && (
                <SearchBox className="SearchBox">
                  <div className="SearchBox_Cancel" onClick={() => setSearchBoxIsOpen(false)}>
                    <span>{I18n.t('generic.cancel')}</span>
                  </div>
                  <div className="SearchBox_Box">
                    <input
                      name="search"
                      type="text"
                      placeholder={I18n.t('placeholder.post.search')}
                      defaultValue={defaultKeywords}
                      onBlur={onSearchHandler}
                      onKeyDown={onKeyDownHandler}
                    />
                    <i className="material-icons search">search</i>
                  </div>
                </SearchBox>
              )}
            </IsMobileOrTablet>
          </div>
        ) : (
          <>
            <IsDesktop>
              <Buttons>
                {props.lang && <LocaleSelect />}
                <Button primary={true} small={true}>
                  <a href="/users/sign_in">{I18n.t('nav.header.login')}</a>
                </Button>
                <Button small={true} textColor="#fff" backgroundColor={'purple'}>
                  <a href="/users/sign_up">{I18n.t('nav.header.guest_signup')}</a>
                </Button>
                <Button small={true} textColor="#fff" backgroundColor={'#30f030'}>
                  <a href="/owners/sign_up">{I18n.t('nav.header.owner_signup')}</a>
                </Button>
              </Buttons>
            </IsDesktop>
            <IsMobileOrTablet>
              <Burger
                className={ClassNames({ isOpen: isBurgerOpen })}
                onClick={() => setIsBurgerOpen(!isBurgerOpen)}
              >
                <span />
                <span />
                <span />
              </Burger>
              {isBurgerOpen && (
                <MobileMenu className="MobileMenu" top={props.top}>
                  {props.isSignedIn && <Avatar user={user} />}
                  <MenuList user={null} mobile={true} isSignedIn={isSignedIn} />
                </MobileMenu>
              )}
            </IsMobileOrTablet>
          </>
        )}
      </div>
    </HeaderWrapper>
  )
}

const SearchBox = styled.div`
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  padding: 0 10px;
  align-items: center;
  z-index: 9100;

  .SearchBox_Cancel {
    width: 80px;
    font-size: 14px;
    font-weight: bold;
    color: #666;
    cursor: pointer;
  }

  .SearchBox_Box {
    flex: 1;
    position: relative;

    input {
      display: block;
      width: 100%;
      padding: 12px 32px 12px 12px;
      border: solid 1px ${constants.BORDER_COLOR};
      border-radius: 4px;
      font-size: 15px;
      transition: border 0.2s ease;
      outline: none;
      background: none;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;

      &:focus {
        border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
      }
    }

    i.search {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 8px;
      margin: auto;
      width: 24px;
      height: 24px;
    }
  }
`

const Search = styled.div`
  position: relative;
  width: 280px;
  margin-right: 24px;

  > i {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 8px;
    margin: auto;
    width: 24px;
    height: 24px;
  }

  > input {
    display: block;
    width: 100%;
    padding: 8px 12px 8px 36px;
    border: solid 1px ${constants.BORDER_COLOR};
    border-radius: 4px;
    font-size: 15px;
    transition: border 0.2s ease;
    outline: none;
    background: none;

    &:focus {
      border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
    }
  }
`

const GlobalStyle = createGlobalStyle`
  main {
    padding-top: 0;
    margin-top: ${constants.HEADER_HEIGHT}px;
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .LocaleSelect {
    margin-right: 16px;
  }
`

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${constants.HEADER_HEIGHT}px;
  padding: 0px 16px;
  border-bottom: solid 1px ${constants.BORDER_COLOR};
  background-color: #fff;
  z-index: 5000;

  .SearchToggle {
    position: absolute;
    top: 0;
    bottom: 0;
    right: ${constants.HEADER_HEIGHT}px;
    width: ${constants.HEADER_HEIGHT}px;
    height: ${constants.HEADER_HEIGHT}px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    i {
      width: 24px;
      height: 24px;
    }
  }

  &.top {
    .Header_Inner {
      display: flex;
      max-width: 1182px;
      margin: 0 auto;
    }
  }

  .Header_Inner {
    display: flex;
    height: ${constants.HEADER_HEIGHT}px;
    justify-content: space-between;
    align-items: center;
  }

  .Header_Left {
    display: flex;
    align-items: center;

    .Search {
      margin-left: 24px;
    }

    &__Icon {
      background: url('/images/logo-icon.png');
      height: 50px;
      width: 50px;
      background-size: cover;
    }
  }

  .Logo {
    > img {
      display: block;
      width: inherit;
      height: auto;
    }
  }

  .Header_Items {
    display: flex;
    align-items: center;
  }

  .Header_Notifications {
    position: relative;
    width: 24px;
    height: 24px;
  }

  .Header_Notifications + .Header_Notifications {
    margin-left: 16px;
  }

  .Header_NotificationsList {
    position: absolute;
    bottom: 0;
    right: -8px;
    width: 320px;
    padding: 12px 0;
    background-color: #fff;
    box-shadow: 0 5px 20px 0 rgba(21, 27, 38, 0.08);
    transform: translateY(100%);
    border-radius: 4px;

    > a {
      color: ${constants.TEXT_COLOR};
      font-size: 14px;

      span {
        font-size: 12px;
        color: #999;
      }
      li {
        padding: 4px 16px;
        cursor: pointer;

        &:hover {
          background-color: #f7f7f7;
        }
      }
    }
  }

  .Header_NotificationsIcon {
    cursor: pointer;

    > i {
      display: block;
      width: inherit;
      height: inherit;
      color: ${constants.BORDER_COLOR};
    }

    > span {
      display: block;
      text-align: center;
      height: 17px;
      padding: 0 5px;
      color: #fff;
      border-radius: 9px;
      background-color: ${constants.DANGER_COLOR};
      font-size: 12px;
      font-weight: bold;
      line-height: 17px;
      position: absolute;
      right: -6px;
      top: -6px;
    }
  }
`

const MobileMenu = styled.div`
  position: absolute;
  top: ${constants.HEADER_HEIGHT}px;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
  color: ${constants.TEXT_COLOR};
  @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
    position: fixed;
    z-index: 8000;
  }

  .Avator {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 16px 0;
  }

  .Avator_Name {
    display: block;
    margin-left: 12px;
  }

  .Avator_Icon {
    width: 40px;
    height: 40px;
    background-color: #f4f4f4;
    border-radius: 50%;
    overflow: hidden;

    > img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }

  > ul {
    padding: 16px 0;

    li {
      padding: 6px 16px;
      font-size: 15px;

      &:hover {
        background-color: #f4f4f4;
      }

      a {
        color: ${constants.TEXT_COLOR};
        display: block;
      }
    }
  }

  > ul.Profile_Menu.mobile {
    > li.separate {
      border-top: solid 1px #eee;
      margin-top: 8px;
      padding-top: 16px;
    }

    .LocaleSelect {
      > select {
        font-size: 15px;
      }
    }
  }
`

const Burger = styled.div`
  position: relative;
  margin-right: -16px;
  width: ${constants.HEADER_HEIGHT}px;
  height: ${constants.HEADER_HEIGHT}px;

  @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 9000;
  }

  > span {
    display: block;
    height: 1px;
    width: 16px;
    left: calc(50% - 8px);
    position: absolute;
    background-color: currentColor;
    transform-origin: center;
    transition-duration: 86ms;
    transition-property: background-color, opacity, transform;
    transition-timing-function: ease-out;

    &:nth-child(1) {
      top: calc(50% - 6px);
    }

    &:nth-child(2) {
      top: calc(50% - 1px);
    }

    &:nth-child(3) {
      top: calc(50% + 4px);
    }
  }

  &.isOpen {
    > span {
      &:nth-child(1) {
        transform: translateY(5px) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translateY(-5px) rotate(-45deg);
      }
    }
  }
`

const Profile = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${constants.HEADER_HEIGHT}px;
  margin-left: 32px;
  cursor: pointer;

  .Avator {
    display: flex;
    align-items: center;
  }

  .Avator_Icon {
    width: 40px;
    height: 40px;
    margin: 2px;
    background: #fff;
    border-radius: 50%;
    margin-left: 10px;
    overflow: hidden;

    > img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }

  &:hover {
    .Profile_Menu.desktop {
      display: block;
    }
  }

  .Profile_Menu.desktop {
    display: none;
    position: absolute;
    top: ${constants.HEADER_HEIGHT}px;
    right: 0;
    width: 180px;
    padding: 12px 0;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 5px 20px 0 rgba(21, 27, 38, 0.08);
    font-size: 14px;

    .LocaleSelect {
      padding: 4px 12px;

      > select {
        font-size: 15px;
      }
    }

    > li a {
      display: block;
      padding: 4px 12px;
      cursor: pointer;
      color: ${constants.TEXT_COLOR};
      text-decoration: none;

      &:hover {
        color: var(${constants.THEME_COLOR_VARIABLE_NAME});
      }
    }

    > li.disabled a {
      color: #ccc;
      cursor: default;

      &:hover {
        color: #ccc;
      }
    }

    > li.separate a {
      border-top: solid 1px ${constants.BORDER_COLOR};
      margin-top: 4px;
      padding-top: 6px;
    }
  }
`

export default Header

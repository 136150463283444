import cookie from 'cookie'
import { get } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'

interface IWindow {
  localePath: { [key: string]: string }
  location: any
}
declare var window: IWindow

export default class LocaleSelect extends React.PureComponent<{}, {}> {
  public lang: any
  private cookie: any
  private browserRanguage: any

  constructor(props) {
    super(props)
    this.cookie = cookie.parse(document.cookie)
    this.browserRanguage =
      (get(window, 'navigator.languages') && get(window, 'navigator.languages[0]')) ||
      get(window, 'navigator.language') ||
      get(window, 'navigator.userLanguage') ||
      get(window, 'navigator.browserLanguage')
  }

  public changeLocale = locale => {
    if (window.localePath) {
      window.location.href = window.localePath[locale]
    }
  }

  public render() {
    return (
      <LocaleSelectWrapper className="LocaleSelect">
        <select
          defaultValue={`${this.cookie.locale || this.browserRanguage}_path`}
          onChange={event => {
            this.changeLocale(event.target.value)
          }}
        >
          <option value="ja_path">日本語</option>
          <option value="en_path">English</option>
        </select>
        <span />
      </LocaleSelectWrapper>
    )
  }
}

const LocaleSelectWrapper = styled.div`
  > select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    border: 0;
    margin: 0;
    padding: 0;
    background: none transparent;
    vertical-align: middle;
    color: inherit;
    box-sizing: content-box;
    outline: none;

    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
  }

  > span {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid #4f5959;
  }
`

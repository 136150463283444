import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { ICategory, IPost, ITag } from '../../../../core/interfaces'
import * as constants from '../../../../static/constants'
import { Button, InputText, InputTextArea, Select, Spinner } from '../../../atoms'
import { Form } from '../../../molecules'

const BASIC_INFO_FIELDS = {
  category_id: 'category_id',
  name: 'name',
  description: 'description',
  price: 'price',
  tag_ids: 'tag_ids',
  note: 'note',
  is_salon_private: 'is_salon_private',
  phone: 'phone',

  // 以下カスタム項目
  open_at: 'open_at',
  close_at: 'close_at',
  time_period: 'time_period',
  is_included_shampoo: 'is_included_shampoo',
  is_included_travel_fee: 'is_included_travel_fee',
  travel_range: 'travel_range',
  easy_reservation: 'easy_reservation',
}

interface IErrors {
  [key: string]: string | null
}

interface IProps {
  post: IPost
  categories: ICategory[]
  tags: ITag[]
  setPost: any
  isProcessing: boolean
  isNew?: boolean
  handleFormSubmit(initialValues: any, values: any): void
}

const BasicInfo: React.FC<IProps> = props => {
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [errors, setErrors] = React.useState<IErrors>({})
  const [is_private, setPrivate] = React.useState(props.post ? props.post.is_salon_private : false)

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }

  const formatSelectOptions = options => {
    return options
      ? options.map((option: { id: number; name: string }) => ({
          value: option.id,
          label: option.name,
        }))
      : []
  }

  const timeTable = () => {
    return [
      { label: '', value: '' },
      { label: '05:00', value: '05:00' },
      { label: '06:00', value: '06:00' },
      { label: '07:00', value: '07:00' },
      { label: '08:00', value: '08:00' },
      { label: '09:00', value: '09:00' },
      { label: '10:00', value: '10:00' },
      { label: '11:00', value: '11:00' },
      { label: '12:00', value: '12:00' },
      { label: '13:00', value: '13:00' },
      { label: '14:00', value: '14:00' },
      { label: '15:00', value: '15:00' },
      { label: '16:00', value: '16:00' },
      { label: '17:00', value: '17:00' },
      { label: '18:00', value: '18:00' },
      { label: '19:00', value: '19:00' },
      { label: '20:00', value: '20:00' },
      { label: '21:00', value: '21:00' },
      { label: '22:00', value: '22:00' },
      { label: '23:00', value: '23:00' },
    ]
  }

  return props.isNew || props.post ? (
    <>
      <DetailWrapper>
        {isSubmitEnabled && !props.isProcessing ? (
          <div className="submit-wrapper">
            <input
              type="submit"
              form="form"
              className="submitForm"
              value={
                props.post
                  ? props.post.id
                    ? I18n.t('generic.update')
                    : I18n.t('generic.create')
                  : I18n.t('generic.create')
              }
            />
          </div>
        ) : (
          <div className="submit-wrapper">
            <input
              type="submit"
              form="form"
              className="submitForm inactive"
              value={
                props.post
                  ? props.post.id
                    ? I18n.t('generic.update')
                    : I18n.t('generic.create')
                  : I18n.t('generic.create')
              }
              disabled={true}
            />
            {!isSubmitEnabled && <p className="message">フィールドを更新してください</p>}
          </div>
        )}
        <div className="Editor_Panel Editor_Panel-details">
          <h2 className="Editor_Title">{I18n.t('post.input_basic_info')}</h2>
          {props.tags && props.categories && (
            <Form
              fields={BASIC_INFO_FIELDS}
              handleUpdateForm={handleUpdateForm}
              handleSubmit={props.handleFormSubmit}
            >
              <ul>
                <FormItem>
                  <Select
                    required={true}
                    name="category_id"
                    label={I18n.t('generic.category')}
                    options={formatSelectOptions(props.categories)}
                    defaultValue={props.post ? String(props.post.category_id) : ''}
                    error={errors.category_id}
                  />
                </FormItem>
                <FormItem>
                  <InputText
                    required={true}
                    name="name"
                    label={I18n.t('post.post_name')}
                    placeholder={I18n.t('post.post_name')}
                    defaultValue={props.post ? props.post.name : ''}
                    error={errors.name}
                  />
                </FormItem>
                <FormItem>
                  <div className="isSalonPrivate">
                    <p className="isSalonPrivateTitle">
                      {I18n.t('post.is_salon_private')}
                      <span className="required">必須</span>
                    </p>
                    <Radio>
                      <label>
                        <input
                          type="radio"
                          name="is_salon_private"
                          value="true"
                          required={true}
                          checked={props.post ? is_private === true : is_private}
                          onChange={() => {
                            setPrivate(true)
                          }}
                        />
                        <span>{I18n.t('post.salon_is_private')}</span>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="is_salon_private"
                          value="false"
                          required={true}
                          checked={props.post ? is_private === false : !is_private}
                          onChange={() => {
                            setPrivate(false)
                          }}
                        />
                        <span>{I18n.t('post.salon_is_public')}</span>
                      </label>
                    </Radio>
                  </div>
                </FormItem>

                <FormItem>
                  <InputText
                    required={false}
                    name="phone"
                    label={I18n.t('post.phone')}
                    placeholder={I18n.t('post.phone')}
                    defaultValue={props.post ? props.post.phone : ''}
                    error={errors.phone}
                  />
                </FormItem>

                <FormItem>
                  <InputTextArea
                    required={true}
                    name="description"
                    label={I18n.t('post.post_description')}
                    placeholder={I18n.t('post.post_description')}
                    defaultValue={props.post ? props.post.description : ''}
                    error={errors.description}
                  />
                </FormItem>
                <FormItem>
                  <InputText
                    required={true}
                    name="price"
                    label="基本料金"
                    placeholder={I18n.t('placeholder.post_price')}
                    defaultValue={props.post ? String(props.post.price) : ''}
                    error={errors.price}
                  />
                  <span className="Price_Explanation">(美容師の場合はカット価格)(円)</span>
                </FormItem>
                <FormItem>
                  <CheckBox>
                    <p className="CheckBox_Title">{I18n.t('post.is_included_shampoo')}</p>
                    <div className="CheckBox_Main">
                      <label>
                        <input
                          type="checkbox"
                          name="is_included_shampoo"
                          defaultChecked={props.post ? props.post.is_included_shampoo : false}
                        />
                        <span className="CheckBox_Icon">
                          <i className="material-icons filled">check_box</i>
                          <i className="material-icons outline">check_box_outline_blank</i>
                        </span>
                        <span>{I18n.t('post.is_included')}</span>
                      </label>
                    </div>
                  </CheckBox>
                </FormItem>
                <FormItem>
                  <CheckBox>
                    <p className="CheckBox_Title">{I18n.t('post.is_included_travel_fee')}</p>
                    <div className="CheckBox_Main">
                      <label>
                        <input
                          type="checkbox"
                          name="is_included_travel_fee"
                          defaultChecked={props.post ? props.post.is_included_travel_fee : false}
                        />
                        <span className="CheckBox_Icon">
                          <i className="material-icons filled">check_box</i>
                          <i className="material-icons outline">check_box_outline_blank</i>
                        </span>
                        <span>{I18n.t('post.is_included')}</span>
                      </label>
                    </div>
                  </CheckBox>
                </FormItem>
                <FormItem>
                  <CheckBox>
                    <p className="CheckBox_Title">{I18n.t('generic.tag')}</p>
                    <div className="CheckBox_Main">
                      {props.tags &&
                        props.tags.map(tag => (
                          <label key={tag.id}>
                            <input
                              type="checkbox"
                              name="tag_ids"
                              value={String(tag.id)}
                              defaultChecked={
                                props.post &&
                                props.post.tags.map(tagItem => tagItem.id).includes(tag.id)
                              }
                            />
                            <span className="CheckBox_Icon">
                              <i className="material-icons filled">check_box</i>
                              <i className="material-icons outline">check_box_outline_blank</i>
                            </span>
                            <span>{tag.name}</span>
                          </label>
                        ))}
                    </div>
                  </CheckBox>
                </FormItem>
                <FormItem>
                  <Select
                    required={false}
                    name="open_at"
                    label={I18n.t('post.open_at')}
                    options={timeTable()}
                    defaultValue={props.post ? String(props.post.open_at) : ''}
                    error={errors.open_at}
                  />
                </FormItem>
                <FormItem>
                  <Select
                    required={false}
                    name="close_at"
                    label={I18n.t('post.close_at')}
                    options={timeTable()}
                    defaultValue={props.post ? String(props.post.close_at) : ''}
                    error={errors.close_at}
                  />
                </FormItem>
                <FormItem>
                  <Select
                    required={false}
                    name="time_period"
                    label={I18n.t('post.time_period')}
                    options={[
                      { label: '15分', value: '15' },
                      { label: '30分', value: '30' },
                    ]}
                    defaultValue={props.post ? String(props.post.time_period) : ''}
                    error={errors.time_period}
                  />
                </FormItem>
                <FormItem>
                  <InputText
                    required={false}
                    name="travel_range"
                    label={I18n.t('post.travel_range')}
                    placeholder={I18n.t('placeholder.post.travel_range')}
                    defaultValue={props.post ? props.post.travel_range : ''}
                    error={errors.travel_range}
                  />
                </FormItem>
                <FormItem>
                  <InputText
                    required={false}
                    name="easy_reservation"
                    label={I18n.t('post.easy_reservation')}
                    placeholder={I18n.t('placeholder.post.easy_reservation')}
                    defaultValue={props.post ? props.post.easy_reservation : ''}
                    error={errors.easy_reservation}
                  />
                </FormItem>
                <FormItem>
                  <InputTextArea
                    required={false}
                    name="note"
                    label={I18n.t('generic.note')}
                    placeholder={I18n.t('generic.note')}
                    defaultValue={props.post ? props.post.note : ''}
                    error={errors.note}
                  />
                </FormItem>
              </ul>
              <div className="Editor_Footer Editor_Footer_SubmitButton">
                <Button primary={true} disabled={!isSubmitEnabled || props.isProcessing}>
                  {props.post
                    ? props.post.id
                      ? I18n.t('generic.update')
                      : I18n.t('generic.create')
                    : I18n.t('generic.create')}
                </Button>
              </div>
            </Form>
          )}
        </div>
      </DetailWrapper>
    </>
  ) : (
    <Spinner />
  )
}

const DetailWrapper = styled.div`
  display: flex;

  flex-direction: column;

  .submit-wrapper {
    position: sticky;
    top: 0;
    z-index: 20;
    background: #f6f8f9;
    padding: 20px 0;
    text-align: center;
  }

  & > div.submit-wrapper > input {
    height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    line-height: 1;
    font-size: 14px;
    background-color: var(--theme-color);
    color: #fff;
    border: none;
    width: 200px;
    cursor: ponter;
    margin-left: 15px;
    position: initial;

    &.inactive {
      color: #000;
      background-color: #f3f3f3;
      border: 1px solid #ccc;
      cursor: not-allowed;
    }
  }

  & > div.submit-wrapper > .message {
    position: relative;
    top: 6px;
    left: 10px;
    font-size: 12px;
    display: none;
  }

  .submitForm:hover + .message {
    display: block !important;
    z-index: 10;
  }

  .Editor_Panel-details {
    margin: 10px auto !important;
    width: 100%;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      margin: 10px 0 !important;
    }
  }

  .Editor_Footer_SubmitButton > button {
    width: 200px;
  }
`

const FormItem = styled.div`
  & + & {
    margin-top: 16px;
  }

  .FormItem_Title {
    font-size: 15px;
    font-weight: bold;
  }
  .isSalonPrivate {
    display: flex;
    flex-direction: row;
    align-items: center;
    .isSalonPrivateTitle {
      width: 240px;
      font-weight: bold;
      line-height: 18px;
    }
    .required {
      float: right;
      margin-right: 20px;
      display: inline-block;
      border-radius: 3px;
      color: #fff;
      background-color: #f66;
      vertical-align: middle;
      font-size: 11px;
      font-weight: normal;
      padding: 4px 6px;
      margin-left: 8px;
      line-height: 12px;
    }
  }
  .Price_Explanation {
    font-size: 11px;
  }
`
const CheckBox = styled.div`
  display: flex;

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }

  .CheckBox_Title {
    width: 240px;
    margin-top: 8px;
    font-size: 15px;
    font-weight: bold;
    line-height: 1;

    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  .CheckBox_Main {
    flex: 1;

    > label {
      display: block;
      position: relative;
      cursor: pointer;

      &:hover {
        opacity: 0.75;
      }

      input {
        opacity: 0;
        height: 0;
        width: 0;
      }

      .CheckBox_Icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 24px;
        height: 24px;

        i {
          position: absolute;
          top: 0;
          left: 0;

          &.filled {
            display: none;
            color: var(${constants.THEME_COLOR_VARIABLE_NAME});
          }

          &.outline {
            display: block;
            color: ${constants.BORDER_COLOR};
          }
        }
      }

      input:checked + .CheckBox_Icon {
        i {
          &.filled {
            display: block;
          }

          &.outline {
            display: none;
          }
        }
      }

      span {
        font-size: 14px;
        padding-left: 28px;
      }
    }

    > label + label {
      margin-top: 16px;
    }
  }
`

const Radio = styled.div`
  margin: 0;

  > h3 {
    display: block;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 14px;
  }

  > label {
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: 32px;

    &:hover {
      opacity: 0.75;
    }

    input {
      opacity: 0;
      height: 0;
      width: 0;
    }

    input + span {
      font-size: 14px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }

      &::before {
        width: 18px;
        height: 18px;
        border: solid 1px ${constants.BORDER_COLOR};
        border-radius: 50%;
      }
    }

    input:checked + span {
      &::before {
        width: 18px;
        height: 18px;
        border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
        border-radius: 50%;
      }

      &::after {
        left: 4px;
        width: 10px;
        height: 10px;
        background-color: var(${constants.THEME_COLOR_VARIABLE_NAME});
        border-radius: 50%;
      }
    }
  }

  > label + label {
    margin-top: 16px;
  }
`
export default BasicInfo

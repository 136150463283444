import * as React from 'react'
import styled from 'styled-components'
import { topService } from '../../../core/services'
import * as constants from '../../../static/constants'
import { Score } from '../../atoms'

const TopReview: React.FC<{}> = () => {
  const [reviews, seReviews] = React.useState(null)

  React.useEffect(() => {
    const f = async () => {
      const { reviews: initialReviews } = await topService.topRate()
      seReviews(initialReviews)
    }
    if (!reviews) {
      f()
    }
  }, [reviews])

  return (
    <Wrapper>
      {reviews &&
        reviews.map((review, index) => (
          <div className="Review" key={'review-' + index}>
            <div className="Review__Left">
              <img src={review.reviewer.avatar_url} alt="avatar" />
            </div>
            <div className="Review__Right">
              <div className="Review__Right__star">
                <Score score={review.rating} />
              </div>
              <div className="Review__Right__comment">{review.body}</div>
              <div className="Review__Right__shop">
                <a href={'/posts/' + review.reservation.post.slug}>
                  {review.reservation.post.name}
                </a>
              </div>
            </div>
          </div>
        ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    display: block;
  }
  .Review {
    display: flex;
    padding: 20px;
    width: 50%;

    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      width: 100%;
    }

    &__Left {
      width: 200px;
      text-align: center;

      img {
        height: 150px;
        width: 150px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &__Right {
      position: relative;
      padding-left: 20px;
      &__comment {
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 20px;
      }
      &__shop {
        position: absolute;
        bottom: 0px;
        font-size: 12px;
        font-weight: bold;
        a {
          color: gray;
        }
      }
    }
  }
`
export default TopReview

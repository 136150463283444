import httpClient from '../httpClient'
import JsonApiSerializer from '../JsonApiSerializer'
import CompanyService from './CompanyService'
import ContactService from './ContactService'
import MessageService from './MessageService'
import NotificationService from './NotificationService'
import PostImageService from './PostImageService'
import PostOptionService from './PostOptionService'
import PostService from './PostService'
import ReservationService from './ReservationService'
import SettingsService from './SettingsService'
import TopService from './TopService'
import UsageService from './UsageService'
import UserService from './UserService'
import UtilService from './UtilService'

export const jsonApiSerializer = new JsonApiSerializer()
export const companyService = new CompanyService(httpClient, jsonApiSerializer)
export const contactService = new ContactService(httpClient, jsonApiSerializer)
export const postService = new PostService(httpClient, jsonApiSerializer)
export const postImageService = new PostImageService(httpClient, jsonApiSerializer)
export const postOptionService = new PostOptionService(httpClient, jsonApiSerializer)
export const userService = new UserService(httpClient, jsonApiSerializer)
export const reservationService = new ReservationService(httpClient, jsonApiSerializer)
export const settingsService = new SettingsService(httpClient, jsonApiSerializer)
export const usageService = new UsageService(httpClient, jsonApiSerializer)
export const utilService = new UtilService(httpClient, jsonApiSerializer)
export const messageService = new MessageService(httpClient, jsonApiSerializer)
export const notificationService = new NotificationService(httpClient, jsonApiSerializer)
export const topService = new TopService(httpClient, jsonApiSerializer)

import { IJsonResponse } from 'core/interfaces'
import * as React from 'react'
import I18n from '../../../../core/i18n'
import { categoryService, utilService } from '../../../../core/services/admin'
import * as Hooks from '../../../../utils/hooks'
import { InputText } from '../../../atoms'
import AdminLayoutEdit from '../_layouts/edit'

interface IProps {
  category: IJsonResponse
}

const FIELDS = {
  id: 'id',
  name: 'name',
  position: 'position',
}

const title = I18n.t('admin.edit', {
  model: I18n.t('category', { scope: 'activerecord.models' }),
})

const AdminCategoryEdit: React.FC<IProps> = props => {
  Hooks.SetDocumentTitle('カテゴリ管理')
  const { data: category } = utilService.getDataFromJson(props.category)
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    await categoryService.update(values)
    location.href = '/admin/categories'
  }, [])

  return (
    <AdminLayoutEdit
      model="category"
      title={title}
      fields={FIELDS}
      indexLink="/admin/categories"
      formItems={
        <>
          <InputText
            readonly={true}
            name="id"
            defaultValue={category.id}
            label={I18n.t('generic.id')}
          />
          <InputText
            required={true}
            name="name"
            defaultValue={category.name}
            label={I18n.t('generic.name')}
          />
          <InputText
            required={true}
            name="position"
            defaultValue={category.position}
            label={I18n.t('generic.position')}
          />
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

export default AdminCategoryEdit

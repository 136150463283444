import { AxiosInstance } from 'axios'
import { IJsonResponse } from 'core/interfaces'
import IJsonApiSerializer from 'core/interfaces/IJsonApiSerializer'

class UtilService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(json: IJsonResponse) {
    const data = this.serializer.parseResourceData(json, json.data)
    const pagination = { ...json.meta }

    return { data, pagination }
  }

  public async getFilterMasters() {
    const { data } = await this.httpClient.get(`/api/util/filter_masters`)
    const categories = this.serializer.parseResourceData(data.categoris, data.categoris.data)
    const tags = this.serializer.parseResourceData(data.tags, data.tags.data)

    return { categories, tags }
  }
}

export default UtilService

import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { IJsonResponse, IWindow } from '../../../../core/interfaces'
import { settingsService, userService } from '../../../../core/services'
import * as constants from '../../../../static/constants'
import { InputText, Panel } from '../../../atoms'
import { Form } from '../../../molecules'
import SettingsCommonLayout from '../_layouts/common'

declare var window: IWindow

interface IProps {
  user: IJsonResponse
  active_path: string
}

interface IState {
  user: any
  social_profiles: string[]
  email: string
  is_owner: boolean
  showModal: boolean
  isSubmitEnabled: boolean
  errors: { [key: string]: null | string }
}

export default class AccountEdit extends React.Component<IProps, IState> {
  private mailFormRef: any
  private passwordFormRef: any

  constructor(props) {
    super(props)

    const { user: initialUser } = userService.getUserFromJson(props.user)

    this.state = {
      user: initialUser,
      social_profiles: [...initialUser.social_profiles],
      email: initialUser.email,
      is_owner: initialUser.is_owner,
      showModal: false,
      isSubmitEnabled: true,
      errors: {},
    }
    this.mailFormRef = React.createRef()
    this.passwordFormRef = React.createRef()
  }

  public handleUpdateForm = (errors, isSubmitEnabled) => {
    this.setState({ errors, isSubmitEnabled })
  }

  public updateEmail = async values => {
    await userService.updateUser(values)
    this.showEmailConfirm(values.email)
  }

  public updatePassword = async values => {
    const { flash } = await userService.updatePassword({ user: values })
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
    window.globalModal.closeModal()
  }

  public showMailForm = () => {
    const FIELDS_MAIL = { email: 'email' }
    const MailForm: React.FC<{}> = props => {
      const [errors, setErrors] = React.useState({ email: null })

      return (
        <Form
          ref={this.mailFormRef}
          fields={FIELDS_MAIL}
          handleUpdateForm={(updatedErrors, isSubmitEnabled) => setErrors(updatedErrors)}
          handleSubmit={(initialValues, values) => this.updateEmail(values)}
        >
          <InputText
            required={true}
            name="email"
            label={I18n.t('settings.accounts.new_email')}
            error={errors.email}
            defaultValue={this.state.email}
          />
          <p className="description">{I18n.t('settings.accounts.confirmation_email_will_be')}</p>
        </Form>
      )
    }

    window.globalModal.showModal({
      title: I18n.t('settings.accounts.change_email_address'),
      body: <MailForm />,
      closeText: I18n.t('generic.cancel'),
      submitText: I18n.t('generic.update'),
      handleSubmit: () => this.mailFormRef.current.handleFormSubmit(),
    })
  }

  public showEmailConfirm = email => {
    const EmailConfirm: React.FC<{}> = props => (
      <>
        <p>'{email}'</p>
        <p className="description">{I18n.t('settings.accounts.confirm_email')}</p>
      </>
    )

    window.globalModal.showModal({
      title: I18n.t('settings.accounts.change_email_address'),
      body: <EmailConfirm />,
      closeText: I18n.t('generic.close'),
      submitText: '',
      handleSubmit: null,
    })
  }

  public showPasswordForm = () => {
    const FIELDS_PASSWORD = {
      password: 'password',
      current_password: 'current_password',
    }
    const PasswordForm: React.FC<{}> = props => {
      const [errors, setErrors] = React.useState({
        password: null,
        current_password: null,
      })

      return (
        <Form
          ref={this.passwordFormRef}
          fields={FIELDS_PASSWORD}
          handleUpdateForm={(updatedErrors, isSubmitEnabled) => setErrors(updatedErrors)}
          handleSubmit={(initialValues, values) => this.updatePassword(values)}
        >
          <FormItem>
            <InputText
              required={true}
              password={true}
              name="current_password"
              label={I18n.t('settings.accounts.current_password')}
              defaultValue=""
              error={errors.password}
            />
          </FormItem>
          <FormItem>
            <InputText
              required={true}
              password={true}
              name="password"
              label={I18n.t('settings.accounts.new_password')}
              defaultValue=""
              error={errors.current_password}
            />
          </FormItem>
        </Form>
      )
    }

    window.globalModal.showModal({
      title: I18n.t('settings.accounts.change_password'),
      body: <PasswordForm />,
      closeText: I18n.t('generic.cancel'),
      submitText: I18n.t('generic.update'),
      handleSubmit: () => this.passwordFormRef.current.handleFormSubmit(),
    })
  }

  public deleteSocialProfile = async social_profile => {
    const { flash } = await settingsService.deleteSocialProfile(social_profile.id)
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
    this.setState({
      social_profiles: this.state.social_profiles.filter(item => item !== social_profile),
    })
  }

  public render() {
    return (
      <SettingsCommonLayout
        activePath={this.props.active_path}
        is_owner={this.state.is_owner}
        main={
          <Main>
            <Panel title={I18n.t('settings.accounts.title')}>
              <Field>
                <label>{I18n.t('generic.email')}</label>
                <div>
                  <p>{this.state.email}</p>
                  <a onClick={this.showMailForm}>{I18n.t('generic.change')}</a>
                </div>
              </Field>
              <Field>
                <label>{I18n.t('generic.password')}</label>
                <div>
                  <p>******</p>
                  <a onClick={this.showPasswordForm}>{I18n.t('generic.change')}</a>
                </div>
              </Field>

              {this.state.social_profiles.length > 0 && (
                <ul className="RemoveSocialLinks">
                  {this.state.social_profiles.map(social_profile => (
                    <li
                      key={social_profile}
                      onClick={() => this.deleteSocialProfile(social_profile)}
                    >
                      {I18n.t('settings.accounts.delete_provider', social_profile)}
                    </li>
                  ))}
                </ul>
              )}

              {/* <div className="has-text-centered u-margin--t40">
                <a onClick={() => this.setState({ showModal: true })}>{I18n.t('settings.account.form.delete')}</a>
              </div> */}

              {this.state.showModal && (
                <Modal className="c-modal__window">
                  <div className="c-modal__inner has-text-centered">
                    <p className="is-size-6 has-text-weight-bold u-margin--b20">アカウントの削除</p>
                    <p className="">
                      アカウント情報・履歴などの情報をすべて削除します。
                      <br />
                      <span className="has-text-weight-bold">復元の対応はできません</span>
                      ので、ご確認の上削除をお願いいたします。
                    </p>
                    <div className="u-margin--t10">
                      <ul className="c-modal__list">
                        <li>
                          <span className="has-text-weight-bold">
                            本人環境をご利用の上、決済の利用契約を停止させていただきます。
                          </span>
                        </li>
                        <li>
                          稼働中の定期課金がある場合は、アカウント削除前に停止処理を行ってください。
                        </li>
                        <li>
                          <span className="has-text-weight-bold">
                            未入金の売り上げ金額が振込手数料である250円以上残っている場合、翌月の末日にご登録済みの口座へ入金させていただきます。
                          </span>
                        </li>
                      </ul>
                    </div>
                    <form>
                      <div className="u-margin--b20 u-margin--t20">
                        <label className="checkbox">
                          <input type="checkbox" />
                          上記の内容すべてに同意します
                        </label>
                      </div>
                      <div className="control u-width--300 u-margin--auto">
                        <button
                          type="submit"
                          className="button is-danger is-fullwidth"
                          value="アカウントを削除"
                        />
                      </div>
                    </form>
                  </div>
                  <a
                    className="c-modal__close"
                    onClick={() => this.setState({ showModal: false })}
                  />
                </Modal>
              )}
            </Panel>
          </Main>
        }
      />
    )
  }
}

const FormItem = styled.div`
  & + & {
    margin-top: 16px;
  }
`

const Field = styled.div`
  display: flex;
  & + & {
    margin-top: 24px;
    padding-top: 20px;
    border-top: solid 1px ${constants.BORDER_COLOR};
  }
  > label {
    width: 200px;
    font-weight: bold;
  }
  > div {
    flex: 1;
  }
  a {
    color: var(${constants.THEME_COLOR_VARIABLE_NAME});
    display: inline-block;
    margin-top: 4px;
    font-size: 14px;
    cursor: pointer;
  }

  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    display: block;
  }
`

const Main = styled.div`
  flex: 1;
  padding: 16px;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #f6f8f9;
`

const Modal = styled.div`
  display: block;
  opacity: 1;
  transform: none;
  z-index: 6000;
`

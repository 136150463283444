import * as React from 'react'
import I18n from '../../../../core/i18n'
import { IJsonResponse, IPost, IWindow } from '../../../../core/interfaces'
import { postService, utilService } from '../../../../core/services/admin'
import * as Hooks from '../../../../utils/hooks'
import { EditTable } from '../../../organisms'
import AdminLayoutIndex from '../_layouts/index'

declare var window: IWindow

interface IProps {
  posts: {
    posts: IJsonResponse
  }
}

const COLUMNS = [
  {
    name: 'ID',
    field: 'id',
    required: false,
    readOnly: true,
  },
  {
    name: '店名',
    field: 'name',
    required: true,
  },
  {
    name: '電話番号',
    field: 'phone',
    required: true,
  },
  {
    name: 'オーナー',
    field: 'owner',
    required: true,
  },
  {
    name: '状態',
    field: 'aasm_state',
    required: false,
  },
  {
    name: '説明',
    field: 'description',
    required: true,
  },
  {
    name: '緯度',
    field: 'latitude',
    required: false,
  },
  {
    name: '経度',
    field: 'longitude',
    required: false,
  },
  {
    name: '値段',
    field: 'price',
    required: true,
  },
  {
    name: '公開日',
    field: 'published_at',
    required: false,
  },
]

const Index: React.FC<IProps> = props => {
  Hooks.SetDocumentTitle(I18n.t('nav.admins.post'))
  const { data: initialPosts } = utilService.getDataFromJson(props.posts.posts)
  const [posts, setPosts] = React.useState<IPost[]>(initialPosts)
  const handleDelete = React.useCallback(
    async id => {
      if (confirm('本当に削除しますか？')) {
        const response = await postService.delete(id)
        const flash = response.data.flash
        window.flashMessages.addMessage({ text: flash.message, type: flash.type })
        setPosts(posts.filter(post => post.id !== id))
      }
    },
    [posts]
  )

  return (
    <AdminLayoutIndex
      model="post"
      main={
        <EditTable
          title={I18n.t('nav.admins.post')}
          editable={true}
          columns={COLUMNS}
          records={posts}
          pagination={props.posts.posts.meta}
          handleDelete={handleDelete}
        />
      }
    />
  )
}

export default Index

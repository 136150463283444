import { AxiosInstance } from 'axios'
import IJsonApiSerializer from 'core/interfaces/IJsonApiSerializer'

class AdminIdentificationService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getIdentificationsFromJson(data) {
    const identifications = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { identifications, pagination }
  }

  public async approve(id) {
    const { data } = await this.httpClient.put(`/admin/api/identifications/${id}/approve`)
    const identification = this.serializer.parseResourceData(
      data.identification,
      data.identification.data
    )

    return { identification, flash: data.flash }
  }

  public async decline(id) {
    const { data } = await this.httpClient.put(`/admin/api/identifications/${id}/decline`)
    const identification = this.serializer.parseResourceData(
      data.identification,
      data.identification.data
    )

    return { identification, flash: data.flash }
  }
}

export default AdminIdentificationService

import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { IIdentification, IJsonResponse, IWindow } from '../../../../core/interfaces'
import { settingsService, utilService } from '../../../../core/services'
import * as constants from '../../../../static/constants'
import { Button, DropZoneUploader, Panel } from '../../../atoms'
import { SettingsSidebar } from '../../../organisms'

declare var window: IWindow

interface IProps {
  identification: IJsonResponse
}

interface IPreviewImage {
  file: any
  url: string
}

const Identification: React.FC<IProps> = props => {
  // console.log('settings/identification props', props)
  const { data: initialIdentification } = utilService.getDataFromJson(props.identification)
  const [identification] = React.useState<IIdentification>(initialIdentification)
  // console.log('identification', identification)
  const [previewImages, setPreviewImage] = React.useState<IPreviewImage[]>([])
  const [requested, setRequested] = React.useState(false)
  const onDropHandler = addedImages => {
    addedImages.forEach(addedImage => {
      const fileReader = new FileReader()

      fileReader.onloadend = (event: any) => {
        const loadedImage = {
          file: addedImage,
          url: event.target.result,
        }

        setPreviewImage(currentPreviewImages => [...currentPreviewImages, loadedImage])
      }
      fileReader.readAsDataURL(addedImage)
    })
  }

  const submitIdentification = React.useCallback(async () => {
    let result
    for (const image of previewImages) {
      result = await settingsService.createIdentificationImage(image.file)
    }
    if (result) {
      setRequested(true)
      const { flash } = result
      window.flashMessages.addMessage({ text: flash.message, type: flash.type })
    }
  }, [previewImages])

  const deletePreviewImage = deleteIndex => {
    setPreviewImage(currentPreviewImages =>
      currentPreviewImages.filter((image, index) => index !== deleteIndex)
    )
  }

  const renderState = state => {
    return (
      <Field>
        <label>{I18n.t('generic.status')}</label>
        <div>{state}</div>
      </Field>
    )
  }

  const renderUploadField = () => {
    return requested ? (
      <div className="Identification_requested">{I18n.t('settings.images_has_sent')}</div>
    ) : (
      <div>
        <Field>
          <label>{I18n.t('generic.image')}</label>
          <div className="Identification_Preview">
            {previewImages.map((image, index) => (
              <div key={index} className="Identification_PreviewItem">
                <div className="Identification_PreviewImage">
                  <img src={image.url} alt="" />
                </div>
                <Button handleClick={() => deletePreviewImage(index)}>
                  {I18n.t('generic.delete')}
                </Button>
              </div>
            ))}
            <DropZoneUploader onDrop={onDropHandler} />
          </div>
        </Field>
        <div className="Identification_Footer">
          <Button
            primary={true}
            handleClick={submitIdentification}
            disabled={previewImages.length === 0}
          >
            {I18n.t('generic.send')}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <Wrapper>
      <SettingsSidebar active={'identification'} />
      <Main>
        <Panel title={I18n.t('settings.set_identification')}>
          {identification && identification.workflow_state
            ? renderState(identification.workflow_state)
            : renderUploadField()}
        </Panel>
      </Main>
    </Wrapper>
  )
}

const Field = styled.div`
  display: flex;

  & + & {
    margin-top: 24px;
    padding-top: 20px;
    border-top: solid 1px ${constants.BORDER_COLOR};
  }

  > label {
    width: 200px;
    font-weight: bold;
  }

  > div {
    flex: 1;
  }

  a {
    color: var(${constants.THEME_COLOR_VARIABLE_NAME});
    display: inline-block;
    margin-top: 4px;
    font-size: 14px;
  }
`

const Wrapper = styled.div`
  display: flex;
  margin: 0 auto;
  height: calc(100vh - ${constants.HEADER_HEIGHT}px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  .Identification_Preview {
    img {
      display: block;
      max-width: 400px;
    }

    .Button {
      margin-top: 12px;
    }
  }

  .Identification_PreviewItem {
    display: flex;
    align-items: center;
    padding: 12px 0;
    border-bottom: solid 1px ${constants.BORDER_COLOR};

    .Button {
      margin-left: 24px;
    }
  }

  .Identification_PreviewImage {
    width: 360px;
    height: 240px;
    background-color: #fafafa;

    > img {
      width: inherit;
      height: inherit;
      object-fit: contain;
    }
  }

  .DropZoneUploader {
    width: 100%;
    margin: 12px 0 0;
  }

  .Identification_Footer {
    margin-top: 24px;
  }

  .Identification_requested {
    text-align: center;
    padding: 16px 0;
  }
`

const Main = styled.div`
  flex: 1;
  padding: 16px;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #f6f8f9;
`

export default Identification

import { AxiosInstance } from 'axios'
import { IJsonResponse } from 'core/interfaces'
import { IJsonResource } from 'core/interfaces/IJson'
import IJsonApiSerializer from 'core/interfaces/IJsonApiSerializer'

class AdminReservationService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public parseRelationships(resources: IJsonResponse, resource: IJsonResource | IJsonResource[]) {
    return this.serializer.parseResourceData(resources, resource)
  }

  public getReservationFromJson(data) {
    const reservation = this.serializer.parseResourceData(data, data.data)

    return { reservation }
  }

  public getReservationsFromJson(data) {
    const reservations = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { reservations, pagination }
  }

  public async request(id) {
    const { data } = await this.httpClient.put(`/admin/api/reservations/${id}/requested`)
    const { flash } = data
    const reservation = this.serializer.parseResourceData(data.reservation, data.reservation.data)

    return { reservation, flash }
  }
}

export default AdminReservationService

import * as React from 'react'
import { CardElement } from 'react-stripe-elements'
import I18n from '../../../../core/i18n'
import { ICard, IWindow } from '../../../../core/interfaces'
import { settingsService } from '../../../../core/services'
import * as constants from '../../../../static/constants'
import { Button } from '../../../atoms'

declare var window: IWindow

interface IProps {
  stripe: any
  isNew: boolean
  setCard(card: ICard): void
  setEdit(edit: boolean): void
}

const StripeForm: React.FC<IProps> = props => {
  const [isProcessing, setIsProcessing] = React.useState(false)

  const getStripeToken = async () => {
    return await props.stripe.createToken()
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setIsProcessing(true)
    const { token } = await getStripeToken()

    if (token.id) {
      const { card, flash } = await settingsService.createCard(token.id)

      props.setCard(card)
      props.setEdit(false)
      setIsProcessing(false)
      window.flashMessages.addMessage({ text: flash.message, type: flash.type })
    }
  }

  const renderButtons = () => {
    return props.isNew ? (
      <Button primary={true}>{I18n.t('generic.registration')}</Button>
    ) : (
      <>
        <Button primary={true}>{I18n.t('generic.change')}</Button>
        <Button handleClick={() => props.setEdit(false)}>{I18n.t('generic.cancel')}</Button>
      </>
    )
  }

  const createOptions = () => {
    return {
      hidePostalCode: true,
      style: {
        base: {
          fontSize: '16px',
          color: constants.TEXT_COLOR,
          letterSpacing: '0.5px',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: constants.DANGER_COLOR,
        },
      },
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <CardElement {...createOptions()} />
        {isProcessing ? <div>{I18n.t('generic.connecting')}</div> : renderButtons()}
      </div>
    </form>
  )
}

export default StripeForm

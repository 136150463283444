import React from 'react'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import { Button } from '../../atoms'
import AdminLayoutIndex from './_layouts'

const Download: React.FC<{}> = () => {
  return (
    <AdminLayoutIndex
      model="download"
      heading={I18n.t('activerecord.models.sales')}
      main={
        <DLButton>
          <Button href="/admin/download/sales">ダウンロード</Button>
        </DLButton>
      }
    />
  )
}

const DLButton = styled.div`
  padding: 20px;
`

export default Download

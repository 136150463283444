import ClassNames from 'classnames'
import * as Color from 'color'
import { IPager, IPost } from 'core/interfaces'
import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import { postService } from '../../core/services'
import * as constants from '../../static/constants'
import Pagination from '../atoms/Pagination'
import Score from '../atoms/Score'
import Spinner from '../atoms/Spinner'

const urlParams = new URLSearchParams(window.location.search)

interface IPostListProps {
  loading?: boolean
  isSignedIn: boolean
  favorite: boolean
  posts: IPost[]
  pagination: IPager
  hasMap?: boolean
  noPostsTitle?: string
  handleOnMouseEnter?(markerId): void
  handleOnMouseLeave?(): void
  getPostsByPage?(page: number): void
  title(count: number): string
  toggleLike(postId: number, like: boolean): void
}

const PostList: React.FC<IPostListProps> = props => {
  const [showMap, setShowMap] = React.useState(true)

  const changeOrder = (column, direction) => {
    location.href = `${location.pathname}?sort=${column}&direction=${direction}`
  }

  const getCurrentSortColumn = () => {
    return urlParams.get('sort') || 'name'
  }

  const getCurrentSortDirection = () => {
    return urlParams.get('direction') || 'asc'
  }

  const toggleLike = async post => {
    if (!props.isSignedIn) {
      location.href = '/users/sign_in'
      return
    }

    const { like } = await postService.toggleLike(post)
    props.toggleLike(post.id, like.isUserLiked)
  }

  const handleOnMouseEnter = (id: number) => {
    if (typeof props.handleOnMouseEnter === 'function') {
      props.handleOnMouseEnter(id)
    }
  }

  const handleOnMouseLeave = () => {
    if (typeof props.handleOnMouseLeave === 'function') {
      props.handleOnMouseLeave()
    }
  }

  return (
    <PostListWrapper className={ClassNames('PostList', { showMap })}>
      <div className="PostList_Main">
        {props.loading ? (
          <Spinner />
        ) : (
          <>
            {props.favorite && (
              <h1 className="PostList_FavoriteHeader">{I18n.t('post.favorite_post_heading')}</h1>
            )}
            <div className="PostList_Header">
              <p className="PostList_Count">
                {!props.pagination || !props.pagination.total_count ? (
                  <span className="Wrapper_NoResult">
                    {props.noPostsTitle
                      ? props.noPostsTitle
                      : props.favorite
                      ? I18n.t('favorite.no_favorites')
                      : I18n.t('post.no_search_result')}
                  </span>
                ) : (
                  <span>{props.title(props.pagination.total_count)}</span>
                )}
              </p>
              {/* {props.posts.length > 0 && (
                <div className="PostList_Sort">
                  <span>Sort </span>
                  <OrderSelect>
                    <select
                      defaultValue={getCurrentSortColumn()}
                      onChange={event => changeOrder(event.target.value, getCurrentSortDirection())}
                    >
                      <option value="name">Name</option>
                      <option value="price">Price</option>
                    </select>
                    <span />
                  </OrderSelect>
                  <span onClick={() => changeOrder(getCurrentSortColumn(), 'asc')}>↓ Asc</span>
                  <span onClick={() => changeOrder(getCurrentSortColumn(), 'desc')}>↑ Desc</span>
                </div>
              )} */}
            </div>
            <ul>
              {props.posts &&
                props.posts.map((post, index) => (
                  <li
                    key={index}
                    className="PostListItem"
                    onMouseEnter={() => handleOnMouseEnter(post.id)}
                    onMouseLeave={handleOnMouseLeave}
                  >
                    <a href={`/posts/${post.slug}`} target="_blank">
                      <div className="PostListItem_Upper">
                        <div className="PostListItem_Image">
                          {post.post_image && post.post_image.image_url ? (
                            <img src={post.post_image.image_url} alt="" />
                          ) : (
                            <p className="PostListItem_NoImage">No Image</p>
                          )}
                        </div>
                        <div className="PostListItem_Info">
                          <div className="PostListItem_Header">
                            <div className="PostListItem_HeaderInfo">
                              {post.category && (
                                <div className="PostListItem_Category">{post.category.name}</div>
                              )}
                              <h2 className="PostListItem_Title">{post.name}</h2>
                              {post.tags && (
                                <div className="PostListItem_Tags">
                                  {post.tags.map((tag, tagIndex) => (
                                    <span key={tagIndex} className="Tag">
                                      <i className="material-icons">local_offer</i>
                                      {tag.name}
                                    </span>
                                  ))}
                                </div>
                              )}
                            </div>
                            <div
                              className="PostListItem_Like"
                              onClick={event => {
                                event.preventDefault()
                                toggleLike(post)
                              }}
                            >
                              {post.user_liked ? (
                                <i className="material-icons liked">favorite</i>
                              ) : (
                                <i className="material-icons">favorite_border</i>
                              )}
                            </div>
                          </div>
                          <div className="PostListItem_Footer">
                            <div>
                              <Score score={post.avarage_review_score} />
                            </div>
                            <div className="PostListItem_Price">基本料金 ¥{post.price}</div>
                          </div>
                        </div>
                      </div>
                      <div className="PostListItem_Description">
                        <p>{post.description}</p>
                      </div>
                    </a>
                  </li>
                ))}
            </ul>
            {!props.pagination || props.pagination.total_pages <= 1 ? null : (
              <Pagination
                onChangePageHandler={props.getPostsByPage}
                currentPage={Number(props.pagination.current_page)}
                prevPage={props.pagination.prev_page}
                nextPage={props.pagination.next_page}
                totalPages={props.pagination.total_pages}
                totalCount={props.pagination.total_count}
              />
            )}
          </>
        )}
      </div>
      {props.hasMap && (
        <>
          <Map>
            <div id="map" />
          </Map>
          <div className="PostList_MapToggle" onClick={() => setShowMap(!showMap)}>
            {showMap ? I18n.t('generic.to_index') : I18n.t('generic.map')}
          </div>
        </>
      )}
    </PostListWrapper>
  )
}

const PostListWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
    display: block;
  }

  .PostList_MapToggle {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 24px;
    width: 100px;
    height: 32px;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    margin: auto;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    z-index: 1000;
    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      display: flex;
    }
  }

  .PostList_Main {
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding: 0 24px 12px;
    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      width: 100%;
      padding-bottom: 64px;
      position: relative;
      z-index: 900;
      display: block;
      background-color: #fff;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      padding: 0 12px 72px;
    }
  }

  &.showMap {
    .PostList_Main {
      @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
        display: none;
      }
    }
  }

  .PostList_FavoriteHeader {
    margin-top: 24px;
    font-size: 24px;
  }

  .PostList_Count {
    > span {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .PostList_Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
  }

  .PostList_Sort {
    display: flex;
    align-items: center;
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      display: none;
    }
  }

  .PostListItem {
    position: relative;
    margin-top: 16px;

    > a {
      display: block;
      padding: 16px;
      overflow: hidden;
      color: ${constants.TEXT_COLOR};
      border-radius: 4px;
      border: solid 1px ${constants.BORDER_COLOR};
      background-color: #fff;
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        padding: 0;
        border: none;
      }

      &:hover {
        box-shadow: 0 2px 6px 0 rgba(21, 27, 38, 0.2);
        @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
          box-shadow: none;
        }
      }
    }

    .PostListItem_Upper {
      display: flex;
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        display: block;
      }
    }

    .PostListItem_Description {
      margin-top: 16px;
      padding-top: 16px;
      border-top: solid 1px ${constants.BORDER_COLOR};
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        padding: 0 0 24px;
        border: none;
      }

      > p {
        font-size: 14px;
      }
    }

    .PostListItem_NoImage {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f4f4f4;
    }

    .PostListItem_Title {
      font-size: 18px;
      @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
        font-size: 14px;
      }
    }

    .PostListItem_Image {
      width: 220px;
      height: 160px;
      border-radius: 3px;
      overflow: hidden;
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        width: 100%;
        height: 200px;
      }

      > img {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }

    .PostListItem_Header {
      display: flex;

      .PostListItem_Like {
        width: 24px;
        height: 24px;
        margin-left: 12px;
        cursor: pointer;
        @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
          position: absolute;
          top: 12px;
          right: 12px;
          width: 40px;
          height: 40px;
          background-color: #fff;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px,
            rgba(0, 0, 0, 0.08) 0px 1px 2px;
        }

        .material-icons {
          color: ${Color(constants.TEXT_COLOR)
            .lighten(3.6)
            .hex()};

          &.liked {
            color: #fb585c;
          }
        }
      }
    }

    .PostListItem_Category {
      color: #999;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 4px;
      @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
        font-size: 12px;
      }
    }

    .PostListItem_Tags {
      display: flex;
      margin-top: 10px;
      color: var(${constants.THEME_COLOR_VARIABLE_NAME});
      font-size: 16px;
      @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
        font-size: 14px;
      }

      .Tag {
        display: flex;
        align-items: center;

        .material-icons {
          font-size: 14px;
          margin-right: 4px;
        }
      }

      .Tag + .Tag {
        margin-left: 10px;
      }
    }

    .PostListItem_HeaderInfo {
      flex: 1;
    }

    .PostListItem_Footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
        margin-top: 12px;
      }
    }

    .PostListItem_Price {
      font-size: 14px;
      font-weight: bold;
    }

    .PostListItem_Info {
      flex: 1;
      padding-left: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        margin-top: 12px;
        padding: 0;
      }
    }
  }

  .Pagination {
    height: 48px;
    border-top: solid 1px ${constants.BORDER_COLOR};
  }
`

const OrderSelect = styled.div`
  position: relative;
  border: solid 1px #eee;
  margin-left: 12px;
  border-radius: 3px;

  > select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    border: 0;
    margin: 0;
    padding: 0;
    background: none transparent;
    vertical-align: middle;
    color: inherit;
    box-sizing: content-box;
    outline: none;

    width: 120px;
    padding: 2px 8px;
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
    background-color: #fff;
  }

  > span {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 9px;
    margin: auto;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid #4f5959;
  }
`

const Map = styled.div`
  display: block;
  position: relative;
  flex: 1;
  height: 100%;
  width: 100%;
  @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
    position: absolute;
    top: 0;
    left: 0;
  }

  #map {
    height: 100%;
    width: 100%;
  }
`

export default PostList

import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { ICompany, IUser, IWindow } from '../../../../core/interfaces'
import { companyService } from '../../../../core/services'
import * as constants from '../../../../static/constants'
import { Button, InputText, Panel } from '../../../atoms'
import { Form } from '../../../molecules'
import SettingsCommonLayout from '../_layouts/common'

declare var window: IWindow

const FIELDS = {
  name: 'name',
}

interface IProps {
  current_user: IUser
}

const Company: React.FC<IProps> = props => {
  const [company, setCompany] = React.useState<ICompany>(null)

  React.useEffect(() => {
    const f = async () => {
      const result = await companyService.get()
      setCompany(result.company)
    }
    if (company === null) {
      f()
    }
  }, [company])

  const handleSubmit = async (initialValues, values) => {
    const formData = new FormData()

    Object.keys(values).forEach(key => {
      if (initialValues[key] !== values[key]) {
        formData.append(`company[${key}]`, values[key])
      }
    })

    const { updatedCompany, flash } = await companyService.update(formData)
    setCompany(updatedCompany)
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
  }

  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [errors, setErrors] = React.useState<any>({})

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }

  return (
    <SettingsCommonLayout
      activePath={'company'}
      is_owner={props.current_user.is_owner}
      main={
        <Main>
          <Panel title={I18n.t('settings.companies.title')}>
            {company && (
              <Form fields={FIELDS} handleSubmit={handleSubmit} handleUpdateForm={handleUpdateForm}>
                <FormItem>
                  <InputText
                    required={false}
                    name="name"
                    label={I18n.t('settings.companies.name')}
                    defaultValue={company.name}
                    error={errors.name}
                  />
                </FormItem>
                <div className="Save_Button">
                  <Button primary={true} disabled={!isSubmitEnabled}>
                    {I18n.t('generic.save')}
                  </Button>
                </div>
              </Form>
            )}
          </Panel>
        </Main>
      }
    />
  )
}

const Main = styled.div`
  flex: 1;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #f6f8f9;
  label {
    cursor: pointer;
    color: var(${constants.THEME_COLOR_VARIABLE_NAME});
    border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
    border-radius: 3px;
    padding: 4px 8px;
    font-size: 14px;
  }
  input[type='file'] {
    display: none;
  }

  .Save_Button {
    text-align: center;
    margin-top: 16px;
  }
`

const FormItem = styled.div`
  & + & {
    margin-top: 16px;
  }
`

export default Company

import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../../core/i18n'
import { IPager, IStaff } from '../../../../../core/interfaces'
import { postService } from '../../../../../core/services'
import * as constants from '../../../../../static/constants'
import { Button, Pagination } from '../../../../atoms'
import StaffEditor from './StaffEditor'

interface IProps {
  post_id: number
}

const Index: React.FC<IProps> = props => {
  const [staff, setStaff] = React.useState<IStaff>(null)
  const [staffs, setStaffs] = React.useState<IStaff[]>(null)
  const [pagination, setPagination] = React.useState<IPager>()
  const [qualifications, setQualifications] = React.useState<string[]>([])

  React.useEffect(() => {
    const f = async () => {
      const result = await postService.getStaffs(props.post_id, {})
      setStaffs(result.staffs)
      setPagination(result.pagination)
    }
    if (props.post_id !== null && staffs === null) {
      f()
    }
  }, [staffs])

  const onChangePageHandler = async page => {
    const result = await postService.getStaffs(props.post_id, { page })
    setStaffs(result.staffs)
    setPagination(result.pagination)
  }

  const handleNew = () => {
    const newStaff: IStaff = {
      id: null,
      fullname: '',
      kana: '',
      price: null,
      avatar_url: '',
      experience: '',
      bio: '',
      workflow_state: null,
      qualification_urls: [],
      created_at: null,
      title: '',
      skill: '',
      image: '',
      gender: '',
    }
    setStaff(newStaff)
  }

  const handleEdit = async id => {
    const {
      staff: selectedStaff,
      qualifications: selectedQualifications,
    } = await postService.getStaff(props.post_id, id)
    setStaff(selectedStaff)
    setQualifications(selectedQualifications)
  }

  const refleshStaffs = (updatedStaff, method) => {
    switch (method) {
      case 'CREATE':
        staffs.unshift(updatedStaff)
        break
      case 'UPDATE':
        setStaffs(staffs.map(item => (item.id === updatedStaff.id ? updatedStaff : { ...item })))
        break
      case 'DELETE':
        staffs.map((item, index) => {
          if (item.id === updatedStaff.id) {
            staffs.splice(index, 1)
          }
        })
        break
    }
    setStaff(null)
  }

  return (
    <>
      {staff ? (
        <StaffEditor
          post_id={props.post_id}
          staff={staff}
          qualifications={qualifications}
          handleSetStaff={setStaff}
          handleRefleshStaffs={refleshStaffs}
        />
      ) : (
        <>
          <h2 className="Editor_Title">
            {I18n.t('staff.title')}
            <Button small={true} handleClick={handleNew} right={true}>
              {I18n.t('generic.create_new')}
            </Button>
          </h2>
          <div className="EditTable_Scroller">
            <List>
              <table>
                <thead>
                  <tr>
                    <th />
                    <th>{I18n.t('generic.name')}</th>
                    <th>{I18n.t('generic.name_kana')}</th>
                    <th>{I18n.t('generic.created_at')}</th>
                    <th>{I18n.t('generic.status')}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {staffs &&
                    staffs.map(record => (
                      <tr key={record.id}>
                        <td>
                          <ListImage>
                            {record.avatar_url ? (
                              <img src={record.avatar_url} alt="" />
                            ) : (
                              <ListNoImage>No Image</ListNoImage>
                            )}
                          </ListImage>
                        </td>
                        <td>{record.fullname}</td>
                        <td>{record.kana}</td>
                        <td>{record.created_at}</td>
                        <td>
                          {record.workflow_state === 'Requested' && '認証中'}
                          {record.workflow_state === 'Approved' && '認証済'}
                          {record.workflow_state === 'Declined' && '却下'}
                        </td>
                        <td>
                          <Button>
                            {/* <a href={`/conversations/${conversation.slug}`}> */}
                            <a
                              onClick={() => {
                                handleEdit(record.id)
                              }}
                            >
                              {I18n.t('generic.show_detail')}
                            </a>
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </List>
          </div>
          {pagination && (
            <Pagination
              onChangePageHandler={onChangePageHandler}
              currentPage={pagination.current_page}
              prevPage={pagination.prev_page}
              nextPage={pagination.next_page}
              totalPages={pagination.total_pages}
              totalCount={pagination.total_count}
            />
          )}
        </>
      )}
    </>
  )
}

const List = styled.div`
  table {
    width: 100%;
  }

  th,
  td {
    padding: 8px 12px;
    max-width: 320px;

    > a {
      color: var(${constants.THEME_COLOR_VARIABLE_NAME});
      text-decoration: underline;
    }
  }

  th {
    cursoor: pointer;

    &:hover {
      background-color: #f6f8f9;
    }
  }

  tbody {
    > tr {
      border-top: solid 1px ${constants.BORDER_COLOR};
    }
  }
`

const ListImage = styled.div`
  width: 160px;
  height: 120px;
  border-radius: 3px;
  overflow: hidden;

  > img {
    object-fit: cover;
    width: inherit;
    height: inherit;
  }
`

const ListNoImage = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
`

export default Index

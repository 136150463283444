import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IJsonResponse, IUsage, IUser, IWindow } from '../../../../core/interfaces'
import { usageService, userService } from '../../../../core/services'
import * as constants from '../../../../static/constants'
import {
  BadgeRequired,
  Button,
  CheckBox,
  InputText,
  InputTextArea,
  Panel,
  Select,
} from '../../../atoms'
import { Form } from '../../../molecules'
import SettingsCommonLayout from '../_layouts/common'

declare var window: IWindow

interface IProps {
  current_user: IUser
  usage: IJsonResponse
  relationships: string[]
  reasons: string[]
  statuses: string[]
  genders: string[]
  full_ages: string[]
}

const FIELDS = {
  relationship: 'relationship',
  reason: 'reason',
  status: 'status',
  locations_chair: 'locations_chair',
  locations_bed: 'locations_bed',
  locations_washroom: 'locations_washroom',
  locations_other: 'locations_other',
  gender: 'gender',
  age: 'age',
  genders: 'genders',
  ages: 'ages',
  patient_name: 'patient_name',
  patient_name_katakana: 'patient_name_katakana',
  patient_relation_note: 'patient_relation_note',
  patient_reason_note: 'patient_reason_note',
  patient_address: 'patient_address',
  patient_address_detail: 'patient_address_detail',
}

const Usage: React.FC<IProps> = props => {
  const { usage: initialUsage } = usageService.getUsageFromJson(props.usage)
  const [usage, setUsage] = React.useState<IUsage>(initialUsage)
  const [user, setUser] = React.useState<IUser>(props.current_user)
  const [relation, setRelation] = React.useState<boolean>(
    usage.relationship === '本人' || usage.relationship === ''
  )

  const handleSubmit = async (initialValues, values) => {
    const formData = new FormData()
    let hasValue = false
    Object.keys(values).forEach(key => {
      if (initialValues[key] !== values[key]) {
        formData.append(`usage[${key}]`, values[key])
        hasValue = true
      }
    })
    if (hasValue) {
      const { updatedUsage, flash } = await usageService.update(formData)
      setUsage(updatedUsage)
      window.flashMessages.addMessage({ text: flash.message, type: flash.type })
    } else {
      window.flashMessages.addMessage({
        text: '変更がないか、設定されていない項目があります',
        type: 'error',
      })
    }
  }

  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [errors, setErrors] = React.useState<any>({})

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }

  const formatSelectOptions = options => {
    return options.map(option => ({
      value: option,
      label: option,
    }))
  }

  const handleSubmitUser = async (initialValues, values) => {
    const formData = new FormData()
    let hasValue = false
    Object.keys(values).forEach(key => {
      if (initialValues[key] !== values[key]) {
        formData.append(`user[${key}]`, values[key])
        hasValue = true
      }
    })
    if (hasValue) {
      const { updatedUser, flash } = await userService.updateUser(formData)
      setUser(updatedUser)
      if (flash.type === 'success') {
        window.flashMessages.addMessage({ text: '連絡先を更新しました', type: flash.type })
      } else {
        window.flashMessages.addMessage({ text: flash.message, type: flash.type })
      }
    } else {
      window.flashMessages.addMessage({
        text: '変更がないか、設定されていない項目があります',
        type: 'error',
      })
    }
  }

  const [isSubmitEnabledUser, setIsSubmitEnabledUser] = React.useState(false)
  const [errorsUser, setErrorsUser] = React.useState<any>({})

  const handleUpdateFormUser = (updatedErrorsUser, updatedIsSubmitEnabledUser) => {
    setErrorsUser(updatedErrorsUser)
    setIsSubmitEnabledUser(updatedIsSubmitEnabledUser)
  }

  return (
    <SettingsCommonLayout
      activePath={'usage'}
      is_owner={props.current_user.is_owner}
      main={
        <Main>
          <Panel title={'連絡先設定'}>
            <Form
              fields={{ phone: 'phone', address: 'address', address_detail: 'address_detail' }}
              handleSubmit={handleSubmitUser}
              handleUpdateForm={handleUpdateFormUser}
            >
              {/* 住所 */}
              <div className="Form__item">
                <label>電話番号</label>
                <div className="Required">必須</div>
                <InputText
                  required={true}
                  name="phone"
                  defaultValue={user.phone}
                  error={errors.phone}
                />
              </div>
              <div className="Form__item large">
                <label>住所</label>
                <div className="Required">必須</div>
                <InputText
                  required={true}
                  name="address"
                  defaultValue={user.address}
                  error={errors.address}
                />
              </div>
              <div className="Form__item large">
                <label>住所（マンション名など）</label>
                <InputText
                  requiredTextHide={true}
                  name="address_detail"
                  defaultValue={user.address_detail}
                  error={errors.address_detail}
                />
              </div>

              <div className="Form__buttons">
                <Button primary={true} disabled={!isSubmitEnabledUser}>
                  {I18n.t('generic.save')}
                </Button>
              </div>
            </Form>
          </Panel>

          <Panel title={I18n.t('settings.usages.title')} className={'Panel__requires'}>
            <Form fields={FIELDS} handleSubmit={handleSubmit} handleUpdateForm={handleUpdateForm}>
              {/* サービス利用設定 */}
              <div className="Form__item">
                <label>{I18n.t('settings.usages.relationship')}</label>
                <Select
                  required={true}
                  requiredTextHide={true}
                  name="relationship"
                  options={formatSelectOptions(props.relationships)}
                  defaultValue={String(usage.relationship)}
                  error={errors.relationship}
                  onChangeHandler={e =>
                    setRelation(e.currentTarget.value === '本人' || e.currentTarget.value === '')
                  }
                />
              </div>
              <S.Info isVisible={!relation}>
                <h4>訪問サービスを受ける人の情報</h4>

                <div className="Form__item large">
                  <label>名前（山田 太郎）</label>
                  <InputText
                    required={false}
                    name="patient_name"
                    defaultValue={usage.patient_name}
                    error={errors.patient_name}
                  />
                </div>

                <div className="Form__item large">
                  <label>名前 カタカナ（ヤマダ タロウ）</label>
                  <InputText
                    required={false}
                    name="patient_name_katakana"
                    defaultValue={usage.patient_name_katakana}
                    error={errors.patient_name_katakana}
                  />
                </div>
                <div className="Form__item large">
                  <label>住所</label>
                  <InputText
                    required={false}
                    name="patient_address"
                    defaultValue={usage.patient_address}
                    error={errors.patient_address}
                  />
                </div>
                <div className="Form__item large">
                  <label>住所（マンション名など）</label>
                  <InputText
                    required={false}
                    name="patient_address_detail"
                    defaultValue={usage.patient_address_detail}
                    error={errors.patient_address_detail}
                  />
                </div>
                <div className="Form__item large">
                  <label>訪問サービスを受ける人との関係 - ノート</label>
                  <InputTextArea
                    required={false}
                    name="patient_relation_note"
                    defaultValue={usage.patient_relation_note}
                    error={errors.patient_relation_note}
                  />
                </div>
                <div className="Form__item large">
                  <label>訪問サービスを受ける理由 - ノート</label>
                  <InputTextArea
                    required={false}
                    name="patient_reason_note"
                    defaultValue={usage.patient_reason_note}
                    error={errors.patient_reason_note}
                  />
                </div>
              </S.Info>

              <div className="Form__item">
                <label>{I18n.t('settings.usages.reason')}</label>
                <Select
                  required={true}
                  requiredTextHide={true}
                  name="reason"
                  options={formatSelectOptions(props.reasons)}
                  defaultValue={String(usage.reason)}
                  error={errors.reason}
                />
              </div>
              <div className="Form__item">
                <label>{I18n.t('settings.usages.status')}</label>
                <Select
                  required={true}
                  requiredTextHide={true}
                  name="status"
                  options={formatSelectOptions(props.statuses)}
                  defaultValue={String(usage.status)}
                  error={errors.status}
                />
              </div>
              <div className="Form__item">
                <label>{I18n.t('settings.usages.location')}</label>
                <div className="checkboxWrapper">
                  <CheckBox name="locations_chair" defaultChecked={usage.locations_chair} />
                  <label>部屋の中の椅子</label>
                </div>
                <div className="checkboxWrapper">
                  <CheckBox name="locations_bed" defaultChecked={usage.locations_bed} />
                  <label>部屋の中のベッドや寝床</label>
                </div>
                <div className="checkboxWrapper">
                  <CheckBox name="locations_washroom" defaultChecked={usage.locations_washroom} />
                  <label>洗面所</label>
                </div>
                <div className="checkboxWrapper">
                  <CheckBox name="locations_other" defaultChecked={usage.locations_other} />
                  <label>その他</label>
                </div>
              </div>
              <div className="Form__item">
                <label>{I18n.t('settings.usages.gender')}</label>
                <Select
                  required={true}
                  requiredTextHide={true}
                  small={true}
                  name="gender"
                  options={formatSelectOptions(props.genders)}
                  defaultValue={String(usage.gender)}
                  error={errors.gender}
                />
              </div>
              <div className="Form__item">
                <label>{I18n.t('settings.usages.age')}</label>

                <Select
                  required={true}
                  requiredTextHide={true}
                  small={true}
                  name="age"
                  options={formatSelectOptions(props.full_ages)}
                  defaultValue={String(usage.age)}
                  error={errors.age}
                />
              </div>
              <div className="Form__item">
                <label>{I18n.t('settings.usages.genders')}</label>

                <Select
                  required={true}
                  requiredTextHide={true}
                  small={true}
                  name="genders"
                  options={formatSelectOptions(props.genders)}
                  defaultValue={String(usage.genders)}
                  error={errors.genders}
                />
              </div>
              <div className="Form__item">
                <label>{I18n.t('settings.usages.ages')}</label>

                <Select
                  required={true}
                  requiredTextHide={true}
                  small={true}
                  name="ages"
                  options={formatSelectOptions(props.full_ages)}
                  defaultValue={String(usage.ages)}
                  error={errors.ages}
                />
              </div>

              <div className="Form__buttons">
                <Button primary={true} disabled={!isSubmitEnabled}>
                  {I18n.t('generic.save')}
                </Button>
              </div>
            </Form>
          </Panel>
        </Main>
      }
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.Info = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`

const Main = styled.div`
  flex: 1;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #f6f8f9;
  input[type='file'] {
    display: none;
  }
  .Panel__requires {
    .Panel_Title {
      &:after {
        content: '全て必須';
        // float: right;
        margin-right: 20px;
        display: inline-block;
        border-radius: 3px;
        color: #fff;
        background-color: ${constants.DANGER_COLOR};
        vertical-align: middle;
        font-size: 11px;
        font-weight: normal;
        padding: 4px 6px;
        margin-left: 8px;
      }
    }
  }
  .Panel {
    margin-bottom: 15px;

    .Form {
      display: flex;
      flex-wrap: wrap;

      &__item {
        width: 50%;
        margin-top: 16px;

        @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
          width: 100%;
        }

        label {
          font-size: 12px;
          font-weight: bold;
        }

        .InputText_Main {
          max-width: 300px;
        }

        &.large {
          width: 100%;
          .InputText_Main {
            max-width: 700px;
          }
        }

        .Select_Main {
          max-width: 300px;
        }

        .checkboxWrapper {
          display: flex;
          .CheckBox_Icon {
            margin-right: 10px;
            position: initial;
          }
        }
      }

      &__buttons {
        text-align: center;
        margin-top: 16px;
        width: 100%;
      }
    }
  }

  .Required {
    margin-right: 20px;
    display: inline-block;
    border-radius: 3px;
    color: #fff;
    background-color: ${constants.DANGER_COLOR};
    vertical-align: middle;
    font-size: 11px;
    font-weight: normal;
    padding: 1px 6px;
    margin-left: 8px;
  }
`

export default Usage

import ClassNames from 'classnames'
import * as React from 'react'
import { ProgressBar, Step } from 'react-step-progress-bar'
import styled from 'styled-components'

interface IProps {
  step: number
}

const StepProgressBar: React.FC<IProps> = props => {
  return (
    <ProgressBarWrapper>
      <ProgressBar percent={props.step}>
        <Step>
          {({ accomplished, index }) => (
            <div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div className={`indexedStep ${accomplished ? 'accomplished' : null}`}>{index + 1}</div>
          )}
        </Step>
      </ProgressBar>
    </ProgressBarWrapper>
  )
}
const ProgressBarWrapper = styled.div`
  margin: 25px;
  .RSPBprogressBar {
    height: 10px;
    line-height: 1;
    border-radius: 10px;
    position: relative;
    background-color: lightgrey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;

    .RSPBstep {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      z-index: 0;
      position: absolute;
      transform: translateX(-50%);
      transition-property: all;
      transition-timing-function: ease;
    }

    .RSPBprogressBarText {
      color: white;
      font-size: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .RSPBprogression {
      position: absolute;
      transition: width 0.3s ease;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: 10px;
      background: #4a90e2;
      z-index: -1;
    }
  }

  .indexedStep {
    color: white;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background-color: rgba(211, 211, 211, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .indexedStep.accomplished {
    background-color: rgba(0, 116, 217, 1);
  }
`

export default StepProgressBar

import AdminTable from 'components/atoms/AdminTable'
import * as React from 'react'
import I18n from '../../../../core/i18n'
import { IJsonResponse, ITag, IWindow } from '../../../../core/interfaces'
import { tagService, utilService } from '../../../../core/services/admin'
import * as Hooks from '../../../../utils/hooks'
import { Button, Pagination } from '../../../atoms'
import AdminLayoutIndex from '../_layouts/index'

declare var window: IWindow

interface IProps {
  tags: {
    tags: IJsonResponse
  }
}

const AdminTagIndex: React.FC<IProps> = props => {
  Hooks.SetDocumentTitle('タグ管理')
  const { data: initialTags, pagination } = utilService.getDataFromJson(props.tags.tags)
  const [tags, setTags] = React.useState<ITag[]>(initialTags)
  const handleDelete = React.useCallback(
    async id => {
      if (confirm('本当に削除しますか？')) {
        const { flash } = await tagService.delete(id)
        window.flashMessages.addMessage({ text: flash.message, type: flash.type })
        setTags(tags.filter(tag => tag.id !== id))
      }
    },
    [tags]
  )

  const onChangePageHandler = React.useCallback(page => {
    location.href = `${location.pathname}?page=${page}`
  }, [])

  return (
    <AdminLayoutIndex
      model="tag"
      main={
        <AdminTable
          body={
            <>
              <h1 className="EditTable_Title">
                <span>{I18n.t('nav.admins.tag')}</span>
                <Button small={true}>
                  <a href="/admin/tags/new">{I18n.t('generic.create_new')}</a>
                </Button>
              </h1>
              <div className="EditTable_Scroller">
                <div className="EditTable_Sizer">
                  <table>
                    <thead>
                      <tr>
                        <th className="w_0">{I18n.t('generic.id')}</th>
                        <th>{I18n.t('generic.name')}</th>
                        <th className="w_0">{I18n.t('generic.position')}</th>
                        <th className="w_0" />
                      </tr>
                    </thead>
                    <tbody>
                      {tags.map(tag => (
                        <tr key={tag.id}>
                          <td>{tag.id}</td>
                          <td>{tag.name}</td>
                          <td>{tag.position}</td>
                          <td className="_buttons">
                            <Button key="edit" small={true}>
                              <a href={`/admin/tags/${tag.id}/edit`}>{I18n.t('generic.edit')}</a>
                            </Button>
                            {handleDelete && (
                              <Button
                                key="delete"
                                small={true}
                                handleClick={() => handleDelete(tag.id)}
                              >
                                {I18n.t('generic.delete')}
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {pagination && (
                <Pagination
                  onChangePageHandler={onChangePageHandler}
                  currentPage={pagination.current_page}
                  prevPage={pagination.prev_page}
                  nextPage={pagination.next_page}
                  totalPages={pagination.total_pages}
                  totalCount={pagination.total_count}
                />
              )}
            </>
          }
        />
      }
    />
  )
}

export default AdminTagIndex

import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import * as constants from '../../../../static/constants'
import * as Hooks from '../../../../utils/hooks'
import { Button, Panel } from '../../../atoms'
import Nav from '../../../organisms/AdminNav'

interface IProps {
  settings: any
}

const AdminSettingsShow: React.FC<IProps> = props => {
  Hooks.SetDocumentTitle(I18n.t('nav.admins.setting'))
  const settings = props.settings.reduce((obj, setting) => {
    obj[setting.var] = setting.value
    return obj
  }, {})

  return (
    <IndexWrapper>
      <Nav active="setting" />
      <section className="Index_Main">
        <Panel title={I18n.t('nav.admins.setting')}>
          <dl>
            {/* <dt>Host</dt>
            <dd>{settings.host}</dd> */}
            <dt>{'運営中'}</dt>
            <dd>{settings.operation ? 'はい' : 'いいえ'}</dd>
            <dt>{I18n.t('admin.setting.default_site')}</dt>
            <dd>{settings.default_site}</dd>
            <dt>{I18n.t('admin.setting.default_title')}</dt>
            <dd>{settings.default_title}</dd>
            <dt>{I18n.t('admin.setting.default_description')}</dt>
            <dd>{settings.default_description}</dd>
            <dt>{I18n.t('admin.setting.default_keywords')}</dt>
            <dd>{settings.default_keywords}</dd>
            <dt>{I18n.t('admin.setting.theme_color')}</dt>
            <dd>{settings.theme_color}</dd>
            <dt>{I18n.t('admin.setting.ga_tracking_id')}</dt>
            <dd>{settings.ga_tracking_id}</dd>
            {/* <dt>{I18n.t('admin.setting.social_profiles')}</dt>
            <dd>{settings.social_profiles}</dd> */}
          </dl>
          <div className="Buttons">
            <Button primary={true}>
              <a href="/admin/setting/edit">{I18n.t('generic.edit')}</a>
            </Button>
          </div>
        </Panel>
      </section>
    </IndexWrapper>
  )
}

const IndexWrapper = styled.div`
  display: flex;
  height: calc(100vh - ${constants.HEADER_HEIGHT}px);
  margin: 0 auto;
  background-color: #f6f8f9;

  .Index_Main {
    flex: 1;
    padding 16px;
  }

  dl {
    display: flex;
    flex-wrap: wrap;
  }

  dt {
    width: 240px;
    margin-top: 24px;
    font-weight: bold;
  }

  dd {
    width: calc(100% - 240px);
    margin-top: 24px;
  }

  .Buttons {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 48px;

    .Button[type="submit"] {
      width: 160px;
    }
  }
`

export default AdminSettingsShow

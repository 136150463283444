const Cable = ActionCable.createConsumer()

interface ISettings {
  name: string
  connected?(): void
  disconnected?(): void
  received?(data: any): void
}

export const create = (settings: ISettings) => {
  const channel = Cable.subscriptions.create(settings.name, {
    connected(): void {
      // console.info('connected')
      if (typeof settings.connected === 'function') {
        settings.connected()
      }
    },
    disconnected(): void {
      // console.info('disconnected')
      if (typeof settings.disconnected === 'function') {
        settings.disconnected()
      }
    },
    received(data: any): any {
      // console.log('received')
      if (typeof settings.received === 'function') {
        settings.received(data)
      }
    },
  })

  return channel
}

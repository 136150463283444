import AdminTable from 'components/atoms/AdminTable'
import * as React from 'react'
import I18n from '../../../../core/i18n'
import { IPager, IUser, IWindow } from '../../../../core/interfaces'
import { userService } from '../../../../core/services/admin'
import * as Hooks from '../../../../utils/hooks'
import { Button, Pagination } from '../../../atoms'
import AdminLayoutIndex from '../_layouts/index'

declare var window: IWindow

interface IProps {
  current_user_id: number
}

const AdminUserIndex: React.FC<IProps> = props => {
  Hooks.SetDocumentTitle('Admin users')
  const [users, setUsers] = React.useState<IUser[]>(null)
  const [pagination, setPagination] = React.useState<IPager>()

  React.useEffect(() => {
    const f = async () => {
      const result = await userService.index({})
      setUsers(result.users)
      setPagination(result.pagination)
    }
    if (!users) {
      f()
    }
  }, [users])

  const handleDelete = React.useCallback(
    async id => {
      if (confirm('本当に削除しますか？')) {
        const { flash } = await userService.delete(id)
        window.flashMessages.addMessage({
          text: flash.message,
          type: flash.type,
        })
        setUsers(users.filter(user => user.id !== id))
      }
    },
    [users]
  )

  const handleBecome = React.useCallback(
    async id => {
      await userService.becomeUser(id)
      location.href = '/'
    },
    [users]
  )

  const isUserAdmin = user_id => {
    return user_id === props.current_user_id
  }

  const onChangePageHandler = async page => {
    const result = await userService.index({ page })
    setUsers(result.users)
    setPagination(result.pagination)
  }

  return (
    <AdminLayoutIndex
      model="user"
      main={
        <AdminTable
          body={
            <>
              <h1 className="EditTable_Title">
                <span>{I18n.t('nav.admins.user')}</span>
                <Button small={true}>
                  <a href="/admin/users/new">{I18n.t('generic.create_new')}</a>
                </Button>
              </h1>
              <div className="EditTable_Scroller">
                <div className="EditTable_Sizer">
                  <table>
                    <thead>
                      <tr>
                        <th className="w_0">{I18n.t('generic.id')}</th>
                        <th>{I18n.t('user.avatar')}</th>
                        <th>{I18n.t('generic.username')}</th>
                        <th className="w_0 ">{I18n.t('user.is_admin')}</th>
                        <th className="w_0">{I18n.t('user.is_owner')}</th>
                        <th>{I18n.t('generic.email')}</th>
                        <th>{I18n.t('generic.phone')}</th>
                        <th>{I18n.t('settings.profiles.bio')}</th>
                        <th className="w_0" />
                      </tr>
                    </thead>
                    <tbody>
                      {users &&
                        users.map(user => (
                          <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>
                              <img src={user.avatar_url} alt={user.username} />
                            </td>
                            <td>{user.username}</td>
                            <td className="text-center">{user.is_admin && '○'}</td>
                            <td className="text-center">{user.is_owner && '○'}</td>
                            <td>{user.email}</td>
                            <td>{user.phone}</td>
                            <td>{user.bio}</td>
                            <td className="_buttons">
                              <Button key="edit" small={true}>
                                <a href={`/admin/users/${user.id}/edit`}>
                                  {I18n.t('generic.edit')}
                                </a>
                              </Button>
                              {handleDelete && (
                                <Button
                                  key="delete"
                                  small={true}
                                  handleClick={() => handleDelete(user.id)}
                                >
                                  {I18n.t('generic.delete')}
                                </Button>
                              )}
                              {handleBecome && !isUserAdmin(user.id) && (
                                <Button key="become" handleClick={() => handleBecome(user.id)}>
                                  {I18n.t('generic.become')}
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {pagination && (
                <Pagination
                  onChangePageHandler={onChangePageHandler}
                  currentPage={pagination.current_page}
                  prevPage={pagination.prev_page}
                  nextPage={pagination.next_page}
                  totalPages={pagination.total_pages}
                  totalCount={pagination.total_count}
                />
              )}
            </>
          }
        />
      }
    />
  )
}

export default AdminUserIndex

import ClassNames from 'classnames'
import { IJsonResponse, IPost, IReservation } from 'core/interfaces'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import { postService, reservationService } from '../../../core/services'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../../static/constants'
import * as constants from '../../../static/constants'
import * as Util from '../../../utils'
import * as forms from '../../../utils/form'
import { Button, InputTextArea } from '../../atoms'
import { Form } from '../../molecules'

interface IProps {
  // review: IJsonResponse
  reservation: IJsonResponse
  post: IJsonResponse
  // post_images: IJsonResponse
  // reviewer: IJsonResponse
}

const FIELDS = {
  rating: 'rating',
  body: 'body',
}

const ReviewNew: React.FC<IProps> = props => {
  const starsRef = React.useRef(null)
  const { post: initialPost } = postService.getPostFromJson(props.post)
  const [post] = React.useState<IPost>(initialPost)
  const { reservation } = reservationService.getReservationFromJson(props.reservation)
  const [score, setScore] = React.useState(0)
  const [errors, setErrors] = React.useState({ body: null })
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [completed, setCompleted] = React.useState(false)

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }

  const handleSubmit = async (initialValues, values) => {
    const params = {
      reservation_id: reservation.id,
      rating: Number(values.rating),
      body: values.body,
    }

    await postService.createReview(params)
    setCompleted(true)
  }

  return (
    <ReviewNewWrapper>
      <div className="ReviewNew_Main">
        <div className="ReviewNew_Post">
          <div className="ReviewNew_Image">
            <img src={post.post_images[0].image_url} alt="" />
          </div>
          <div className="ReviewNew_PostInfo">
            <h2 className="ReviewNew_PostTitle">{post.name}</h2>
            <div className="ReviewNew_PostOwner">
              <div className="ReviewNew_Avator">
                <img src={post.user.avatar_url} alt="" />
              </div>
              <span>{post.user.username}</span>
            </div>
          </div>
        </div>

        <div className="ReviewNew_Editor">
          {completed ? (
            <div>
              <S.Done>
                <i className="material-icons filled">done</i>
                <p>{I18n.t('review.completed')}</p>
              </S.Done>
              <S.FlexCenter>
                <S.Link>
                  <a href="/reservations?past=true">{I18n.t('review.back_to_index')}</a>
                </S.Link>
              </S.FlexCenter>
            </div>
          ) : (
            <Form fields={FIELDS} handleSubmit={handleSubmit} handleUpdateForm={handleUpdateForm}>
              <h2>{I18n.t('review.add_review')}</h2>
              <Stars>
                <input
                  required={true}
                  readOnly={true}
                  type="text"
                  name="rating"
                  defaultValue=""
                  ref={starsRef}
                />
                <div className="Stars_Wrapper">
                  {[1, 2, 3, 4, 5].map(value => (
                    <span
                      key={value}
                      onClick={() => {
                        forms.setNativeValue(starsRef.current, value, false)
                        starsRef.current.dispatchEvent(new Event('input', { bubbles: true }))
                        setScore(value)
                      }}
                    >
                      <svg
                        className={ClassNames('Stars_Star', {
                          filled: value <= score,
                        })}
                        viewBox="0 0 64 64"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polygon
                          fillRule="nonzero"
                          points="32.5 45.9878947 48.877 56 44.531 37.13 59 24.4336842 39.9465 22.7963158 32.5 5 25.0535 22.7963158 6 24.4336842 20.469 37.13 16.123 56"
                        />
                      </svg>
                    </span>
                  ))}
                </div>
              </Stars>
              <InputTextArea
                required={true}
                name="body"
                placeholder={I18n.t('placeholder.review_body')}
                defaultValue=""
                error={errors.body}
              />
              <Button primary={true} disabled={!isSubmitEnabled}>
                {I18n.t('generic.send')}
              </Button>
            </Form>
          )}
        </div>
      </div>
    </ReviewNewWrapper>
  )
}

const Stars = styled.div`
  margin-top: 4px;

  > input {
    display: none;
  }

  .Stars_Wrapper {
    display: flex;

    > span {
      cursor: pointer;
    }
  }

  .Stars_Star {
    width: 32px;
    height: auto;
    fill: none;

    > polygon {
      stroke: #e0e4e6;
      stroke-width: 2px;
    }

    &.filled {
      stroke: none;
      fill: #ffb800;

      > polygon {
        stroke: none;
      }
    }
  }
`
const S: { [key: string]: AnyStyledComponent } = {}

const ReviewNewWrapper = styled.div`
  padding: 24px 12px 0;

  .ReviewNew_Editor {
    margin-top: 24px;
    border-top: solid 1px ${constants.BORDER_COLOR};

    h2 {
      margin-top: 12px;
      font-size: 18px;
    }

    .Button {
      margin-top: 12px;
    }
  }

  .ReviewNew_Main {
    max-width: 800px;
    margin: 0 auto;
  }

  .ReviewNew_Post {
    display: flex;
    align-items: center;
  }

  .ReviewNew_Image {
    width: 200px;
    height: 160px;
    border-radius: 3px;
    overflow: hidden;

    > img {
      width: 100%;
      height: inherit;
      object-fit: cover;
    }
  }

  .ReviewNew_PostInfo {
    flex: 1;
    margin-left: 16px;
  }

  .ReviewNew_PostTitle {
    font-size: 20px;
  }

  .ReviewNew_PostOwner {
    display: flex;
    align-items: center;

    > span {
      margin-left: 12px;
    }
  }

  .ReviewNew_Avator {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 12px;

    > img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }
`

S.FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

S.Done = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
  padding: 8px;
  color: ${COLORS.Success};
  border: solid 1px ${COLORS.Success};
  border-radius: 3px;
  box-shadow: 0 0 0 4px #dff7df inset;

  > p {
    margin-left: 12px;
    font-weight: bold;
    font-size: 14px;
  }

  .material-icons {
    fill: ${COLORS.Success};
  }
`

S.Link = styled.div`
  color: var(${THEME_COLOR_VARIABLE_NAME});
  text-decoration: underline;
`
export default ReviewNew

import Color from 'color'
import React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'

const SocialLoginButtons: React.FC = props => {
  return (
    <SocialLoginButtonsWrapper>
      {/* <a className="_google" href="/users/auth/google_aouth2">
        <img src="/images/logo_google.svg" alt="" />
        <span>Sign in with Google</span>
      </a> */}
      <a className="_facebook" href="/users/auth/facebook">
        <img src="/images/logo_facebook.svg" alt="" />
        <span>Sign in with Facebook</span>
      </a>
      {/* <a className="_myrate" href="/users/auth/myrate">
        <span>Sign in with Myrate</span>
      </a>
      <a className="_twitter" href="/users/auth/twitter">
        <img src="/images/logo_twitter.svg" alt="" />
        <span>Sign in with Twitter</span>
      </a> */}
    </SocialLoginButtonsWrapper>
  )
}

const COLOR_FACEBOOK = '#1877F2'
const COLOR_TWITTER = '#1DA1F2'

const SocialLoginButtonsWrapper = styled.div`
  max-width: 360px;
  margin: 24px auto 0;

  a {
    display: flex;
    align-items: center;
    height: 50px;
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    border-radius: 4px;
    text-decoration: none;
    overflow: hidden;
    color: ${constants.TEXT_COLOR};
    font-size: 16px;
    transition: all 0.1s;

    &:hover {
      box-shadow: 0 1px 3px 2px rgba(21, 27, 38, 0.15);
    }

    &._google {
      background-color: #fff;

      &:hover {
        background-color: ${Color('#fff')
          .darken(0.1)
          .hex()};
      }
    }

    &._facebook {
      background-color: ${COLOR_FACEBOOK};
      color: #fff;

      &:hover {
        background-color: ${Color(COLOR_FACEBOOK)
          .darken(0.2)
          .hex()};
      }
    }

    &._twitter {
      background-color: ${COLOR_TWITTER};
      color: #fff;

      &:hover {
        background-color: ${Color(COLOR_TWITTER)
          .darken(0.2)
          .hex()};
      }
    }

    > img {
      height: inherit;
    }

    > span {
      margin-left: 12px;
    }
  }

  a + a {
    margin-top: 12px;
  }
`

export default SocialLoginButtons

import * as React from 'react'
import I18n from '../../../../core/i18n'
import { tagService } from '../../../../core/services/admin'
import * as Hooks from '../../../../utils/hooks'
import { InputText } from '../../../atoms'
import AdminLayoutNew from '../_layouts/new'

const FIELDS = {
  name: 'name',
  position: 'position',
}

const title = I18n.t('admin.create_new', {
  model: I18n.t('tag', { scope: 'activerecord.models' }),
})

const AdminTagNew: React.FC<{}> = props => {
  Hooks.SetDocumentTitle('タグ管理')
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    await tagService.create(values)
    location.href = '/admin/tags'
  }, [])

  return (
    <AdminLayoutNew
      model="tag"
      title={title}
      fields={FIELDS}
      indexLink="/admin/tags"
      formItems={
        <>
          <InputText required={true} name="name" defaultValue="" label={I18n.t('generic.name')} />
          <InputText
            required={true}
            name="position"
            defaultValue=""
            label={I18n.t('generic.position')}
          />
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

export default AdminTagNew

import * as React from 'react'
import styled from 'styled-components'
import Chevron from './Chevron'

interface IProps {
  title: any
  content: any
}

const Accordion: React.FC<IProps> = props => {
  const [setActive, setActiveState] = React.useState('')
  const [setHeight, setHeightState] = React.useState('0px')
  const [setRotate, setRotateState] = React.useState('accordion__icon')
  const content = React.useRef(null)

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'active' : '')
    setHeightState(setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`)
    setRotateState(setActive === 'active' ? 'accordion__icon' : 'accordion__icon rotate')
  }

  return (
    <AccordionWrapper>
      <div className="accordion__section">
        <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
          <p className="accordion__title">{props.title}</p>
          <Chevron className={`${setRotate}`} width={10} fill={'#777'} />
        </button>
        <div ref={content} style={{ maxHeight: `${setHeight}` }} className="accordion__content">
          <div className="accordion__text" dangerouslySetInnerHTML={{ __html: props.content }} />
        </div>
      </div>
    </AccordionWrapper>
  )
}

const AccordionWrapper = styled.div`
  /* Style the accordion section */
  .accordion__section {
    display: flex;
    flex-direction: column;
  }

  /* Style the buttons that are used to open and close the accordion panel */
  .accordion {
    background-color: #f8f5f2;
    color: #444;
    cursor: pointer;
    padding: 18px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
  }

  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .accordion:hover,
  .active {
    background-color: #f8f5f2;
  }

  /* Style the accordion content title */
  .accordion__title {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
  }

  /* Style the accordion chevron icon */
  .accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }

  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(90deg);
  }

  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
  }

  /* Style the accordion content text */
  .accordion__text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    // padding: 18px;
  }
`

export default Accordion

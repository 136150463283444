import * as React from 'react'
import { render } from 'react-dom'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import { IPost, IWindow } from '../../core/interfaces'
import * as constants from '../../static/constants'
import Score from './Score'

declare var window: IWindow

interface IMarkerProps {
  post: IPost
}

const Detail: React.FC<IMarkerProps> = props => {
  return (
    <DetailMarker className="Marker_Detail">
      <a href={`/posts/${props.post.slug}`} target="_blank">
        <div className="Marker_Image">
          {props.post.post_image?.image_url ? (
            <img src={props.post.post_image?.image_url} alt="" />
          ) : (
            <div className="Marker_NoImage">No Image</div>
          )}
        </div>
        <div className="Marker_Body">
          <p className="Marker_PostName">{props.post.name}</p>
          <p className="Marker_PostPrice">カット ¥ {props.post.price}</p>
          <Score score={props.post.avarage_review_score} />
        </div>
      </a>
    </DetailMarker>
  )
}

const Price: React.FC<IMarkerProps> = props => {
  return <PriceMarker className="PriceMarker">¥ {props.post.price}</PriceMarker>
}

const PriceMarker = styled.div`
  padding: 6px;
  background-color: #fff;
  border-radius: 3px;

  .hovered & {
    background-color: var(${constants.THEME_COLOR_VARIABLE_NAME});
    color: #fff;
  }

  &:hover {
    color: var(${constants.THEME_COLOR_VARIABLE_NAME});
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -4px;
    display: block;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    background-color: inherit;
    z-index: -1;
  }
`

const DetailMarker = styled.div`
  width: 280px;
  height: 320px;
  white-space: initial;
  border-radius: 3px;
  overflow: hidden;
  background-color: #fff;

  img {
    width: 100%;
  }

  .Marker_Body {
    padding: 8px;
  }

  .Marker_Image {
    width: 100%;
    height: 180px;

    > img {
      object-fit: cover;
      height: inherit;
    }
  }

  .Marker_NoImage {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    color: #333;
  }

  .Marker_PostPrice {
    font-weight: normal;
    margin-top: 8px;
  }

  .Score {
    margin-top: 12px;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -4px;
    display: block;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    background-color: inherit;
    z-index: -1;
  }
`

function CustomMarker(googleMaps) {
  class Marker extends googleMaps.OverlayView {
    public PADDING_TOP = 66
    public PADDING_LEFT = 16
    public PADDING_RIGHT = 66
    public PADDING_BOTTOM = 16
    public INFO_MARKER_WIDTH = 280
    public INFO_MARKER_HEIGHT = 320
    public latlng: any
    public map: any
    public args: any
    public markerId: number
    public div: any

    constructor(latlng, map, args) {
      super()

      this.latlng = latlng
      this.map = map
      this.args = args
      this.markerId = args.post.id
      this.setMap(map)
    }

    public getMarkerId() {
      return this.markerId
    }

    public showDetail() {
      render(<Detail post={this.args.post} />, this.div)
      this.div.style.zIndex = '1200'
      this.adjustMapPan()
    }

    public closeDetail() {
      render(<Price post={this.args.post} />, this.div)
      this.div.style.zIndex = 'auto'
    }

    public handleOnMouseEnter() {
      if (this.div) {
        this.div.classList.add('hovered')
        // this.div.style.zIndex = '1000'
      }
    }

    public handleOnMouseLeave() {
      if (this.div) {
        this.div.classList.remove('hovered')
        // this.div.style.zIndex = 'auto'
      }
    }

    private onAdd() {
      let div = this.div

      if (!div) {
        div = this.div = document.createElement('div')
        div.id = `marker_${this.args.post.id}`
        div.classList.add('Marker')
        div.style.zIndex = 'auto'
        div.style.position = 'absolute'
        div.style.display = 'inline-block'
        div.style.whiteSpace = 'nowrap'
        div.style.fontSize = '14px'
        div.style.fontWeight = 'bold'
        div.style.transform = 'translate(-50%, -100%)'
        div.style.boxShadow = '0 1px 3px 0 rgba(21, 27, 38, 0.15)'
        div.style.cursor = 'pointer'

        render(<Price post={this.args.post} />, div)

        googleMaps.event.addDomListener(div, 'click', event => {
          event.stopPropagation()
          this.showDetail()
          this.args.setActiveMarker(this.markerId)
        })

        const panes = this.getPanes()
        panes.floatPane.appendChild(div)
      }
    }

    private draw() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng)

      if (!this.div) {
        return
      }

      this.div.style.left = `${point.x}px`
      this.div.style.top = `${point.y}px`
    }

    private adjustMapPan() {
      const containerPoint = this.getProjection().fromLatLngToContainerPixel(this.latlng)
      const mapWidth = this.map.getDiv().offsetWidth
      const mapHeight = this.map.getDiv().offsetHeight
      const markerPositions = {
        left: containerPoint.x - this.INFO_MARKER_WIDTH / 2,
        right: containerPoint.x + this.INFO_MARKER_WIDTH / 2,
        top: containerPoint.y - this.INFO_MARKER_HEIGHT,
        bottom: containerPoint.y,
      }

      let panX = 0
      let panY = 0

      if (markerPositions.top < 0 + this.PADDING_TOP) {
        panY = markerPositions.top - this.PADDING_TOP
      }

      if (markerPositions.bottom > mapHeight - this.PADDING_BOTTOM) {
        const offset = markerPositions.bottom - mapHeight + this.PADDING_BOTTOM
        panY = offset
      }

      if (markerPositions.left < 0 + this.PADDING_LEFT) {
        panX = markerPositions.left - this.PADDING_LEFT
      }

      if (markerPositions.right > mapWidth - this.PADDING_RIGHT) {
        const offset = markerPositions.right - mapWidth + this.PADDING_RIGHT
        panX = offset
      }

      if (panX !== 0 || panY !== 0) {
        this.map.panBy(panX, panY)
      }
    }

    private onRemove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div)
        this.div = null
      }
    }
  }

  return (latlng, map, args) => new Marker(latlng, map, args)
}

export default CustomMarker

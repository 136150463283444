import { AxiosInstance } from 'axios'
import { IJsonResponse } from 'core/interfaces'
import IJsonApiSerializer from 'core/interfaces/IJsonApiSerializer'

class UserService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getUserFromJson(json: IJsonResponse) {
    const user = this.serializer.parseResourceData(json, json.data)
    return { user }
  }

  public async signIn(formData) {
    const { headers } = await this.httpClient.post('/users/sign_in', formData)

    return { redirectUrl: headers.location }
  }

  public async createPasswordResetInstruction(email) {
    const { headers } = await this.httpClient.post('/users/password', email)

    return { redirectUrl: headers.location }
  }

  public async resetPassword(formData) {
    const { headers } = await this.httpClient.put('/users/password', formData)

    return { redirectUrl: headers.location }
  }

  public async createConfirmation(email) {
    const { headers } = await this.httpClient.post('/users/confirmation', email)

    return { redirectUrl: headers.location }
  }

  public async show() {
    const { data } = await this.httpClient.get('/api/user')
    const user = this.serializer.parseResourceData(data.user, data.user.data)
    return { user }
  }

  public async createUser(formData) {
    const {
      data: { flash },
    } = await this.httpClient.post('/users', formData)

    return { flash }
  }

  public async updateUser(formData) {
    const { data } = await this.httpClient.patch('/api/user', formData)
    const { flash } = data
    const updatedUser = this.serializer.parseResourceData(data.user, data.user.data)

    return { updatedUser, flash }
  }

  // 未使用
  public async deleteUser(user) {
    return this.httpClient.delete(`/user/${user.id}`)
  }

  public async updateProfile(formData) {
    const { data } = await this.httpClient.patch('/api/user', formData)
    const { flash } = data
    const updatedUser = this.serializer.parseResourceData(data.user, data.user.data)

    return { updatedUser, flash }
  }

  public async updatePassword(formData) {
    const { data } = await this.httpClient.put('/api/password', formData)
    const { flash } = data
    return { flash }
  }

  public async readNotification(id) {
    await this.httpClient.get(`/api/notifications/${id}/read`)
  }
}

export default UserService

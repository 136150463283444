import * as React from 'react'
import styled from 'styled-components'
import { BadgeRequired } from '../../components/atoms'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'

interface IProps {
  name: string
  defaultValue: string
  label?: null | string
  placeholder?: null | string
  error?: null | string
  required?: boolean
  optional?: boolean
  fullWidth?: boolean
}

const InputTextArea: React.FC<IProps> = props => {
  return (
    <InputTextAreaWrapper className="InputTextArea">
      {props.label && (
        <p className="InputTextArea_Title">
          {props.label}
          {props.required && <BadgeRequired />}
          {props.optional && <span className="InputTextArea_Optional">{I18n.t('optional')}</span>}
        </p>
      )}
      <div className="InputTextArea_Main">
        <textarea
          name={props.name}
          placeholder={props.placeholder}
          required={props.required ? true : false}
          defaultValue={props.defaultValue}
        />
        {props.error && <span className="InputTextArea_Error">{props.error}</span>}
      </div>
    </InputTextAreaWrapper>
  )
}

const InputTextAreaWrapper = styled.div`
  display: flex;

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }

  textarea {
    display: block;
    width: 100%;
    height: 160px;
    padding: 8px 12px;
    border: solid 1px ${constants.BORDER_COLOR};
    border-radius: 4px;
    font-size: 15px;
    transition: border 0.2s ease;
    outline: none;
    background: none;
    line-height: 1.5;

    &:focus {
      border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
    }
  }

  .InputTextArea_Title {
    width: 240px;
    margin-top: 8px;
    font-size: 15px;
    font-weight: bold;
    line-height: 1;

    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  .InputTextArea_Optional {
    float: right;
    margin-right: 20px;
    display: inline-block;
    border-radius: 3px;
    color: #fff;
    vertical-align: middle;
    font-size: 11px;
    font-weight: normal;
    padding: 4px 6px;
    margin-left: 8px;
  }

  .InputTextArea_Optional {
    background-color: #aaa;
  }

  .InputTextArea_Main {
    flex: 1;
  }

  .InputTextArea_Error {
    display: block;
    margin-top: 8px;
    color: ${constants.DANGER_COLOR};
    font-size: 14px;
  }
`

export default InputTextArea

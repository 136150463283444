import * as React from 'react'
import 'react-dates/initialize'
import I18n from '../../../../core/i18n'
import { InputTextArea } from '../../../atoms'
import { Form } from '../../../molecules'

interface IProps {
  price: number
  formRef: any
  reservationId: number
  onCancelHandler(id: number, values: any): void
}

const CancelModal: React.FC<IProps> = props => {
  const [modalErrors, setModalErrors] = React.useState<{ reason: null | string }>({ reason: null })

  return (
    <div>
      {/*props.price > 0 ? (
        <p>{I18n.t('reservation.cancel.confirmation', { price: props.price })}</p>
      ) : (
        <p>{I18n.t('reservation.cancel.no_price')}</p>
      )*/}
      <p>{I18n.t('reservation.cancel.no_price')}</p>
      <Form
        ref={props.formRef}
        fields={{ reason: 'reason' }}
        handleUpdateForm={updatedErrors => setModalErrors(updatedErrors)}
        handleSubmit={(initialValues, values) => props.onCancelHandler(props.reservationId, values)}
      >
        <InputTextArea
          name="reason"
          label={I18n.t('reservation.cancel.reason')}
          error={modalErrors.reason}
          defaultValue=""
        />
      </Form>
    </div>
  )
}

export default CancelModal

import { AxiosInstance } from 'axios'
import { IBound, ICategory, IStaff, ITag } from 'core/interfaces'
import IJsonApiSerializer from 'core/interfaces/IJsonApiSerializer'

class PostService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getPostFromJson(data) {
    const post = this.serializer.parseResourceData(data, data.data)
    return { post }
  }

  public getPostsFromJson(data) {
    const posts = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { posts, pagination }
  }

  public getStaffsFromJson(data) {
    const staffs = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { staffs, pagination }
  }

  public getOptionsFromJson(data) {
    const options = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { options, pagination }
  }

  public getReviewsFromJson(data) {
    const reviews = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { reviews, pagination }
  }

  public getCategoriesFromJson(json) {
    const categories: ICategory[] = this.serializer.parseResourceData(json, json.data)
    return { categories }
  }

  public getTagsFromJson(json) {
    const tags: ITag[] = this.serializer.parseResourceData(json, json.data)
    return { tags }
  }

  public async index(params) {
    const { data: json } = await this.httpClient.get(`/api/posts`, { params })
    const posts = this.serializer.parseResourceData(json.posts, json.posts.data)
    const pagination = json.posts.meta

    return { posts, pagination }
  }

  public async toggleLike(post) {
    const { data } = await this.httpClient.put(`/api/posts/${post.id}/toggle_like`)

    return { like: data.like }
  }

  public async show(id) {
    const { data } = await this.httpClient.get(`/api/posts/${id}`)
    const post = this.serializer.parseResourceData(data.post, data.post.data)

    return { post }
  }

  public async create(params) {
    const { data } = await this.httpClient.post('/api/posts', params)
    const { flash } = data
    const post = this.serializer.parseResourceData(data.post, data.post.data)

    return { post, flash }
  }

  public async update(params) {
    const { data } = await this.httpClient.patch(`/api/posts/${params.id}`, { post: params })
    const { flash } = data
    const post = this.serializer.parseResourceData(data.post, data.post.data)

    return { post, flash }
  }

  public async publish(params) {
    const { data } = await this.httpClient.put(`/api/posts/${params.id}/publish`, {})
    const { post_path } = data

    return { post_path }
  }

  public async search(params: {
    bounds?: IBound
    keywords?: string
    favorite?: boolean
    page?: number
  }) {
    const { data } = await this.httpClient.get('/api/posts/search', { params })
    const posts = this.serializer.parseResourceData(data.posts, data.posts.data)
    const pagination = data.posts.meta

    return { posts, pagination }
  }

  public async createCollectionCalendar(post_id, calendar_attributes) {
    const formData = {
      post: { calendar_attributes },
    }
    const { data } = await this.httpClient.post(
      `/api/posts/${post_id}/collection_calendars`,
      formData
    )

    return { flash: data.flash }
  }

  public async getCalendarCollections(post_id, month) {
    const { data } = await this.httpClient.get(`/api/posts/${post_id}/calendar`, {
      params: { month },
    })
    const calendarCollections = this.serializer.parseResourceData(
      data.calendars,
      data.calendars.data
    )

    return { calendarCollections }
  }

  public async getCalculation(post_id, params) {
    const { start_date, end_date, number, orders, nominate, date } = params
    const { data } = await this.httpClient.get(`/api/posts/${post_id}/calculate`, {
      params: {
        start_date,
        end_date,
        date,
        number,
        orders,
        nominate,
      },
    })

    return { price: data.price, is_blocked_this_day: data.is_blocked_this_day }
  }

  public async getRelatedPosts(post) {
    const { data } = await this.httpClient.get(`/api/posts/${post.id}/related`)
    const relatedPosts = this.serializer.parseResourceData(data.posts, data.posts.data)

    return { relatedPosts }
  }

  public async createReview(params) {
    return this.httpClient.post(`/api/reviews`, params)
  }

  public async createReviewReply(params) {
    const { data } = await this.httpClient.post(`/api/review_replies`, params)

    return { reviewReply: data }
  }

  public async getStaff(post_id, id) {
    const { data } = await this.httpClient.get(`/api/posts/${post_id}/staffs/${id}`)
    const staff: IStaff = this.serializer.parseResourceData(data.staff, data.staff.data)
    const qualifications: string[] = data.qualifications
    return { staff, qualifications }
  }

  public async createStaff(post_id, params) {
    const { data } = await this.httpClient.post(`/api/posts/${post_id}/staffs`, params)
    const { flash } = data
    const staff = this.serializer.parseResourceData(data.staff, data.staff.data)

    return { staff, flash }
  }

  public async updateStaff(post_id, id, params) {
    const { data } = await this.httpClient.patch(`/api/posts/${post_id}/staffs/${id}`, params)
    const { flash } = data
    const staff = this.serializer.parseResourceData(data.staff, data.staff.data)

    return { staff, flash }
  }

  public async deleteStaff(post_id, id) {
    const { data } = await this.httpClient.delete(`/api/posts/${post_id}/staffs/${id}`)
    const { flash } = data
    const staff = this.serializer.parseResourceData(data.staff, data.staff.data)

    return { staff, flash }
  }

  public async getStaffs(post_id, params) {
    const { data: json } = await this.httpClient.get(`/api/posts/${post_id}/staffs`, { params })
    const staffs = this.serializer.parseResourceData(json.staffs, json.staffs.data)
    const pagination = json.staffs.meta
    return { staffs, pagination }
  }

  public async getOption(post_id, id) {
    const { data } = await this.httpClient.get(`/api/posts/${post_id}/options/${id}`)
    const option = this.serializer.parseResourceData(data.option, data.option.data)
    return { option }
  }

  public async createOption(params) {
    const { data } = await this.httpClient.post(`/api/posts/${params.post_id}/options`, params)
    const { flash } = data
    const option = this.serializer.parseResourceData(data.option, data.option.data)

    return { option, flash }
  }

  public async updateOption(params) {
    const { data } = await this.httpClient.patch(
      `/api/posts/${params.post_id}/options/${params.id}`,
      params
    )
    const { flash } = data
    const option = this.serializer.parseResourceData(data.option, data.option.data)
    return { option, flash }
  }

  public async deleteOption(params) {
    const { data } = await this.httpClient.delete(
      `/api/posts/${params.post_id}/options/${params.id}`
    )
    const { flash } = data
    return { flash }
  }

  public async getOptions(post_id) {
    const { data } = await this.httpClient.get(`/api/posts/${post_id}/options`)
    const options = this.serializer.parseResourceData(data.options, data.options.data)

    return { options }
  }
}

export default PostService

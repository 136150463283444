import { AxiosInstance } from 'axios'
import IJsonApiSerializer from 'core/interfaces/IJsonApiSerializer'

class AdminUserService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public async index(params) {
    const { data } = await this.httpClient.get('/admin/api/users', { params })
    const users = this.serializer.parseResourceData(data.users, data.users.data)
    const pagination = data.users.meta

    return { users, pagination }
  }

  public async create(user) {
    const { data } = await this.httpClient.post('/admin/api/users', user)
    const createdUser = this.serializer.parseResourceData(data.user, data.user.data)

    return { createdUser, flash: data.flash }
  }

  public async update(user) {
    const { data } = await this.httpClient.patch(`/admin/api/users/${user.id}`, user)
    const updatedUser = this.serializer.parseResourceData(data.user, data.user.data)

    return { updatedUser, flash: data.flash }
  }

  public async delete(id) {
    const {
      data: { flash },
    } = await this.httpClient.delete(`/admin/api/users/${id}`)

    return { flash }
  }

  public async becomeUser(id) {
    const { data } = await this.httpClient.post(`/admin/api/users/${id}/become`)

    const becameUser = this.serializer.parseResourceData(data.user, data.user.data)

    return { becameUser, flash: data.flash }
  }
}

export default AdminUserService

import Loading from 'components/atoms/Loading'
import { IPager, IPost } from 'core/interfaces'
import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import { postService } from '../../../core/services'
import * as constants from '../../../static/constants'
import * as Hooks from '../../../utils/hooks'
import { Pagination } from '../../atoms'

const PostIndex: React.FC<{}> = () => {
  Hooks.SetDocumentTitle(I18n.t('nav.header.posts'))
  const [posts, setPosts] = React.useState<IPost[]>(null)
  const [pagination, setPagination] = React.useState<IPager>()

  const [order, setOrder] = React.useState({
    column: getCurrentSortColumn(),
    direction: getCurrentSortDirection(),
  })
  const tableRef = React.useRef(null)
  const scrollerRef = React.useRef(null)

  React.useEffect(() => {
    const f = async () => {
      const result = await postService.index({})
      setPosts(result.posts)
      setPagination(result.pagination)
    }
    if (!posts) {
      f()
    }
  }, [posts])

  // React.useEffect(() => {
  //   if (tableRef && scrollerRef) {
  //     // const tableWidth = tableRef.current.offsetWidth
  //     // scrollerRef.current.style.width = `${tableWidth}px`
  //   }
  // }, [tableRef, scrollerRef])

  const getDirection = column => {
    if (order.column === column) {
      return order.direction === 'asc' ? 'desc' : 'asc'
    } else {
      return 'asc'
    }
  }

  const changeOrder = column => {
    const direction = getDirection(column)

    location.href = `/posts?sort=${column}&direction=${direction}`
  }

  const getOrderIndicator = column => {
    if (order.column !== column) {
      return null
    } else {
      return order.direction === 'asc' ? '↓' : '↑'
    }
  }

  const onChangePageHandler = async page => {
    const result = await postService.index({ page })
    setPosts(result.posts)
    setPagination(result.pagination)
  }

  return (
    <Wrapper>
      <div className="Wrapper_Header">
        <h2>{I18n.t('nav.header.posts')}</h2>
      </div>
      <div className="Wrapper_Main" ref={scrollerRef}>
        {!posts ? (
          <Loading />
        ) : (
          <table ref={tableRef}>
            <thead>
              <tr>
                <th />
                <th />
                <th onClick={() => changeOrder('name')}>
                  {getOrderIndicator('name')}
                  {I18n.t('post.post_name')}
                </th>
                <th onClick={() => changeOrder('description')}>
                  {getOrderIndicator('description')}
                  {I18n.t('post.post_description')}
                </th>
                <th onClick={() => changeOrder('aasm_state')}>
                  {getOrderIndicator('aasm_state')}
                  {I18n.t('generic.status')}
                </th>
                <th onClick={() => changeOrder('price')}>
                  {getOrderIndicator('price')}
                  {I18n.t('generic.price')}
                </th>
                <th onClick={() => changeOrder('updated_at')}>
                  {getOrderIndicator('updated_at')}
                  {I18n.t('generic.updated_at')}
                </th>
                <th onClick={() => changeOrder('created_at')}>
                  {getOrderIndicator('created_at')}
                  {I18n.t('generic.created_at')}
                </th>
              </tr>
            </thead>
            <tbody>
              {posts &&
                posts.map((post, index) => (
                  <tr key={index}>
                    <td>
                      <div className="PostList_Image">
                        {post.post_image && post.post_image.image_url ? (
                          <img src={post.post_image.image_url} alt="" />
                        ) : (
                          <p className="PostList_NoImage">No Image</p>
                        )}
                      </div>
                    </td>
                    <td>
                      <a href={`posts/${post.slug}/edit`}>{I18n.t('generic.edit')}</a>
                    </td>
                    <td>
                      <a href={`/posts/${post.slug}`} target="_brank">
                        {post.name}
                      </a>
                    </td>
                    <td>{trimText(post.description)}</td>
                    <td>{post.aasm_state}</td>
                    <td>¥ {post.price}</td>
                    <td>{post.updated_at}</td>
                    <td>{post.created_at}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {pagination && (
          <Pagination
            onChangePageHandler={onChangePageHandler}
            currentPage={pagination.current_page}
            prevPage={pagination.prev_page}
            nextPage={pagination.next_page}
            totalCount={pagination.total_count}
            totalPages={pagination.total_pages}
          />
        )}
      </div>
    </Wrapper>
  )
}

function getCurrentSortColumn() {
  const urlParams = new URLSearchParams(window.location.search)

  return urlParams.get('sort') || 'name'
}

function getCurrentSortDirection() {
  const urlParams = new URLSearchParams(window.location.search)

  return urlParams.get('direction') || 'asc'
}

function trimText(text) {
  const MAX_LENGTH = 50

  if (typeof text === 'string') {
    return text.length > MAX_LENGTH ? `${text.slice(0, MAX_LENGTH)}...` : text
  } else {
    return ''
  }
}

const Wrapper = styled.div`
  margin: 0 auto;

  .Wrapper_Header {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 24px;
    border-bottom: solid 1px ${constants.BORDER_COLOR};
  }

  .Wrapper_Main {
    overflow: scroll;

    .PostList_Image {
      width: 160px;
      height: 120px;
      border-radius: 3px;
      overflow: hidden;

      > img {
        object-fit: cover;
        width: inherit;
        height: inherit;
      }
    }

    .PostList_NoImage {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f4f4f4;
    }

    table {
      width: 100%;
    }

    th,
    td {
      padding: 8px 12px;
      max-width: 320px;

      > a {
        color: var(${constants.THEME_COLOR_VARIABLE_NAME});
        text-decoration: underline;
      }
    }

    th {
      cursoor: pointer;

      &:hover {
        background-color: #f6f8f9;
      }
    }

    tbody {
      > tr {
        border-top: solid 1px ${constants.BORDER_COLOR};
      }
    }

    .Pagination {
      height: 48px;
      border-top: solid 1px ${constants.BORDER_COLOR};
    }
  }
`

const PostList = styled.div`
  width: 5000px;
`

export default PostIndex

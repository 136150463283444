import ClassNames from 'classnames'
import * as React from 'react'
import ClickOutside from 'react-click-outside'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import * as constants from '../../../static/constants'

interface IType {
  id: number | string
  name: string
}

interface IProps {
  field: string
  name: string
  types: IType[]
  setIsFilterPanelOpen?(isOpen: boolean): void
  handleOnSubmit(filterValue: any): void
}

const initialParams = new URLSearchParams(window.location.search)

const TypeFilter: React.FC<IProps> = props => {
  const getInitialValue = () =>
    initialParams.has(`${props.field}[]`) ? initialParams.getAll(`${props.field}[]`) : []
  const [isOpen, setIsOpen] = React.useState(false)
  const [types, setTypes] = React.useState(getInitialValue())
  const [labelName, setLabelName] = React.useState(props.name)
  const setPanelIsOpen = React.useCallback(
    (open: boolean) => {
      if (open === isOpen) {
        return
      }
      if (typeof props.setIsFilterPanelOpen === 'function') {
        props.setIsFilterPanelOpen(open)
      }
      setIsOpen(open)
    },
    [isOpen]
  )

  const onChangeHandler = event => {
    const value = event.target.value

    if (event.target.checked) {
      if (!types.includes(value)) {
        setTypes([...types, value])
      }
    } else {
      if (types.includes(value)) {
        setTypes(types.filter(type => type !== value))
      }
    }
  }

  React.useEffect(() => {
    if (types.length) {
      const updatedLabelName = props.types
        .map(type => (types.includes(String(type.id)) ? type.name : null))
        .filter(type => type !== null)
        .join(', ')
      setLabelName(updatedLabelName)
    } else {
      setLabelName(props.name)
    }
  }, [types])

  const hasValue = () => types.length
  const onClear = () => {
    setTypes([])
  }
  const onApply = React.useCallback(() => {
    props.handleOnSubmit({ [props.field]: types })
    setPanelIsOpen(false)
  }, [types, setPanelIsOpen, props.handleOnSubmit])

  return (
    <ClickOutside onClickOutside={() => setPanelIsOpen(false)}>
      <TypeFilterWrapper className="TypeFilter">
        <FilterLabel
          className={ClassNames('Filter_Label', { filled: isOpen || hasValue() })}
          onClick={() => setPanelIsOpen(!isOpen)}
        >
          {labelName}
        </FilterLabel>
        {isOpen && (
          <FilterWindow className="FilterWindow">
            <form action="">
              <CheckBox>
                <div className="CheckBox_Main">
                  {props.types.map(type => (
                    <label key={type.id}>
                      <input
                        type="checkbox"
                        name="type_ids"
                        value={String(type.id)}
                        onChange={onChangeHandler}
                        checked={types.includes(String(type.id))}
                      />
                      <span className="CheckBox_Icon">
                        <i className="material-icons filled">check_box</i>
                        <i className="material-icons outline">check_box_outline_blank</i>
                      </span>
                      <span>{type.name}</span>
                    </label>
                  ))}
                </div>
              </CheckBox>
            </form>
            <FilterFooter>
              <div className={ClassNames('_clear', { disabled: !hasValue() })} onClick={onClear}>
                {I18n.t('generic.clear')}
              </div>
              <div className="_apply" onClick={onApply}>
                {I18n.t('generic.apply')}
              </div>
            </FilterFooter>
          </FilterWindow>
        )}
      </TypeFilterWrapper>
    </ClickOutside>
  )
}

const FilterWindow = styled.div`
  position: absolute;
  left: 0;
  bottom: -8px;
  transform: translateY(100%);
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  padding: 16px;
  border-radius: 4px;
  z-index: 1100;
  width: 320px;

  @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
    position: fixed;
    top: ${constants.HEADER_HEIGHT}px;
    left: 0;
    right: 0;
    height: calc(100vh - ${constants.HEADER_HEIGHT}px);
    transform: none;
    width: auto;
  }
`

const FilterLabel = styled.div`
  border: solid 1px ${constants.BORDER_COLOR};
  height: 36px;
  display: flex;
  align-items: center;
  margin: 0 6px;
  padding: 0 12px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;

  &.filled {
    background-color: var(${constants.THEME_COLOR_VARIABLE_NAME});
    border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
    color: #fff;
  }

  &:hover:not(.filled) {
    border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
    color: var(${constants.THEME_COLOR_VARIABLE_NAME});
  }
`

const FilterFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 12px 0;

  ._clear,
  ._apply {
    font-weight: bold;
    cursor: pointer;
  }

  ._clear {
    color: #aaa;

    &.disabled {
      visibility: hidden;
    }
  }

  ._apply {
    color: var(${constants.THEME_COLOR_VARIABLE_NAME});
  }
`

const TypeFilterWrapper = styled.div`
  position: relative;
`

const CheckBox = styled.div`
  display: flex;

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }

  .CheckBox_Title {
    width: 240px;
    margin-top: 8px;
    font-size: 15px;
    font-weight: bold;
    line-height: 1;

    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  .CheckBox_Main {
    flex: 1;

    > label {
      display: block;
      position: relative;
      cursor: pointer;

      &:hover {
        opacity: 0.75;
      }

      input {
        opacity: 0;
        height: 0;
        width: 0;
      }

      .CheckBox_Icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 24px;
        height: 24px;

        i {
          position: absolute;
          top: 0;
          left: 0;

          &.filled {
            display: none;
            color: var(${constants.THEME_COLOR_VARIABLE_NAME});
          }

          &.outline {
            display: block;
            color: ${constants.BORDER_COLOR};
          }
        }
      }

      input:checked + .CheckBox_Icon {
        i {
          &.filled {
            display: block;
          }

          &.outline {
            display: none;
          }
        }
      }

      span {
        font-size: 14px;
        padding-left: 28px;
      }
    }

    > label + label {
      margin-top: 16px;
    }
  }
`

export default TypeFilter

import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IJsonResponse, IUser, IWindow } from '../../../../core/interfaces'
import { userService } from '../../../../core/services'
import { COLORS } from '../../../../static/constants'
import { Button, InputText, Panel, Select } from '../../../atoms'
import { Form } from '../../../molecules'

import SettingsCommonLayout from '../_layouts/common'

declare let window: IWindow

interface IProps {
  user: IJsonResponse
}

const FIELDS = {
  financial_institution_name: 'financial_institution_name',
  financial_branch_name: 'financial_branch_name',
  financial_account_number: 'financial_account_number',
  financial_account_type: 'financial_account_type',
  financial_account_holder_name: 'financial_account_holder_name',
}

const ProfileEdit: React.FC<IProps> = props => {
  const { user: initialUser } = userService.getUserFromJson(props.user)
  const [user, setUser] = React.useState<IUser>(initialUser)

  const handleSubmit = async (initialValues, values) => {
    const formData = new FormData()
    Object.keys(values).forEach(key => {
      formData.append(`user[${key}]`, values[key])
    })
    const { updatedUser, flash } = await userService.updateProfile(formData)

    setUser(updatedUser)
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
  }

  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [errors, setErrors] = React.useState<any>({})

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }

  return (
    <SettingsCommonLayout
      activePath={'bank_account'}
      is_owner={user.is_owner}
      main={
        <>
          <Panel title={I18n.t('settings.bank_account.title')}>
            <Form fields={FIELDS} handleSubmit={handleSubmit} handleUpdateForm={handleUpdateForm}>
              <S.FormItem>
                <InputText
                  required={true}
                  name="financial_institution_name"
                  label={I18n.t('settings.bank_account.name')}
                  defaultValue={user.financial_institution_name || ''}
                  error={errors.financial_institution_name}
                  placeholder={I18n.t('settings.bank_account.placeholder.name')}
                />
              </S.FormItem>
              <S.FormItem>
                <InputText
                  required={true}
                  name="financial_branch_name"
                  label={I18n.t('settings.bank_account.branch_name')}
                  defaultValue={user.financial_branch_name || ''}
                  error={errors.financial_branch_name}
                  placeholder={I18n.t('settings.bank_account.placeholder.branch_name')}
                />
              </S.FormItem>
              <S.FormItem>
                <InputText
                  required={true}
                  name="financial_account_number"
                  label={I18n.t('settings.bank_account.number')}
                  defaultValue={user.financial_account_number || ''}
                  error={errors.financial_account_number}
                  placeholder={I18n.t('settings.bank_account.placeholder.number')}
                />
              </S.FormItem>
              <S.FormItem>
                <Select
                  required={true}
                  name="financial_account_type"
                  label={I18n.t('settings.bank_account.type')}
                  options={[
                    { value: '普通', label: '普通' },
                    { value: '当座', label: '当座' },
                  ]}
                  defaultValue={user.financial_account_type || '普通'}
                />
              </S.FormItem>
              <S.FormItem>
                <InputText
                  required={true}
                  name="financial_account_holder_name"
                  label={I18n.t('settings.bank_account.account_name')}
                  defaultValue={user.financial_account_holder_name || ''}
                  error={errors.financial_account_holder_name}
                  placeholder={I18n.t('settings.bank_account.placeholder.account_name')}
                />
              </S.FormItem>
              <S.FormItem>
                <Button primary={true} disabled={!isSubmitEnabled}>
                  {I18n.t('generic.save')}
                </Button>
              </S.FormItem>
            </Form>
          </Panel>
        </>
      }
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.FormItem = styled.div`
  & + & {
    margin-top: 16px;
  }
`

S.BankAccout = styled.div`
  margin: 24px 0;
  padding-top: 24px;
  border-top: solid 1px ${COLORS.Border};
`

export default ProfileEdit

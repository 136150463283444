import * as Color from 'color'
import { createGlobalStyle } from 'styled-components'
import * as constants from './constants'

const themeColor =
  getComputedStyle(document.documentElement)
    .getPropertyValue(`${constants.THEME_COLOR_VARIABLE_NAME}`)
    .trim() || '#4A90E2'

const dateRangePickerCustomStyle = createGlobalStyle`
  .SingleDatePicker.SingleDatePicker_1 {
    width: 100%;
  }

  .SingleDatePickerInput.SingleDatePickerInput_1 {
    width: 100%;
    border: solid 1px ${constants.BORDER_COLOR};
  }

  .DateInput.DateInput_1 {
    width: 100%;
  }

  .DateInput_input.DateInput_input_1 {
    font-size: 16px;
  }

  .DateInput_input__focused {
    border-bottom: none;
  }

  .CalendarDay__selected_span {
    background: ${Color(themeColor)
      .lighten(0.25)
      .hex()};
    border: 1px double
      ${Color(themeColor)
        .lighten(0.15)
        .hex()};
    color: #fff;
  }

  .CalendarDay__selected {
    background: ${themeColor};
    border: 1px double ${themeColor};
    color: white;
  }

  .CalendarDay__selected:hover {
    background: ${themeColor};
    color: white;
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: ${Color(themeColor)
      .lighten(0.25)
      .hex()};
    border: 1px double
      ${Color(themeColor)
        .lighten(0.15)
        .hex()};
    color: #fff;
  }
`

export default dateRangePickerCustomStyle

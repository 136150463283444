import * as React from 'react'
import 'react-dates/initialize'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { IWindow } from '../../../../core/interfaces'
import { reservationService } from '../../../../core/services'
import { InputText } from '../../../atoms'
import { Form } from '../../../molecules'

declare var window: IWindow

interface IProps {
  reservation_slug: string
  createReceiptFormRef: any
  setReservation: any
}

const ReceiptModal: React.FC<IProps> = props => {
  const [errors, setErrors] = React.useState<{ receipt_address: null | string }>({
    receipt_address: null,
  })
  const handleSubmit = async (initialValues, values) => {
    const { reservation } = await reservationService.createReceipt(props.reservation_slug, {
      receipt: {
        name: values.receipt_address,
      },
    })
    props.setReservation(reservation)
    const link = document.createElement('a')
    link.setAttribute('target', '_blank')
    link.setAttribute('href', `/reservations/${props.reservation_slug}/receipt`)
    document.body.appendChild(link).click()
    window.globalModal.closeModal()
  }

  return (
    <div>
      <Form
        ref={props.createReceiptFormRef}
        fields={{ receipt_address: 'receipt_address' }}
        handleUpdateForm={(updatedErrors, isSubmitEnabled) => setErrors(updatedErrors)}
        handleSubmit={handleSubmit}
      >
        <InputText
          required={true}
          name="receipt_address"
          label={I18n.t('receipt.receipt_address')}
          error={errors.receipt_address}
          defaultValue=""
        />
        <Note>{I18n.t('receipt.note')}</Note>
      </Form>
    </div>
  )
}

const Note = styled.span`
  display: block;
  font-size: 14px;
  margin-top: 12px;
`

export default ReceiptModal

import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { IUser, IWindow } from '../../../../core/interfaces'
import { settingsService } from '../../../../core/services'
import * as constants from '../../../../static/constants'
import { Panel } from '../../../atoms'
import SettingsCommonLayout from '../_layouts/common'

declare var window: IWindow

interface IProps {
  current_user: IUser
  email_notification: boolean
}

const Show: React.FC<IProps> = props => {
  const [emailNotification, setEmailNotification] = React.useState(props.email_notification)

  const handleChangeCheck = async event => {
    const data: any = {}
    const updatedParams: any = {}
    updatedParams[event.target.name] = event.target.checked
    data.settings = updatedParams
    const { flash } = await settingsService.updateNotification(data)
    setEmailNotification(!emailNotification)
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
  }

  return (
    <SettingsCommonLayout
      activePath={'notification'}
      is_owner={props.current_user.is_owner}
      main={
        <Panel title={I18n.t('settings.notifications.title')}>
          <Field>
            <label>{I18n.t('settings.notifications.email')}</label>
            <div>
              <Switch>
                <input
                  name="email_notification"
                  type="checkbox"
                  defaultChecked={emailNotification}
                  onChange={event => {
                    handleChangeCheck(event)
                  }}
                />
                <span className="slider round" />
              </Switch>
            </div>
          </Field>
        </Panel>
      }
    />
  )
}

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slider {
    background-color: var(${constants.THEME_COLOR_VARIABLE_NAME});
  }
  input:focus + .slider {
    box-shadow: 0 0 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
`

const Field = styled.div`
  display: flex;
  & + & {
    margin-top: 24px;
    padding-top: 20px;
    border-top: solid 1px ${constants.BORDER_COLOR};
  }
  > label {
    width: 200px;
    font-weight: bold;
  }
  > div {
    flex: 1;
  }
  a {
    color: var(${constants.THEME_COLOR_VARIABLE_NAME});
    display: inline-block;
    margin-top: 4px;
    font-size: 14px;
  }
`

export default Show

import { IPostImage } from 'core/interfaces'
import 'moment/locale/ja'
import * as React from 'react'
import 'react-dates/initialize'
import Slider from 'react-slick'
import styled from 'styled-components'
import * as constants from '../../../../static/constants'

interface IProps {
  postImages: IPostImage[]
}

const PostGallery: React.FC<IProps> = props => {
  const main = React.useRef(null)
  const mainSettings = {
    arrows: false,
    dots: false,
  }

  return (
    <PostGalleryWrapper>
      <div className="PostGallery_Main">
        <Slider {...mainSettings} ref={main}>
          {props.postImages.map(image => (
            <div key={image.id} className="Slick_MainImage">
              <img src={image.image_url} alt="" />
              {image.description && <p className="Slick_ImageDescription">{image.description}</p>}
            </div>
          ))}
        </Slider>
      </div>
    </PostGalleryWrapper>
  )
}

const PostGalleryWrapper = styled.div`
  height: 180px;
  margin: 0;

  /* @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    height: 512px;
  }
  @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
    height: 412px;
  } */

  .Slick_MainImage {
    position: relative;
    width: 100%;
    height: 180px;
    outline: none;
    background-color: #fafafa;
    border-radius: 3px;
    overflow: hidden;
    /* @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      height: 400px;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      height: 320px;
    } */

    img {
      width: 100%;
      height: inherit;
      object-fit: cover;
    }

    .Slick_ImageDescription {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 8px 12px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.65);
    }
  } 
`

export default PostGallery

import * as React from 'react'
import styled from 'styled-components'
import { BadgeRequired } from '../../components/atoms'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'

interface IProps {
  name: string
  defaultValue: string | number
  readonly?: boolean
  label?: null | string
  placeholder?: null | string
  error?: null | string
  required?: boolean
  requiredTextHide?: boolean
  non_public?: boolean
  password?: boolean
  inputRef?: any
  hidden?: boolean
  display?: boolean
  isNumber?: boolean
  onChangeHandler?(event: any): void | Promise<void>
  onBlurHandler?(event: any): void | Promise<void>
}

const InputText: React.FC<IProps> = props => {
  return (
    <InputTextWrapper className="InputText">
      {props.label && (
        <p className="InputText_Title">
          {props.label}
          {props.required && !props.requiredTextHide && <BadgeRequired />}
          {props.non_public && (
            <span className="InputText_Required">{I18n.t('generic.non_public')}</span>
          )}
        </p>
      )}
      <div className="InputText_Main" data-hidden={props.hidden}>
        <input
          id={props.name}
          type={props.password ? 'password' : props.isNumber ? 'number' : 'text'}
          name={props.name}
          placeholder={props.placeholder}
          required={props.required ? true : false}
          defaultValue={props.defaultValue || ''}
          onChange={event => {
            if (typeof props.onChangeHandler === 'function') {
              props.onChangeHandler(event)
            }
          }}
          onBlur={event => {
            if (typeof props.onBlurHandler === 'function') {
              props.onBlurHandler(event)
            }
          }}
          ref={props.inputRef || null}
          readOnly={props.readonly || false}
        />
        {props.error && <span className="InputText_Error">{props.error}</span>}
      </div>
      {props.display && (
        <label>
          <p className="Display_Label">{props.defaultValue}</p>
        </label>
      )}
    </InputTextWrapper>
  )
}

const InputTextWrapper = styled.div`
  display: flex;

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }

  input {
    display: block;
    width: 100%;
    padding: 8px 12px;
    border: solid 1px ${constants.BORDER_COLOR};
    border-radius: 4px;
    font-size: 15px;
    transition: border 0.2s ease;
    outline: none;
    background: none;

    &:not([readonly]):focus {
      border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
    }
  }

  input[readonly] {
    background-color: ${constants.BORDER_COLOR};
  }

  .InputText_Title {
    width: 240px;
    margin-top: 8px;
    font-size: 15px;
    font-weight: bold;
    line-height: 1;

    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  .Display_Label {
    margin-top: 8px;
  }

  .InputText_Main {
    flex: 1;

    &[data-hidden='true'] {
      display: none;
    }

    &[data-display='true'] {
      display: flex;
    }
  }

  .InputText_Error {
    display: block;
    margin-top: 8px;
    color: ${constants.DANGER_COLOR};
    font-size: 14px;
  }
`

export default InputText

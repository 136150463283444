import { AxiosInstance } from 'axios'
import IJsonApiSerializer from 'core/interfaces/IJsonApiSerializer'

class MessageService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public async get(params) {
    const { data } = await this.httpClient.get('/api/messages/', { params })
    const messages = this.serializer.parseResourceData(data.messages, data.messages.data)
    const pagination = data.messages.meta
    return { messages, pagination }
  }
}

export default MessageService

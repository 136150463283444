import ClassNames from 'classnames'
import * as moment from 'moment'
import 'moment/locale/ja'
import * as React from 'react'
import ClickOutside from 'react-click-outside'
import { DayPickerRangeController } from 'react-dates'
import 'react-dates/initialize'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import * as constants from '../../../static/constants'
import DateRangePickerCustomStyle from '../../../static/dateRangePickerCustomStyle'

interface IProps {
  name: string
  field: string
  setIsFilterPanelOpen?(isOpen: boolean): void
  handleOnSubmit(filterValue: any): void
}

moment.locale('ja', {
  months: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
})

interface ISelectedDate {
  startDate: null | moment.Moment
  endDate: null | moment.Moment
}

const initialParams = new URLSearchParams(window.location.search)
const getInitialValue = () => ({
  startDate: initialParams.has('start_date') ? moment(initialParams.get('start_date')) : null,
  endDate: initialParams.has('end_date') ? moment(initialParams.get('end_date')) : null,
})

const useDateRange = ({ handleOnSubmit, setPanelIsOpen }) => {
  const [labelName, setLabelName] = React.useState(I18n.t('generic.date'))
  const [selectedDate, setSelectedDate] = React.useState<ISelectedDate>(getInitialValue())

  const hasValue = React.useCallback(
    () => moment.isMoment(selectedDate.startDate) && moment.isMoment(selectedDate.endDate),
    [selectedDate]
  )

  const onDateRangePickerChange = React.useCallback((dates: ISelectedDate) => {
    const updatedSelectedDate = {
      startDate: dates.startDate,
      endDate: dates.endDate,
    }
    setSelectedDate(updatedSelectedDate)
  }, [])

  const onClear = React.useCallback(() => {
    setSelectedDate({ startDate: null, endDate: null })
  }, [])

  const onApply = React.useCallback(() => {
    const hasStartDate = moment.isMoment(selectedDate.startDate)
    const hasEndDate = moment.isMoment(selectedDate.endDate)

    if (hasStartDate && !hasEndDate) {
      setSelectedDate({
        startDate: moment(selectedDate.startDate),
        endDate: moment(selectedDate.startDate).add(1, 'days'),
      })
    }

    const filterValue: any = {
      start_date: hasStartDate ? selectedDate.startDate.format('YYYY-MM-DD') : null,
      end_date: hasEndDate
        ? selectedDate.endDate.format('YYYY-MM-DD')
        : hasStartDate
        ? moment(selectedDate.startDate)
            .add(1, 'days')
            .format('YYYY-MM-DD')
        : null,
    }

    handleOnSubmit(filterValue)
    setPanelIsOpen(false)
  }, [selectedDate, handleOnSubmit, setPanelIsOpen])

  React.useEffect(() => {
    const hasStartDate = moment.isMoment(selectedDate.startDate)
    const hasEndDate = moment.isMoment(selectedDate.endDate)

    if (hasStartDate && hasEndDate) {
      setLabelName(
        `${selectedDate.startDate.format('M月D日')} - ${selectedDate.endDate.format('M月D日')}`
      )
    } else if (hasStartDate && !hasEndDate) {
      setLabelName(`${selectedDate.startDate.format('M月D日')} - `)
    } else {
      setLabelName(I18n.t('generic.date'))
    }
  }, [selectedDate])

  return {
    hasValue,
    labelName,
    onApply,
    onClear,
    onDateRangePickerChange,
    selectedDate,
  }
}

const DateRangeFilter: React.FC<IProps> = props => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [focusedInput, setFocusedInput] = React.useState<null | 'startDate' | 'endDate'>(
    'startDate'
  )
  const isMobile = matchMedia(`(max-width: ${constants.BREAKPOINT_TABLET_SMALL}px)`).matches
  const setPanelIsOpen = React.useCallback(
    (open: boolean) => {
      if (open === isOpen) {
        return
      }
      if (typeof props.setIsFilterPanelOpen === 'function') {
        props.setIsFilterPanelOpen(open)
      }
      setIsOpen(open)
    },
    [isOpen]
  )
  const { handleOnSubmit } = props
  const {
    hasValue,
    labelName,
    onApply,
    onClear,
    onDateRangePickerChange,
    selectedDate,
  } = useDateRange({ handleOnSubmit, setPanelIsOpen })

  return (
    <ClickOutside onClickOutside={() => setPanelIsOpen(false)}>
      <DateRangeFilterWrapper className="DateRangeFilter">
        <FilterLabel
          className={ClassNames('Filter_Label', { filled: isOpen || hasValue() })}
          onClick={() => setPanelIsOpen(!isOpen)}
        >
          {labelName}
        </FilterLabel>
        {isOpen && (
          <div className="Filter_Main">
            <DateRangePickerCustomStyle />
            <DayPickerRangeController
              monthFormat="YYYY年M月"
              noBorder={true}
              startDate={selectedDate.startDate}
              endDate={selectedDate.endDate}
              onDatesChange={onDateRangePickerChange}
              focusedInput={focusedInput}
              onFocusChange={(currentFocusedInput: null | 'startDate' | 'endDate') =>
                setFocusedInput(currentFocusedInput || 'startDate')
              }
              enableOutsideDays={false}
              numberOfMonths={isMobile ? 1 : 2}
              hideKeyboardShortcutsPanel={true}
            />
            <FilterFooter>
              <div className={ClassNames('_clear', { disabled: !hasValue() })} onClick={onClear}>
                {I18n.t('generic.clear')}
              </div>
              <div className="_apply" onClick={onApply}>
                {I18n.t('generic.apply')}
              </div>
            </FilterFooter>
          </div>
        )}
      </DateRangeFilterWrapper>
    </ClickOutside>
  )
}

const FilterLabel = styled.div`
  border: solid 1px ${constants.BORDER_COLOR};
  height: 36px;
  display: flex;
  align-items: center;
  margin: 0 6px;
  padding: 0 12px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;

  &.filled {
    background-color: var(${constants.THEME_COLOR_VARIABLE_NAME});
    border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
    color: #fff;
  }

  &:hover:not(.filled) {
    border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
    color: var(${constants.THEME_COLOR_VARIABLE_NAME});
  }
`

const FilterFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 12px 0;

  ._clear,
  ._apply {
    font-weight: bold;
    cursor: pointer;
  }

  ._clear {
    color: #aaa;

    &.disabled {
      visibility: hidden;
    }
  }

  ._apply {
    color: var(${constants.THEME_COLOR_VARIABLE_NAME});
  }
`

const DateRangeFilterWrapper = styled.div`
  position: relative;

  & + & {
    margin-left: 12px;
  }

  .Filter_Main {
    position: absolute;
    left: 0;
    bottom: -8px;
    transform: translateY(100%);
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    padding: 16px;
    border-radius: 4px;
    z-index: 1100;
    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      position: fixed;
      top: ${constants.HEADER_HEIGHT}px;
      left: 0;
      right: 0;
      height: calc(100vh - 52px);
      transform: none;
      width: auto;
    }

    .DayPicker {
      margin: auto;
    }
  }
`

export default DateRangeFilter

import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import * as constants from '../../../../static/constants'
import * as Hooks from '../../../../utils/hooks'
import { Button, Panel } from '../../../atoms'
import Nav from '../../../organisms/AdminNav'

interface IProps {
  settings: any
}

const AdminCompaniesShow: React.FC<IProps> = props => {
  Hooks.SetDocumentTitle(I18n.t('nav.admins.company'))
  const settings = props.settings.reduce((obj, setting) => {
    obj[setting.var] = setting.value
    return obj
  }, {})

  return (
    <IndexWrapper>
      <Nav active="company" />
      <section className="Index_Main">
        <Panel title={I18n.t('nav.admins.company')}>
          <dl>
            <dt>{I18n.t('admin.company.name')}</dt>
            <dd>{settings.company_name}</dd>
            <dt>{I18n.t('admin.company.address')}</dt>
            <dd>{settings.company_address}</dd>
            <dt>{I18n.t('admin.company.phone')}</dt>
            <dd>{settings.company_phone}</dd>
            <dt>{I18n.t('admin.company.fax')}</dt>
            <dd>{settings.company_fax}</dd>
            <dt>{I18n.t('admin.company.opening_time')}</dt>
            <dd>{settings.company_opening_time}</dd>
            <dt>{I18n.t('admin.company.mail')}</dt>
            <dd>{settings.company_mail}</dd>
            <dt>{I18n.t('admin.company.bank')}</dt>
            <dd className="Pre_Wrap">{settings.company_bank}</dd>
            {/* <dt>{I18n.t('admin.setting.social_profiles')}</dt>
            <dd>{settings.social_profiles}</dd> */}
          </dl>
          <div className="Buttons">
            <Button primary={true}>
              <a href="/admin/company/edit">{I18n.t('generic.edit')}</a>
            </Button>
          </div>
        </Panel>
      </section>
    </IndexWrapper>
  )
}

const IndexWrapper = styled.div`
  display: flex;
  height: calc(100vh - ${constants.HEADER_HEIGHT}px);
  margin: 0 auto;
  background-color: #f6f8f9;

  .Index_Main {
    flex: 1;
    padding 16px;
  }

  dl {
    display: flex;
    flex-wrap: wrap;
  }

  dt {
    width: 240px;
    margin-top: 24px;
    font-weight: bold;
  }

  dd {
    width: calc(100% - 240px);
    margin-top: 24px;

    &.Pre_Wrap {
      white-space: pre-wrap;
    }
  }

  .Buttons {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 48px;

    .Button[type="submit"] {
      width: 160px;
    }
  }
`

export default AdminCompaniesShow

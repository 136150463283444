import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'

interface IProps {
  body: any
}

const AdminTable: React.FC<IProps> = props => {
  return <EditTableWrapper>{props.body}</EditTableWrapper>
}

const EditTableWrapper = styled.div`
  .Pagination {
    padding-bottom: 24px;
  }

  .EditTable_Title {
    font-size: 18px;
    padding: 16px 20px;

    .Button {
      margin-left: 16px;
    }
  }

  .EditTable_Scroller {
    overflow: scroll;
    padding-bottom: 16px;
  }

  table {
    width: 100%;
    border-bottom: solid 1px ${constants.BORDER_COLOR};

    th,
    td {
      max-width: 400px;
      padding: 4px 8px;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-right: solid 1px ${constants.BORDER_COLOR};

      &.w_0 {
        width: 10px;
      }

      &.text-center {
        text-align: center;
      }

      img {
        height: 100px;
        width: auto;
      }
    }

    tr {
      border-top: solid 1px ${constants.BORDER_COLOR};
    }
  }
`

export default AdminTable

import ClassNames from 'classnames'
import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'

const TopDescription: React.FC = props => {
  return (
    <TopDescriptionWrapper>
      <section className="section description" id="description">
        <div className="section-inner">
          <h1 className="section-title red">
            訪問美容室を呼んで、<span className="text-block">オウチでキレイになろう❣️</span>
          </h1>
          <p className="section-sub-title">
            ワンオペ育児やご高齢などで外出が大変な方へ
            <br />
            美容師が、ご自宅や施設まで出張いたします！
          </p>
          <p className="section-paragraph bold">
            Jet hair Salon は訪問美容に特化した美容師・理容師をご自宅や施設まで呼べるサービスです。
            <br />
            外出しなくても、これまでと同じ生活を楽しめます。
            <br />
          </p>

          <h3 className="section-header">《Jet hair Salon の3つの特徴》</h3>

          <div className="section-items">
            <div className="section-left">
              <div className="section-image">
                <svg
                  viewBox="0 0 25 25"
                  fill="#30f030"
                  width="120px"
                  height="120px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.762 8.047l-4.381 4.475-2.215-2.123-1.236 1.239 3.451 3.362 5.619-5.715-1.238-1.238zm-3.762-5.503c2.5 1.805 4.555 2.292 7 2.416v9.575c0 3.042-1.686 3.827-7 7.107-5.309-3.278-7-4.065-7-7.107v-9.575c2.447-.124 4.5-.611 7-2.416zm0-2.544c-3.371 2.866-5.484 3-9 3v11.535c0 4.603 3.203 5.804 9 9.465 5.797-3.661 9-4.862 9-9.465v-11.535c-3.516 0-5.629-.134-9-3z" />
                </svg>
              </div>
              <div className="section-list-item">
                <h3 className="section-list-title">1. 安心・安全</h3>
                <p>Jet hair Salon では顔の見えるプロが集まっています。</p>
                <p>
                  理美容師資格や身分証明書を確認済みのプロだけが
                  <br />
                  登録できますので安心です。
                </p>
              </div>
            </div>

            <div className="section-left section-right">
              <div className="section-image">
                <svg
                  viewBox="0 0 25 25"
                  fill="#f45d47"
                  width="120px"
                  height="120px"
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                >
                  <path d="M24 8.2c0-.318-.126-.623-.351-.849-.226-.225-.531-.351-.849-.351h-6.6c-.318 0-.623.126-.849.351-.225.226-.351.531-.351.849v13.6c0 .318.126.623.351.849.226.225.531.351.849.351h6.6c.318 0 .623-.126.849-.351.225-.226.351-.531.351-.849v-13.6zm-11 14.8h-8l2.599-3h5.401v3zm6.5-1c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm3.5-3v-9.024h-7v9.024h7zm-2-14h-2v-2h-17v13h11v2h-13v-17h21v4zm-.5 4c.276 0 .5-.224.5-.5s-.224-.5-.5-.5h-2c-.276 0-.5.224-.5.5s.224.5.5.5h2z" />
                </svg>
              </div>
              <div className="section-list-item">
                <h3 className="section-list-title">2. ネットで簡単予約</h3>
                <p>ネットで完結するかんたん予約です。</p>
                <p>
                  髪型の細かな打ち合わせや日時の変更も、
                  <br />
                  チャットで簡単に相談できます。
                </p>
              </div>
            </div>

            <div className="section-left">
              <div className="section-image">
                <svg
                  viewBox="0 0 25 25"
                  fill="#39baec"
                  width="120px"
                  height="120px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M22 4h-20c-1.104 0-2 .896-2 2v12c0 1.104.896 2 2 2h20c1.104 0 2-.896 2-2v-12c0-1.104-.896-2-2-2zm-19 5.78c0-.431.349-.78.78-.78h.428v1.125h-1.208v-.345zm0 .764h1.208v.968h-1.208v-.968zm0 1.388h1.208v1.068h-.428c-.431 0-.78-.349-.78-.78v-.288zm3 5.068h-3v-1h3v1zm1-4.78c0 .431-.349.78-.78.78h-.429v-1.068h1.209v.288zm0-.708h-1.209v-.968h1.209v.968zm0-1.387h-1.629v2.875h-.743v-4h1.592c.431 0 .78.349.78.78v.345zm4 6.875h-3v-1h3v1zm1-6.5c0-1.381 1.119-2.5 2.5-2.5.484 0 .937.138 1.32.377-.531.552-.857 1.3-.857 2.123 0 .824.327 1.571.857 2.123-.383.239-.836.377-1.32.377-1.381 0-2.5-1.119-2.5-2.5zm4 6.5h-3v-1h3v1zm5 0h-3v-1h3v1zm-2.5-4c-1.38 0-2.5-1.119-2.5-2.5s1.12-2.5 2.5-2.5c1.381 0 2.5 1.119 2.5 2.5s-1.119 2.5-2.5 2.5z" />
                </svg>
              </div>
              <div className="section-list-item">
                <h3 className="section-list-title">3. お支払いも安心</h3>
                <p>
                  クレジットカード決済ですので、
                  <br />
                  わずらわしい現金のやり取りがありません。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </TopDescriptionWrapper>
  )
}

const TopDescriptionWrapper = styled.div`
  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    margin-top: 400px;
  }

  .section-header {
    text-align: center;
    margin-top: 30px;
  }

  .section-items {
    max-width: 1024px;
    background-color: #fff;
    padding: 28px 40px;
    border-radius: 8px;
    margin: 20px auto 40px;
  }

  .section-left {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 600px) {
      margin-bottom: 30px;
      flex-direction: column;
    }
  }
  .section-image {
    flex: 1;
    text-align: center;
  }
  .section-img {
    width: 120px;
    height: 120px;
    margin: 8px;
  }
  .section-list-item {
    width: 60%;
    font-size: 18px;
    text-align: center;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  .section-right {
    flex-direction: row-reverse;
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
  .red {
    color: #ee5b75;
  }
  .bold {
    font-weight: bold;
  }
`

export default TopDescription

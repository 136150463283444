import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import * as constants from '../../../static/constants'
import * as Hooks from '../../../utils/hooks'
import Button from '../../atoms/Button'

const StaticPagesSignUp: React.FC<{}> = () => {
  Hooks.SetDocumentTitle(I18n.t('hook.sign_up'))

  return (
    <Wrapper>
      <div className="Button_Box Sign_Up_Owner">
        <div className="Title">
          美容/理容室を
          <br />
          利用するお客様
        </div>

        <div className="Detail">
          <ul className="Type_List">
            <li>
              <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 35 35">
                <path d="M16.5 14.492c0 .828-.56 1.5-1.25 1.5s-1.25-.671-1.25-1.5.56-1.5 1.25-1.5 1.25.672 1.25 1.5zm-7.75-1.5c-.69 0-1.25.672-1.25 1.5s.56 1.5 1.25 1.5 1.25-.672 1.25-1.5-.56-1.5-1.25-1.5zm3.25 8.354c2.235 0 3-2.354 3-2.354h-6s.847 2.354 3 2.354zm12-6.041c0 1.765-.985 3.991-3.139 4.906-2.348 3.731-5.484 5.781-8.861 5.781-3.377 0-6.513-2.05-8.862-5.781-2.153-.915-3.138-3.141-3.138-4.906 0-1.472.387-2.937 1.682-3.636-.377-2.311-.117-6.176 4.193-7.593 1.031-1.99 3.125-4.084 6.125-4.084s5.094 2.094 6.125 4.083c4.31 1.418 4.57 5.282 4.193 7.594 1.295.699 1.682 2.164 1.682 3.636zm-2.176-1.252c-4.687-.258-6.833-4.66-7.241-7.394-1.167 6.333-9 1.792-9.896 9.052-.886-.467-1.715-1.308-2.215-2.159-.996.997-.54 4.154 1.661 4.899.224.076.413.228.535.43 1.991 3.296 4.595 5.111 7.332 5.111s5.34-1.815 7.331-5.111c.122-.202.312-.354.535-.43 1.771-.599 2.517-2.769 1.958-4.398z" />
              </svg>
            </li>
            <li>
              <svg
                className="petite"
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 45 45"
              >
                <path d="M20.5 12.5c0 .828-.56 1.5-1.25 1.5s-1.25-.672-1.25-1.5.56-1.5 1.25-1.5 1.25.672 1.25 1.5zm-7.75-1.5c-.69 0-1.25.672-1.25 1.5s.56 1.5 1.25 1.5 1.25-.672 1.25-1.5-.56-1.5-1.25-1.5zm3.25 8.354c2.235 0 3-2.354 3-2.354h-6s.847 2.354 3 2.354zm12-5.803c0 1.925-.896 4.738-3.317 5.188-1.959 3.492-4.877 5.261-8.683 5.261s-6.724-1.769-8.683-5.26c-2.421-.451-3.317-3.263-3.317-5.189 0-2.147.809-4.093 2.843-4.223.453-5.099 3.946-8.328 9.157-8.328s8.704 3.229 9.157 8.329c2.036.129 2.843 2.073 2.843 4.222zm-2.631-2.116c-.272-.178-.615-.104-.756-.041-.661.293-1.406-.191-1.406-.914 0-1.344-.315-5.007-3.46-6.654 1.304 1.354 1.753 3.792 1.753 5.174h-12.591999999999999c-.09.601-.115 1.124-.115 1.48 0 .723-.745 1.207-1.406.914-.142-.063-.484-.137-.756.041-1.102.716-.798 5.326 1.337 5.367.367.007.702.216.87.543 2.391 4.655 5.981 4.655 7.162 4.655s4.771 0 7.162-4.655c.168-.328.503-.536.87-.543 2.134-.042 2.44-4.651 1.337-5.367zm-.619-6.748c3.797-1.093.375 7.346 7.25 7.313-2.266-1.469-.893-5.476-1.5-8.166-.518-2.293-2.328-2.834-3.5-2.834-1.875 0-3.106 1.106-3.603 1.922.544.478 1.014 1.224 1.353 1.765zm-16.147-1.765c-.497-.816-1.728-1.922-3.603-1.922-1.172 0-2.982.541-3.5 2.834-.607 2.69.766 6.697-1.5 8.166 6.875.033 3.453-8.406 7.25-7.313.339-.541.809-1.287 1.353-1.765z" />
              </svg>
            </li>
            <li>
              <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 35 35">
                <path d="M17.5 12.5c0 .828-.56 1.5-1.25 1.5s-1.25-.672-1.25-1.5.56-1.5 1.25-1.5 1.25.672 1.25 1.5zm-7.75-1.5c-.69 0-1.25.672-1.25 1.5s.56 1.5 1.25 1.5 1.25-.672 1.25-1.5-.56-1.5-1.25-1.5zm3.25 8.354c2.235 0 3-2.354 3-2.354h-6s.847 2.354 3 2.354zm13 3.639c-2.653 1.714-5.418 1.254-6.842-1.488-1.672 1.505-3.706 2.487-6.158 2.487-2.53 0-4.517-.91-6.184-2.445-1.431 2.702-4.178 3.15-6.816 1.446 4.375-1.75-2.729-11.813 4.104-19.375 2.282-2.525 5.472-3.618 8.896-3.618s6.614 1.093 8.896 3.618c6.833 7.562-.271 17.625 4.104 19.375zm-5.668-6.111c.122-.202.312-.354.535-.43 2.447-.828 2.554-4.361 1.554-5.012-.235-.152-.531-.115-.672-.053-.664.295-1.406-.194-1.406-.914 0-.471-.034-1.001-.096-1.473h-10.101c-.813-1.021-.771-2.945-.396-4.57-.903.982-1.693 3.249-1.875 4.57h-2.121c-.062.472-.096 1.002-.096 1.473 0 .72-.742 1.209-1.406.914-.141-.062-.436-.1-.672.053-1 .651-.893 4.184 1.554 5.012.224.076.413.228.535.43 1.709 2.829 4.015 5.111 7.332 5.111 3.316 0 5.623-2.283 7.331-5.111z" />
              </svg>
            </li>
          </ul>
        </div>
        <Button small={true} textColor="#fff" backgroundColor={'purple'}>
          <a href="/users/sign_up">{I18n.t('nav.header.guest_signup')}</a>
        </Button>
      </div>
      <div className="Button_Box Sign_Up_Owner">
        <div className="Title">
          美容/理容室の
          <br />
          オーナーの方
        </div>
        <div className="Detail">
          <ul className="Type_List">
            <li>
              <img className="icon" src="/images/top/icon-scissors.svg" alt="" />
              <div className="type-name">美容室</div>
            </li>
            <li>
              <img className="icon" src="/images/top/icon-beard.svg" alt="" />
              <div className="type-name">理容室</div>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="other"
                width="36"
                height="36"
                viewBox="0 0 36 36"
              >
                <path d="M6 12c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3zm9 0c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3zm9 0c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z" />
              </svg>
              <div className="type-name">その他</div>
            </li>
          </ul>
        </div>
        <Button small={true} textColor="#fff" backgroundColor={'#f45d47'}>
          <a href="/owners/sign_up">{I18n.t('nav.header.owner_signup')}</a>
        </Button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-evenly;
  background-color: #f8f5f2;

  @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
    display: block;
    padding-top: 50px;
  }

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    color: blue;
  }

  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    font-size: 12px;
    color: yellow;
  }

  @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 10px;
    color: green;
  }

  .Button_Box {
    height: 400px;
    width: 400px;
    position: relative;
    background-color: #fff;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    margin: 20px 0;
    box-shadow: 0 0 3px 0 rgba(21, 27, 38, 0.15);

    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      height: 350px;
      width: 350px;
    }

    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      height: 300px;
      width: 300px;
    }

    .Title {
      font-size: 40px;
      font-weight: bold;
      color: #625233;
      margin-bottom: 20px;

      @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
        font-size: 30px;
      }

      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        font-size: 25px;
      }
    }

    .Detail {
      color: #937d53;
    }

    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      margin: 0 auto;
      margin-bottom: 20px;
    }

    button {
      // font-weight: bold;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      // padding: 1em 1.5em;
      // border: none;
      // background-color: transparent;
      cursor: pointer;
      outline: none;
      font-size: 18px;
      height: 40px;
      margin: 10px auto;
      padding: 0 30px;

      a {
        color: #fff;
      }

      // color: #566473;

      //   &:after,
      //   &:before {
      //     content: '';
      //     display: block;
      //     position: absolute;
      //     width: 20%;
      //     height: 20%;
      //     border: 2px solid;
      //     transition: all 0.6s ease;
      //     border-radius: 2px;
      //   }

      //   &:after {
      //     bottom: 0;
      //     right: 0;
      //     border-top-color: transparent;
      //     border-left-color: transparent;
      //     border-bottom-color: #566473;
      //     border-right-color: #566473;
      //   }
      //   &:before {
      //     top: 0;
      //     left: 0;
      //     border-bottom-color: transparent;
      //     border-right-color: transparent;
      //     border-top-color: #566473;
      //     border-left-color: #566473;
      //   }
      //   &:hover:after,
      //   &:hover:before {
      //     width: 100%;
      //     height: 100%;
      //   }
    }
  }

  .Sign_Up_User {
    .Detail {
      .Policy_Link {
        margin-top: 20px;
        text-decoration: underline;
      }

      @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
        // font-size: 20px;
      }
    }
  }

  .Sign_Up_Owner {
    max-height: 400px;
    max-width: 400px;

    .Detail {
      .Type_List {
        display: flex;
        justify-content: center;
        margin-top: 40px;

        @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
          margin-top: 0px;
        }

        > li {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background-color: #f8f5f2;
        }

        > li + li {
          margin-left: 20px;

          @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
            margin-left: 40px;
          }
        }

        .icon {
          width: 36px;
        }

        .type-name {
          margin-top: 12px;
          font-weight: bold;
          color: #625233;
        }
        svg {
          top: 10px;
          left: 15px;
          position: relative;
          fill: #4b4b4b;
          &.petite {
            top: 25px;
          }
          &.other {
            left: 5px;
          }
        }
      }
    }
  }

  a:link,
  a:visited {
    color: #937d53;
  }
`

export default StaticPagesSignUp

import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { IJsonResponse, IUser, IWindow } from '../../../../core/interfaces'
import { userService } from '../../../../core/services'
import * as constants from '../../../../static/constants'
import { Button, InputText, InputTextArea, Panel } from '../../../atoms'
import { Form } from '../../../molecules'
import CropperUploader from '../../../organisms/CropperUploader'
import SettingsCommonLayout from '../_layouts/common'

declare var window: IWindow

interface IProps {
  user: IJsonResponse
}

const FIELDS = {
  username: 'username',
  bio: 'bio',
  phone: 'phone',
}

const ProfileEdit: React.FC<IProps> = props => {
  const { user: initialUser } = userService.getUserFromJson(props.user)
  const [user, setUser] = React.useState<IUser>(initialUser)
  const [showModal, setShowModal] = React.useState(false)
  const [cropping, setCropping] = React.useState(false)
  const [previewImage, setPreviewImage] = React.useState(null)

  const AUTHENTICATION_ITEMS = [
    {
      name: I18n.t('generic.identification'),
      authenticated: user.identification_workflow_state === 'approve',
    },
  ]

  const handleSubmit = async (initialValues, values) => {
    const formData = new FormData()

    Object.keys(values).forEach(key => {
      if (initialValues[key] !== values[key]) {
        formData.append(`user[${key}]`, values[key])
      }
    })

    if (previewImage) {
      formData.append('user[avatar]', previewImage.file)
    }

    const { updatedUser, flash } = await userService.updateProfile(formData)

    setUser(updatedUser)
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
  }

  const setCroppedImage = croppedCanvas => {
    croppedCanvas.toBlob(blob => {
      setCropping(false)
      setPreviewImage({
        file: blob,
        url: croppedCanvas.toDataURL(),
      })
    }, 'image/*')
  }

  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [errors, setErrors] = React.useState<any>({})

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }

  return (
    <SettingsCommonLayout
      activePath={'profile'}
      is_owner={user.is_owner}
      main={
        <Wrapper>
          <Panel title={I18n.t('settings.profiles.title')}>
            <Form fields={FIELDS} handleSubmit={handleSubmit} handleUpdateForm={handleUpdateForm}>
              <Avator>
                <img src={previewImage ? previewImage.url : user.avatar_url} />
                <p>{user.username}</p>
              </Avator>
              <Uploaders>
                <Button handleClick={() => setShowModal(true)}>
                  {I18n.t('generic.select_image')}
                </Button>

                {/* <label htmlFor="avatorFile">
                  {I18n.t('generic.select_image')}
                  <input
                    id="avatorFile"
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={event => {
                      setPreviewImage({
                        file: event.currentTarget.files[0],
                        url: URL.createObjectURL(event.currentTarget.files[0]),
                      })
                    }}
                  />
                </label> */}
              </Uploaders>
              <FormItem>
                <InputText
                  required={true}
                  name="username"
                  label={I18n.t('generic.name')}
                  defaultValue={user.username}
                  error={errors.username}
                />
              </FormItem>
              <FormItem>
                <InputText
                  required={true}
                  name="phone"
                  label={I18n.t('generic.phone')}
                  defaultValue={user.phone}
                  error={errors.phone}
                />
              </FormItem>
              <FormItem>
                <InputTextArea
                  required={true}
                  name="bio"
                  label={I18n.t('settings.profiles.bio')}
                  defaultValue={user.bio}
                  error={errors.bio}
                />
              </FormItem>
              <div className="Save_Button">
                <Button primary={true} disabled={!isSubmitEnabled}>
                  {I18n.t('generic.save')}
                </Button>
              </div>
            </Form>
          </Panel>
          {showModal && (
            <Modal>
              <div className="Modal_Container">
                <p className="Modal_Title">{I18n.t('settings.profiles.avatar_image')}</p>
                <div className="Modal_Cropper">
                  {cropping ? (
                    <CropperUploader
                      imageFile={previewImage.url}
                      setCroppedImage={setCroppedImage}
                      cancelCrop={() => setCropping(false)}
                    />
                  ) : (
                    <div className="Modal_CurrentAvator">
                      <img src={previewImage ? previewImage.url : user.avatar_url} />
                      <div className="Modal_ImageSelect">
                        <label htmlFor="avatorFile">
                          {I18n.t('generic.select_image')}
                          <input
                            id="avatorFile"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={event => {
                              setPreviewImage({
                                file: event.currentTarget.files[0],
                                url: URL.createObjectURL(event.currentTarget.files[0]),
                              })
                            }}
                          />
                        </label>
                        {previewImage && (
                          <button
                            type="button"
                            className="Modal_Trimming"
                            onClick={() => setCropping(true)}
                          >
                            {I18n.t('generic.trimming')}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="Modal_Buttons">
                  <Button
                    handleClick={() => {
                      setPreviewImage(null)
                      setShowModal(false)
                    }}
                  >
                    {I18n.t('generic.cancel')}
                  </Button>
                  <Button
                    primary={true}
                    handleClick={() => {
                      setShowModal(false)
                    }}
                  >
                    {I18n.t('generic.ok')}
                  </Button>
                </div>
              </div>
            </Modal>
          )}
        </Wrapper>
      }
    />
  )
}

const Wrapper = styled.div`
  .Panel + .Panel {
    margin-top: 24px;
  }
  .AuthentificationList {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > i {
        color: #888;
      }
      > i._checked {
        color: ${constants.SUCCESS_COLOR};
      }
    }
  }

  .Save_Button {
    text-align: center;
    margin-top: 16px;
  }
`

const FormItem = styled.div`
  & + & {
    margin-top: 16px;
  }
`

const Uploaders = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 12px 0 32px;
`

const Avator = styled.div`
  > img {
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 40px;
    object-fit: cover;
  }
  > p {
    text-align: center;
    margin: 8px 0 0;
    line-height: 1;
  }
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(34, 43, 55, 0.6);
  z-index: 6000;
  .Modal_Container {
    width: 680px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    background-color: #fff;
    color: #24282a;
  }
  .Modal_Title {
    padding: 16px;
    font-weight: bold;
    border-bottom: 1px solid ${constants.BORDER_COLOR};
  }
  .Modal_Cropper {
    display: flex;
    justify-content: center;
    height: 250px;
    padding: 16px;
  }
  .Modal_CurrentAvator {
    width: 100%;
    height: 100%;
    text-align: center;
    > img {
      height: calc(100% - 36px);
      padding: 4px;
      border: solid 1px ${constants.BORDER_COLOR};
    }
    .Modal_Trimming {
      padding: 0 4px;
      text-decoration: underline;
      border: none;
      color: #3273dc;
      font-size: 14px;
    }
    label {
      cursor: pointer;
      color: var(${constants.THEME_COLOR_VARIABLE_NAME});
      border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
      border-radius: 3px;
      padding: 4px 8px;
      font-size: 14px;
    }
    input[type='file'] {
      display: none;
    }
  }
  .Modal_ImageSelect {
    margin-top: 12px;
  }
  .Modal_Buttons {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  }
`

export default ProfileEdit

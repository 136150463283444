import * as React from 'react'
import { Elements, StripeProvider } from 'react-stripe-elements'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { IPost, IReservation } from '../../../../core/interfaces'
import * as constants from '../../../../static/constants'
import { getStripePublicKey } from '../../../../utils'
import * as Hooks from '../../../../utils/hooks'
import { Panel } from '../../../atoms'
import StripeCardForm from '../../../organisms/StripeCardForm'

interface IProps {
  post: IPost
  reservation: IFormatedReservation
  setCreatedReservation: any
  setCurrentPage(page: string): void
}

interface IFormatedReservation extends IReservation {
  nominate: IDName
  orders: any
}

interface IDName {
  id: string
  name: string
}

const Confirm: React.FC<IProps> = props => {
  Hooks.SetDocumentTitle(props.post.name)

  const formatReservation = reservation => {
    return {
      start_date: reservation.selectedDate.format(constants.DATE_FORMAT),
      price: reservation.total_price,
      number: reservation.number,
      time_table_1: reservation.time_table_1,
      time_table_2: reservation.time_table_2,
      time_table_3: reservation.time_table_3,
      options: filterZeroLoop(reservation.orders),
      staff: { id: filterZero(reservation.nominate.id) },
    }
  }

  const filterZero = number => {
    if (number > 0) {
      return number
    } else {
      return null
    }
  }

  const filterZeroLoop = options => {
    const result = []
    options.map(option => result.push({ id: option.id }))
    return result
  }

  return (
    <ReservationNewWrapper>
      <Panel title={I18n.t('post.confirm.title')}>
        <div className="ReservationNew_Post">
          <div className="ReservationNew_Image">
            {props.post.post_images && <img src={props.post.post_images[0].image_url} alt="" />}
          </div>
          <div className="ReservationNew_PostInfo">
            <h2 className="ReservationNew_PostTitle">{props.post.name}</h2>
            {/* <div className="ReservationNew_PostOwner">
                  <div className="ReservationNew_Avator">
                    <img src={props.post.user.avatar_url} alt="" />
                  </div>
                  <span>{props.post.user.username}</span>
                </div> */}
          </div>
        </div>
        <div className="Panel_Section">
          <dl className="ReservationNew_InfoList">
            <dt>{I18n.t('generic.check_in')}</dt>
            <dd>{props.reservation.selectedDate.format('YYYY年M月D日')}</dd>
            <dt>{I18n.t('generic.number')}</dt>
            <dd>{props.reservation.number}人</dd>
            <dt>{I18n.t('post.option')}</dt>
            <dd>
              {props.reservation.orders
                ? props.reservation.orders.map((option, index) => (
                    <dd key={index}>
                      {option.name}
                      {option.description ? `: ${option.description}` : ''}
                    </dd>
                  ))
                : I18n.t('generic.none')}
            </dd>
            <dt>{I18n.t('staff.nominate')}</dt>
            <dd>{props.reservation.nominate.name || I18n.t('generic.none')}</dd>
            <dt>{I18n.t('generic.price')}</dt>
            <dd>¥ {props.reservation.total_price}</dd>
            <dt>{I18n.t('reservation.side.time_table_1')}</dt>
            <dd>{props.reservation.time_table_1 || I18n.t('generic.none')}</dd>
            <dt>{I18n.t('reservation.side.time_table_2')}</dt>
            <dd>{props.reservation.time_table_2 || I18n.t('generic.none')}</dd>
            <dt>{I18n.t('reservation.side.time_table_3')}</dt>
            <dd>{props.reservation.time_table_3 || I18n.t('generic.none')}</dd>
            <dt>{I18n.t('reservation.remarks_notes')}</dt>
            <dd>
              <p>{props.post.note}</p>
            </dd>
          </dl>
        </div>
        <div className="Panel_Section">
          <StripeProvider apiKey={getStripePublicKey()}>
            <Elements>
              <StripeCardForm
                post={props.post}
                setCreatedReservation={props.setCreatedReservation}
                reservation={formatReservation(props.reservation)}
                setCurrentPage={props.setCurrentPage}
              />
            </Elements>
          </StripeProvider>
        </div>
      </Panel>
    </ReservationNewWrapper>
  )
}

const ReservationNewWrapper = styled.div`
  padding: 24px 12px;

  .Panel_Section {
    margin-top: 24px;
    padding-top: 16px;
    border-top: solid 1px #eaedef;

    > h3 {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  .ReservationNew_CompletedHeader {
    margin-bottom: 24px;
    text-align: center;
    font-size: 20px;
  }

  .ReservationNew_CompletedButtons {
    display: flex;
    justify-content: center;
  }

  .ReservationNew_InfoList {
    font-size: 15px;

    dt {
      font-weight: bold;
    }

    dd {
      margin-top: 8px;

      > p {
        white-space: pre-wrap;
      }
    }

    dd + dt {
      margin-top: 12px;
    }
  }

  .ReservationNew_Post {
    display: flex;
    align-items: center;

    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      display: block;
    }

    .ReservationNew_Image {
      width: 200px;
      height: 160px;
      border-radius: 3px;
      overflow: hidden;

      > img {
        width: 100%;
        height: inherit;
        object-fit: cover;
      }
    }

    .ReservationNew_PostInfo {
      flex: 1;
      margin-left: 16px;

      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        margin-left: 0px;
        margin-top: 16px;
      }

      .ReservationNew_PostTitle {
        font-size: 20px;
      }
    }
  }

  .ReservationNew_PostOwner {
    display: flex;
    align-items: center;

    > span {
      margin-left: 12px;
    }
  }

  .ReservationNew_Avator {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 12px;

    > img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }
`

export default Confirm

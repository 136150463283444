import I18n from 'core/i18n'
import * as React from 'react'
import { Button } from '.'

interface IColumn {
  name: string
  field: string
  required?: boolean
  readOnly?: boolean
}

interface IProps {
  editable: boolean
  columns: IColumn[]
  record: any
  handleDelete?(id: number): void
  getEditLink(id: number): string
}

const AdminTableRow: React.FC<IProps> = props => {
  return (
    <tr>
      {props.columns.map(column => (
        <td key={column.field}>{props.record[column.field]}</td>
      ))}
      {props.editable && (
        <td className="_buttons">
          {typeof props.getEditLink === 'function' && (
            <Button key="edit" small={true}>
              <a href={props.getEditLink(props.record.id)}>{I18n.t('generic.edit')}</a>
            </Button>
          )}
          {props.handleDelete && (
            <Button
              key="delete"
              small={true}
              handleClick={() => props.handleDelete(props.record.id)}
            >
              {I18n.t('generic.delete')}
            </Button>
          )}
        </td>
      )}
    </tr>
  )
}

export default AdminTableRow

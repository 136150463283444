import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../../core/i18n'
import { IStaff, IWindow } from '../../../../../core/interfaces'
import { postService } from '../../../../../core/services'
import * as constants from '../../../../../static/constants'
import {
  BadgeRequired,
  Button,
  DropZoneUploader,
  InputText,
  InputTextArea,
  Select,
} from '../../../../atoms'
import { Form } from '../../../../molecules'

declare var window: IWindow

const FIELDS = {
  fullname: 'fullname',
  kana: 'kana',
  title: 'tile',
  bio: 'bio',
  image: 'image',
  experience: 'experience',
  skill: 'skill',
  price: 'price',
  gender: 'gender',
}

interface IErrors {
  [key: string]: string | null
}

interface IProps {
  post_id: number
  staff: IStaff
  qualifications: string[]
  handleSetStaff: any
  handleRefleshStaffs: any
}

interface IPreviewImage {
  file: any
  url: string
}

const StaffEditor: React.FC<IProps> = props => {
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [errors, setErrors] = React.useState<IErrors>({})
  const [showModal, setShowModal] = React.useState(false)
  const [previewImage, setPreviewImage] = React.useState(null)
  const [previewImages, setPreviewImages] = React.useState<IPreviewImage[]>([])

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)

    if (!props.staff) {
      if (updatedIsSubmitEnabled && previewImages.length > 0) {
        setIsSubmitEnabled(true)
      } else {
        setIsSubmitEnabled(false)
      }
    } else {
      setIsSubmitEnabled(updatedIsSubmitEnabled)
    }
  }

  const handleFormSubmit = async (initialValues, values) => {
    const formData = new FormData()
    formData.append(`post[id]`, String(props.post_id))

    Object.keys(values).forEach(key => {
      if (initialValues[key] !== values[key]) {
        formData.append(`staff[${key}]`, values[key])
      }
    })

    if (previewImage) {
      formData.append('staff[avatar]', previewImage.file)
    }

    if (!props.staff.id) {
      previewImages.forEach((image, index) => {
        formData.append(`staff[qualifications][]`, image.file)
      })
    }
    setIsProcessing(true)
    if (!props.staff.id) {
      const { staff, flash } = await postService.createStaff(props.post_id, formData)
      props.handleRefleshStaffs(staff, 'CREATE')
      setPreviewImage(null)
      window.flashMessages.addMessage({ text: flash.message, type: flash.type })
    } else {
      const { staff, flash } = await postService.updateStaff(
        props.post_id,
        props.staff.id,
        formData
      )
      props.handleRefleshStaffs(staff, 'UPDATE')
      setPreviewImage(null)
      window.flashMessages.addMessage({ text: flash.message, type: flash.type })
    }
    setIsProcessing(false)
  }

  const onDropHandler = addedImages => {
    addedImages.forEach(addedImage => {
      const fileReader = new FileReader()

      fileReader.onloadend = (event: any) => {
        const loadedImage = {
          file: addedImage,
          url: event.target.result,
        }

        setPreviewImages(currentPreviewImages => [...currentPreviewImages, loadedImage])
      }
      fileReader.readAsDataURL(addedImage)
    })
  }

  const deletePreviewImage = deleteIndex => {
    setPreviewImages(currentPreviewImages =>
      currentPreviewImages.filter((image, index) => index !== deleteIndex)
    )

    if (isSubmitEnabled && previewImages.length > 0) {
      setIsSubmitEnabled(true)
    } else {
      setIsSubmitEnabled(false)
    }
  }

  const deleteStaff = async id => {
    const { staff, flash } = await postService.deleteStaff(props.post_id, id)
    props.handleRefleshStaffs(staff, 'DELETE')
    props.handleSetStaff(null)
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
  }

  return (
    <>
      <h2 className="Editor_Title">
        {I18n.t('staff.title')}
        <Button small={true} right={true}>
          <a
            onClick={() => {
              props.handleSetStaff(null)
            }}
          >
            {I18n.t('generic.back')}
          </a>
        </Button>
      </h2>
      <Form fields={FIELDS} handleUpdateForm={handleUpdateForm} handleSubmit={handleFormSubmit}>
        <Avator>
          {props.staff.id ? (
            <img src={previewImage ? previewImage.url : props.staff.avatar_url} />
          ) : (
            <img src={previewImage ? previewImage.url : '/images/no-avatar.svg'} />
          )}
        </Avator>
        <Uploaders>
          <Button handleClick={() => setShowModal(true)}>{I18n.t('generic.select_image')}</Button>
        </Uploaders>
        <ul>
          <FormItem>
            {props.staff.id ? (
              <>
                <InputText
                  display={true}
                  hidden={true}
                  label={I18n.t('staff.fullname')}
                  name={'fullname'}
                  defaultValue={props.staff.fullname}
                />
              </>
            ) : (
              <InputText
                required={true}
                name="fullname"
                label={I18n.t('staff.fullname')}
                placeholder={I18n.t('staff.fullname')}
                defaultValue={props.staff.fullname}
                error={errors.fullname}
              />
            )}
          </FormItem>
          <FormItem>
            {props.staff.id ? (
              <InputText
                display={true}
                hidden={true}
                label={I18n.t('staff.kana')}
                name={'kana'}
                defaultValue={props.staff.kana}
              />
            ) : (
              <InputText
                required={false}
                name={'kana'}
                label={I18n.t('staff.kana')}
                placeholder={I18n.t('staff.kana')}
                defaultValue={props.staff.kana}
                error={errors.kana}
              />
            )}
          </FormItem>
          <FormItem>
            <Select
              label={I18n.t('staff.gender')}
              required={true}
              defaultValue={String(props.staff.gender) || 'female'}
              name="gender"
              options={[
                { value: 'female', label: '女' },
                { value: 'male', label: '男' },
              ]}
            />
          </FormItem>
          <FormItem>
            <InputTextArea
              name={'bio'}
              label={I18n.t('staff.bio')}
              placeholder={I18n.t('staff.bio')}
              defaultValue={props.staff.bio}
              error={errors.bio}
            />
          </FormItem>
          <FormItem>
            <InputText
              name={'title'}
              label={I18n.t('staff.job_title')}
              placeholder={I18n.t('staff.job_title')}
              defaultValue={props.staff.title}
              error={errors.title}
            />
          </FormItem>

          <FormItem>
            <InputText
              name={'experience'}
              label={I18n.t('staff.experience')}
              placeholder={I18n.t('staff.experience')}
              defaultValue={props.staff.experience}
              error={errors.experience}
            />
          </FormItem>
          <FormItem>
            <InputText
              name={'image'}
              label={I18n.t('staff.image')}
              placeholder={I18n.t('staff.image')}
              defaultValue={props.staff.image}
              error={errors.image}
            />
          </FormItem>
          <FormItem>
            <InputText
              name={'skill'}
              label={I18n.t('staff.skill')}
              placeholder={I18n.t('staff.skill')}
              defaultValue={props.staff.skill}
              error={errors.skill}
            />
          </FormItem>
          <FormItem>
            <InputText
              name={'price'}
              label={I18n.t('staff.price')}
              placeholder={I18n.t('staff.price')}
              defaultValue={props.staff.price}
              error={errors.price}
            />
          </FormItem>
          <Field>
            <FieldTitle>
              ①{I18n.t('staff.qualification.image')}
              <br />②{I18n.t('staff.qualification.id_document.html')}
              {!props.staff.id && <BadgeRequired />}
            </FieldTitle>
            {props.staff.id ? (
              <Identification_Preview>
                {props.qualifications.map((image, index) => (
                  <div key={index} className="Identification_PreviewItem">
                    <div className="Identification_PreviewImage">
                      <img src={image} />
                    </div>
                  </div>
                ))}
              </Identification_Preview>
            ) : (
              <Identification_Preview>
                {previewImages.map((image, index) => (
                  <div key={index} className="Identification_PreviewItem">
                    <div className="Identification_PreviewImage">
                      <img src={image.url} alt="" />
                    </div>
                    <Button handleClick={() => deletePreviewImage(index)}>
                      {I18n.t('generic.delete')}
                    </Button>
                  </div>
                ))}
                <DropZoneUploader onDrop={onDropHandler} />
              </Identification_Preview>
            )}
          </Field>
          {/*  以下カスタム項目 */}
        </ul>
        <div className="Editor_Footer">
          {props.staff.id && (
            <Button handleClick={() => deleteStaff(props.staff.id)}>
              {I18n.t('generic.delete')}
            </Button>
          )}
          <Button primary={true} disabled={!isSubmitEnabled || isProcessing}>
            {props.staff.id ? I18n.t('generic.update') : I18n.t('generic.create')}
          </Button>
        </div>
      </Form>

      {showModal && (
        <Modal>
          <div className="Modal_Container">
            <div className="Modal_Cropper">
              <div className="Modal_CurrentAvator">
                {props.staff.id ? (
                  <img src={previewImage ? previewImage.url : props.staff.avatar_url} />
                ) : (
                  <img src={previewImage ? previewImage.url : '/images/no-avatar.svg'} />
                )}
                <div className="Modal_ImageSelect">
                  <label htmlFor="avatorFile">
                    {I18n.t('generic.select_image')}
                    <input
                      id="avatorFile"
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={event => {
                        setPreviewImage({
                          file: event.currentTarget.files[0],
                          url: URL.createObjectURL(event.currentTarget.files[0]),
                        })
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="Modal_Buttons">
              <Button
                handleClick={() => {
                  setPreviewImage(null)
                  setShowModal(false)
                }}
              >
                {I18n.t('generic.cancel')}
              </Button>
              <Button
                primary={true}
                handleClick={() => {
                  setShowModal(false)
                }}
              >
                {I18n.t('generic.ok')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

const FormItem = styled.div`
  & + & {
    margin-top: 16px;
  }

  .FormItem_Title {
    font-size: 15px;
    font-weight: bold;
  }
`

const Field = styled.div`
  display: flex;
  margin-top: 16px;

  & + & {
    margin-top: 24px;
    padding-top: 20px;
    border-top: solid 1px ${constants.BORDER_COLOR};
  }

  > label {
    width: 200px;
    font-weight: bold;
  }

  > div {
    flex: 1;
  }

  a {
    color: var(${constants.THEME_COLOR_VARIABLE_NAME});
    display: inline-block;
    margin-top: 4px;
    font-size: 14px;
  }
`

const FieldTitle = styled.p`
  width: 240px;
  margin-top: 8px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
    margin-bottom: 12px;
  }
`

const Identification_Preview = styled.div`
  img {
    display: block;
    max-width: 400px;
  }

  .Button {
    margin-top: 12px;
  }

  .Identification_PreviewItem {
    display: flex;
    align-items: center;
    padding: 12px 0;
    border-bottom: solid 1px ${constants.BORDER_COLOR};

    .Button {
      margin-left: 24px;
    }
  }

  .Identification_PreviewImage {
    width: 360px;
    height: 240px;
    background-color: #fafafa;

    > img {
      width: inherit;
      height: inherit;
      object-fit: contain;
    }
  }

  .DropZoneUploader {
    width: 100%;
    margin: 12px 0 0;
  }

  .Identification_Footer {
    margin-top: 24px;
  }

  .Identification_requested {
    text-align: center;
    padding: 16px 0;
  }
`

const Uploaders = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 12px 0 32px;
`

const Avator = styled.div`
  > img {
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 40px;
    object-fit: cover;
  }

  > p {
    text-align: center;
    margin: 8px 0 0;
    line-height: 1;
  }
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(34, 43, 55, 0.6);
  z-index: 6000;

  .Modal_Container {
    width: 680px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    background-color: #fff;
    color: #24282a;
  }

  .Modal_Title {
    padding: 16px;
    font-weight: bold;
    border-bottom: 1px solid ${constants.BORDER_COLOR};
  }

  .Modal_Cropper {
    display: flex;
    justify-content: center;
    height: 250px;
    padding: 16px;
  }

  .Modal_CurrentAvator {
    width: 100%;
    height: 100%;
    text-align: center;

    > img {
      height: calc(100% - 36px);
      padding: 4px;
      border: solid 1px ${constants.BORDER_COLOR};
    }

    .Modal_Trimming {
      padding: 0 4px;
      text-decoration: underline;
      border: none;
      color: #3273dc;
      font-size: 14px;
    }

    label {
      cursor: pointer;
      color: var(${constants.THEME_COLOR_VARIABLE_NAME});
      border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
      border-radius: 3px;
      padding: 4px 8px;
      font-size: 14px;
    }

    input[type='file'] {
      display: none;
    }
  }

  .Modal_ImageSelect {
    margin-top: 12px;
  }

  .Modal_Buttons {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  }
`

export default StaffEditor

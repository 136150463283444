import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import * as constants from '../../../../static/constants'
import { Button, Panel } from '../../../atoms'
import { Form } from '../../../molecules'
import { AdminNav } from '../../../organisms'

interface IProps {
  model: string
  title: string
  fields: any
  indexLink: string
  formItems: any
  handleSubmit(initialValues, values): void
}

const AdminLayoutNew: React.FC<IProps> = props => {
  return (
    <Wrapper>
      <AdminNav active={props.model} />
      <section className="Index_Main">
        <Panel title={props.title}>
          <Form fields={props.fields} handleSubmit={props.handleSubmit}>
            {props.formItems}
            <div className="Buttons">
              <Button>
                <a href={props.indexLink}>
                  <span className="material-icons">keyboard_arrow_left</span>
                  {I18n.t('generic.back_to_index')}
                </a>
              </Button>
              <Button primary={true}>{I18n.t('generic.create')}</Button>
            </div>
          </Form>
        </Panel>
      </section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - ${constants.HEADER_HEIGHT}px);
  margin: 0 auto;
  background-color: #f6f8f9;
  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    display: block;
  }

  .Buttons {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 48px;

    .Button[type="submit"] {
      width: 160px;
    }

    .Button[type="button"] {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;

      .material-icons {
        margin-left: -10px;
      }
    }
  }

  .Index_Main {
    flex: 1;
    padding 16px;
    overflow: auto;
  }

  .InputText + .InputText,
  .InputText + .CheckBox,
  .CheckBox + .CheckBox {
    margin-top: 24px;
  }
`

export default AdminLayoutNew

import * as React from 'react'
import I18n from '../../../../core/i18n'
import { settingsService } from '../../../../core/services'
import * as Hooks from '../../../../utils/hooks'
import { InputText, InputTextArea } from '../../../atoms'
import AdminLayoutEdit from '../_layouts/edit'

interface IProps {
  settings: any
}

const FIELDS = {
  company_name: 'company_name',
  company_address: 'company_address',
  company_phone: 'company_phone',
  company_fax: 'company_fax',
  company_opening_time: 'company_opening_time',
  company_mail: 'company_mail',
  company_bank: 'company_bank',
}

const title = I18n.t('admin.edit', {
  model: I18n.t('setting', { scope: 'activerecord.models' }),
})

const AdminCompaniesEdit: React.FC<IProps> = props => {
  Hooks.SetDocumentTitle(I18n.t('nav.admins.company'))
  const settings = props.settings.reduce((obj, setting) => {
    obj[setting.var] = setting.value
    return obj
  }, {})

  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const updatedParams: any = {}
    Object.keys(FIELDS).forEach(field => {
      if (initialValues[field] !== values[field]) {
        updatedParams[field] = values[field]
      }
    })
    await settingsService.updateSetting(updatedParams)
    location.href = '/admin/company'
  }, [])

  return (
    <AdminLayoutEdit
      model="company"
      title={title}
      fields={FIELDS}
      indexLink="/admin/company"
      formItems={
        <>
          <InputText
            name="company_name"
            defaultValue={settings.company_name}
            label={I18n.t('admin.company.name')}
          />
          <InputText
            name="company_address"
            defaultValue={settings.company_address}
            label={I18n.t('admin.company.address')}
          />
          <InputText
            name="company_phone"
            defaultValue={settings.company_phone}
            label={I18n.t('admin.company.phone')}
          />
          <InputText
            name="company_fax"
            defaultValue={settings.company_fax}
            label={I18n.t('admin.company.fax')}
          />
          <InputText
            name="company_opening_time"
            defaultValue={settings.company_opening_time}
            label={I18n.t('admin.company.opening_time')}
          />
          <InputText
            name="company_mail"
            defaultValue={settings.company_mail}
            label={I18n.t('admin.company.mail')}
          />
          <InputTextArea
            name="company_bank"
            defaultValue={settings.company_bank}
            label={I18n.t('admin.company.bank')}
          />
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

export default AdminCompaniesEdit

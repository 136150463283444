import { AxiosInstance } from 'axios'
import IJsonApiSerializer from 'core/interfaces/IJsonApiSerializer'

class SettingsService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public async createIdentificationImage(image) {
    const formData = new FormData()
    formData.append('identification_image[image]', image)

    const {
      data: { identifcation, flash },
    } = await this.httpClient.post(`/api/identification_images`, formData)
    const createdIdentificationImage = this.serializer.parseResourceData(
      identifcation,
      identifcation.data
    )

    return { createdIdentificationImage, flash }
  }

  public async updateNotification(formData) {
    const {
      data: { user, flash },
    } = await this.httpClient.patch('/api/settings/notification', formData)

    return { user, flash }
  }

  public async deleteSocialProfile(id) {
    const { data } = await this.httpClient.delete(`/api/social_profiles/${id}`)
    return { flash: data.flash }
  }

  public async updateSetting(setting) {
    const { settings, flash } = await this.httpClient.patch(`/admin/api/setting`, setting)

    return { settings, flash }
  }

  public async getCard() {
    const { data } = await this.httpClient.get('/api/card')
    const card = this.serializer.parseResourceData(data.card, data.card.data)
    return { card }
  }

  public async createCard(stripeToken) {
    // cardをjson apiのレスポンスに
    const {
      data: { card, flash },
    } = await this.httpClient.post('/api/card', { stripeToken })

    return { card, flash }
  }
}

export default SettingsService

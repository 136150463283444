import * as React from 'react'
import styled from 'styled-components'
import { topService } from '../../../core/services'
import * as constants from '../../../static/constants'
import { Score } from '../../atoms'

const TopRecomment: React.FC<{}> = () => {
  const [recommends, seRecommends] = React.useState(null)

  React.useEffect(() => {
    const f = async () => {
      const { posts: initialRecords } = await topService.recommend()
      seRecommends(initialRecords)
    }
    if (!recommends) {
      f()
    }
  }, [recommends])

  return (
    <Wrapper>
      {recommends &&
        recommends.map((recommend, index) => (
          <a href={'/posts/' + recommend.slug} className="Recommend" key={'recommend-' + index}>
            {recommend.post_images.length > 0 ? (
              <img src={recommend.post_images[0].image_url} alt="recommend" />
            ) : (
              <img src={'/images/no-image.svg'} alt="recommend" />
            )}

            <div className="Recommend__title">{recommend.name}</div>
            <div className="Recommend__body">{recommend.description}</div>
            {recommend.avarage_rate && <Score score={recommend.avarage_rate} />}
          </a>
        ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  .Recommend {
    color: gray;
    padding: 20px;
    // width: 50%;
    width: 25%;
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      width: 50%;
    }
    &__title {
      font-size: 12px;
      font-weight: bold;
      color: #000;
    }

    &__body {
      font-size: 14px;
      margin: 10px 0;
    }
  }
`
export default TopRecomment

import { IJsonResponse } from 'core/interfaces'
import * as React from 'react'
import I18n from '../../../../core/i18n'
import { utilService } from '../../../../core/services'
import { InputText } from '../../../atoms'
import AdminLayoutEdit from '../_layouts/edit'

interface IProps {
  reservation: IJsonResponse
}

const FIELDS = {
  id: 'id',
  // user_id: 'user_id',
  // post_id: 'post_id',
  start_date: 'start_date',
  // end_date: 'end_date',
  // workflow_state: 'workflow_state',
  price: 'price',
  // paid_at: 'paid_at',
  // canceled_at: 'canceled_at',
  // updated_at: 'updated_at',
  refund_amount: 'refund_amount',
  // authorized_at: 'authorized_at',
}

const title = I18n.t('admin.edit', {
  model: I18n.t('reservation', { scope: 'activerecord.models' }),
})

const AdminReservationEdit: React.FC<IProps> = props => {
  const { data: reservation } = utilService.getDataFromJson(props.reservation)
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    // const response = await api.updateReservationByAdmin(values)
    // location.href = '/admin/reservations'
  }, [])

  return (
    <AdminLayoutEdit
      model="reservation"
      title={title}
      fields={FIELDS}
      indexLink="/admin/reservations"
      formItems={
        <>
          <InputText
            readonly={true}
            name="id"
            defaultValue={reservation.id}
            label={I18n.t('generic.id')}
          />
          {/* <InputText name="user_id" defaultValue={reservation.user_id} label="User id" /> */}
          {/* <InputText name="post_id" defaultValue={reservation.post_id} label="Post id" /> */}
          <InputText
            name="start_date"
            defaultValue={reservation.start_date}
            label={I18n.t('generic.check_in')}
          />
          {/* <InputText name="end_date" defaultValue={reservation.end_date} label="End date" /> */}
          {/* <InputText
            name="workflow_state"
            defaultValue={reservation.workflow_state}
            label="Workflow state"
          /> */}
          <InputText
            name="price"
            defaultValue={reservation.price}
            label={I18n.t('generic.price')}
          />
          {/* <InputText name="paid_at" defaultValue={reservation.paid_at} label="Paid at" /> */}
          {/* <InputText
            name="canceled_at"
            defaultValue={reservation.canceled_at}
            label="Canceled at"
          /> */}
          {/* <InputText name="updated_at" defaultValue={reservation.updated_at} label="Updated at" /> */}
          <InputText
            name="refund_amount"
            defaultValue={reservation.refund_amount}
            label={I18n.t('generic.price_refund')}
          />
          {/* <InputText
            name="authorized_at"
            defaultValue={reservation.authorized_at}
            label="Authorized at"
          /> */}
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

export default AdminReservationEdit

import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../../core/i18n'
import { IOption, IPost, IWindow } from '../../../../../core/interfaces'
import { postService } from '../../../../../core/services'
import { Button, InputText, InputTextArea, Select } from '../../../../atoms'
import { Form } from '../../../../molecules'

declare var window: IWindow

const FIELDS = {
  name: 'name',
  price: 'price',
  description: 'description',
  position: 'position',
}

interface IErrors {
  [key: string]: string | null
}

interface IProps {
  post: IPost
  option: IOption
  handleSetOption: any
  handleSetOptions: any
}

const OptionEditor: React.FC<IProps> = props => {
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [errors, setErrors] = React.useState<IErrors>({})

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }

  const handleFormSubmit = async (initialValues, values) => {
    const params = { ...values }
    params.post_id = props.post.id
    setIsProcessing(true)
    if (props.option && props.option.id) {
      params.id = props.option.id
      const { flash } = await postService.updateOption(params)
      window.flashMessages.addMessage({ text: flash.message, type: flash.type })
      props.handleSetOption(null)
      const { options } = await postService.getOptions(props.post.id)
      props.handleSetOptions(options)
    } else {
      const { flash } = await postService.createOption(params)
      window.flashMessages.addMessage({ text: flash.message, type: flash.type })
      props.handleSetOption(null)
      const { options } = await postService.getOptions(props.post.id)
      props.handleSetOptions(options)
    }
    setIsProcessing(false)
  }

  const deleteOption = async id => {
    const params = { post_id: props.post.id, id }
    const { flash } = await postService.deleteOption(params)
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
    props.handleSetOption(null)
    const { options } = await postService.getOptions(props.post.id)
    props.handleSetOptions(options)
  }

  return (
    <>
      <h2 className="Editor_Title">
        {I18n.t('post.option')}
        <Button small={true} right={true}>
          <a
            onClick={() => {
              props.handleSetOption(null)
            }}
          >
            {I18n.t('generic.back')}
          </a>
        </Button>
      </h2>
      <Form fields={FIELDS} handleUpdateForm={handleUpdateForm} handleSubmit={handleFormSubmit}>
        <ul>
          <FormItem>
            <InputText
              required={true}
              name="name"
              label={'メニュー名'}
              placeholder={I18n.t('placeholder.option.name')}
              defaultValue={props.option && props.option.name}
              error={errors.name}
            />
          </FormItem>
          <FormItem>
            <InputText
              required={true}
              name="price"
              label={'料金'}
              placeholder={I18n.t('placeholder.option.price')}
              defaultValue={props.option && props.option.price}
              error={errors.price}
            />
          </FormItem>
          <FormItem>
            <InputText
              name="description"
              label={'説明'}
              placeholder={I18n.t('placeholder.option.description')}
              defaultValue={props.option && props.option.description}
              error={errors.description}
            />
          </FormItem>
          <FormItem>
            <InputText
              name="position"
              label={'順序※'}
              placeholder={I18n.t('placeholder.option.position')}
              defaultValue={props.option && props.option.position}
              error={errors.position}
            />
            <p className="Position_Explanation">
              ※1～180までの数字を入力することで、その順序に表示が並び替え可能です
            </p>
          </FormItem>
        </ul>
        <div className="Editor_Footer">
          {props.option && (
            <Button handleClick={() => deleteOption(props.option.id)}>
              {I18n.t('generic.delete')}
            </Button>
          )}
          <Button primary={true} disabled={!isSubmitEnabled || isProcessing}>
            {props.option && props.option.id ? I18n.t('generic.update') : I18n.t('generic.create')}
          </Button>
        </div>
      </Form>
    </>
  )
}

const FormItem = styled.div`
  & + & {
    margin-top: 16px;
  }

  .FormItem_Title {
    font-size: 15px;
    font-weight: bold;
  }

  .Position_Explanation {
    font-size: 14px;
    text-align: right;
  }
`

export default OptionEditor

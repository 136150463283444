import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { IPost, IReservation } from '../../../../core/interfaces'
import { Button, Panel } from '../../../atoms'

interface IProps {
  post: IPost
  createdReservation: IReservation
}

const PostShowComplete: React.FC<IProps> = props => {
  return (
    <ReservationNewWrapper>
      <Panel title={I18n.t('post.confirm.title')}>
        <div className="ReservationNew_Completed">
          <h2 className="ReservationNew_CompletedHeader">
            {I18n.t('reservation.reservation_completed')}
          </h2>
          <div className="ReservationNew_CompletedButtons">
            <Button>
              <a href={`/reservations/${props.createdReservation.slug}`}>
                {I18n.t('reservation.to_messages')}
              </a>
            </Button>
            <Button>
              <a href={`/posts/search`}>{I18n.t('reservation.back_to_posts')}</a>
            </Button>
          </div>
        </div>
      </Panel>
    </ReservationNewWrapper>
  )
}

const ReservationNewWrapper = styled.div`
  padding: 24px 12px;

  .Panel_Section {
    margin-top: 24px;
    padding-top: 16px;
    border-top: solid 1px #eaedef;

    > h3 {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  .ReservationNew_CompletedHeader {
    margin-bottom: 24px;
    text-align: center;
    font-size: 20px;
  }

  .ReservationNew_CompletedButtons {
    display: flex;
    justify-content: center;
  }

  .ReservationNew_InfoList {
    font-size: 15px;

    dt {
      font-weight: bold;
    }

    dd {
      margin-top: 8px;

      > p {
        white-space: pre-wrap;
      }
    }

    dd + dt {
      margin-top: 12px;
    }
  }

  .ReservationNew_Post {
    display: flex;
    align-items: center;
  }

  .ReservationNew_Image {
    width: 200px;
    height: 160px;
    border-radius: 3px;
    overflow: hidden;

    > img {
      width: 100%;
      height: inherit;
      object-fit: cover;
    }
  }

  .ReservationNew_PostInfo {
    flex: 1;
    margin-left: 16px;
  }

  .ReservationNew_PostTitle {
    font-size: 20px;
  }

  .ReservationNew_PostOwner {
    display: flex;
    align-items: center;

    > span {
      margin-left: 12px;
    }
  }

  .ReservationNew_Avator {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 12px;

    > img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }
`

export default PostShowComplete

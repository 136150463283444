import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { IWindow } from '../../../../core/interfaces'
import { identificationService } from '../../../../core/services/admin'
import * as constants from '../../../../static/constants'
import * as Util from '../../../../utils'
import * as Hooks from '../../../../utils/hooks'
import { Button, Panel } from '../../../atoms'
import Nav from '../../../organisms/AdminNav'
import AdminLayoutIndex from '../_layouts/index'

declare var window: IWindow

interface IProps {
  identifications: {
    identifications: any
  }
}

const HIDE_STATUS = ['approved', 'declined']

const Index: React.FC<IProps> = props => {
  // console.log('Identification Index', props)
  Hooks.SetDocumentTitle('Admin Identification')
  const {
    identifications: initialIdentifications,
  } = identificationService.getIdentificationsFromJson(props.identifications.identifications)
  const [identifications, setIdentifications] = React.useState(initialIdentifications)

  const approveIdentification = React.useCallback(
    async identification => {
      const { identification: approvedIdentification, flash } = await identificationService.approve(
        identification.id
      )
      setIdentifications(
        identifications.map(item =>
          item.id === approvedIdentification.id ? approvedIdentification : { ...item }
        )
      )
      if (window && window.flashMessages) {
        window.flashMessages.addMessage({ text: flash.message, type: flash.type })
      }
    },
    [identifications]
  )

  const declineIdentification = React.useCallback(
    async identification => {
      const { identification: declinedIdentification, flash } = await identificationService.decline(
        identification.id
      )
      setIdentifications(
        identifications.map(item =>
          item.id === declinedIdentification.id ? declinedIdentification : { ...item }
        )
      )
      if (window && window.flashMessages) {
        window.flashMessages.addMessage({ text: flash.message, type: flash.type })
      }
    },
    [identifications]
  )

  return (
    <AdminLayoutIndex
      model="identifications"
      main={
        <Wrapper>
          <div className="Sizer">
            <IdentificationsTable>
              <thead>
                <tr>
                  <th>{I18n.t('generic.user')}</th>
                  <th>{I18n.t('generic.image')}</th>
                  <th>{I18n.t('generic.status')}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {identifications
                  .filter(identification => !HIDE_STATUS.includes(identification.workflow_state))
                  .map(identification => (
                    <tr key={identification.id}>
                      <td>
                        <a href={`/user/${identification.user.username}`} target="_blank">
                          {identification.user.username}
                        </a>
                      </td>
                      <td>
                        {identification.identification_images &&
                          identification.identification_images.map(image => (
                            <img key={image.id} src={image.image_url} alt="" />
                          ))}
                      </td>
                      <td>{identification.workflow_state}</td>
                      <td className="_buttons">
                        <Button
                          handleClick={() => approveIdentification(identification)}
                          primary={true}
                        >
                          {I18n.t('generic.approve')}
                        </Button>
                        <Button handleClick={() => declineIdentification(identification)}>
                          {I18n.t('generic.decline')}
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </IdentificationsTable>
          </div>
        </Wrapper>
      }
    />
  )
}

const IdentificationsTable = styled.table`
  width: auto;

  img {
    display: block;
    max-width: 360px;
  }

  img + img {
    margin-top: 12px;
  }

  th,
  td {
    max-width: 400px;
    min-width: 120px;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 8px 12px;
    border: solid 1px ${constants.BORDER_COLOR};

    > a {
      color: var(${constants.THEME_COLOR_VARIABLE_NAME});
      text-decoration: underline;
    }
  }

  td._buttons {
    .Button {
      display: block;
      width: 120px;
      margin: 0;
      font-size: 14px;
    }

    .Button + .Button {
      margin-top: 12px;
    }
  }
`

const Wrapper = styled.div`
  overflow: scroll;

  .Sizer {
    width: 0;
  }
`

export default Index

import ClassNames from 'classnames'
import { IReservation } from 'core/interfaces'
import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import { postService, reservationService } from '../../../core/services'
import * as constants from '../../../static/constants'
import * as forms from '../../../utils/form'
import { Button, InputTextArea, Panel } from '../../atoms'
import { Form } from '../../molecules'

interface IProps {
  params: {
    id: string
  }
}

const FIELDS = {
  rating: 'rating',
  body: 'body',
}

const ReviewNew: React.FC<IProps> = props => {
  const starsRef = React.useRef(null)
  const [reservation, setReservation] = React.useState<IReservation>(null)

  const [score, setScore] = React.useState(0)
  const [errors, setErrors] = React.useState({ body: null })
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [completed, setCompleted] = React.useState(false)

  React.useEffect(() => {
    const f = async () => {
      const result = await reservationService.show(props.params.id)
      setReservation(result.reservation)
    }
    if (reservation == null) {
      f()
    }
  }, [reservation])

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }

  const handleSubmit = async (initialValues, values) => {
    const params = {
      reservation_id: reservation.id,
      rating: Number(values.rating),
      body: values.body,
    }

    await postService.createReview(params)
    setCompleted(true)
  }

  return (
    <ReviewNewWrapper>
      {completed ? (
        <Panel>
          <p className="ReviewNew_Completed">{I18n.t('reservation.review.completed')}</p>
        </Panel>
      ) : (
        <>
          {reservation && (
            <div className="ReviewNew_Main">
              <div className="ReviewNew_Post">
                <div className="ReviewNew_Image">
                  {reservation.post.post_images.length > 0 ? (
                    <img src={reservation.post.post_images[0].image_url} alt="" />
                  ) : (
                    <div className="PostShow_Image">
                      <div className="PostShow_NoImage">No Image</div>
                    </div>
                  )}
                </div>
                <div className="ReviewNew_PostInfo">
                  <h2 className="ReviewNew_PostTitle">{reservation.post.name}</h2>
                  <div className="ReviewNew_PostOwner">
                    <div className="ReviewNew_Avator">
                      <img src={reservation.post.user.avatar_url} alt="" />
                    </div>
                    <span>{reservation.post.user.username}</span>
                  </div>
                </div>
              </div>
              <div className="ReviewNew_Editor">
                <Form
                  fields={FIELDS}
                  handleSubmit={handleSubmit}
                  handleUpdateForm={handleUpdateForm}
                >
                  <h2>{I18n.t('review.add_review')}</h2>
                  <Stars>
                    <input
                      required={true}
                      readOnly={true}
                      type="text"
                      name="rating"
                      defaultValue=""
                      ref={starsRef}
                    />
                    <div className="Stars_Wrapper">
                      {[1, 2, 3, 4, 5].map(value => (
                        <span
                          key={value}
                          onClick={() => {
                            forms.setNativeValue(starsRef.current, value, false)
                            starsRef.current.dispatchEvent(new Event('input', { bubbles: true }))
                            setScore(value)
                          }}
                        >
                          <svg
                            className={ClassNames('Stars_Star', {
                              filled: value <= score,
                            })}
                            viewBox="0 0 64 64"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <polygon
                              fillRule="nonzero"
                              points="32.5 45.9878947 48.877 56 44.531 37.13 59 24.4336842 39.9465 22.7963158 32.5 5 25.0535 22.7963158 6 24.4336842 20.469 37.13 16.123 56"
                            />
                          </svg>
                        </span>
                      ))}
                    </div>
                  </Stars>
                  <InputTextArea
                    required={true}
                    name="body"
                    placeholder={I18n.t('placeholder.review_body')}
                    defaultValue=""
                    error={errors.body}
                  />
                  <Button primary={true} disabled={!isSubmitEnabled}>
                    {I18n.t('generic.send')}
                  </Button>
                </Form>
              </div>
            </div>
          )}
        </>
      )}
    </ReviewNewWrapper>
  )
}

const Stars = styled.div`
  margin-top: 4px;

  > input {
    display: none;
  }

  .Stars_Wrapper {
    display: flex;

    > span {
      cursor: pointer;
    }
  }

  .Stars_Star {
    width: 32px;
    height: auto;
    fill: none;

    > polygon {
      stroke: #e0e4e6;
      stroke-width: 2px;
    }

    &.filled {
      stroke: none;
      fill: #ffb800;

      > polygon {
        stroke: none;
      }
    }
  }
`

const ReviewNewWrapper = styled.div`
  padding: 24px 12px 0;

  .ReviewNew_Editor {
    margin-top: 24px;
    border-top: solid 1px ${constants.BORDER_COLOR};

    h2 {
      margin-top: 12px;
      font-size: 18px;
    }

    .Button {
      margin-top: 12px;
    }
  }

  .ReviewNew_Main {
    max-width: 800px;
    margin: 0 auto;
  }

  .ReviewNew_Post {
    display: flex;
    align-items: center;
  }

  .ReviewNew_Image {
    width: 200px;
    height: 160px;
    border-radius: 3px;
    overflow: hidden;

    > img {
      width: 100%;
      height: inherit;
      object-fit: cover;
    }
  }

  .ReviewNew_PostInfo {
    flex: 1;
    margin-left: 16px;
  }

  .ReviewNew_PostTitle {
    font-size: 20px;
  }

  .ReviewNew_PostOwner {
    display: flex;
    align-items: center;

    > span {
      margin-left: 12px;
    }
  }

  .ReviewNew_Avator {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 12px;

    > img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }
`

export default ReviewNew

import Loading from 'components/atoms/Loading'
import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import { IPager, IReservation, IWindow } from '../../../core/interfaces'
import { reservationService } from '../../../core/services'
import * as constants from '../../../static/constants'
import * as Hooks from '../../../utils/hooks'
import { Button, CheckBox, InputTextArea, Pagination, TypeFilter } from '../../atoms'
import { Form } from '../../molecules'

declare var window: IWindow

interface IType {
  id: number | string
  name: string
}

const STATUS: IType[] = [
  {
    id: 'canceled',
    name: I18n.t('enums.reservation.workflow_state.canceled'),
  },
  {
    id: 'approved',
    name: I18n.t('enums.reservation.workflow_state.approved'),
  },
  {
    id: 'declined',
    name: I18n.t('enums.reservation.workflow_state.declined'),
  },
  {
    id: 'requested',
    name: I18n.t('enums.reservation.workflow_state.requested'),
  },
  {
    id: 'pending',
    name: I18n.t('enums.reservation.workflow_state.pending'),
  },
]

interface IFilterParams {
  past: boolean
  state: string[]
  page: number
}

const isMobile = matchMedia(`(max-width: ${constants.BREAKPOINT_TABLET_SMALL}px)`).matches

const ReservationsIndex: React.FC<{}> = () => {
  Hooks.SetDocumentTitle(I18n.t('nav.header.reservations'))
  const formRef = React.useRef(null)
  const [reservations, setReservations] = React.useState<IReservation[]>(null)
  const [pagination, setPagination] = React.useState<IPager>()
  const [filterParams, setFilterParams] = React.useState<IFilterParams>({
    past: null,
    state: [],
    page: 1,
  })
  const [isProcessing, setIsProcessing] = React.useState(false)

  React.useEffect(() => {
    const f = async () => {
      const result = await reservationService.search({})
      setReservations(result.reservations)
      setPagination(result.pagination)
    }
    if (reservations === null) {
      f()
    }
  }, [])

  const updateReservations = updatedReservation => {
    const updatedReservations = reservations.map(reservation =>
      reservation.id === updatedReservation.id ? updatedReservation : { ...reservation }
    )
    setReservations(updatedReservations)
  }
  const onCancelHandler = async (id, params) => {
    const { reservation: canceledReservation, flash } = await reservationService.cancel(id, params)

    updateReservations(canceledReservation)
    window.globalModal.closeModal()
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
  }
  const showCancelModal = async id => {
    // const { price } = await reservationService.calcRefundAmount(id)
    const ModalBody: React.FC<{}> = modalBodyProps => {
      const [errors, setErrors] = React.useState<{ reason: null | string }>({ reason: null })
      return (
        <div>
          {/*
          price > 0 ? (
            <p>{I18n.t('reservation.cancel.confirmation', { price })}</p>
          ) : (
            <p>{I18n.t('reservation.cancel.no_price')}</p>
          )
          */}

          <p>{I18n.t('reservation.cancel.no_price')}</p>
          <Form
            ref={formRef}
            fields={{ reason: 'reason' }}
            handleUpdateForm={(updatedErrors, isSubmitEnabled) => setErrors(updatedErrors)}
            handleSubmit={(initialValues, values) => onCancelHandler(id, values)}
          >
            <InputTextArea
              name="reason"
              label={I18n.t('reservation.cancel.reason')}
              error={errors.reason}
              defaultValue=""
            />
          </Form>
        </div>
      )
    }

    window.globalModal.showModal({
      title: I18n.t('reservation.cancel.reservation'),
      body: <ModalBody />,
      closeText: I18n.t('generic.label_no'),
      submitText: I18n.t('generic.label_yes'),
      handleSubmit: () => formRef.current.handleFormSubmit(),
    })
  }

  const getShowPastReservation = () => {
    const past = filterParams.past
    return past === true
  }

  const approveReservation = async id => {
    setIsProcessing(true)
    const { reservation: changedReservation, flash } = await reservationService.approve(id)
    setIsProcessing(false)

    updateReservations(changedReservation)
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
  }

  const declineReservation = async id => {
    setIsProcessing(true)
    const { reservation: changedReservation, flash } = await reservationService.decline(id)
    setIsProcessing(false)

    updateReservations(changedReservation)
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
  }

  const renderWorkFlowStateButtons = reservation => {
    if (!reservation.next_work_flow_state) {
      return null
    }

    return reservation.next_work_flow_state.map((state, index) => {
      let button = null
      switch (state) {
        case 'approved':
          button = (
            <Button
              key={`${reservation.id}-${index}`}
              small={true}
              handleClick={() => approveReservation(reservation.id)}
              disabled={isProcessing}
            >
              {I18n.t('reservation.approve')}
            </Button>
          )
          break
        case 'declined':
          button = (
            <Button
              key={`${reservation.id}-${index}`}
              small={true}
              handleClick={() => declineReservation(reservation.id)}
              disabled={isProcessing}
            >
              {I18n.t('reservation.decline')}
            </Button>
          )
          break
        case 'canceled':
          button = (
            <Button
              key={`${reservation.id}-${index}`}
              small={true}
              handleClick={() => showCancelModal(reservation.id)}
            >
              {I18n.t('reservation.cancel.reservation')}
            </Button>
          )
          break
      }
      return button
    })
  }

  const showDetailModal = reservation => {
    const ModalBody: React.FC<{}> = modalBodyProps => (
      <DetailModalBody>
        <DetailList>
          <dt>{I18n.t('activerecord.attributes.reservation.workflow_state')}</dt>
          <dd>{reservation.workflow_state_name}</dd>
          <dt>{I18n.t('generic.user')}</dt>
          <dd>{reservation.user.username}</dd>
          <dt>{I18n.t('post.post_name')}</dt>
          <dd>{reservation.post.name}</dd>
          <dt>{I18n.t('reservation.reservation_date')}</dt>
          <dd>{reservation.created_at}</dd>
          <dt>{I18n.t('generic.check_in')}</dt>
          <dd>{reservation.start_date}</dd>
          <dt>{I18n.t('generic.price')}</dt>
          <dd>{reservation.price}</dd>
        </DetailList>
        <div className="DetailModalBody_Buttons">
          {getShowPastReservation() ? (
            reservation.reviewable && (
              <Button small={true}>
                <a href={`/reservations/${reservation.id}/reviews/new`}>
                  {I18n.t('review.write_review')}
                </a>
              </Button>
            )
          ) : (
            <>
              <Button small={true}>
                <a href={`/reservations/${reservation.slug}`}>{I18n.t('reservation.message')}</a>
              </Button>
              {filterParams.past === null && renderWorkFlowStateButtons(reservation)}
            </>
          )}
        </div>
      </DetailModalBody>
    )

    window.globalModal.showModal({
      title: I18n.t('reservation.reservation_detail'),
      body: <ModalBody />,
      closeText: I18n.t('generic.close'),
      submitText: null,
      handleSubmit: null,
    })
  }

  const handleOnSubmit = async filterValue => {
    const params = filterParams
    params.state = filterValue.state
    params.page = 1
    setFilterParams(params)
    refleshReservations(params)
  }

  const onChangePageHandler = async page => {
    const params = filterParams
    params.page = page
    setFilterParams(params)
    refleshReservations(params)
  }

  const showPastReservationHandler = event => {
    const params = filterParams
    if (event.target.checked) {
      params.past = true
    } else {
      params.past = null
    }
    params.page = 1
    setFilterParams(params)
    refleshReservations(params)
  }

  const refleshReservations = async params => {
    const {
      reservations: searchedReservations,
      pagination: pagier,
    } = await reservationService.search(params)
    setReservations(searchedReservations)
    setPagination(pagier)
  }

  const tableRef = React.useRef(null)
  const scrollerRef = React.useRef(null)

  React.useEffect(() => {
    if (tableRef.current && scrollerRef.current) {
      const tableWidth = tableRef.current.offsetWidth
      scrollerRef.current.style.width = `${tableWidth}px`
    }
  }, [tableRef, scrollerRef])

  const renderTable = () => {
    return (
      <div className="Scroller" ref={scrollerRef}>
        <table ref={tableRef}>
          <thead>
            <tr>
              <th>{I18n.t('activerecord.attributes.reservation.workflow_state')}</th>
              <th>{I18n.t('generic.user')}</th>
              <th>{I18n.t('post.post_name')}</th>
              <th>{I18n.t('reservation.reservation_date')}</th>
              <th>{I18n.t('generic.check_in')}</th>
              <th>{I18n.t('generic.price')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {reservations.map((reservation, index) => (
              <tr key={index}>
                <td>{reservation.workflow_state_name}</td>
                <td>
                  {/* <a href={`/user/${reservation.user.username}`} target="_blank"> */}
                  {reservation.user.username}
                  {/* </a> */}
                </td>
                <td>
                  <a href={`/posts/${reservation.post.slug}`} target="_blank">
                    {reservation.post.name}
                  </a>
                </td>
                <td>{reservation.created_at}</td>
                <td>{reservation.start_date}</td>
                <td>{reservation.price}</td>
                <td className="_buttons">
                  {getShowPastReservation() ? (
                    reservation.reviewable && (
                      <Button small={true}>
                        <a href={`/reservations/${reservation.id}/reviews/new`}>
                          {I18n.t('review.write_review')}
                        </a>
                      </Button>
                    )
                  ) : (
                    <>
                      <Button small={true}>
                        <a href={`/reservations/${reservation.slug}`}>
                          {I18n.t('reservation.message')}
                        </a>
                      </Button>
                      {filterParams.past === null && renderWorkFlowStateButtons(reservation)}
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  const renderList = () => {
    return (
      <div>
        <ReservationsList className="ReservationsList">
          {reservations &&
            reservations.map((reservation, index) => (
              <li
                key={index}
                className="ReservationsList_Item"
                onClick={() => showDetailModal(reservation)}
              >
                <div className="ReservationsList_ItemTitle">{reservation.post.name}</div>
                <div className="ReservationsList_ItemDate">{reservation.start_date}</div>
                <div className="ReservationsList_ItemState">{reservation.workflow_state_name}</div>
              </li>
            ))}
        </ReservationsList>
      </div>
    )
  }

  return (
    <>
      <ReservationsIndexWrapper>
        <div className="ReservationIndex_Header">
          <h2>{I18n.t('generic.filter')}</h2>
          <div className="ReservationIndex_Filters">
            <TypeFilter
              name={I18n.t('generic.status')}
              field="state"
              handleOnSubmit={handleOnSubmit}
              types={STATUS}
            />
            <CheckBox
              name="show_passed_reservation"
              label={I18n.t('reservation.show_passed_reservation')}
              defaultChecked={getShowPastReservation()}
              onChangeHandler={showPastReservationHandler}
              showLabelInline={true}
            />
          </div>
        </div>
        {!reservations ? (
          <Loading />
        ) : reservations?.length ? (
          isMobile ? (
            renderList()
          ) : (
            renderTable()
          )
        ) : (
          <div className="ReservationIndex_NoReservation">
            <i className="material-icons">calendar_today</i>
            <h1>{I18n.t('reservation.no_reservations')}</h1>
          </div>
        )}

        {pagination && (
          <Pagination
            onChangePageHandler={onChangePageHandler}
            currentPage={pagination.current_page}
            prevPage={pagination.prev_page}
            nextPage={pagination.next_page}
            totalPages={pagination.total_pages}
            totalCount={pagination.total_count}
          />
        )}
      </ReservationsIndexWrapper>
    </>
  )
}

const DetailModalBody = styled.div`
  .DetailModalBody_Buttons {
    margin-top: 16px;
  }
`

const DetailList = styled.dl`
  font-size: 14px;

  dt {
    font-weight: bold;
  }

  dd {
    margin-top: 4px;
  }

  dd + dt {
    margin-top: 12px;
  }
`

const ReservationsList = styled.ul`
  .ReservationsList_Item {
    padding: 12px;
    border-top: solid 1px ${constants.BORDER_COLOR};
  }

  .ReservationsList_ItemTitle {
    font-size: 14px;
    font-weight: bold;
  }

  .ReservationsList_ItemDate {
    font-size: 12px;
    margin-top: 8px;
  }

  .ReservationsList_ItemState {
    color: #999;
    font-size: 12px;
    margin-top: 4px;
  }
`

const ReservationsIndexWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;

  .ReservationIndex_NoReservation {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 24px;

    > i {
      font-size: 32px;
    }

    > h1 {
      margin-top: 12px;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .ReservationIndex_Header {
    padding: 12px 16px;

    > h2 {
      font-size: 14px;
      margin-bottom: 8px;
    }
  }

  .ReservationIndex_Filters {
    white-space: nowrap;
    margin: 0 -6px;

    > div {
      display: inline-block;
    }
  }

  .Scroller {
    width: 100%;
  }

  table {
    width: 100%;
    font-size: 14px;
    border-top: solid 1px ${constants.BORDER_COLOR};

    tr {
      border-bottom: solid 1px ${constants.BORDER_COLOR};
    }

    th,
    td {
      max-width: 400px;
      padding: 8px 12px;

      &._buttons {
        width: 210px;
      }

      > a {
        color: var(${constants.THEME_COLOR_VARIABLE_NAME});
        text-decoration: underline;
      }

      > .Button {
        display: block;
        margin: 0;
      }

      > .Button + .Button {
        margin-top: 8px;
      }
    }
  }
`

export default ReservationsIndex

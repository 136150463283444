import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { userService, utilService } from '../../../../core/services/admin'
import * as constants from '../../../../static/constants'
import * as Util from '../../../../utils'
import { CheckBox, InputText, Select } from '../../../atoms'
import AdminLayoutEdit from '../_layouts/edit'

const FIELDS = {
  id: 'id',
  username: 'username',
  bio: 'bio',
  email: 'email',
  phone: 'phone',
  email_notification: 'email_notification',
  is_admin: 'is_admin',
  is_owner: 'is_owner',
  financial_institution_name: 'financial_institution_name',
  financial_branch_name: 'financial_branch_name',
  financial_account_number: 'financial_account_number',
  financial_account_type: 'financial_account_type',
  financial_account_holder_name: 'financial_account_holder_name',
}

const title = I18n.t('admin.edit', {
  model: I18n.t('user', { scope: 'activerecord.models' }),
})

const AdminUserEdit: React.FC<{ user: any }> = props => {
  const { data: user } = utilService.getDataFromJson(props.user)
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    await userService.update(values)
    location.href = '/admin/users'
  }, [])
  const [role, setRole] = React.useState<boolean>(user.is_owner)

  return (
    <AdminLayoutEdit
      model="user"
      title={title}
      fields={FIELDS}
      indexLink="/admin/users"
      formItems={
        <>
          <InputText readonly={true} name="id" defaultValue={user.id} label="ID" />
          <InputText
            required={true}
            name="username"
            defaultValue={user.username}
            label="ユーザー名"
          />
          <InputText required={true} name="bio" defaultValue={user.bio} label="紹介文" />
          <InputText
            required={true}
            name="email"
            defaultValue={user.email}
            label="メールアドレス"
          />
          <CheckBoxes>
            <CheckBox name="is_admin" defaultChecked={user.is_admin} label="管理者権限" />
            <CheckBox
              name="is_owner"
              defaultChecked={role}
              label="オーナー権限"
              onChangeHandler={e => setRole(!role)}
            />
            <CheckBox
              name="email_notification"
              defaultChecked={user.email_notification}
              label="メール通知"
            />
          </CheckBoxes>
          <InputText required={false} name="phone" defaultValue={user.phone} label="電話番号" />
          <S.Bank isVisible={role}>
            <InputText
              required={role}
              name="financial_institution_name"
              label={I18n.t('settings.bank_account.name')}
              defaultValue={user.financial_institution_name || ''}
              placeholder={I18n.t('settings.bank_account.placeholder.name')}
            />

            <InputText
              required={role}
              name="financial_branch_name"
              label={I18n.t('settings.bank_account.branch_name')}
              defaultValue={user.financial_branch_name || ''}
              placeholder={I18n.t('settings.bank_account.placeholder.branch_name')}
            />

            <InputText
              required={role}
              name="financial_account_number"
              label={I18n.t('settings.bank_account.number')}
              defaultValue={user.financial_account_number || ''}
              placeholder={I18n.t('settings.bank_account.placeholder.number')}
            />
            <BankType>
              <Select
                required={role}
                name="financial_account_type"
                label={I18n.t('settings.bank_account.type')}
                options={[
                  { value: '普通', label: '普通' },
                  { value: '当座', label: '当座' },
                ]}
                defaultValue={user.financial_account_type || '普通'}
              />
            </BankType>
            <InputText
              required={role}
              name="financial_account_holder_name"
              label={I18n.t('settings.bank_account.account_name')}
              defaultValue={user.financial_account_holder_name || ''}
              placeholder={I18n.t('settings.bank_account.placeholder.account_name')}
            />
          </S.Bank>
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.Bank = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`

const CheckBoxes = styled.div`
  margin: 20px 0;
`

const BankType = styled.div`
  margin: 20px 0;
`
export default AdminUserEdit

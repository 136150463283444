import { ICategory, ITag } from 'core/interfaces'
import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import * as constants from '../../../../static/constants'
import { DateRangeFilter, PriceFilter, TypeFilter } from '../../../atoms'

interface ISearchHeaderProps {
  categories: ICategory[]
  tags: ITag[]
  handleOnFilterSubmit(params: any): void
}

const SearchHeader: React.FC<ISearchHeaderProps> = props => {
  const [isFilterPanelOpen, setIsFilterPanelOpen] = React.useState(false)

  return (
    <SearchHeaderWrapper className={`SearchHeader${isFilterPanelOpen ? ' isFilterPanelOpen' : ''}`}>
      <FilterList>
        <DateRangeFilter
          name={I18n.t('date')}
          field="date"
          setIsFilterPanelOpen={setIsFilterPanelOpen}
          handleOnSubmit={props.handleOnFilterSubmit}
        />
        <PriceFilter
          name={I18n.t('generic.price')}
          field="price"
          setIsFilterPanelOpen={setIsFilterPanelOpen}
          handleOnSubmit={props.handleOnFilterSubmit}
        />
        {props.categories && (
          <TypeFilter
            name={I18n.t('generic.category')}
            field="category_ids"
            setIsFilterPanelOpen={setIsFilterPanelOpen}
            handleOnSubmit={props.handleOnFilterSubmit}
            types={props.categories}
          />
        )}
        {props.tags && (
          <TypeFilter
            name={I18n.t('generic.tag')}
            field="tag_ids"
            setIsFilterPanelOpen={setIsFilterPanelOpen}
            handleOnSubmit={props.handleOnFilterSubmit}
            types={props.tags}
          />
        )}
      </FilterList>
    </SearchHeaderWrapper>
  )
}

const SearchHeaderWrapper = styled.div`
  height: 56px;
  padding: 10px 24px;
  border-bottom: solid 1px ${constants.BORDER_COLOR};
  @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
    height: auto;
    padding: 6px 12px;
  }

  &.isFilterPanelOpen {
    overflow: visible;
  }
`

const FilterList = styled.div`
  /* display: flex; */
  margin: 0 -6px;
  white-space: nowrap;

  > div {
    display: inline-block;
  }
`

export default SearchHeader

import { IPostImage } from 'core/interfaces'
import * as React from 'react'
import MediaQuery from 'react-responsive'
import Slider from 'react-slick'
import styled from 'styled-components'
import * as constants from '../../static/constants'

interface IProps {
  postImages: IPostImage[]
}

const SlickSlider: React.FC<IProps> = props => {
  return (
    <SlickSliderWrapper>
      {props.postImages.length > 0 ? (
        <MediaQuery minDeviceWidth={constants.BREAKPOINT_DESKTOP}>
          {matches => (
            <Slider dots={true} arrows={matches}>
              {props.postImages.map(image => (
                <div key={image.id} className="Slider_Item">
                  <img alt="" src={image.image_url} />
                </div>
              ))}
            </Slider>
          )}
        </MediaQuery>
      ) : (
        <img alt="" className="Slider_Item" src="/images/no-image.svg" />
      )}
    </SlickSliderWrapper>
  )
}

const SlickSliderWrapper = styled.div`
  .Slider_Item {
    height: 270px;
    overflow: hidden;
    background-color: #e7e7e7;
    border-radius: 4px;
    overflow: hidden;

    > img {
      width: inherit;
      height: inherit;
      object-fit: contain;
    }
  }

  .slick-prev::before,
  .slick-next::before {
    color: #e7e7e7;
    opacity: 1;
  }

  .slick-prev:hover::before,
  .slick-next:hover::before {
    opacity: 0.75;
  }
`

export default SlickSlider

import { range } from 'lodash'
import * as moment from 'moment'
import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import * as constants from '../../../static/constants'

function getSelectableYearMonthList(): moment.Moment[] {
  const list: moment.Moment[] = []
  const thisYear = moment().year()

  // 去年、今年、来年
  range(12).forEach((month: number) => {
    list.push(moment([thisYear - 1, month, 1]))
  })
  range(12).forEach((month: number) => {
    list.push(moment([thisYear, month, 1]))
  })
  range(12).forEach((month: number) => {
    list.push(moment([thisYear + 1, month, 1]))
  })

  return list
}

interface IButtonProps {
  primary?: boolean
}

interface IProps {
  changeYearMonth: (event: React.FormEvent<HTMLSelectElement | HTMLButtonElement>) => void
  yearMonth: moment.Moment
}

const themeColor = getComputedStyle(document.documentElement)
  .getPropertyValue(constants.THEME_COLOR_VARIABLE_NAME)
  .trim()

const CalendarYearMonthSelect: React.FC<IProps> = props => {
  const { changeYearMonth, yearMonth } = props

  return (
    <CalendarHeader>
      <Select>
        <select onChange={changeYearMonth} value={yearMonth.format(constants.DATE_FORMAT)}>
          {getSelectableYearMonthList().map((selectableYearMonth: moment.Moment, index: number) => (
            <option
              key={`yearMonth-${index}`}
              value={selectableYearMonth.format(constants.DATE_FORMAT)}
            >
              {selectableYearMonth.format('YYYY年 M月')}
            </option>
          ))}
        </select>
        <span />
      </Select>
      <Button
        type="button"
        className="prev"
        value={moment(yearMonth)
          .subtract(1, 'months')
          .format(constants.DATE_FORMAT)}
        onClick={changeYearMonth}
      >
        ←
      </Button>
      <Button
        type="button"
        className="next"
        value={moment(yearMonth)
          .add(1, 'months')
          .format(constants.DATE_FORMAT)}
        onClick={changeYearMonth}
      >
        →
      </Button>
      <Button
        type="button"
        className="today"
        value={moment([moment().year(), moment().month(), 1]).format(constants.DATE_FORMAT)}
        onClick={changeYearMonth}
      >
        {I18n.t('generic.today')}
      </Button>
    </CalendarHeader>
  )
}

const Button = styled.button`
  // reset default style
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;

  // added style
  display: block;
  height: 40px;
  border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
  border-radius: 4px;
  padding: 0 12px;
  color: ${(props: IButtonProps) => (props.primary ? '#fff' : themeColor)};
  background-color: ${(props: IButtonProps) => (props.primary ? themeColor : 'transparent')};

  & + & {
    margin-left: 12px;
  }
`

const Select = styled.div`
  margin-right: 32px;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  > select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    border: 0;
    margin: 0;
    padding: 0;
    background: none transparent;
    vertical-align: middle;
    font-size: inherit;
    color: inherit;
    box-sizing: content-box;
    outline: none;

    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }

  > span {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid #4f5959;
  }
`

const CalendarHeader = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 24px;

  button {
    height: 32px;
    border: solid 1px ${constants.BORDER_COLOR};
    color: #34495e;

    &:hover {
      opacity: 0.5;
    }

    &.prev {
      border-radius: 8px 0 0 8px;
    }

    &.next {
      margin: 0 0 0 -1px;
      border-radius: 0 8px 8px 0;
    }

    &.today {
      margin: 0 0 0 16px;
      border-radius: 8px;
      font-size: 14px;
    }
  }
`

export default CalendarYearMonthSelect

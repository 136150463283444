import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'
import { Button } from '../atoms'
import Pagination from '../atoms/Pagination'

interface IColumn {
  name: string
  field: string
  required?: boolean
  readOnly?: boolean
}

interface IProps {
  title: string | React.ReactElement
  columns: IColumn[]
  records: any[]
  editable: boolean
  pagination?: any
  handleDelete?(id: number): void
  getEditLink?(id: number): string
}

const EditTable: React.FC<IProps> = props => {
  const sizerRef = React.useRef(null)
  const tableRef = React.useRef(null)
  const onChangePageHandler = React.useCallback(page => {
    location.href = `${location.pathname}?page=${page}`
  }, [])

  return (
    <EditTableWrapper>
      <h1 className="EditTable_Title">{props.title}</h1>
      <div className="EditTable_Scroller">
        <div className="EditTable_Sizer" ref={sizerRef}>
          <table ref={tableRef}>
            <thead>
              <tr>
                {props.columns.map(field => (
                  <th key={field.field}>{field.name}</th>
                ))}
                {props.editable && <th />}
              </tr>
            </thead>
            <tbody>
              {props.records.map(record => (
                <EditTableRow
                  key={record.id}
                  editable={props.editable}
                  columns={props.columns}
                  record={record}
                  handleDelete={props.handleDelete}
                  getEditLink={props.getEditLink}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {props.pagination && (
        <Pagination
          onChangePageHandler={onChangePageHandler}
          currentPage={props.pagination.current_page}
          prevPage={props.pagination.prev_page}
          nextPage={props.pagination.next_page}
          totalPages={props.pagination.total_pages}
          totalCount={props.pagination.total_count}
        />
      )}
    </EditTableWrapper>
  )
}

interface ICategory {
  id: number
  name_i18n: string
  position: number
}

interface IEditTableRowProps {
  editable: boolean
  columns: IColumn[]
  record: ICategory
  handleDelete?(id: number): void
  getEditLink(id: number): string
}

const EditTableRow: React.FC<IEditTableRowProps> = props => {
  return (
    <tr>
      {props.columns.map(column => (
        <td key={column.field}>{props.record[column.field]}</td>
      ))}
      {props.editable && (
        <td className="_buttons">
          {typeof props.getEditLink === 'function' && (
            <Button key="edit" small={true}>
              <a href={props.getEditLink(props.record.id)}>{I18n.t('generic.edit')}</a>
            </Button>
          )}
          {props.handleDelete && (
            <Button
              key="delete"
              small={true}
              handleClick={() => props.handleDelete(props.record.id)}
            >
              {I18n.t('generic.delete')}
            </Button>
          )}
        </td>
      )}
    </tr>
  )
}

const EditTableWrapper = styled.div`
  .Pagination {
    padding-bottom: 24px;
  }

  .EditTable_Title {
    font-size: 18px;
    padding: 16px 20px;

    .Button {
      margin-left: 16px;
    }
  }

  .EditTable_Scroller {
    overflow: scroll;
    padding-bottom: 16px;
  }

  table {
    width: auto;
    border-bottom: solid 1px ${constants.BORDER_COLOR};

    th,
    td {
      max-width: 400px;
      min-width: 120px;
      padding: 4px 8px;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-right: solid 1px ${constants.BORDER_COLOR};

      &._buttons {
        width: 180px;
      }
    }

    tr {
      border-top: solid 1px ${constants.BORDER_COLOR};
    }
  }
`

export default EditTable

import httpClient from '../../httpClient'
import JsonApiSerializer from '../../JsonApiSerializer'
import UtilService from '../UtilService'
import CategoryService from './CategoryService'
import IdentificationService from './IdentificationService'
import PostService from './PostService'
import ReservationService from './ReservationService'
import StaffService from './StaffService'
import TagService from './TagService'
import UserService from './UserService'

export const jsonApiSerializer = new JsonApiSerializer()
export const categoryService = new CategoryService(httpClient, jsonApiSerializer)
export const identificationService = new IdentificationService(httpClient, jsonApiSerializer)
export const postService = new PostService(httpClient, jsonApiSerializer)
export const reservationService = new ReservationService(httpClient, jsonApiSerializer)
export const staffService = new StaffService(httpClient, jsonApiSerializer)
export const tagService = new TagService(httpClient, jsonApiSerializer)
export const userService = new UserService(httpClient, jsonApiSerializer)
export const utilService = new UtilService(httpClient, jsonApiSerializer)

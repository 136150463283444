import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../static/constants'

interface IProps {
  instagraAppID: string
  graphAPIToken: string
}

const TopInstagram: React.FC<IProps> = props => {
  const [images, setImages] = React.useState(null)

  React.useEffect(() => {
    const f = async () => {
      const mediaId = []
      setImages([])

      await fetch(
        `https://graph.facebook.com/v6.0/${props.instagraAppID}/media?access_token=${props.graphAPIToken}`
      )
        .then(res => res.json())
        .then(data => {
          data.data.map((media, index) => {
            if (index > 5) {
              return
            }
            mediaId.push(media.id)
          })
        })

      mediaId.map(
        async (id, index) =>
          await fetch(
            `https://graph.facebook.com/v6.0/${id}?fields=media_url&access_token=${props.graphAPIToken}`
          )
            .then(res => res.json())
            .then(data => {
              setImages(currentImages => [...currentImages, data.media_url])
            })
      )
    }
    if (!images) {
      f()
    }
  }, [images])

  return (
    <Wrapper>
      {images &&
        images.map((image, index) => (
          <div key={'image-' + index} className="insta">
            <img src={image} alt={'insta-' + index} />
          </div>
        ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  .insta {
    padding: 10px;
    width: 33%;

    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      width: 50%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
`
export default TopInstagram

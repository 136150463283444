import AdminTable from 'components/atoms/AdminTable'
import * as React from 'react'
import I18n from '../../../../core/i18n'
import { IJsonResponse, IStaff, IWindow } from '../../../../core/interfaces'
import { staffService, utilService } from '../../../../core/services/admin'
import * as Hooks from '../../../../utils/hooks'
import { Button, Pagination } from '../../../atoms'
import AdminLayoutIndex from '../_layouts/index'

declare var window: IWindow

interface IProps {
  staffs: {
    staffs: IJsonResponse
  }
  posts: any[]
}

const HIDE_STATUS = ['approved', 'declined']

const Index: React.FC<IProps> = props => {
  Hooks.SetDocumentTitle('スタッフ承認')
  const { data: initialStaffs, pagination } = utilService.getDataFromJson(props.staffs.staffs)
  const [staffs, setStaffs] = React.useState<IStaff[]>(initialStaffs)

  const approve = async id => {
    const { staff: approvedStaff, flash } = await staffService.approve(id)
    setStaffs(staffs.map(item => (item.id === approvedStaff.id ? approvedStaff : { ...item })))
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
  }

  const decline = async id => {
    const { staff: declinedStaff, flash } = await staffService.decline(id)
    setStaffs(staffs.map(item => (item.id === declinedStaff.id ? declinedStaff : { ...item })))
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
  }

  const onChangePageHandler = React.useCallback(page => {
    location.href = `${location.pathname}?page=${page}`
  }, [])

  return (
    <AdminLayoutIndex
      model="qualifications"
      main={
        <AdminTable
          body={
            <>
              <h1 className="EditTable_Title">
                <span>{I18n.t('nav.admins.qualification')}</span>
              </h1>
              <div className="EditTable_Scroller">
                <div className="EditTable_Sizer">
                  <table>
                    <thead>
                      <tr>
                        <th className="w_0">{I18n.t('staff.qualification.avatar_image')}</th>
                        <th>{I18n.t('generic.name')}</th>
                        <th>{I18n.t('generic.name_kana')}</th>
                        <th>{I18n.t('staff.post')}</th>
                        <th>{I18n.t('staff.qualification.image')}</th>
                        <th className="w_0">{I18n.t('generic.status')}</th>
                        <th className="w_0" />
                      </tr>
                    </thead>
                    <tbody>
                      {staffs
                        .filter(staff => !HIDE_STATUS.includes(staff.workflow_state))
                        .map(staff => (
                          <tr key={staff.id}>
                            <td>
                              <img src={staff.avatar_url} alt={staff.fullname} />
                            </td>
                            <td>{staff.fullname}</td>
                            <td>{staff.kana}</td>
                            <td>
                              <a href={`/posts/${staff.post.slug}`}>{staff.post.name}</a>
                            </td>
                            <td>
                              {staff.qualification_urls &&
                                staff.qualification_urls.map((url, index) => (
                                  <a key={'img-' + index} href={url} target="_blank">
                                    <img src={url} alt="" />
                                  </a>
                                ))}
                            </td>
                            <td>{staff.workflow_state}</td>
                            <td className="_buttons">
                              <Button handleClick={() => approve(staff.id)} primary={true}>
                                {I18n.t('generic.approve')}
                              </Button>
                              <Button handleClick={() => decline(staff.id)}>
                                {I18n.t('generic.decline')}
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {pagination && (
                <Pagination
                  onChangePageHandler={onChangePageHandler}
                  currentPage={pagination.current_page}
                  prevPage={pagination.prev_page}
                  nextPage={pagination.next_page}
                  totalPages={pagination.total_pages}
                  totalCount={pagination.total_count}
                />
              )}
            </>
          }
        />
      }
    />
  )
}

export default Index

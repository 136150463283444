import { AxiosInstance } from 'axios'
import IJsonApiSerializer from 'core/interfaces/IJsonApiSerializer'

class NotificationService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public async get(params) {
    const { data: json } = await this.httpClient.get(`/api/notifications`, { params })
    const notifications = this.serializer.parseResourceData(
      json.notifications,
      json.notifications.data
    )
    const pagination = json.notifications.meta
    return { notifications, pagination }
  }
}

export default NotificationService

import { IPost, IReview } from 'core/interfaces'
import moment from 'moment'
import 'moment/locale/ja'
import * as React from 'react'
import 'react-dates/initialize'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { postService } from '../../../../core/services'
import * as constants from '../../../../static/constants'
import { Button } from '../../../atoms'
import { Form } from '../../../molecules'

interface IProps {
  review: IReview
  post: IPost
  updateReviews(review: any): void
}

moment.locale('ja', {
  months: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
})

const REVIEW_REPLY_FIELDS = {
  body: 'body',
}

const ReviewReplyForm: React.FC<IProps> = props => {
  const [edit, setEdit] = React.useState(false)
  const textareaRef = React.useRef(null)
  const handleSubmit = async (initialValues, values) => {
    const { reviewReply } = await postService.createReviewReply({
      review_id: props.review.id,
      body: values.body,
    })

    props.updateReviews({
      ...props.review,
      review_replies: [
        {
          body: reviewReply.body,
          id: reviewReply.id,
          created_at: moment().format('YYYY/MM/DD'),
        },
      ],
    })
    location.href = '/posts/' + props.post.slug
    return
  }

  React.useEffect(() => {
    if (textareaRef.current !== null) {
      textareaRef.current.focus()
    }
  }, [edit])

  return (
    <AddReply>
      {edit ? (
        <div className="AddReply_Editor">
          <Avatar className="Avatar">
            <div className="Avatar_Image">
              <img src={props.post.user.avatar_url} alt="" />
            </div>
            <div className="Avatar_Info">
              <h4>{props.post.user.username}</h4>
            </div>
          </Avatar>
          <Form fields={REVIEW_REPLY_FIELDS} handleSubmit={handleSubmit}>
            <textarea
              ref={textareaRef}
              name="body"
              placeholder={I18n.t('placeholder.post.review.reply')}
            />
            <div className="AddReply_Footer">
              <Button primary={true}>{I18n.t('generic.post')}</Button>
              <Button handleClick={() => setEdit(false)}>{I18n.t('generic.cancel')}</Button>
            </div>
          </Form>
        </div>
      ) : (
        <Button handleClick={() => setEdit(true)}>{I18n.t('generic.reply')}</Button>
      )}
    </AddReply>
  )
}

const AddReply = styled.div`
  margin-top: 24px;

  .Form {
    margin-top: 12px;
  }

  textarea {
    display: block;
    width: 100%;
    height: 160px;
    padding: 8px 12px;
    border: solid 1px ${constants.BORDER_COLOR};
    border-radius: 4px;
    font-size: 15px;
    transition: border 0.2s ease;
    outline: none;
    background: none;

    &:focus {
      border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
    }
  }

  .AddReply_Editor {
    margin-left: 48px;
  }

  .AddReply_Footer {
    margin-top: 12px;
  }
`

const Avatar = styled.div`
  display: flex;
  align-items: center;

  &.staff {
    width: calc(50% - 20px);
    margin: 16px 10px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      margin: 12px 10px;
      width: calc(100% - 20px);
    }

    .staffFullname {
      font-size: 16px;
      color: var(${constants.THEME_COLOR_VARIABLE_NAME});
    }

    .experience {
      font-size: 12px;
      font-weight: normal;
      margin-left: 8px;
    }

    .staffBio {
      font-size: 14px;
    }
  }

  .Avatar_Image {
    ${(props: { size: number }) =>
      props.size
        ? `
      width: ${props.size}px;
      height: ${props.size}px;
      `
        : `
      width: 64px;
      height: 64px;
      `}
    border-radius: 50%;
    margin-right: 24px;
    overflow: hidden;
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      ${(props: { size: number }) =>
        props.size
          ? `
      width: ${props.size * 0.75}px;
      height: ${props.size * 0.75}px;
      `
          : `
      width: 48px;
      height: 48px;
      `}
      margin-right: 12px;
    }

    > img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }

  .Avatar_Info {
    flex: 1;

    h4 {
      font-size: 16px;
      margin-bottom: 4px;
    }

    > span {
      display: block;
    }
  }
`

export default ReviewReplyForm

import * as React from 'react'
import { Elements, injectStripe, StripeProvider } from 'react-stripe-elements'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { ICard, IPager, IUser } from '../../../../core/interfaces'
import { getStripePublicKey } from '../../../../utils'
import { Button, Panel, Spinner } from '../../../atoms'

import { settingsService } from 'core/services'
import SettingsCommonLayout from '../_layouts/common'
import StripeForm from './StripeForm'

const StripeInjectedForm = injectStripe(StripeForm)

interface IProps {
  current_user: IUser
}

const CardShow: React.FC<IProps> = props => {
  const [card, setCard] = React.useState<ICard>()
  const [edit, setEdit] = React.useState(false)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const f = async () => {
      const result = await settingsService.getCard()
      setCard(result.card)
      setLoading(false)
    }
    if (!card) {
      f()
    }
  }, [card])

  const renderRegisterCard = () => {
    return (
      <>
        <h3>{I18n.t('card.register_card')}</h3>
        <StripeProvider apiKey={getStripePublicKey()}>
          <Elements>
            {card ? (
              <StripeInjectedForm setCard={setCard} setEdit={setEdit} />
            ) : (
              <StripeInjectedForm isNew={true} setCard={setCard} setEdit={setEdit} />
            )}
          </Elements>
        </StripeProvider>
      </>
    )
  }

  const renderEditCard = () => {
    return (
      <>
        <h3>{I18n.t('card.registered_cards')}</h3>
        <div className="Panel_Body">
          {edit ? (
            <StripeProvider apiKey={getStripePublicKey()}>
              <Elements>
                <StripeInjectedForm setCard={setCard} setEdit={setEdit} />
              </Elements>
            </StripeProvider>
          ) : (
            <div>
              <p className="CardShow_RegisteredCard">
                <span>{card.brand}</span>
                <span>{I18n.t('card.card_end')}: </span>
                <span>{card.last4}</span>
                <span className="exp">{I18n.t('generic.expiration_date')}: </span>
                <span>
                  {card.exp_month}/{card.exp_year}
                </span>
              </p>
              <Button handleClick={() => setEdit(true)}>{I18n.t('generic.change')}</Button>
              {/* <Button>{I18n.t('card.delete_card')}</Button> */}
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <SettingsCommonLayout
      activePath={'card'}
      is_owner={props.current_user.is_owner}
      main={
        <CardShowWrapper>
          <div className="CardShow_Main">
            <Panel title={I18n.t('generic.card')}>
              {loading ? <Spinner /> : card ? renderEditCard() : renderRegisterCard()}
            </Panel>
          </div>
        </CardShowWrapper>
      }
    />
  )
}

const CardShowWrapper = styled.div`
  h3 {
    font-size: 16px;
  }

  .CardShow_RegisteredCard {
    span + span {
      margin-left: 8px;
    }

    span.exp {
      margin-left: 24px;
    }

    & + .Button {
      margin-top: 16px;
    }
  }

  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-radius: 4px;
    background: white;
  }

  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    transition: all 150ms ease;
  }

  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
`

export default CardShow

import { AxiosInstance } from 'axios'
import IJsonApiSerializer from 'core/interfaces/IJsonApiSerializer'

class TopService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public async topRate() {
    const { data } = await this.httpClient.get(`/api/reviews/top_rate`)
    const reviews = this.serializer.parseResourceData(data.reviews, data.reviews.data)
    return { reviews }
  }

  public async recommend() {
    const { data } = await this.httpClient.get(`/api/posts/top_post`)
    const posts = this.serializer.parseResourceData(data.posts, data.posts.data)
    return { posts }
  }
}

export default TopService

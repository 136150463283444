import * as Color from 'color'
import { createGlobalStyle } from 'styled-components'
import * as constants from './constants'

const themeColor =
  getComputedStyle(document.documentElement)
    .getPropertyValue(`${constants.THEME_COLOR_VARIABLE_NAME}`)
    .trim() || '#4A90E2'

const dateRangePickerCustomStyle = createGlobalStyle`
  .DateInput_input__focused {
    border-color: ${themeColor};
  }

  .CalendarDay__selected_span {
    background: ${Color(themeColor)
      .lighten(0.25)
      .hex()};
    border: 1px double
      ${Color(themeColor)
        .lighten(0.15)
        .hex()};
    color: #fff;
  }

  .CalendarDay__selected {
    background: var(${constants.THEME_COLOR_VARIABLE_NAME});
    border: 1px double var(${constants.THEME_COLOR_VARIABLE_NAME});
    color: white;
  }

  .CalendarDay__selected:hover {
    background: var(${constants.THEME_COLOR_VARIABLE_NAME});
    color: white;
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: ${Color(themeColor)
      .lighten(0.25)
      .hex()};
    border: 1px double
      ${Color(themeColor)
        .lighten(0.15)
        .hex()};
    color: #fff;
  }
`

export default dateRangePickerCustomStyle

import ClassNames from 'classnames'
import Color from 'color'
import { once, range, throttle } from 'lodash'
import moment from 'moment'
import 'moment/locale/ja'
import * as React from 'react'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import ReactSelect from 'react-select'
import { TwitterTimelineEmbed } from 'react-twitter-embed'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { IPost, IReservation, IUser, IWindow } from '../../../../core/interfaces'
import { postService } from '../../../../core/services'
import * as constants from '../../../../static/constants'
import SingleDatePickerCustomStyle from '../../../../static/singleDatePickerCustomStyle'
import { Button, Panel, Spinner } from '../../../atoms'

import PostGallery from './PostGallery'
import ReviewReplyForm from './ReviewReplyForm'

declare var window: IWindow

moment.locale('ja', {
  months: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
})

interface IProps {
  current_user: IUser
  google: any
  post: IPost
  currentPage: string
  reservation: IReservation
  operation: boolean
  setReservation(reservation: any): void
  setCurrentPage(page: string): void
}

const SCORE_VALUES = [1, 2, 3, 4, 5]

const PostDetail: React.FC<IProps> = props => {
  const [map, setMap] = React.useState(null)
  const [reviews, setReviews] = React.useState([...props.post.reviews])
  const [showReservationPanel, setShowReservationPanel] = React.useState(false)
  const [relatedPosts, setRelatedPosts] = React.useState<IPost[]>(null)
  const [focused, setFocused] = React.useState(false)
  const [totalPrice, setTotalPrice] = React.useState(null)
  const [selectedDate, setSelectedDate] = React.useState(null)
  const [blockedDays, setBlockedDays] = React.useState([])
  const [isblocked, setIsBlocked] = React.useState(false)

  const [reservationOptions, setReservationOptions] = React.useState({
    number: '',
    orders: [],
    nominate: {
      id: '',
      name: '',
    },
    date: '',
  })

  const onSelectChange = React.useCallback((option, event) => {
    if (!option) {
      option = []
    }

    if (event.name === 'nominate') {
      setReservationOptions(current => ({
        ...current,
        nominate: {
          id: option.value,
          name: option.label,
        },
      }))
    } else if (event.name === 'option') {
      const selectedOption = []
      option.map(item =>
        selectedOption.push({
          id: item.value,
          name: item.label,
          description: props.post.options.find(o => o.id === item.value)?.description,
        })
      )
      setReservationOptions(current => ({
        ...current,
        orders: selectedOption,
      }))
    } else {
      setReservationOptions(current => ({
        ...current,
        [event.name]: Array.isArray(option) ? option.map(item => item.value) : option.value,
      }))
    }
  }, [])

  const updateTotalFee = React.useCallback(async params => {
    const { price, is_blocked_this_day } = await postService.getCalculation(props.post.id, {
      ...params,
    })
    setIsBlocked(is_blocked_this_day)
    setTotalPrice(price)
  }, [])

  const onDatePickerChange = React.useCallback(
    changedDate => {
      setSelectedDate(changedDate)
    },
    [reservationOptions]
  )

  React.useEffect(() => {
    if (moment.isMoment(selectedDate)) {
      updateTotalFee({
        ...reservationOptions,
        date: selectedDate.format(constants.DATE_FORMAT),
      })
    }
  }, [selectedDate, reservationOptions])

  const createReservation = React.useCallback(() => {
    if (props.current_user && props.post.user.id === props.current_user.id) {
      window.flashMessages.addMessage({
        text: I18n.t('post.cannot_reserve_own_post'),
        type: 'error',
      })
      return
    }

    if (!moment.isMoment(selectedDate)) {
      setFocused(true)
      return
    }

    props.setReservation({
      ...reservationOptions,
      selectedDate,
      total_price: totalPrice,
    })

    props.setCurrentPage('option')
  }, [reservationOptions, selectedDate, totalPrice])

  React.useEffect(() => {
    if (props.google) {
      initializeMap()
    }
  }, [props.google, props.currentPage])

  const initializeMap = () => {
    const { latitude, longitude } = props.post
    if (!latitude || !longitude) {
      return
    }
    const LatLng = new props.google.maps.LatLng(latitude, longitude)

    let zoom = 15
    if (props.post.is_salon_private === true) {
      zoom = 13
    }
    const Options = {
      zoom,
      center: LatLng,
      mapTypeId: 'roadmap',
      scrollwheel: false,
    }
    const initializedMap = new props.google.maps.Map(document.getElementById('map'), Options)

    if (props.post.is_salon_private === false) {
      new props.google.maps.Marker({
        position: LatLng,
        title: props.post.name,
        map: initializedMap,
      })
    }
    setMap(initializedMap)
  }

  const updateReviews = updatedReview => {
    const nextReviews = reviews.map(review => {
      if (review.id === updatedReview.id) {
        return { ...updatedReview }
      } else {
        return { ...review }
      }
    })

    setReviews(nextReviews)
  }

  const renderReplies = reply => {
    return (
      <div key={reply.id} className="Review_Reply">
        <Avatar className="Avatar">
          <a className="Avatar_Image" href={`/user/${reply.user.username}`} target="_blank">
            <img src={reply.user.avatar_url} alt="" />
          </a>
          <div className="Avatar_Info">
            <h4>{reply.user.username}</h4>
            <span>{reply.user.created_at}</span>
          </div>
        </Avatar>
        <p>{reply.body}</p>
      </div>
    )
  }

  const getRelatedPosts = async () => {
    const data = await postService.getRelatedPosts(props.post)
    setRelatedPosts(data.relatedPosts)
  }

  const renderScore = score => {
    const Star = () => (
      <span className="Stars_Star">
        <svg viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon
            fillRule="nonzero"
            points="32.5 45.9878947 48.877 56 44.531 37.13 59 24.4336842 39.9465 22.7963158 32.5 5 25.0535 22.7963158 6 24.4336842 20.469 37.13 16.123 56"
          />
        </svg>
      </span>
    )

    return (
      <Score className="Score">
        <div className="Score_StarsWrapper">
          <div className="Score_Stars">
            {SCORE_VALUES.map(value => (
              <Star key={value} />
            ))}
          </div>
          <div className="Score_Stars filled" style={{ width: `${(score / 5) * 100}%` }}>
            {SCORE_VALUES.map(value => (
              <Star key={value} />
            ))}
          </div>
        </div>
        <span className="Stars_Score">{score}</span>
      </Score>
    )
  }

  const [userLiked, setUserLiked] = React.useState(props.post.user_liked)
  const toggleLike = async () => {
    if (!props.current_user) {
      location.href = '/users/sign_in'
      return
    }
    const { like } = await postService.toggleLike(props.post)
    setUserLiked(like.isUserLiked)
  }

  const renderPostImages = () => {
    if (!props.post.post_images.length) {
      return (
        <div className="PostShow_Images">
          <div className="PostShow_Image">
            <div className="PostShow_NoImage">No Image</div>
          </div>
        </div>
      )
    }

    return (
      <div className="PostShow_Images">
        {props.post.post_images.length === 1 ? (
          <div className="PostShow_Image">
            <img src={props.post.post_images[0].image_url} alt="" />
          </div>
        ) : (
          <div className="PostShow_Image">
            <PostGallery postImages={props.post.post_images} />
          </div>
        )}
        <div className="PostShow_MobileToggleLike" onClick={toggleLike}>
          {userLiked ? (
            <i className="material-icons liked">favorite</i>
          ) : (
            <i className="material-icons">favorite_border</i>
          )}
        </div>
      </div>
    )
  }

  React.useEffect(() => {
    const THROTTLE_WAIT = 300
    const scrollRange = scrollerRef.current.scrollHeight - scrollerRef.current.offsetHeight
    const getRelatedPostsOnce = once(getRelatedPosts)
    const onScrollHandler = throttle(event => {
      if (event.target.scrollTop > scrollRange - 100) {
        getRelatedPostsOnce()
      }
    }, THROTTLE_WAIT)

    scrollerRef.current.addEventListener('scroll', onScrollHandler)
  }, [])

  const [calendarEvents, setCalendarEvents] = React.useState([])

  const getCalendarEvents = async yearMonth => {
    const { calendarCollections } = await postService.getCalendarCollections(
      props.post.id,
      yearMonth
    )
    setCalendarEvents(calendarCollections)
  }

  React.useEffect(() => {
    getCalendarEvents(getCalendarEvents)
  }, [])

  const scrollerRef = React.useRef(null)

  const validStaffs = []
  props.post.staffs.forEach((staff, index) => {
    if (staff.is_valid === true) {
      validStaffs.push(staff)
    }
  })

  const menuOptions = []

  if (props.post.options) {
    props.post.options.forEach((option, index) =>
      menuOptions.push({
        value: option.id,
        label: `${option.name} (${option.price})`,
      })
    )
  }

  const selectOptions = {
    number: range(1, 11).map((number, index) => ({
      value: number,
      label: `${number}人`,
    })),
    option: menuOptions,
    nominate: validStaffs.map((staff, index) => ({
      value: staff.id,
      label: `${staff.fullname}${staff.price > 0 ? ` (¥${staff.price})` : ''}`,
    })),
  }
  const getDefaultSelectOption = React.useCallback((field, value) => {
    if (!value) {
      return null
    } else if (Array.isArray(value)) {
      return selectOptions[field].filter(option => value.includes(option.value)) || null
    } else {
      return selectOptions[field].find(option => option.value === value) || null
    }
  }, [])

  const selectCustomStyles = {
    control: (provided, state) => ({
      ...provided,
      border: `1px solid ${constants.BORDER_COLOR}`,
      borderRadius: 0,
    }),
  }

  const getCalendarCollections = async yearMonth => {
    const { calendarCollections } = await postService.getCalendarCollections(
      props.post.id,
      yearMonth
    )
    const blockedDayz = []

    // block all days by default
    for (let m = 1; m <= 31; m += 1) {
      const d = moment(yearMonth + '-' + m)
      if (d.isValid()) {
        blockedDayz.push(d.format('YYYY-MM-DD'))
      }
    }

    // remove now days that are not blocked
    if (calendarCollections.length > 0) {
      calendarCollections.forEach(collection => {
        if (collection.blocked !== true) {
          const index = blockedDayz.indexOf(collection.day)
          if (index > -1) {
            blockedDayz.splice(index, 1)
          }
        }
      })
    }
    setBlockedDays(blockedDayz)
  }

  const isBlocked = day => {
    const day_date = moment(day).format('YYYY-MM-DD')
    return blockedDays.includes(day_date)
  }

  const onFocusChanged = (changed, date) => {
    if (changed === true) {
      // get this month if date is null
      if (date === null) {
        const dateToday = moment().year() + '-' + (moment().month() + 1)
        const yearMonthToday = moment(dateToday).format('YYYY-MM')
        getCalendarCollections(yearMonthToday)
      }
      // get the selected date month otherwise
      else {
        const yearMonth = moment(date).format('YYYY-MM')
        getCalendarCollections(yearMonth)
      }
    }
    setFocused(changed)
  }

  const updateMonthAvailibility = (direction, date) => {
    let yearMonth
    let addMonth = 0
    if (direction === 'prev') {
      addMonth = -1
    } else if (direction === 'next') {
      addMonth = 1
    }

    if (date === null) {
      const dateToday = moment().year() + '-' + (moment().month() + 1)
      yearMonth = moment(dateToday)
        .add(addMonth, 'M')
        .format('YYYY-MM')
    } else {
      // get the selected date month otherwise
      yearMonth = moment(date)
        .add(addMonth, 'M')
        .format('YYYY-MM')
    }
    setSelectedDate(moment(yearMonth))
    getCalendarCollections(yearMonth)
  }

  return (
    <PostShowWrapper className="PostShow" ref={scrollerRef}>
      {props.current_user && props.post.user.id === props.current_user.id && (
        <div className="PostShow_Owner">
          <p>{I18n.t('post.it_is_your_post')}</p>
          <a className="PostShow_Owner-editButton" href={`/posts/${props.post.slug}/edit`}>
            {I18n.t('generic.edit_post_data')}
          </a>
        </div>
      )}

      {/* <div className="PostShow_Hero">
        <img src={props.post_images[0].image_url} alt="" />
      </div> */}

      <div className="PostShow_Main">
        <div className="PostShow_Content">
          <div className="PostShow_Header">
            <div>
              {renderPostImages()}

              <div className="PostShow_MainInfo">
                <div>
                  <div className="category">{props.post.category && props.post.category.name}</div>
                  <h3 className="postName">{props.post.name}</h3>
                  {renderScore(props.post.avarage_review_score)}

                  <div className="place">
                    <i className="material-icons">room</i>
                    <span>{props.post.state}, </span>
                    <span>{props.post.city}</span>
                  </div>
                </div>

                <div className="PostShow_Tags">
                  <ul>
                    {props.post.tags.map(tag => (
                      <li key={tag.id}>
                        <i className="material-icons">local_offer</i>
                        {tag.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <p className="PostShow_Description">{props.post.description}</p>
          </div>

          <section className="PostShow_Section two_columns">
            <div className="PostShow_Staffs">
              <h3>{I18n.t('post.staffs')}</h3>
              {props.post.staffs.map(
                (staff, index) =>
                  staff.is_valid && (
                    <Avatar key={staff.id} className="Avatar staff" size={92}>
                      <div className="Avatar_Image">
                        <img src={staff.avatar_url} alt={staff.fullname} />
                      </div>
                      <div className="Avatar_Info">
                        <h4>
                          <span className="staffFullname">{staff.fullname}</span>
                          {staff.experience && (
                            <small className="experience">(歴{staff.experience}年)</small>
                          )}
                        </h4>
                        <p className="staffBio">{staff.bio}</p>
                      </div>
                    </Avatar>
                  )
              )}
            </div>
            {props.post.twitter_username && (
              <div>
                <h3>Twitter</h3>
                <TwitterWrapper>
                  <Panel className="timeline">
                    <TwitterTimelineEmbed
                      sourceType="profile"
                      screenName={props.post.twitter_username}
                      options={{ height: 600 }}
                      lang="ja"
                    />
                  </Panel>
                </TwitterWrapper>
              </div>
            )}
          </section>

          {props.post.latitude !== 0 && props.post.longitude !== 0 && (
            <section className="PostShow_Section">
              <h3>{I18n.t('generic.access')}</h3>
              {props.post.is_salon_private ? (
                <div className="place">
                  <i className="material-icons">room</i>
                  <span>{props.post.state}, </span>
                  <span>{props.post.city}</span>
                </div>
              ) : (
                <div className="place">
                  <i className="material-icons">room</i>
                  <span>{props.post.full_address}</span>
                </div>
              )}
              <div id="map" className="PostShow_Map" />
            </section>
          )}
          {props.post.note?.length > 0 && <div className="PostShow_Note">{props.post.note}</div>}

          {props.post.travel_range && (
            <section className="PostShow_Section">
              <h3>{I18n.t('post.travel_range')}</h3>
              <p className="PostShow_TravelRange">{props.post.travel_range}</p>
            </section>
          )}

          {props.post.easy_reservation && (
            <section className="PostShow_Section">
              <h3>{I18n.t('post.easy_reservation')}</h3>
              <p className="PostShow_TravelRange">{props.post.easy_reservation}</p>
            </section>
          )}

          <section className="PostShow_Section">
            <h3>{I18n.t('review.review_count', { count: reviews.length })}</h3>
            <div className="PostShow_ReviewScore">
              {renderScore(props.post.avarage_review_score)}
            </div>
            {reviews.map((review, index) => (
              <Review key={index}>
                <div className="Review_Main">
                  <Avatar className="Avatar">
                    <a
                      className="Avatar_Image"
                      href={`/user/${review.reviewer.username}`}
                      target="_blank"
                    >
                      <img src={review.reviewer.avatar_url} alt="" />
                    </a>
                    <div className="Avatar_Info">
                      <h4>{review.reviewer.username}</h4>
                      <span>{review.reviewer.created_at}</span>
                    </div>
                  </Avatar>
                  <p>{review.body}</p>
                </div>
                {review.review_reply ? (
                  renderReplies(review.review_reply)
                ) : props.current_user && props.post.user.id === props.current_user.id ? (
                  <ReviewReplyForm
                    review={review}
                    updateReviews={updateReviews}
                    post={props.post}
                  />
                ) : null}
              </Review>
            ))}
          </section>

          {/* <section className="PostShow_Section">
            <h3>{I18n.t('generic.about_host')}</h3>
            <div className="PostShow_Host">
              <div className="PostShow_Avatar">
                <a
                  className="PostShow_AvatarImage"
                  href={`/user/${props.post.user.username}`}
                  target="_blank"
                >
                  <img src={props.post.user.avatar_url} alt="" />
                </a>
                <div className="PostShow_AvatarInfo">
                  <h4>{props.post.user.username}</h4>
                  <span>
                    {I18n.t('avatar.avatar_created_at', { created_at: props.post.user.created_at })}
                  </span>
                  {props.post.user.reviews_count && (
                    <span>{I18n.t('avatar.review', { count: props.post.user.reviews_count })}</span>
                  )}
                </div>
              </div>
              <p>{props.post.user.bio}</p>
              <Button>ホストに連絡する</Button>
            </div>
          </section> */}
        </div>

        <Sidebar className={ClassNames('Sidebar', { show: showReservationPanel })}>
          <div className="PostShow_Sidebar">
            <div className="PostShow_Panel">
              <div className="Sidebar_Close" onClick={() => setShowReservationPanel(false)}>
                <i className="material-icons">clear</i>
              </div>
              <p className="PostShow_Price">
                <span>
                  {props.post.category && props.post.category.name === '美容室' ? (
                    <span className="label">{I18n.t('post.cut')}</span>
                  ) : (
                    <span className="label">{I18n.t('generic.basic_fee')}</span>
                  )}

                  <span className="price">¥ {props.post.price}</span>
                </span>
                {props.post.is_included_shampoo ? (
                  <small>{I18n.t('post.shampoo_included')}</small>
                ) : (
                  <small>{I18n.t('post.shampoo_not_included')}</small>
                )}
              </p>

              <div className="PostShow_Date">
                <div className="PostShow_OptionLabel">{I18n.t('reservation.side.date')}</div>
                <SingleDatePickerCustomStyle />
                <SingleDatePicker
                  id="date"
                  date={selectedDate}
                  onDateChange={onDatePickerChange}
                  placeholder={I18n.t('placeholder.selector.select')}
                  focused={focused}
                  onFocusChange={changed => onFocusChanged(changed.focused, selectedDate)}
                  numberOfMonths={1}
                  hideKeyboardShortcutsPanel={true}
                  isDayBlocked={isBlocked}
                  onPrevMonthClick={clicked => updateMonthAvailibility('prev', selectedDate)}
                  onNextMonthClick={clicked => updateMonthAvailibility('next', selectedDate)}
                />
                {isblocked === true && <span className="unavailable">利用不能日</span>}
              </div>

              <div>
                <Select>
                  <div className="Select_Label">人数</div>
                  <ReactSelect
                    onChange={onSelectChange}
                    placeholder={I18n.t('placeholder.selector.select')}
                    defaultValue={getDefaultSelectOption(
                      'number',
                      props.reservation && props.reservation.number
                    )}
                    isSearchable={false}
                    maxMenuHeight={143}
                    name="number"
                    options={selectOptions.number}
                    styles={selectCustomStyles}
                  />
                </Select>

                <Select>
                  <div className="Select_Label">{I18n.t('reservation.side.option')}</div>
                  <ReactSelect
                    onChange={onSelectChange}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    placeholder={I18n.t('placeholder.selector.none')}
                    isSearchable={false}
                    maxMenuHeight={143}
                    name="option"
                    options={selectOptions.option}
                    styles={selectCustomStyles}
                  />
                </Select>

                <Select>
                  <div className="Select_Label">{I18n.t('reservation.side.nominate')}</div>
                  <ReactSelect
                    onChange={onSelectChange}
                    placeholder={I18n.t('placeholder.selector.none')}
                    isSearchable={false}
                    menuShouldScrollIntoView={true}
                    maxMenuHeight={143}
                    name="nominate"
                    options={selectOptions.nominate}
                    styles={selectCustomStyles}
                  />
                </Select>

                {totalPrice > 0 && (
                  <div className="PostShow_TotalPrice">
                    <span>
                      <span className="label">{I18n.t('generic.total')}</span>
                      <span className="price">¥ {totalPrice}</span>
                    </span>
                    {props.post.is_included_travel_fee ? (
                      <small>{I18n.t('post.travel_fee_included')}</small>
                    ) : (
                      <small>{I18n.t('post.travel_fee_not_included')}</small>
                    )}
                  </div>
                )}

                {props.operation ? (
                  <>
                    {props.current_user ? (
                      <>
                        {props.current_user.has_right ? (
                          <Button
                            primary={true}
                            handleClick={createReservation}
                            disabled={totalPrice <= 0 || isblocked}
                          >
                            {I18n.t('generic.reservation')}
                          </Button>
                        ) : (
                          <div style={{ marginTop: '10px' }}>
                            <div
                              style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold' }}
                            >
                              サービス利用設定が済んでいないため、予約することが出来ません。
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <Button primary={true}>
                        <a href="/users/sign_in">{I18n.t('generic.login')}</a>
                      </Button>
                    )}
                  </>
                ) : (
                  <div style={{ marginTop: '10px' }}>
                    <div style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold' }}>
                      現在サービス停止中のため
                      <br />
                      ご利用できません。
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="PostShow_Buttons">
              <Button handleClick={toggleLike}>
                {userLiked ? (
                  <>
                    <i className="material-icons liked">favorite</i>
                    <span>{I18n.t('favorite.is_liked')}</span>
                  </>
                ) : (
                  <>
                    <i className="material-icons">favorite_border</i>
                    <span>{I18n.t('favorite.add_like')}</span>
                  </>
                )}
              </Button>
            </div>
          </div>
        </Sidebar>
      </div>

      {!relatedPosts ? (
        <div className="RelatedPosts_Loading">
          <Spinner />
        </div>
      ) : (
        relatedPosts.length > 0 && (
          <RelatedPosts>
            <h3>{I18n.t('post.related_posts')}</h3>
            <div className="RelatedPosts_Inner">
              {relatedPosts.map(post => (
                <a key={post.id} className="RelatedPosts_Post" href={`/posts/${post.slug}`}>
                  <div className="RelatedPosts_Image">
                    {post.post_image && post.post_image.image_url ? (
                      <img src={post.post_image.image_url} alt="" />
                    ) : (
                      <p className="RelatedPosts_NoImage">No Image</p>
                    )}
                  </div>
                  <div className="RelatedPosts_Info">
                    <p className="RelatedPosts_Title">{post.name}</p>
                    <p className="RelatedPosts_Price">
                      <span>¥ {post.price}</span> {I18n.t('generic.per_day')}
                    </p>
                    <div className="RelatedPosts_Score">
                      {renderScore(post.avarage_review_score)}
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </RelatedPosts>
        )
      )}
      <Footer>
        <div>
          <p className="Footer_Price">
            ¥ {props.post.price} <span>{I18n.t('generic.per_day')}</span>
          </p>
          {renderScore(props.post.avarage_review_score)}
        </div>
        <Button primary={true} handleClick={() => setShowReservationPanel(true)}>
          {I18n.t('generic.reservation')}
        </Button>
      </Footer>
    </PostShowWrapper>
  )
}

const TwitterWrapper = styled.div`
  margin-top: 20px;
  .timeline {
    flex: 50%;
  }
`

const Footer = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 72px;
  background-color: #fff;
  border-top: solid 1px ${constants.BORDER_COLOR};
  z-index: 100;
  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    display: flex;
  }

  .Footer_Price {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 4px;
  }
`

const RelatedPosts = styled.div`
  max-width: 1088px;
  margin: 0 auto 48px;
  padding: 0 24px 48px;
  overflow: hidden;
  @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
    padding: 0 12px 48px;
  }

  > h3 {
    font-size: 20px;
    padding-top: 32px;
    border-top: solid 1px ${constants.BORDER_COLOR};
  }

  .RelatedPosts_Image {
    width: 100%;
    height: 180px;
    border-radius: 3px;
    overflow: hidden;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      width: 180px;
      height: 120px;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      width: 100%;
      height: 180px;
    }

    > img {
      width: 100%;
      height: inherit;
      object-fit: cover;
    }
  }

  .RelatedPosts_NoImage {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
  }

  .RelatedPosts_Title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 14px;
    }
  }

  .RelatedPosts_Price {
    margin-top: 8px;
    color: #7c7c7c;
    font-size: 12px;

    > span {
      font-size: 14px;
      font-weight: bold;
      @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
        font-size: 12px;
      }
    }
  }

  .RelatedPosts_Score {
    margin-top: 8px;
  }

  .RelatedPosts_Inner {
    display: flex;
    flex-wrap: wrap;
    margin: 32px -12px 0;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      display: block;
    }
  }

  .RelatedPosts_Post {
    width: calc(33.33% - 24px);
    margin: 10px 12px;
    display: flex;
    flex-direction: column;
    color: ${constants.TEXT_COLOR};
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      width: auto;
      flex-direction: row;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      flex-direction: column;
    }
  }

  .RelatedPosts_Post + .RelatedPosts_Post {
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      margin-top: 24px;
    }
  }

  .RelatedPosts_Info {
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      padding-left: 16px;
      flex: 1;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      padding-left: 0;
    }
  }
`

const Avatar = styled.div`
  display: flex;
  align-items: center;

  &.staff {
    width: calc(100% - 20px);
    margin: 16px 10px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      margin: 12px 10px;
      width: calc(100% - 20px);
    }

    .staffFullname {
      font-size: 16px;
      color: var(${constants.THEME_COLOR_VARIABLE_NAME});
    }

    .experience {
      font-size: 12px;
      font-weight: normal;
      margin-left: 8px;
    }

    .staffBio {
      font-size: 14px;
    }
  }

  .Avatar_Image {
    ${(props: { size: number }) =>
      props.size
        ? `
      width: ${props.size}px;
      height: ${props.size}px;
      `
        : `
      width: 64px;
      height: 64px;
      `}
    border-radius: 50%;
    margin-right: 24px;
    overflow: hidden;
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      ${(props: { size: number }) =>
        props.size
          ? `
      width: ${props.size * 0.75}px;
      height: ${props.size * 0.75}px;
      `
          : `
      width: 48px;
      height: 48px;
      `}
      margin-right: 12px;
    }

    > img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }

  .Avatar_Info {
    flex: 1;

    h4 {
      font-size: 16px;
      margin-bottom: 4px;
    }

    > span {
      display: block;
    }
  }
`

const Review = styled.div`
  padding-bottom: 32px;

  & + & {
    padding-top: 32px;
    border-top: solid 1px ${constants.BORDER_COLOR};
  }

  p {
    margin-top: 20px;
  }

  .Review_Reply {
    margin-top: 24px;
    margin-left: 48px;
  }
`

const Score = styled.div`
  display: flex;
  align-items: center;

  .Stars_Score {
    margin-left: 6px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      font-size: 12px;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 10px;
    }
  }

  .Score_StarsWrapper {
    position: relative;
    width: 90px;
    height: 18px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      width: 80px;
      height: 16px;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      width: 60px;
      height: 12px;
    }
  }

  .Score_Stars {
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    display: flex;

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }

    &.filled {
      overflow: hidden;

      svg {
        fill: #ffb800;
      }
    }
  }

  .Stars_Star {
    position: relative;
    display: block;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      width: 16px;
      height: 16px;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      width: 12px;
      height: 12px;
    }

    svg {
      width: inherit;
      height: inherit;
      fill: #ededed;
    }
  }
`

const PostShowWrapper = styled.div`
  height: calc(100vh - ${constants.HEADER_HEIGHT}px);
  overflow: scroll;

  .PostShow_Header {
    padding-bottom: 32px;

    > div {
      display: flex;
      padding-bottom: 16px;
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        display: block;
      }
    }
  }

  .PostShow_HeaderAside {
    flex: 1;
  }

  .PostShow_HeaderInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
  }

  .PostShow_Images {
    width: 280px;
    height: 180px;
  }

  .PostShow_MainInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 24px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      display: block;
      margin-top: 24px;
      padding-left: 0;
    }

    .category {
      font-size: 12px;
      color: #9da0a4;
    }

    .postName {
      font-size: 24px;
      font-weight: bold;
    }

    .place {
      margin-top: 12px;
      color: #9da0a4;

      span {
        font-size: 14px;
        vertical-align: middle;
      }

      .material-icons {
        margin-right: 4px;
        font-size: 18px;
        vertical-align: middle;
      }
    }

    .Score {
      margin-top: 12px;
    }
  }

  .PostShow_Tags {
    ul {
      display: flex;
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
        margin-top: 16px;
      }
    }

    li {
      position: relative;
      display: flex;
      align-items: center;
      padding: 8px 12px;
      font-weight: bold;
      font-size: 14px;
      line-height: 1;
      color: var(${constants.THEME_COLOR_VARIABLE_NAME});
      border-radius: 3px;
      overflow: hidden;
      @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
        padding: 6px 8px;
        font-size: 12px;

        > i {
          font-size: 10px;
          margin-right: 4px;
        }
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(${constants.THEME_COLOR_VARIABLE_NAME});
        opacity: 0.12;
      }

      > i {
        font-size: 16px;
        margin-right: 8px;
      }
    }

    li + li {
      margin-left: 12px;
    }
  }

  .PostShow_Staffs {
    flex: 50%;
    margin: 0 -10px;
    overflow: hidden;
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      margin: 10px;
    }
  }

  .PostShow_Owner {
    display: flex;
    height: 48px;
    padding: 0 24px;
    align-items: center;
    background-color: #ff9d33;
    color: #fff;
    font-weight: bold;

    > a {
      color: #fff;
      text-decoration: underline;
      margin-left: 12px;
    }

    .PostShow_Owner-editButton {
      background: white;
      padding: 6px;
      border-radius: 8px;
      width: 200px;
      text-align: center;
      border: 1px solid var(${constants.THEME_COLOR_VARIABLE_NAME});
      text-decoration: none;
      font-weight: normal;
      color: var(${constants.THEME_COLOR_VARIABLE_NAME});
      &:hover {
        opacity: 0.6;
      }
    }
  }

  .PostShow_NoImage {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
  }

  .PostShow_Images {
    position: relative;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: auto;
      height: auto;
      margin: 0 -24px;

      &::before {
        content: '';
        display: block;
        padding-top: 56.25%;
      }
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      margin: 0 -12px;
    }
  }

  .PostShow_MobileToggleLike {
    display: none;
    position: absolute;
    top: 24px;
    right: 24px;
    width: 32px;
    height: 32px;
    color: #fff;
    cursor: pointer;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      display: block;
    }

    .material-icons {
      font-size: 32px;
      text-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);

      &.liked {
        color: #fb585c;
      }
    }
  }

  .PostShow_Image {
    width: inherit;
    height: inherit;
    border-radius: 3px;
    overflow: hidden;

    > img {
      width: 100%;
      height: inherit;
      object-fit: cover;
    }
  }

  .PostShow_Image,
  .PostShow_Image > img {
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }

  .PostShow_Main {
    display: flex;
    max-width: 1088px;
    margin: 32px auto 0;
    padding: 0 24px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      margin-top: 0;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      padding: 0 12px;
    }
  }

  .PostShow_TotalPrice {
    margin-top: 24px;
    padding-bottom: 12px;
    border-bottom: solid 1px ${constants.BORDER_COLOR};

    > span {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .label {
      font-size: 14px;
    }

    .price {
      font-size: 20px;
      font-weight: bold;
    }

    small {
      display: block;
      text-align: right;
    }
  }

  .PostShow_Place {
    font-size: 16px;

    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 14px;
    }
  }

  .PostShow_Avatar {
    display: flex;
    align-items: center;

    .PostShow_AvatarImage {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      margin-right: 24px;
      overflow: hidden;
      @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
        margin-right: 12px;
      }

      > img {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }

    .PostShow_AvatarInfo {
      flex: 1;

      h4 {
        font-size: 20px;
        margin-bottom: 4px;
        @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
          font-size: 14px;
        }
      }

      > span {
        display: block;
        font-size: 14px;
        @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
          font-size: 12px;
        }
      }
    }
  }

  .PostShow_Content {
    flex: 1;
    margin-right: 16px;
    line-height: 1.5;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      flex: initial;
      margin-right: 0;
    }
  }

  .PostShow_Title {
    font-weight: bold;
    font-size: 20px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      font-size: 16px;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 14px;
    }
  }

  .PostShow_Section {
    border-top: solid 1px ${constants.BORDER_COLOR};
    padding: 32px 0 48px;

    > h3 {
      font-size: 20px;
    }
  }

  .two_columns {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      flex-direction: column;
    }
  }

  .PostShow_Description,
  .PostShow_Note {
    white-space: pre-wrap;
  }

  .PostShow_Map {
    margin-top: 16px;
    height: 440px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      height: 320px;
    }
  }

  .PostShow_TravelRange {
    margin-top: 24px;
  }

  .PostShow_ReviewScore {
    margin: 24px 0 48px;
  }

  .PostShow_Host {
    margin-top: 32px;

    > p {
      margin-top: 32px;
    }

    > .Button {
      margin-top: 24px;
    }
  }

  .PostShow_Price {
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: solid 1px ${constants.BORDER_COLOR};

    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      margin-bottom: 12px;
      padding-bottom: 4px;
    }
    > span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      font-size: 20px;
    }

    .label {
      font-size: 14px;
    }

    .price {
      font-size: 20px;
      font-weight: bold;
    }

    small {
      display: block;
      text-align: right;
    }
  }

  .RelatedPosts_Loading {
    margin: 32px 0;
  }
`

const Sidebar = styled.div`
  margin-bottom: 48px;
  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    display: none;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 2000;

    &.show {
      display: flex;
    }
  }

  .Button {
    height: 48px;
  }

  .Sidebar_Close {
    display: none;
    font-size: 26px;
    margin: 0 0 12px -4px;
    cursor: pointer;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      display: block;
      margin-bottom: 0;
    }
  }

  .PostShow_Sidebar {
    /* position: sticky; */
    /* top: 24px; */
    padding-top: 24px;
    width: 267px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      /* position: static; */
      max-height: calc(100% - 76px);
      width: 100%;
      max-width: 720px;
      margin: 0 12px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .Button {
      width: 100%;
      margin-top: 24px;
    }
  }

  .PostShow_Panel {
    padding: 16px;
    border: solid 1px ${constants.BORDER_COLOR};
    background-color: #fff;

    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      padding: 24px;
      margin-bottom: 80px;
    }

    .Button {
      font-weight: bold;
    }
  }

  .PostShow_Buttons {
    padding: 16px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      display: none;
    }

    .Button {
      font-weight: bold;
      color: ${constants.TEXT_COLOR};
      border: solid 1px
        ${Color(constants.TEXT_COLOR)
          .lighten(3.6)
          .hex()};
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 1;
      }

      .material-icons {
        margin-right: 6px;
        color: ${Color(constants.TEXT_COLOR)
          .lighten(3.6)
          .hex()};

        &.liked {
          color: #fb585c;
        }
      }
    }
  }
  .unavailable {
    color: red;
    font-size: 11px;
  }
`

const Select = styled.div`
  margin-top: 16px;

  select {
    position: relative;
    appearance: none;
    margin: 0;
    padding: 0;
    background: none transparent;
    vertical-align: middle;
    font-size: inherit;
    color: inherit;
    outline: none;

    width: 100%;
    border: solid 1px #eaedef;
    border-radius: 0;
    padding: 10px 12px;
    font-size: 14px;

    &::after {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      background-color: #ccc;
    }
  }

  .Select_Wrapper {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: 16px;
      top: -6px;
      bottom: 0;
      margin: auto;
      display: block;
      width: 11px;
      height: 11px;
      border-bottom: solid 1px ${constants.TEXT_COLOR};
      border-right: solid 1px ${constants.TEXT_COLOR};
      transform: rotate(45deg);
    }
  }

  .Select_Label {
    font-size: 14px;
    margin-bottom: 8px;
  }
`

export default PostDetail

import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'

interface IProps {
  name: string
  defaultChecked: boolean
  label?: string
  showLabelInline?: boolean
  onChangeHandler?(event: any): void
}

const CheckBox: React.FC<IProps> = props => {
  return (
    <CheckBoxWrapper className="CheckBox">
      {props.label && !props.showLabelInline && <p className="CheckBox_Title">{props.label}</p>}
      <div className="CheckBox_Main">
        <label>
          <input
            type="checkbox"
            name={props.name}
            defaultChecked={props.defaultChecked}
            onChange={event => {
              if (typeof props.onChangeHandler === 'function') {
                props.onChangeHandler(event)
              }
            }}
          />
          <span className="CheckBox_Icon">
            <i className="material-icons filled">check_box</i>
            <i className="material-icons outline">check_box_outline_blank</i>
          </span>
          {props.showLabelInline && <span>{props.label}</span>}
        </label>
      </div>
    </CheckBoxWrapper>
  )
}

const CheckBoxWrapper = styled.div`
  display: flex;

  .CheckBox_Title {
    width: 240px;
    margin-top: 8px;
    font-size: 15px;
    font-weight: bold;
    line-height: 1;
  }

  .CheckBox_Main {
    flex: 1;

    > label {
      display: block;
      position: relative;
      cursor: pointer;

      &:hover {
        opacity: 0.75;
      }

      input {
        opacity: 0;
        height: 0;
        width: 0;
      }

      .CheckBox_Icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 24px;
        height: 24px;

        i {
          position: absolute;
          top: 0;
          left: 0;

          &.filled {
            display: none;
            color: var(${constants.THEME_COLOR_VARIABLE_NAME});
          }

          &.outline {
            display: block;
            color: ${constants.BORDER_COLOR};
          }
        }
      }

      input:checked + .CheckBox_Icon {
        i {
          &.filled {
            display: block;
          }

          &.outline {
            display: none;
          }
        }
      }

      span {
        font-size: 14px;
        padding-left: 28px;
      }
    }

    > label + label {
      margin-top: 16px;
    }
  }
`

export default CheckBox

import { AxiosInstance } from 'axios'
import IJsonApiSerializer from 'core/interfaces/IJsonApiSerializer'

class UsageService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getUsageFromJson(data) {
    const usage = this.serializer.parseResourceData(data, data.data)

    return { usage }
  }

  public async update(formData) {
    const { data } = await this.httpClient.patch('/api/settings/usage', formData)
    const { flash } = data
    const updatedUsage = this.serializer.parseResourceData(data.usage, data.usage.data)

    return { updatedUsage, flash }
  }
}

export default UsageService

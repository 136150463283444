import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'

interface IProps {
  score: number
}

const SCORE_VALUES = [1, 2, 3, 4, 5]

const Score: React.FC<IProps> = props => {
  const Star = () => (
    <span className="Stars_Star">
      <svg viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <polygon
          fillRule="nonzero"
          points="32.5 45.9878947 48.877 56 44.531 37.13 59 24.4336842 39.9465 22.7963158 32.5 5 25.0535 22.7963158 6 24.4336842 20.469 37.13 16.123 56"
        />
      </svg>
    </span>
  )

  return (
    <ScoreWrapper className="Score">
      <div className="Score_StarsWrapper">
        <div className="Score_Stars">
          {SCORE_VALUES.map(value => (
            <Star key={value} />
          ))}
        </div>
        <div className="Score_Stars filled" style={{ width: `${(props.score / 5) * 100}%` }}>
          {SCORE_VALUES.map(value => (
            <Star key={value} />
          ))}
        </div>
      </div>
      <span className="Stars_Score">{props.score}</span>
    </ScoreWrapper>
  )
}

const ScoreWrapper = styled.div`
  display: flex;
  align-items: center;

  .Stars_Score {
    margin-left: 6px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      font-size: 12px;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 10px;
    }
  }

  .Score_StarsWrapper {
    position: relative;
    width: 90px;
    height: 18px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      width: 80px;
      height: 16px;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      width: 60px;
      height: 12px;
    }
  }

  .Score_Stars {
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    display: flex;

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }

    &.filled {
      overflow: hidden;

      svg {
        fill: #ffb800;
      }
    }
  }

  .Stars_Star {
    position: relative;
    display: block;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      width: 16px;
      height: 16px;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      width: 12px;
      height: 12px;
    }

    svg {
      width: inherit;
      height: inherit;
      fill: #ededed;
    }
  }
`

export default Score

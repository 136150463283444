import React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { IWindow } from '../../../../core/interfaces'
import { userService } from '../../../../core/services'
import * as constants from '../../../../static/constants'
import { Button, InputText } from '../../../atoms'
import { Form } from '../../../molecules'
import SocialLoginButtons from '../../../organisms/SocialLoginButtons'

declare var window: IWindow

const FIELDS = {
  username: 'username',
  email: 'email',
  password: 'password',
}

interface IState {
  confirm: boolean
  email: string
  errors: any
}

class UserRegistrationsNew extends React.Component<{}, IState> {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      confirm: false,
      email: null,
    }
  }

  public handleUpdateForm = (errors, hasNoError) => {
    this.setState({ errors })
  }

  public handleSubmit = async (initialValues, values) => {
    const { flash } = await userService.createUser({ user: values })

    this.setState({
      confirm: true,
      email: values.email,
    })
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
  }

  public render() {
    return (
      <Wrapper>
        <div className="Panel">
          <div className="Title">美容/理容室を利用される方として登録</div>
          {this.state.confirm ? (
            <div className="Panel_Confirmation">
              <p>
                <span>{this.state.email}</span>
                <br />
                {I18n.t('session.confirm_email')}
              </p>
            </div>
          ) : (
            <Form
              fields={FIELDS}
              handleSubmit={this.handleSubmit}
              handleUpdateForm={this.handleUpdateForm}
            >
              <div className="Form_Item">
                <InputText
                  required={true}
                  name="username"
                  defaultValue=""
                  label={I18n.t('generic.username')}
                  placeholder="Username"
                  error={this.state.errors.username}
                />
              </div>
              <div className="Form_Item">
                <InputText
                  required={true}
                  name="email"
                  defaultValue=""
                  label={I18n.t('generic.email')}
                  placeholder="Email"
                  error={this.state.errors.email}
                />
              </div>
              <div className="Form_Item">
                <InputText
                  required={true}
                  password={true}
                  name="password"
                  defaultValue=""
                  label={I18n.t('generic.password')}
                  placeholder={I18n.t('generic.password')}
                  error={this.state.errors.password}
                />
              </div>
              <div className="Button_Wrapper">
                <Button primary={true} backgroundColor={'#FF923E'}>
                  {I18n.t('generic.signup')}
                </Button>
                <p>
                  {I18n.t('session.have_account')}
                  <a href="/users/sign_in">{I18n.t('generic.login')}</a>
                </p>

                <p>
                  オーナーとして登録したい場合は
                  <a href="/owners/sign_up">こちら</a>
                </p>
              </div>
            </Form>
          )}
        </div>
        <SocialLoginButtons />
      </Wrapper>
    )
  }
}

const Wrapper = styled.main`
  padding-top: 36px;

  .Panel {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    max-width: 600px;
    margin: 0 auto;

    .Panel_Confirmation {
      text-align: center;
      padding: 20px;

      span {
        font-weight: bold;
      }
    }

    .Form {
      padding: 24px;

      .Form_Item {
        margin-top: 16px;
      }

      .Button_Wrapper {
        margin-top: 24px;
        text-align: center;

        > p {
          display: block;
          margin: 16px auto 0;

          a {
            color: var(${constants.THEME_COLOR_VARIABLE_NAME});
            text-decoration: underline;
          }
        }

        .Button {
          display: block;
          margin: 0 auto;
          width: 160px;
          height: 40px;
          font-weight: bold;
          font-size: 16px;
        }
      }
    }

    .Title {
      border-bottom: 1px solid lightgray;
      font-size: 24px;
      padding: 10px 20px;
      font-weight: bold;
    }
  }
`

export default UserRegistrationsNew

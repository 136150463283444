import AdminTable from 'components/atoms/AdminTable'
import * as React from 'react'
import I18n from '../../../../core/i18n'
import { ICategory, IJsonResponse, IWindow } from '../../../../core/interfaces'
import { categoryService, utilService } from '../../../../core/services/admin'
import * as Hooks from '../../../../utils/hooks'
import { Button, Pagination } from '../../../atoms'
import AdminLayoutIndex from '../_layouts/index'

declare var window: IWindow

interface IProps {
  categories: {
    categories: IJsonResponse
  }
}

const AdminCategoryIndex: React.FC<IProps> = props => {
  Hooks.SetDocumentTitle('カテゴリ管理')
  const { data: initialCategories, pagination } = utilService.getDataFromJson(
    props.categories.categories
  )
  const [categories, setCategories] = React.useState<ICategory[]>(initialCategories)
  const handleDelete = React.useCallback(
    async id => {
      const { flash } = await categoryService.delete(id)
      window.flashMessages.addMessage({ text: flash.message, type: flash.type })
      setCategories(categories.filter(category => category.id !== id))
    },
    [categories]
  )

  const onChangePageHandler = React.useCallback(page => {
    location.href = `${location.pathname}?page=${page}`
  }, [])

  return (
    <AdminLayoutIndex
      model="category"
      main={
        <AdminTable
          body={
            <>
              <h1 className="EditTable_Title">
                <span>{I18n.t('nav.admins.category')}</span>
                <Button small={true}>
                  <a href="/admin/categories/new">{I18n.t('generic.create_new')}</a>
                </Button>
              </h1>
              <div className="EditTable_Scroller">
                <div className="EditTable_Sizer">
                  <table>
                    <thead>
                      <tr>
                        <th className="w_0">{I18n.t('generic.id')}</th>
                        <th>{I18n.t('generic.name')}</th>
                        <th className="w_0">{I18n.t('generic.position')}</th>
                        <th className="w_0" />
                      </tr>
                    </thead>
                    <tbody>
                      {categories.map(category => (
                        <tr key={category.id}>
                          <td>{category.id}</td>
                          <td>{category.name}</td>
                          <td>{category.position}</td>
                          <td className="_buttons">
                            <Button key="edit" small={true}>
                              <a href={`/admin/categories/${category.id}/edit`}>
                                {I18n.t('generic.edit')}
                              </a>
                            </Button>
                            {handleDelete && (
                              <Button
                                key="delete"
                                small={true}
                                handleClick={() => handleDelete(category.id)}
                              >
                                {I18n.t('generic.delete')}
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {pagination && (
                <Pagination
                  onChangePageHandler={onChangePageHandler}
                  currentPage={pagination.current_page}
                  prevPage={pagination.prev_page}
                  nextPage={pagination.next_page}
                  totalPages={pagination.total_pages}
                  totalCount={pagination.total_count}
                />
              )}
            </>
          }
        />
      }
    />
  )
}

export default AdminCategoryIndex

import { IJsonResponse } from 'core/interfaces'
import * as React from 'react'
import I18n from '../../../../core/i18n'
import { tagService, utilService } from '../../../../core/services/admin'
import * as Hooks from '../../../../utils/hooks'
import { InputText } from '../../../atoms'
import AdminLayoutEdit from '../_layouts/edit'

interface IProps {
  tag: IJsonResponse
}

const FIELDS = {
  id: 'id',
  name: 'name',
  position: 'position',
}

const title = I18n.t('admin.edit', {
  model: I18n.t('tag', { scope: 'activerecord.models' }),
})

const AdminTagEdit: React.FC<IProps> = props => {
  Hooks.SetDocumentTitle('タグ管理')
  const { data: tag } = utilService.getDataFromJson(props.tag)
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    await tagService.update(values)
    location.href = '/admin/tags'
  }, [])

  return (
    <AdminLayoutEdit
      model="tag"
      title={title}
      fields={FIELDS}
      indexLink="/admin/tags"
      formItems={
        <>
          <InputText readonly={true} name="id" defaultValue={tag.id} label={I18n.t('generic.id')} />
          <InputText
            required={true}
            name="name"
            defaultValue={tag.name}
            label={I18n.t('generic.name')}
          />
          <InputText
            required={true}
            name="position"
            defaultValue={tag.position}
            label={I18n.t('generic.position')}
          />
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

export default AdminTagEdit

import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../../static/constants'
import { AdminNav } from '../../../organisms'

interface IProps {
  model: string
  main: any
  heading?: string | React.ReactElement
}

const AdminLayoutIndex: React.FC<IProps> = props => {
  return (
    <Wrapper>
      <AdminNav active={props.model} />
      <section className="Index_Main">
        <Panel title={props.heading}>
          {props.heading && <h1 className="Panel_Title">{props.heading}</h1>}
          {props.main}
        </Panel>
      </section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - ${constants.HEADER_HEIGHT}px);
  margin: 0 auto;
  background-color: #f6f8f9;
  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    display: block;
  }

  .Panel_Title {
    font-size: 18px;
    border-bottom: solid 1px #eaedef;
    padding: 16px 20px 8px;
  }

  .Buttons {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 48px;

    .Button[type="submit"] {
      width: 160px;
    }

    .Button[type="button"] {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;

      .material-icons {
        margin-left: -10px;
      }
    }
  }

  .Index_Main {
    flex: 1;
    padding 16px;
    overflow: auto;
  }

  .InputText + .InputText,
  .InputText + .CheckBox,
  .CheckBox + .CheckBox {
    margin-top: 24px;
  }
`

const Panel = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  max-width: 3200px;
  margin: 0 auto;
`

export default AdminLayoutIndex

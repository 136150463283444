import { once } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { IBound, ICategory, IPager, IPost, ITag, IWindow } from '../../../../core/interfaces'
import { getMergedFilterParams, getMergedFilterQueryString } from '../../../../core/queryString'
import { postService, utilService } from '../../../../core/services'
import * as constants from '../../../../static/constants'
import * as Hooks from '../../../../utils/hooks'
import injectGoogleMaps from '../../../../utils/injectGoogleMaps'
import { CustomMarker } from '../../../atoms'
import { PostList } from '../../../organisms'
import AreaSelect from '../../../organisms/AreaSelect'
import SearchHeader from './SearchHeader'

declare var google: any
declare var window: IWindow

interface IProps {
  isSignedIn: boolean
  favorite?: boolean
  google: any
}

const MAP_PADDING = 32
const MAP_DEFAULT_ZOOM = 10
const urlParams = new URLSearchParams(window.location.search)
const FILTER_FIELDS = [
  'area',
  'start_date',
  'end_date',
  'min_price',
  'max_price',
  'category_ids',
  'tag_ids',
]

const PostSearch: React.FC<IProps> = props => {
  Hooks.SetDocumentTitle(props.favorite ? 'お気に入り一覧' : '施設一覧')
  const [posts, setPosts] = React.useState<IPost[]>(null)
  const [pagination, setPagination] = React.useState<IPager>()
  const [categories, setCategories] = React.useState<ICategory[]>(null)
  const [tags, setTags] = React.useState<ITag[]>(null)

  React.useEffect(() => {
    const f = async () => {
      const params = {
        bounds: null,
        favorite: props.favorite ? true : false,
      }
      const result = await postService.search(params)
      setPosts(result.posts)
      setPagination(result.pagination)
      initializeMap(result.posts)
    }
    if (posts === null && props.google) {
      f()
    }
  }, [posts, props.google])

  React.useEffect(() => {
    const f = async () => {
      const result = await utilService.getFilterMasters()
      setCategories(result.categories)
      setTags(result.tags)
    }
    if (!props.favorite && categories === null && tags === null) {
      f()
    }
  }, [categories, tags])

  const [loading, setLoading] = React.useState(props.favorite ? false : true)
  const [hadLocation, setHadLocation] = React.useState(true)

  function getMapBounds(googleMap) {
    const { north, south, east, west } = googleMap.getBounds().toJSON()
    const bounds: IBound = {
      northEast: { lat: north, lng: east },
      southWest: { lat: south, lng: west },
    }
    return bounds
  }

  const updatePostsByMapBounds = React.useCallback(async googleMap => {
    if (!googleMap.getBounds()) {
      return
    }
    setLoading(true)
    const filterParams = getMergedFilterParams(FILTER_FIELDS)
    const bounds = getMapBounds(googleMap)
    const result = await postService.search({ bounds, ...filterParams, favorite: props.favorite })

    setPosts(result.posts)
    setPagination(result.pagination)
    setLoading(false)
  }, [])

  const [map, setMap] = React.useState(null)
  const initializeMap = (targetPosts: IPost[]) => {
    const areaParam = urlParams.get('area')
    const latParam = urlParams.get('lat')
    const lngParam = urlParams.get('lng')
    const mapOptions: any = {
      zoom: MAP_DEFAULT_ZOOM,
      mapTypeId: 'roadmap',
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      scrollwheel: false,
    }
    const updatePostsAfterBoundsChanged = updatedMap => {
      updatedMap.addListener(
        'bounds_changed',
        once(() => updatePostsByMapBounds(updatedMap))
      )
    }

    if (latParam && lngParam) {
      mapOptions.center = {
        lat: parseFloat(latParam),
        lng: parseFloat(lngParam),
      }
    }

    const initializedMap = new props.google.maps.Map(document.getElementById('map'), mapOptions)
    if (latParam && lngParam) {
      // Mapの初期化後, クエリパラメータで取得したlat, lngのboundsの範囲にあるpostsを返却する
      updatePostsAfterBoundsChanged(initializedMap)
    } else if (!props.favorite && (areaParam === '' || areaParam === null)) {
      // 検索情報がなければ現在地から
      navigator.geolocation.getCurrentPosition(
        position => {
          initializedMap.setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
          updatePostsAfterBoundsChanged(initializedMap)
        },
        err => {
          setHadLocation(false)
          // resetPosts()
        }
      )
    } else if (areaParam !== null) {
      const geocoder = new props.google.maps.Geocoder()
      geocoder.geocode({ address: areaParam }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const location = results[0].geometry.location
          initializedMap.setCenter({ lat: location.lat(), lng: location.lng() })
          updatePostsAfterBoundsChanged(initializedMap)
        } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
          // resetPosts()
          // TODO: エラーハンドリング
        }
      })
    } else if (posts && posts.length > 0) {
      // 取得したPostsが収まる範囲にfitBounds, お気に入りで使用
      const latitudes = targetPosts.map(post => post.latitude)
      const longitudes = targetPosts.map(post => post.longitude)
      const minPosition = [Math.min.apply(null, latitudes), Math.min.apply(null, longitudes)]
      const maxPosition = [Math.max.apply(null, latitudes), Math.max.apply(null, longitudes)]
      const latLngBounds = new props.google.maps.LatLngBounds(
        new props.google.maps.LatLng(minPosition[0], minPosition[1]),
        new props.google.maps.LatLng(maxPosition[0], maxPosition[1])
      )
      initializedMap.fitBounds(latLngBounds, MAP_PADDING)
    } else {
      const geocoder = new props.google.maps.Geocoder()
      geocoder.geocode({ address: '東京' }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const location = results[0].geometry.location
          initializedMap.setCenter({ lat: location.lat(), lng: location.lng() })
          updatePostsAfterBoundsChanged(initializedMap)
        } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
          // resetPosts()
          // TODO: エラーハンドリング
        }
      })
    }

    if (!props.favorite) {
      initializedMap.addListener('dragend', () => updatePostsByMapBounds(initializedMap))
      initializedMap.addListener('zoom_changed', () => updatePostsByMapBounds(initializedMap))
    }

    setMap(initializedMap)
  }

  const getPostsByPage = React.useCallback(
    async page => {
      setLoading(true)
      const bounds = getMapBounds(map)
      const result = await postService.search({
        bounds,
        page,
        favorite: props.favorite,
      })

      setPosts(result.posts)
      setPagination(result.pagination)
      setLoading(false)
    },
    [map]
  )

  const handleOnFilterSubmit = React.useCallback(
    async filterValue => {
      setLoading(true)
      if (!map) {
        return
      }
      const bounds = getMapBounds(map)
      const filterParams = getMergedFilterParams(FILTER_FIELDS, filterValue)
      const filterQueryString = getMergedFilterQueryString(FILTER_FIELDS, filterParams)
      const result = await postService.search({ bounds, ...filterParams, favorite: props.favorite })

      setPosts(result.posts)
      setPagination(result.pagination)
      setLoading(false)
      history.replaceState(null, null, `?${filterQueryString}`)
    },
    [map]
  )

  const toggleLike = (postId, like) => {
    setPosts(currentPosts =>
      currentPosts.map(postItem => ({
        ...postItem,
        user_liked: postItem.id === postId ? like : postItem.user_liked,
      }))
    )
  }

  const { handleOnMouseEnter, handleOnMouseLeave } = useMarkers(props.google, map, posts)

  if (!hadLocation && !props.favorite) {
    return <AreaSelect />
  } else {
    return (
      <PostSearchWrapper className="PostSearch">
        {!props.favorite && (
          <SearchHeader
            handleOnFilterSubmit={handleOnFilterSubmit}
            categories={categories}
            tags={tags}
          />
        )}
        <PostList
          loading={loading}
          title={count =>
            props.favorite
              ? I18n.t('post.result_favorite', { count })
              : I18n.t('post.result_search', { count })
          }
          handleOnMouseEnter={handleOnMouseEnter}
          handleOnMouseLeave={handleOnMouseLeave}
          getPostsByPage={getPostsByPage}
          isSignedIn={props.isSignedIn}
          favorite={props.favorite}
          posts={posts}
          toggleLike={toggleLike}
          pagination={pagination}
          hasMap={true}
        />
      </PostSearchWrapper>
    )
  }
}

const useMarkers = (google: any, map: any, posts: IPost[]) => {
  const [markers, setMarkers] = React.useState([])

  const getMarkerPositions = (updatedPosts: IPost[]) => {
    const markerPositions = []
    updatedPosts.map(post => {
      const latLng = new google.maps.LatLng(post.latitude, post.longitude)
      markerPositions.push(latLng)
    })

    return markerPositions
  }

  const setActiveMarker = (activeMarker, currentMarkers) => {
    if (activeMarker === -1) {
      // マップをクリック時に既に開いているマーカー詳細を閉じる
      currentMarkers.forEach(marker => {
        if (marker.div) {
          marker.closeDetail()
        }
      })
    } else {
      // マーカーをクリック時に既に開いているマーカー詳細を閉じる
      currentMarkers.forEach(marker => {
        if (marker.getMarkerId() !== activeMarker) {
          marker.closeDetail()
        }
      })
    }
  }

  const setCustomMarkers = React.useCallback(
    (googleMap, updatedPosts: IPost[]) => {
      if (!googleMap) {
        return
      }

      // Remove markers
      markers.forEach(marker => marker.setMap(null))

      const markerPositions = getMarkerPositions(updatedPosts)
      const updatedMarkers =
        updatedPosts &&
        updatedPosts.map((post, index) =>
          CustomMarker(google.maps)(markerPositions[index], googleMap, {
            post,
            setActiveMarker: markerId => setActiveMarker(markerId, updatedMarkers),
          })
        )
      setMarkers(updatedMarkers)

      googleMap.addListener('click', () => {
        updatedMarkers.forEach(marker => {
          if (marker.div) {
            marker.closeDetail()
          }
        })
      })

      return updatedMarkers
    },
    [map, posts]
  )

  const handleOnMouseEnter = React.useCallback(
    markerId => {
      markers.forEach(marker => {
        if (markerId === marker.markerId) {
          marker.handleOnMouseEnter()
        }
      })
    },
    [markers]
  )

  const handleOnMouseLeave = () => {
    markers.forEach(marker => marker.handleOnMouseLeave())
  }

  React.useEffect(() => {
    setCustomMarkers(map, posts)
  }, [map, posts])

  return { markers, handleOnMouseEnter, handleOnMouseLeave }
}

const PostSearchWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: ${constants.HEADER_HEIGHT}px;
  left: 0;
  right: 0;
  bottom: 0;

  .Wrapper_NoResult {
    font-weight: bold;
  }

  .PostList {
    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      flex: 1;
    }
  }

  .PostList_Main {
    width: 768px;
    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      width: auto;
    }
  }
`

export default injectGoogleMaps(PostSearch)

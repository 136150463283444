import { AxiosInstance } from 'axios'
import IJsonApiSerializer from 'core/interfaces/IJsonApiSerializer'

class CompanyService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getCompanyFromJson(data) {
    const company = this.serializer.parseResourceData(data, data.data)

    return { company }
  }

  public async get() {
    const { data } = await this.httpClient.get('/api/settings/company')
    const company = this.serializer.parseResourceData(data.company, data.company.data)

    return { company }
  }

  public async update(formData) {
    const { data } = await this.httpClient.patch('/api/settings/company', formData)
    const { flash } = data
    const updatedCompany = this.serializer.parseResourceData(data.company, data.company.data)

    return { updatedCompany, flash }
  }
}

export default CompanyService

import ClassNames from 'classnames'
import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../static/constants'

const REMAIN_TIME = 2000

interface IProps {
  uuid: string
  type?: string
  deleteMessage: (uuid: string) => void
}

export default class FlashMessage extends React.PureComponent<IProps> {
  constructor(props) {
    super(props)

    setTimeout(() => {
      this.props.deleteMessage(this.props.uuid)
    }, REMAIN_TIME)
  }

  public onDismiss = () => {
    this.props.deleteMessage(this.props.uuid)
  }

  public render() {
    return (
      <FlashMessageWrapper className={ClassNames({ error: this.props.type === 'error' })}>
        <svg viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle id="Oval" cx="32" cy="32" r="32" />
            <path
              d="M17.9446915,33.5880561 C17.1618871,32.6229794 15.7449494,32.4752186 14.7798727,33.2580231 C13.814796,34.0408276 13.6670352,35.4577653 14.4498397,36.422842 L23.4771835,47.5521585 C24.3155139,48.5856901 25.8628257,48.6697539 26.8081926,47.7331286 L50.7818254,23.9811755 C51.6645769,23.1065866 51.671194,21.6819808 50.7966052,20.7992293 C49.9220163,19.9164778 48.4974105,19.9098607 47.614659,20.7844495 L25.4064746,42.7872789 L17.9446915,33.5880561 Z"
              id="Path"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
          </g>
        </svg>
        {this.props.children}
        <span onMouseDown={this.onDismiss} />
      </FlashMessageWrapper>
    )
  }
}

const FlashMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #42b983;
  padding: 6px 28px 8px 8px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-weight: bold;

  &.error {
    background-color: ${constants.DANGER_COLOR};
  }

  > svg {
    width: 30px;
    height: 30px;
    margin-right: 4px;
  }

  &.fade-enter {
    opacity: 0;
    transform: translateY(100%);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.2s ease-out;
  }

  &.fade-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.fade-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: all 0.2s ease-in;
  }

  > span {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 6px;
    margin: auto;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }

    &::after,
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      content: '';
      width: 13px;
      height: 2px;
      background-color: #fff;
    }

    &::after {
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }
`

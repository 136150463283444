import { Form } from 'components/molecules'
import { AdminNav } from 'components/organisms'
import * as React from 'react'
import * as constants from 'static/constants'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { settingsService } from '../../../../core/services'
import * as Hooks from '../../../../utils/hooks'
import { Button, InputText, Panel } from '../../../atoms'
import AdminLayoutEdit from '../_layouts/edit'

interface IProps {
  settings: any
}

const FIELDS = {
  // host: 'host',
  default_site: 'default_site',
  default_title: 'default_title',
  default_description: 'default_description',
  default_keywords: 'default_keywords',
  theme_color: 'theme_color',
  ga_tracking_id: 'ga_tracking_id',
  // social_profiles: 'social_profiles',
}

const title = I18n.t('admin.edit', {
  model: I18n.t('setting', { scope: 'activerecord.models' }),
})

const AdminSettingsEdit: React.FC<IProps> = props => {
  Hooks.SetDocumentTitle(I18n.t('nav.admins.setting'))
  const settings = props.settings.reduce((obj, setting) => {
    obj[setting.var] = setting.value
    return obj
  }, {})

  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const updatedParams: any = {}
    Object.keys(FIELDS).forEach(field => {
      if (initialValues[field] !== values[field]) {
        updatedParams[field] = values[field]
      }
    })
    await settingsService.updateSetting(updatedParams)
    location.href = '/admin/setting'
  }, [])

  const handleChangeCheck = async event => {
    const updatedParams: any = {}
    updatedParams.operation = event.target.checked
    await settingsService.updateSetting(updatedParams)
    location.href = '/admin/setting'
  }

  return (
    <Wrapper>
      <AdminNav active={'setting'} />
      <section className="Index_Main">
        <Panel title={title}>
          <div className="switchWrapper">
            <p>運営中</p>
            <Switch>
              <input
                name="operation"
                type="checkbox"
                defaultChecked={settings.operation}
                onChange={event => {
                  handleChangeCheck(event)
                }}
              />
              <span className="slider round" />
            </Switch>
          </div>

          <Form fields={FIELDS} handleSubmit={handleSubmit}>
            <InputText
              name="default_site"
              defaultValue={settings.default_site}
              label={I18n.t('admin.setting.default_site')}
            />
            <InputText
              name="default_title"
              defaultValue={settings.default_title}
              label={I18n.t('admin.setting.default_title')}
            />
            <InputText
              name="default_description"
              defaultValue={settings.default_description}
              label={I18n.t('admin.setting.default_description')}
            />
            <InputText
              name="default_keywords"
              defaultValue={settings.default_keywords}
              label={I18n.t('admin.setting.default_keywords')}
            />
            <InputText
              name="theme_color"
              defaultValue={settings.theme_color}
              label={I18n.t('admin.setting.theme_color')}
            />
            <InputText
              name="ga_tracking_id"
              defaultValue={settings.ga_tracking_id}
              label={I18n.t('admin.setting.ga_tracking_id')}
            />
            <div className="Buttons">
              <Button>
                <a href={'/admin/setting'}>
                  <span className="material-icons">keyboard_arrow_left</span>
                  {I18n.t('generic.back_to_index')}
                </a>
              </Button>
              <Button primary={true}>{I18n.t('generic.update')}</Button>
            </div>
          </Form>
        </Panel>
      </section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - ${constants.HEADER_HEIGHT}px);
  margin: 0 auto;
  background-color: #f6f8f9;
  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    display: block;
  }

  .Buttons {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 48px;

    .Button[type='submit'] {
      width: 160px;
    }

    .Button[type='button'] {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;

      .material-icons {
        margin-left: -10px;
      }
    }
  }

  .Index_Main {
    flex: 1;
    padding: 16px;
    overflow: auto;
  }

  .InputText + .InputText,
  .InputText + .CheckBox,
  .CheckBox + .CheckBox {
    margin-top: 24px;
  }

  .switchWrapper {
    display: flex;
    margin-bottom: 15px;

    p {
      width: 240px;
      margin-top: 8px;
      font-size: 15px;
      font-weight: bold;
      line-height: 1;
    }
  }
`

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slider {
    background-color: var(${constants.THEME_COLOR_VARIABLE_NAME});
  }
  input:focus + .slider {
    box-shadow: 0 0 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
`

export default AdminSettingsEdit

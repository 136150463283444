import { ICategory, IPost, IPostImage, ITag, IWindow } from 'core/interfaces'
import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { postService, utilService } from '../../../../core/services'
import * as constants from '../../../../static/constants'
import * as Hooks from '../../../../utils/hooks'
import { Button } from '../../../atoms'
import { Calendar } from '../../../organisms'
import StepProgressBar from '../../../organisms/StepProgressBar'
import PostAddress from './Address'
import BasicInfo from './BasicInfo'
import PostImages from './Images'
import OptionIndex from './option/Index'
import Staff from './staff/Index'
import Twitter from './Twitter'

declare var window: IWindow

interface IProps {
  params: {
    id: string
  }
}

const Index: React.FC<IProps> = props => {
  const [post, setPost] = React.useState<IPost>(null)
  Hooks.SetDocumentTitle(post ? post.name : I18n.t('post.create_post'))
  const [postImages, setPostImages] = React.useState<IPostImage[]>([])

  const [currentPage, setCurrentPage] = React.useState('info')

  const [categories, setCategories] = React.useState<ICategory[]>(null)
  const [tags, setTags] = React.useState<ITag[]>(null)
  const [isProcessing, setIsProcessing] = React.useState(false)

  React.useEffect(() => {
    const f = async () => {
      const result = await utilService.getFilterMasters()
      setCategories(result.categories)
      setTags(result.tags)
    }
    if (categories === null && tags === null) {
      f()
    }
  }, [categories, tags])

  React.useEffect(() => {
    const f = async () => {
      const result = await postService.show(props.params.id)
      setPostImages(result.post.post_images)
      setPost(result.post)
    }
    if (post === null && props.params && props.params.id) {
      f()
    }
  }, [post])

  const changePage = changedPage => {
    setCurrentPage(changedPage)
    history.pushState(null, null, `?active=${changedPage}`)
  }

  const changePageFromParam = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const active = urlParams.get('active')

    if (active) {
      setCurrentPage(active)
    }
  }

  React.useEffect(() => {
    changePageFromParam()
    window.onpopstate = () => {
      changePageFromParam()
    }
  }, [])

  const handleFormSubmit = async (initialValues, values) => {
    const params = { ...values }

    if (post) {
      params.id = post.id
    }

    if (params.price) {
      params.price = Number(params.price.replace(/[^0-9]/g, ''))
    }
    setIsProcessing(true)
    if (post) {
      const { post: updatedPost, flash } = await postService.update(params)
      setPost(updatedPost)
      window.flashMessages.addMessage({ text: flash.message, type: flash.type })
    } else {
      const { post: createdPost, flash } = await postService.create(params)
      setPost(createdPost)
      window.flashMessages.addMessage({ text: flash.message, type: flash.type })
      setCurrentPage('address')
    }
    setIsProcessing(false)
  }

  const publishPost = async () => {
    const { post_path } = await postService.publish(post)
    location.href = post_path
  }

  const getInputStatus = page => {
    const status = { valid: false, message: '' }

    if (post) {
      switch (page) {
        case 'photo':
          ;(status.valid = postImages.length > 0),
            (status.message = postImages.length
              ? I18n.t('post.image_has_uploaded')
              : I18n.t('post.please_upload_image'))
          break
        case 'address':
          const requiredFields = ['zipcode', 'state', 'city']
          const requiredFieldsIsFilled = requiredFields.every(field => post[field] !== null)
          status.valid = requiredFieldsIsFilled
          status.message = requiredFieldsIsFilled
            ? I18n.t('post.address_has_entered')
            : I18n.t('post.please_input_address')
          break
        case 'staff':
          if (post.staffs && post.staffs.length > 0) {
            post.staffs.map(s => {
              if (s.workflow_state === 'Approved') {
                status.valid = true
              }
            })
          }
          break
      }
    }

    return status
  }

  return (
    <PostNewWrapper>
      <Nav>
        <ul className="Nav_List">
          <li
            className={`${currentPage === 'info' && 'active'}`}
            onClick={() => changePage('info')}
          >
            {I18n.t('post.basic_info')}
          </li>
          <li
            className={`${currentPage === 'address' && 'active'} ${!post && 'disabled'}`}
            onClick={() => {
              if (post) {
                changePage('address')
              }
            }}
          >
            <span>{I18n.t('generic.address')}</span>
            {!getInputStatus('address').valid && (
              <span className="note">{I18n.t('post.please_input_address')} </span>
            )}
          </li>
          <li
            className={`${currentPage === 'twitter' && 'active'} ${!post && 'disabled'}`}
            onClick={() => changePage('twitter')}
          >
            {I18n.t('post.twitter')}
          </li>
          <li
            className={`${currentPage === 'photo' && 'active'} ${!post && 'disabled'}`}
            onClick={() => {
              if (post) {
                changePage('photo')
              }
            }}
          >
            <span>{I18n.t('generic.photo')}</span>
            {!getInputStatus('photo').valid && (
              <span className="note">{I18n.t('post.please_upload_image')}</span>
            )}
          </li>
          <li
            className={`${currentPage === 'calendar' && 'active'} ${!post && 'disabled'}`}
            onClick={() => {
              if (post) {
                changePage('calendar')
              }
            }}
          >
            {I18n.t('generic.calender')}
          </li>
          <li
            className={`${currentPage === 'option' && 'active'} ${!post && 'disabled'}`}
            onClick={() => {
              if (post) {
                changePage('option')
              }
            }}
          >
            {I18n.t('post.option')}
          </li>
          <li
            className={`${currentPage === 'staff' && 'active'} ${!post && 'disabled'}`}
            onClick={() => {
              if (post) {
                changePage('staff')
              }
            }}
          >
            {I18n.t('staff.title')}
            {!getInputStatus('staff').valid && (
              <span className="note">{I18n.t('post.please_register_staff')}</span>
            )}
          </li>
          <li>
            {post && post.published ? (
              <p className="Nav_Published">
                {I18n.t('generic.published_status')} : {I18n.t('generic.published')}
              </p>
            ) : (
              <Button
                primary={true}
                disabled={
                  !getInputStatus('photo').valid ||
                  !getInputStatus('address').valid ||
                  !getInputStatus('staff').valid
                }
                handleClick={publishPost}
              >
                <span>{I18n.t('generic.publish')}</span>
              </Button>
            )}
          </li>
          <li>
            {post && (
              <Button primary={true}>
                <a href={`/posts/${post.slug}`}>{I18n.t('post.view_post')}</a>
              </Button>
            )}
          </li>
        </ul>
      </Nav>
      <Editor>
        {currentPage === 'info' && (
          <div>
            <StepProgressBar step={0} />
            <BasicInfo
              post={post}
              isNew={!props.params.id}
              handleFormSubmit={handleFormSubmit}
              categories={categories}
              tags={tags}
              setPost={setPost}
              isProcessing={isProcessing}
            />
          </div>
        )}
        {post && currentPage === 'address' && (
          <div>
            <StepProgressBar step={17} />
            <div className="Editor_Panel">
              <PostAddress
                post={post}
                handleFormSubmit={handleFormSubmit}
                isProcessing={isProcessing}
              />
            </div>
          </div>
        )}
        {post && currentPage === 'twitter' && (
          <div>
            <StepProgressBar step={34} />
            <div className="Editor_Panel">
              <h2 className="Editor_Title">
                Twitter
                <span className="Editor_explanation">
                  ※任意:アカウントを登録すると、ツイート内容が店舗ページに反映されます
                  <br />
                  ※空き時間などの現況を周知できます
                </span>
              </h2>
              <div className="Editor_Main">
                <Twitter
                  post={post}
                  handleFormSubmit={handleFormSubmit}
                  isProcessing={isProcessing}
                />
              </div>
            </div>
          </div>
        )}
        {post && currentPage === 'photo' && (
          <div>
            <StepProgressBar step={50} />
            <div className="Editor_Panel">
              <h2 className="Editor_Title">
                店舗の{I18n.t('generic.photo')}{' '}
                <span className="Editor_explanation">
                  ※スタッフの写真は、⑦(スタッフ)の項目で追加します
                </span>
              </h2>

              <div className="Editor_Main">
                <PostImages post={post} images={postImages} updateImages={setPostImages} />
              </div>
            </div>
          </div>
        )}
        {post && currentPage === 'calendar' && (
          <div>
            <StepProgressBar step={67} />
            <div className="Editor_Panel calendar">
              <p className="Editor_Panel-explanation">
                To begin with select a day or a range of date with your mouse (click and release on
                end date)
              </p>
              <Calendar post={post} />
            </div>
          </div>
        )}
        {post && currentPage === 'option' && (
          <div>
            <StepProgressBar step={84} />
            <div className="Editor_Panel">
              <OptionIndex post={post} />
            </div>
          </div>
        )}
        {post && currentPage === 'staff' && (
          <div>
            <StepProgressBar step={100} />
            <div className="Editor_Panel staff">
              <Staff post_id={post.id} />
            </div>
          </div>
        )}
      </Editor>
    </PostNewWrapper>
  )
}

const PostNewWrapper = styled.div`
  display: flex;
  margin-top: ${constants.HEADER_HEIGHT}px;
  height: calc(100vh - ${constants.HEADER_HEIGHT}px);

  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    flex-direction: column;
  }

  .PostNew_Map {
    height: 240px;
    margin-top: 24px;

    &.disabled {
      display: none;
    }
  }
`

const Nav = styled.section`
  width: 280px;
  height: 100%;
  border-bottom: solid 1px ${constants.BORDER_COLOR};
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.1);
  z-index: 1000;

  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    width: 100%;
    height: 40px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }

  .Nav_List {
    padding: 16px 0;

    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      height: inherit;
      padding: 0;
      display: -webkit-box;
    }

    li {
      @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
        display: inline-block;
        height: inherit;
      }

      display: block;
      padding: 8px 20px;
      cursor: pointer;
      font-size: 14px;
      color: ${constants.TEXT_COLOR};

      &:hover {
        color: var(${constants.THEME_COLOR_VARIABLE_NAME});
      }

      @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
        display: flex;
        align-items: center;
        height: inherit;
        padding: 0 16px;
      }

      .note {
        color: #999;
        font-size: 12px;
        font-weight: normal;
        margin-left: 8px;

        @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
          display: none;
        }
      }

      > .Button {
        width: 100%;
        text-align: center;

        @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
          height: 30px;
          padding: 0 12px;
        }
      }

      &.active {
        border-right: solid 3px var(${constants.THEME_COLOR_VARIABLE_NAME});
        font-weight: bold;

        > a {
          color: var(${constants.THEME_COLOR_VARIABLE_NAME});
        }

        @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
          border-right: none;
          border-bottom: solid 2px var(${constants.THEME_COLOR_VARIABLE_NAME});
        }
      }

      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }

      &:hover:not(.active):not(.disabled) {
        color: var(${constants.THEME_COLOR_VARIABLE_NAME});
      }
    }
  }

  .Nav_Published {
    width: 100%;
    color: var(${constants.THEME_COLOR_VARIABLE_NAME});
    font-weight: bold;
    font-size: 14px;
    text-align: justify;
    cursor: default;
  }

  .Nav_Buttons {
    padding: 8px 20px;
  }
`

const Editor = styled.section`
  flex: 1;
  padding: 0 16px;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #f6f8f9;

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    padding: 0 16px;
  }

  .Editor_Panel {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
    max-width: 880px;
    margin: 0 auto;

    &.calendar {
      max-width: 1200px;
      height: 100%;
    }

    &.staff {
      max-width: 1200px;
    }
    .Editor_Panel-explanation {
      padding: 10px;
    }
  }

  .Editor_Title {
    font-size: 18px;
    border-bottom: solid 1px #eaedef;
    padding: 16px 20px 8px;
  }

  .Editor_Main,
  .Form {
    padding: 20px;
  }

  .Editor_Footer {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  .Editor_explanation {
    font-size: 11px;
  }
`

export default Index

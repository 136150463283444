import { AxiosInstance } from 'axios'
import IJsonApiSerializer from 'core/interfaces/IJsonApiSerializer'

class AdminStaffService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public async create(post) {
    const { data } = await this.httpClient.post(`/admin/api/posts`, post)
    const createdPost = this.serializer.parseResourceData(data.post, data.post.data)

    return { createdPost, flash: data.flash }
  }

  public async update(post) {
    const { data } = await this.httpClient.patch(`/admin/api/posts/${post.id}`, { post })
    const updatedPost = this.serializer.parseResourceData(data.post, data.post.data)

    return { updatedPost, flash: data.flash }
  }

  public async delete(id) {
    return await this.httpClient.delete(`/admin/api/posts/${id}`)
  }
}

export default AdminStaffService

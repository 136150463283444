import React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'

interface IProps {
  updateFile(file: any): void
}

const FileSelectUploader: React.FC<IProps> = props => {
  return (
    <FileSelectUploaderWrapper>
      <label htmlFor="fileSelectUploader">
        画像を変更
        <input
          id="fileSelectUploader"
          type="file"
          accept="image/png, image/jpeg"
          onChange={event => {
            props.updateFile(event.currentTarget.files[0])
          }}
        />
      </label>
    </FileSelectUploaderWrapper>
  )
}

const FileSelectUploaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  outline: none;
  color: var(${constants.THEME_COLOR_VARIABLE_NAME});
  border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
  cursor: pointer;
  margin-bottom: 12px;

  input {
    display: none;
  }
`

export default FileSelectUploader

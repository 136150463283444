import * as moment from 'moment'
import 'moment/locale/ja'
import * as React from 'react'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import styled, { AnyStyledComponent } from 'styled-components'
import { UAParser } from 'ua-parser-js'
import I18n from '../../../../core/i18n'
import { IMessage, IReservation, IUser, IWindow } from '../../../../core/interfaces'
import { reservationService, utilService } from '../../../../core/services'
import * as constants from '../../../../static/constants'
import * as RailsActionCable from '../../../../utils/actionCable'
import * as forms from '../../../../utils/form'
import { Button } from '../../../atoms'
import { Form } from '../../../molecules'
import CancelModal from './CancelModal'
import ReceiptModal from './ReceiptModal'

declare var window: IWindow

moment.locale('ja', {
  months: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
})

const BREAK_POINT = 768

const FIELDS_MESSAGE = {
  file: 'file',
  body: 'body',
}

interface IProps {
  current_user: IUser
  params: {
    id: string
  }
}

const Show: React.FC<IProps> = props => {
  const messageBodyRef = React.useRef(null)
  const scrollerRef = React.useRef(null)
  const formRef = React.useRef(null)
  const [fetching, setFetching] = React.useState(true)
  const [messageAttachFile, setMessageAttachFile] = React.useState(null)
  const [messageAttachFileUrl, setMssageAttachFileUrl] = React.useState(null)
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(null)
  const [errors, setErrors] = React.useState({})
  const [reservation, setReservation] = React.useState<IReservation>(null)
  const [messages, setMessages] = React.useState<IMessage[]>()

  // Zoom起動
  const uaParser = new UAParser()
  const os = uaParser.getOS().name

  // Date
  const [startDate, setStartDate] = React.useState(null)
  const [isEditDate, setIsEditDate] = React.useState(false)
  const [focused, setFocused] = React.useState(false)
  const [isProcessing, setIsProcessing] = React.useState(false)
  const onDatePickerChange = React.useCallback(async changedDate => {
    // refReportAt.current.value = changedDate
    setStartDate(changedDate)
    const { reservation: updatedReservation, flash } = await reservationService.updateDate(
      props.params.id,
      { start_date: changedDate.format('YYYY-MM-DD') }
    )

    if (flash.type === 'success') {
      setReservation(updatedReservation)
    }
    setFocused(false)
    setIsEditDate(false)
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
  }, [])

  React.useEffect(() => {
    const f = async () => {
      const { reservation: initialReservation } = await reservationService.show(props.params.id)
      setReservation(initialReservation)
      setMessages([...initialReservation.conversation.messages].reverse())
      setStartDate(moment(initialReservation.start_date_ol))

      RailsActionCable.create({
        name: 'ConversationsChannel',
        received(data) {
          const { data: parsedMessage } = utilService.getDataFromJson(data.serialized_message)
          addMessage(parsedMessage)
          LastReadChannel.perform('update', { conversation_id: data.conversation_id })
        },
      })

      const LastReadChannel = RailsActionCable.create({ name: 'LastReadChannel' })

      LastReadChannel.perform('update', { conversation_id: initialReservation.conversation.id })

      setFetching(false)
    }
    if (!reservation) {
      f()
    }
  }, [reservation])

  const addMessage = message => {
    setMessages((currentMessages: IMessage[]) => [{ ...message, other: true }, ...currentMessages])
  }

  const handleUpdateMessage = React.useCallback(
    (updatedErrors, updatedIsSubmitEnabled) => {
      setErrors(updatedErrors)
      setIsSubmitEnabled(updatedIsSubmitEnabled)
    },
    [errors, isSubmitEnabled]
  )

  const handleSubmitMessage = async (initialValues, values) => {
    const formData = new FormData()

    Object.keys(FIELDS_MESSAGE).forEach(field => {
      if (field === 'file') {
        if (messageAttachFile) {
          formData.append(`message[${field}]`, messageAttachFile)
        }
      } else {
        formData.append(`message[${field}]`, values[field])
      }
    })

    formData.append('message[sender_id]', String(props.current_user.id))
    formData.append('message[conversation_id]', String(reservation.conversation.id))

    await reservationService.createMessage(formData)

    forms.setNativeValue(messageBodyRef.current, '', false)
    messageBodyRef.current.dispatchEvent(new Event('input', { bubbles: true }))
    setMessageAttachFile(null)
    setMssageAttachFileUrl('')
  }

  const updateMessageFile = file => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      setMessageAttachFile(file)
      setMssageAttachFileUrl(fileReader.result)
    }
    fileReader.readAsDataURL(file)
  }

  const onCancelHandler = async (id, params) => {
    const { reservation: canceledReservation, flash } = await reservationService.cancel(id, params)
    setReservation(canceledReservation)
    window.globalModal.closeModal()
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
  }

  const showCancelModal = async id => {
    let refundPrice = 0
    if (props.current_user.id !== reservation.post.user.id) {
      const { price } = await reservationService.calcRefundAmount(id)
      refundPrice = price
    }

    window.globalModal.showModal({
      title: I18n.t('reservation.cancel.reservation'),
      body: (
        <CancelModal
          price={refundPrice}
          formRef={formRef}
          reservationId={id}
          onCancelHandler={onCancelHandler}
        />
      ),
      closeText: I18n.t('generic.label_no'),
      submitText: I18n.t('generic.label_yes'),
      handleSubmit: () => formRef.current.handleFormSubmit(),
    })
  }

  const approveReservation = React.useCallback(async id => {
    setIsProcessing(true)
    const { reservation: changedReservation, flash } = await reservationService.approve(id)
    setReservation(changedReservation)
    setIsProcessing(false)
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
  }, [])

  const declineReservation = React.useCallback(async id => {
    setIsProcessing(true)
    const { reservation: changedReservation, flash } = await reservationService.decline(id)
    setReservation(changedReservation)
    setIsProcessing(false)
    window.flashMessages.addMessage({ text: flash.message, type: flash.type })
  }, [])

  const createReceiptFormRef = React.useRef(null)
  const showCreateReceiptModal = () => {
    window.globalModal.showModal({
      title: I18n.t('receipt.create_receipt'),
      body: (
        <ReceiptModal
          reservation_slug={reservation.slug}
          createReceiptFormRef={createReceiptFormRef}
          setReservation={setReservation}
        />
      ),
      closeText: I18n.t('generic.cancel'),
      submitText: I18n.t('generic.create'),
      handleSubmit: () => createReceiptFormRef.current.handleFormSubmit(),
    })
  }

  return (
    <Conversation>
      <div className="Conversation_Header">
        <a href={'/reservations'}>
          <i className="material-icons">keyboard_arrow_left</i>
          <span>{I18n.t('reservation.back_to_index')}</span>
        </a>
      </div>
      <div className="Conversation_Main">
        {reservation && (
          <>
            <Info>
              <Host>
                <h1>
                  {props.current_user.id === reservation.post.user.id
                    ? I18n.t('generic.about_guest')
                    : I18n.t('generic.about_host')}
                </h1>
                <div className="Host_Avator">
                  <div className="Host_AvatorImage">
                    {props.current_user.id === reservation.post.user.id ? (
                      <img src={reservation.user.avatar_url} alt="" />
                    ) : (
                      <img src={reservation.post.user.avatar_url} alt="" />
                    )}
                  </div>
                  <div className="Host_AvatorInfo">
                    <div className="Host_AvatorName">
                      {props.current_user.id === reservation.post.user.id
                        ? reservation.user.username
                        : reservation.post.user.username}
                    </div>
                    <p className="Host_AvatorBio">
                      {props.current_user.id === reservation.post.user.id
                        ? reservation.user.bio
                        : reservation.post.user.bio}
                    </p>
                  </div>
                </div>
              </Host>

              <Reservation>
                <h1>{I18n.t('reservation.about_reservation')}</h1>
                <dl>
                  <dt>{I18n.t('activerecord.attributes.reservation.workflow_state')}</dt>
                  <dd>{reservation.workflow_state_name}</dd>
                  <dt>{I18n.t('post.post_name')}</dt>
                  <dd>{reservation.post.name}</dd>
                  <dt>{I18n.t('generic.price')}</dt>
                  <dd>{reservation.price}</dd>
                  <dt>
                    {I18n.t('generic.check_in')}
                    {props.current_user.id === reservation.post.user.id && (
                      <></>
                      // <i
                      //   className="material-icons"
                      //   style={{ verticalAlign: 'middle' }}
                      //   onClick={() => setIsEditDate(!isEditDate)}
                      // >
                      //   edit
                      // </i>
                    )}
                  </dt>
                  <dd>
                    {isEditDate ? (
                      <SingleDatePicker
                        id="date"
                        date={startDate}
                        onDateChange={onDatePickerChange}
                        focused={focused}
                        onFocusChange={changed => {
                          setFocused(changed.focused)
                        }}
                        numberOfMonths={1}
                        hideKeyboardShortcutsPanel={true}
                        placeholder="選択してください"
                      />
                    ) : (
                      reservation.start_date
                    )}
                  </dd>
                  <dt>{I18n.t('generic.number')}</dt>
                  <dd>{reservation.number}</dd>
                  <dt>{I18n.t('post.option')}</dt>
                  <dd>
                    {reservation.reservation_options && reservation.reservation_options.length > 0
                      ? reservation.reservation_options.map((reservation_option, index) => (
                          <p key={index}>{reservation_option.option.name}</p>
                        ))
                      : I18n.t('generic.none')}
                  </dd>
                  <dt>{I18n.t('staff.nominate')}</dt>
                  <dd>
                    {reservation.reservation_staffs && reservation.reservation_staffs.length > 0
                      ? reservation.reservation_staffs.map((reservation_staff, index) => (
                          <p key={index}>{reservation_staff.staff.fullname}</p>
                        ))
                      : I18n.t('generic.none')}
                  </dd>
                  <dt>{I18n.t('reservation.side.time_table_1')}</dt>
                  <dd>{reservation.time_table_1 || I18n.t('generic.none')}</dd>
                  <dt>{I18n.t('reservation.side.time_table_2')}</dt>
                  <dd>{reservation.time_table_2 || I18n.t('generic.none')}</dd>
                  <dt>{I18n.t('reservation.side.time_table_3')}</dt>
                  <dd>{reservation.time_table_3 || I18n.t('generic.none')}</dd>
                </dl>
                {reservation.next_work_flow_state && (
                  <Buttons>
                    {reservation.next_work_flow_state &&
                      reservation.next_work_flow_state.map((state, index) => {
                        let button = null
                        switch (state) {
                          case 'approved':
                            button = (
                              <Button
                                key={`${reservation.id}-${index}`}
                                small={true}
                                handleClick={() => approveReservation(reservation.id)}
                                disabled={isProcessing}
                              >
                                {I18n.t('generic.approve')}
                              </Button>
                            )
                            break
                          case 'declined':
                            button = (
                              <Button
                                key={`${reservation.id}-${index}`}
                                small={true}
                                handleClick={() => declineReservation(reservation.id)}
                                disabled={isProcessing}
                              >
                                {I18n.t('generic.decline')}
                              </Button>
                            )
                            break
                          case 'canceled':
                            button = (
                              <Button
                                key={`${reservation.id}-${index}`}
                                small={true}
                                handleClick={() => showCancelModal(reservation.id)}
                                disabled={isProcessing}
                              >
                                {I18n.t('reservation.cancel.reservation')}
                              </Button>
                            )
                            break
                        }
                        return button
                      })}
                    {props.current_user.id !== reservation.post.user.id &&
                      reservation.receipt_accessible && (
                        <Button handleClick={!reservation.receipt && showCreateReceiptModal}>
                          {reservation.receipt ? (
                            <a href={`/reservations/${reservation.slug}/receipt`} target="_blank">
                              {I18n.t('receipt.title')}
                            </a>
                          ) : (
                            I18n.t('receipt.title')
                          )}
                        </Button>
                      )}
                  </Buttons>
                )}
              </Reservation>
            </Info>
            <div className="Conversation_Separator">
              <span>{I18n.t('generic.message')}</span>
            </div>
            <Thread>
              <div className="Thread_Form">
                <div className="Thread_FormBalloon">
                  <Form
                    fields={FIELDS_MESSAGE}
                    handleSubmit={handleSubmitMessage}
                    handleUpdateForm={handleUpdateMessage}
                  >
                    <div className="Thread_FormHeader">
                      <textarea
                        required={true}
                        placeholder={I18n.t('reservation.input_message')}
                        name="body"
                        ref={messageBodyRef}
                      />
                      {messageAttachFileUrl && (
                        <ImagePreview>
                          <img src={messageAttachFileUrl} alt="" />
                          <span
                            className="ImagePreview_Delete"
                            onClick={() => {
                              setMessageAttachFile(null)
                              setMssageAttachFileUrl('')
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                              <path d="M0 0h24v24H0z" fill="none" />
                            </svg>
                          </span>
                        </ImagePreview>
                      )}
                    </div>
                    <div className="Thread_FormFooter">
                      <div className="zoomBox">
                        {os.indexOf(['Android', 'iOS', 'Mac OS', 'Windows']) && (
                          <S.ZoomButtons>
                            <Button
                              href={
                                os === 'Android' || os === 'iOS'
                                  ? 'zoomus://zoom.us/'
                                  : 'zoommtg://zoom.us/'
                              }
                              small={true}
                            >
                              <img src="/images/zoom.png" className="zoomIcon" /> Zoom
                            </Button>
                          </S.ZoomButtons>
                        )}
                      </div>
                      <Button small={true}>
                        <label>
                          <input
                            name="file"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={event => updateMessageFile(event.currentTarget.files[0])}
                          />
                          {I18n.t('reservation.add_image')}
                        </label>
                      </Button>
                      <Button primary={true} disabled={!isSubmitEnabled} small={true}>
                        {I18n.t('generic.send')}
                      </Button>
                    </div>
                    <S.ZoomButtonExplanation>
                      <a href="https://zoom.us/support/download" className="zoomBox_Link">
                        <p>ZOOMが初めての方へ：このリンクをクリックすると</p>
                        <p>ZOOMアプリをダウンロード後、ビデオ通話ができます</p>
                      </a>
                    </S.ZoomButtonExplanation>
                  </Form>
                </div>

                <div className="Thread_FormAvator">
                  {props.current_user.id === reservation.post.user.id ? (
                    <img src={reservation.user.avatar_url} alt="" />
                  ) : (
                    <img src={reservation.post.user.avatar_url} alt="" />
                  )}
                </div>
              </div>

              <div className="Conversation_Messages">
                {fetching && (
                  <OverLay>
                    <Loader />
                  </OverLay>
                )}
                <div ref={scrollerRef}>
                  <div>
                    <div>
                      {messages &&
                        messages.map(message => (
                          <Message
                            key={message.id}
                            className={
                              message.sender_id === props.current_user.id ? 'guest' : 'host'
                            }
                          >
                            <div className="Message_Avator">
                              <img src={message.sender_avatar_url} alt={message.sender_username} />
                            </div>
                            <div className="Message_Balloon">
                              <div>
                                {message.file_attached && (
                                  <div className="Message_Image">
                                    <img src={message.file_url} alt="" />
                                  </div>
                                )}
                                <div>{message.body}</div>
                              </div>
                              <div className="Message_Time">{message.formatted_sent_time}</div>
                            </div>
                          </Message>
                        ))}
                    </div>
                  </div>
                </div>

                {/* <div>
              <Refresh onClick={updateMessages}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
              </Refresh>
            </div> */}
              </div>
            </Thread>
          </>
        )}
      </div>
    </Conversation>
  )
}

const Buttons = styled.div`
  display: flex;
  margin-top: 12px;

  .Button {
    width: 50%;
    height: 40px;
  }
`

const S: { [key: string]: AnyStyledComponent } = {}
S.ZoomButtons = styled.div`
  margin-right: 12px;

  .Button {
    float: right !important;
    width: 100px;
    display: block;
    text-align: center;
    padding: 3px;
    font-size: 18px;
    text-decoration: none;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      font-size: 14px;
    }
  }

  .zoomIcon {
    vertical-align: middle;
    width: 24px;
    height: 24px;
  }
`

S.ZoomButtonExplanation = styled.div`
  padding-top: 8px;
  max-width: 300px;
  float: right;
  text-align: right;
  justify-content: right;

  .zoomBox_Link {
    font-size: 10px;
    text-decoration: underline;
    color: var(${constants.THEME_COLOR_VARIABLE_NAME});
  }
`

const Conversation = styled.div`
  max-width: 1088px;
  margin: 0 auto;
  padding: 0 24px;
  @media (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
    padding: 16px 12px;
  }

  .Conversation_Header {
    padding: 12px 0;

    > a {
      display: flex;
      align-items: center;
      color: var(${constants.THEME_COLOR_VARIABLE_NAME});
      font-size: 14px;
    }
  }

  .Conversation_Main {
    display: flex;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      display: block;
    }
  }

  .Conversation_Messages {
    flex: 1;
  }

  .Conversation_Separator {
    position: relative;
    display: none;
    margin: 28px 0;
    border-top: solid 1px ${constants.BORDER_COLOR};
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      display: block;
    }

    > span {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: auto;
      padding: 0 16px;
      display: inline-block;
      background-color: #fff;
      color: #999;
      font-weight: bold;
    }
  }
`

const Info = styled.div``

const Thread = styled.div`
  flex: 1;
  margin-left: 16px;
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    margin-left: 0;
  }

  .Thread_Form {
    display: flex;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: solid 1px ${constants.BORDER_COLOR};

    input[type='file'] {
      display: none;
    }

    textarea {
      width: 100%;
      height: 100px;
      padding: 8px 12px
      border: none;
    }
  }

  .Thread_FormHeader {
    margin-bottom: 12px;
  }

  .Thread_FormFooter {
    display: flex;
    justify-content: flex-end;
  }

  .Thread_FormBalloon {
    flex: 1;
    padding: 16px;
    margin-right: 12px;
    border: solid 1px ${constants.BORDER_COLOR};
    border-radius: 12px;
    border-top-right-radius: 0;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      padding: 16px 8px;
      margin-right: 0;
    }
  }

  .Thread_FormAvator {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      display: none;
    }
    > img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }
`

const Reservation = styled.div`
  width: 320px;
  margin-top: 12px;
  padding: 32px 16px 24px;
  border: solid 1px ${constants.BORDER_COLOR};
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    padding: 16px 12px 12px;
    width: auto;
  }

  > h1 {
    font-size: 18px;
    margin-bottom: 24px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }

  > dl {
    font-size: 15px;

    dt {
      font-weight: bold;
    }

    dd + dt {
      margin-top: 12px;
    }
  }

  .DateInput {
    width: 100%;
  }
`

const Host = styled.div`
  width: 320px;
  padding: 32px 16px 24px;
  border: solid 1px ${constants.BORDER_COLOR};
  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    padding: 16px 12px 12px;
    width: auto;
  }

  > h1 {
    font-size: 18px;
    margin-bottom: 24px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }

  .Host_Avator {
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      display: flex;
    }
  }

  .Host_AvatorInfo {
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      flex: 1;
      margin-left: 16px;
    }
  }

  .Host_AvatorName {
    margin-top: 8px;
    text-align: center;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      text-align: left;
    }
  }

  .Host_AvatorBio {
    margin-top: 16px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      font-size: 14px;
    }
  }

  .Host_AvatorImage {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;

    > img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }
`

const Message = styled.div`
  display: flex;

  .Message_Avator {
    flex: none;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: 40px;
      height: 40px;
    }

    > img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }

  .Message_Balloon {
    padding: 8px 16px;
    border: solid 1px ${constants.BORDER_COLOR};
    border-radius: 12px;
  }

  .Message_Image {
    max-width: 360px;

    > img {
      width: 100%;
      height: auto;
    }
  }

  .Message_Time {
    font-size: 12px;
    margin-top: 4px;
  }

  &.host {
    .Message_Balloon {
      border-top-left-radius: 0;
      margin-left: 12px;
      margin-right: 60px;
    }
  }

  &.guest {
    flex-direction: row-reverse;

    .Message_Balloon {
      border-top-right-radius: 0;
      margin-right: 12px;
      margin-left: 60px;
      text-align: right;
    }
  }

  & + & {
    margin-top: 12px;
  }
`

const OverLay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
`

const Refresh = styled.div`
  display: none;
  position: absolute;
  border-radius: 50%;
  background-color: var(${constants.THEME_COLOR_VARIABLE_NAME});
  width: 56px;
  height: 56px;
  left: 12px;
  top: -66px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.1);

  @media (max-width: ${BREAK_POINT}px) {
    top: -60px;
    width: 48px;
    height: 48px;
  }

  &:hover {
    background-color: ${constants.APP_COLOR_LIGHT};
  }

  > svg {
    fill: #fff;
    width: 32px;
    height: 32px;

    @media (max-width: ${BREAK_POINT}px) {
      width: 28px;
      height: 28px;
    }
  }
`

const Loader = styled.div`
  margin: 100px auto;
  font-size: 18px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: load5 1.1s infinite ease;
  transform: translateZ(0);

  @media (max-width: ${BREAK_POINT}px) {
    font-size: 14px;
  }

  @-webkit-keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
        2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
        0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
        -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff,
        2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
        0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
        -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
        1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
        1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
        -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
        -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
        1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7),
        1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
        -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
        -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
        1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
        1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
        -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
        -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
        1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
        1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7),
        -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
        -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
        1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
        1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
        -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
        -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
        1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
        1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
        -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7),
        -1.8em -1.8em 0 0em #ffffff;
    }
  }
  @keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
        2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
        0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
        -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff,
        2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
        0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
        -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
        1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
        1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
        -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
        -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
        1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7),
        1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
        -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
        -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
        1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
        1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
        -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
        -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
        1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
        1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7),
        -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
        -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
        1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
        1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
        -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
        -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
        1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
        1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
        -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7),
        -1.8em -1.8em 0 0em #ffffff;
    }
  }
`

const ImagePreview = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.75);

  > img {
    height: inherit;
  }

  .ImagePreview_Delete {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    > svg {
      fill: #fff;
    }
  }
`

export default Show

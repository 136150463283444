import { Pagination, Spinner } from 'components/atoms'
import I18n from 'core/i18n'
import { INotification, IPager } from 'core/interfaces'
import * as React from 'react'
import styled from 'styled-components'
import { notificationService } from '../../../core/services'
import * as constants from '../../../static/constants'
import * as Hooks from '../../../utils/hooks'

const NotificationsIndex: React.FC<{}> = () => {
  Hooks.SetDocumentTitle(I18n.t('generic.notification'))
  const [notifications, setNotifications] = React.useState<INotification[]>()
  const [pagination, setPagination] = React.useState<IPager>()
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    const f = async () => {
      const result = await notificationService.get({})
      setNotifications(result.notifications)
      setPagination(result.pagination)
      setIsLoading(false)
    }
    if (notifications !== null) {
      f()
    }
  }, [])

  const onChangePageHandler = async page => {
    const result = await notificationService.get({ page })
    setNotifications(result.notifications)
    setPagination(result.pagination)
  }

  return (
    <Wrapper>
      <div className="Wrapper_Header">
        <h2>{I18n.t('generic.notification')}</h2>
      </div>
      <div className="Wrapper_Main">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {notifications && notifications.length > 0 ? (
              <>
                <table>
                  <thead>
                    <tr>
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {notifications.map((notification, index) => (
                      <tr key={index}>
                        <td>{notification.created_at}</td>
                        <td>
                          <a href={notification.url} target="_brank">
                            {notification.title}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {pagination && (
                  <Pagination
                    onChangePageHandler={onChangePageHandler}
                    currentPage={pagination.current_page}
                    prevPage={pagination.prev_page}
                    nextPage={pagination.next_page}
                    totalCount={pagination.total_count}
                    totalPages={pagination.total_pages}
                  />
                )}
              </>
            ) : (
              <div className="No_Messages">お知らせはありません。</div>
            )}
          </>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;

  .Wrapper_Header {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 24px;
    border-bottom: solid 1px ${constants.BORDER_COLOR};
  }

  .Wrapper_Main {
    overflow: scroll;

    table {
      width: 100%;
      font-size: 14px;
      border-top: solid 1px ${constants.BORDER_COLOR};

      tr {
        border-bottom: solid 1px ${constants.BORDER_COLOR};
      }

      th,
      td {
        max-width: 400px;
        padding: 8px 12px;

        &._buttons {
          width: 180px;
        }

        > a {
          color: var(${constants.THEME_COLOR_VARIABLE_NAME});
          text-decoration: underline;
        }

        > .Button {
          display: block;
          margin: 0;
        }

        > .Button + .Button {
          margin-top: 8px;
        }
      }
    }

    .No_Messages {
      text-align: center;
      padding: 20px;
    }
  }
`

export default NotificationsIndex

import { AxiosInstance } from 'axios'
import IJsonApiSerializer from 'core/interfaces/IJsonApiSerializer'

class PostOptionService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public async createTemplate(post_id) {
    const { data } = await this.httpClient.get(`/api/posts/${post_id}/options/template`)
    const { flash } = data

    return { flash }
  }
}

export default PostOptionService

import ClassNames from 'classnames'
import React from 'react'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'
import LocaleSelect from '../atoms/LocaleSelect'

interface IProps {
  active: string
  lang?: any
  is_owner?: boolean
}

const SettingsSidebar: React.FC<IProps> = props => (
  <SettingsNavWrapper className="SettingsNav">
    <ul className="Nav_List">
      <li className={ClassNames({ active: props.active === 'account' })}>
        <a href="/settings/account">{I18n.t('settings.accounts.title')}</a>
      </li>
      <li className={ClassNames({ active: props.active === 'profile' })}>
        <a href="/settings/profile">{I18n.t('settings.profiles.title')}</a>
      </li>

      {props.is_owner ? (
        <>
          <li className={ClassNames({ active: props.active === 'bank_account' })}>
            <a href="/settings/bank_account">{I18n.t('settings.bank_account.title')}</a>
          </li>
        </>
      ) : (
        <>
          <li className={ClassNames({ active: props.active === 'usage' })}>
            <a href="/settings/usage">{I18n.t('settings.usages.title')}</a>
          </li>
          <li className={ClassNames({ active: props.active === 'company' })}>
            <a href="/settings/company">{I18n.t('settings.companies.title')}</a>
          </li>
          <li className={ClassNames({ active: props.active === 'card' })}>
            <a href="/settings/card">{I18n.t('settings.cards.title')}</a>
          </li>
        </>
      )}

      <li className={ClassNames({ active: props.active === 'notification' })}>
        <a href="/settings/notification">{I18n.t('settings.notifications.title')}</a>
      </li>

      {/* <li className={ClassNames({ active: props.active === 'identification' })}>
        <a href="/settings/identification">{I18n.t('generic.identification')}</a>
      </li> */}
    </ul>
    {props.lang && <LocaleSelect />}
  </SettingsNavWrapper>
)

const SettingsNavWrapper = styled.div`
  width: 240px;
  height: 100%;
  border-bottom: solid 1px ${constants.BORDER_COLOR};
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.1);
  z-index: 1000;
  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    width: 100%;
    height: 40px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }
  .Nav_List {
    padding: 16px 0;
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      height: inherit;
      padding: 0;
    }
    li {
      padding: 8px 20px;
      cursor: pointer;
      font-size: 14px;
      @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
        display: inline-block;
        align-items: center;
        height: inherit;
        padding: 0 16px;
      }
      > a {
        display: block;
        text-decoration: none;
        color: ${constants.TEXT_COLOR};
        @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
          display: flex;
          justify-content: center;
          align-items: center;
          height: inherit;
        }
      }
      > .Button {
        width: 100%;
        text-align: center;
        @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
          height: 30px;
          padding: 0 12px;
        }
        a {
          justify-content: center;
        }
      }
      &.active {
        border-right: solid 3px var(${constants.THEME_COLOR_VARIABLE_NAME});
        color: var(${constants.THEME_COLOR_VARIABLE_NAME});
        font-weight: bold;
        > a {
          color: inherit;
          font-weight: inherit;
        }
        @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
          border-right: none;
          border-bottom: solid 2px var(${constants.THEME_COLOR_VARIABLE_NAME});
        }
      }
      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
      &:hover:not(.active):not(.disabled) {
        color: var(${constants.THEME_COLOR_VARIABLE_NAME});
      }
    }
  }
  .Nav_Buttons {
    padding: 8px 20px;
  }
`

export default SettingsSidebar

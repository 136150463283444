import ClassNames from 'classnames'
import { range } from 'lodash'
import * as moment from 'moment'
import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import * as constants from '../../../static/constants'

interface IDay {
  date: moment.Moment
  blocked: boolean
  reserved: boolean
  price: number
  selected?: boolean
  start?: boolean
  end?: boolean
}

function separate(stringNum: string): string {
  const len = stringNum.length

  if (len > 3) {
    return separate(stringNum.substring(0, len - 3)) + ',' + stringNum.substring(len - 3)
  } else {
    return stringNum
  }
}

function formatPrice(price: number): string {
  return `¥${separate(String(price))}`
}

function getDayClassNames(day: IDay) {
  return ClassNames({
    blocked: day.blocked,
    reserved: day.reserved,
    selected: day.selected,
    start: day.start,
    end: day.end,
  })
}

interface IProps {
  data: IDay[]
  clearSelectedDate: () => void
  dragstart: (day: IDay, index: number) => void
  dragend: () => void
  onDrag: (day: IDay) => void
  fetching: boolean
}

const CalendarGrid: React.FC<IProps> = props => {
  const { data, clearSelectedDate, dragstart, dragend, onDrag } = props
  const today = moment()
  const firstDayOfTheMonth = data[0].date
  const offsetCount = firstDayOfTheMonth.isoWeekday() % 7
  const cellCount = offsetCount + data.length
  const rowCount = Math.ceil(cellCount / 7)
  const cellData = [...range(offsetCount).map(() => null), ...data]

  return (
    <Grid>
      <Week>
        {constants.DAYS_OF_THE_WEEK.map((day: string, index: number) => (
          <div key={`week-${index}`}>{day}</div>
        ))}
      </Week>
      <Days>
        {range(rowCount).map((rowIndex: number) => (
          <GridRow key={`row-${rowIndex}`}>
            {range(7).map((dayIndex: number) => {
              const cellIndex = 7 * rowIndex + dayIndex
              const cell: null | IDay = cellData[cellIndex]

              return cell === null || cellIndex >= cellCount ? (
                <Empty key={`cell-${cellIndex}`} onClick={clearSelectedDate} />
              ) : (
                <Day
                  key={`cell-${cellIndex}`}
                  className={getDayClassNames(cell)}
                  onMouseDown={() => dragstart(cell, cell.date.date() - 1)}
                  onMouseUp={() => dragend()}
                  onMouseEnter={() => onDrag(cell)}
                >
                  <h2 className={ClassNames({ today: cell.date.isSame(today, 'day') })}>
                    {`${cell.date.date()}${
                      cell.date.isSame(today, 'day') ? I18n.t('generic.today') : ''
                    }`}
                  </h2>
                  {cell.blocked ? (
                    <svg viewBox="0 0 150 150" preserveAspectRatio="none">
                      <line x1="150" y1="0" x2="0" y2="150" stroke="#999" strokeWidth="1px" />
                    </svg>
                  ) : (
                    <p className="Price">{formatPrice(cell.price)}</p>
                  )}
                </Day>
              )
            })}
          </GridRow>
        ))}
      </Days>
      {props.fetching && <OverLay />}
    </Grid>
  )
}

const Grid = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const GridRow = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 70px;
`

const Week = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: solid 1px ${constants.BORDER_COLOR};

  > div {
    display: flex;
    justify-content: center;
    width: 14.28%;
    height: 20px;
    font-size: 13px;
  }
`

const Days = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: -1px;
`

const Empty = styled.span`
  display: flex;
  width: 14.28%;
  padding: 8px;
  border-bottom: solid 1px ${constants.BORDER_COLOR};

  &:last-of-type {
    border-right: solid 1px ${constants.BORDER_COLOR};
  }
`

const Day = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 14.28%;
  padding: 8px;
  border-right: solid 1px ${constants.BORDER_COLOR};
  border-bottom: solid 1px ${constants.BORDER_COLOR};
  cursor: pointer;
  transition: opacity 0.1s ease;

  &.blocked {
    background-color: #f3f3f3;
  }

  &.reserved {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(${constants.THEME_COLOR_VARIABLE_NAME});
      opacity: 0.2;
    }
  }

  &.selected {
    padding: 6px 8px 7px 8px;
    border-top: solid 2px var(${constants.THEME_COLOR_VARIABLE_NAME});
    border-bottom: solid 2px var(${constants.THEME_COLOR_VARIABLE_NAME});

    &.start {
      padding: 6px 8px 7px 6px;
      border-left: solid 2px var(${constants.THEME_COLOR_VARIABLE_NAME});
    }

    &.end {
      padding: 6px 7px 7px 8px;
      border-right: solid 2px var(${constants.THEME_COLOR_VARIABLE_NAME});
    }

    &.start.end {
      padding: 6px 7px 7px 6px;
    }
  }

  .hasSelectedDate & {
    &:not(.selected) {
      opacity: 0.5;
    }
  }

  > h2 {
    margin: 0;
    font-weight: normal;
    font-size: 14px;

    &.today {
      font-weight: bold;
      color: var(${constants.THEME_COLOR_VARIABLE_NAME});
    }
  }

  > p {
    margin: 0;
    text-align: right;
    font-size: 14px;
  }

  > svg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .Price {
    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      display: none;
    }
  }
`

const OverLay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 9999;
`

export default CalendarGrid

import ClassNames from 'classnames'
import { clamp } from 'lodash'
import * as React from 'react'
import ClickOutside from 'react-click-outside'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import * as constants from '../../../static/constants'
import isTouchDevice from '../../../utils/isTouchDevice'
import Slider from '../Slider'

interface IProps {
  name: string
  field: string
  setIsFilterPanelOpen?(isOpen: boolean): void
  handleOnSubmit(filterValue: any): void
}

interface IPrice {
  min: string
  max: string
}

const initialParams = new URLSearchParams(window.location.search)
const MAX_PRICE = 100000

const PriceFilter: React.FC<IProps> = props => {
  const getInitialValue = () => ({
    min: initialParams.has('min_price') ? initialParams.get('min_price') : '',
    max: initialParams.has('max_price') ? initialParams.get('max_price') : '',
  })
  const getLabelText = updatedPrice => {
    if (!updatedPrice.min && !updatedPrice.max) {
      return props.name
    } else if (updatedPrice.min && !updatedPrice.max) {
      return `¥${updatedPrice.min} 〜`
    } else if (!updatedPrice.min && updatedPrice.max) {
      return `〜 ¥${updatedPrice.max}`
    } else if (updatedPrice.min && updatedPrice.max) {
      return `¥${updatedPrice.min} 〜 ¥${updatedPrice.max}`
    }
  }
  const [isOpen, setIsOpen] = React.useState(false)
  const [price, setPrice] = React.useState<any>(getInitialValue())
  const [labelName, setLabelName] = React.useState(getLabelText(getInitialValue()))
  const [clear, setClear] = React.useState(false)
  const setPanelIsOpen = React.useCallback(
    (open: boolean) => {
      if (open === isOpen) {
        return
      }
      if (typeof props.setIsFilterPanelOpen === 'function') {
        props.setIsFilterPanelOpen(open)
      }
      setIsOpen(open)
    },
    [isOpen]
  )

  const setFormatLabelText = React.useCallback(() => {
    setLabelName(getLabelText(price))
  }, [price])

  const hasValue = React.useCallback(() => {
    return price.min || price.max
  }, [price])

  const onClear = () => {
    setPrice({ min: '', max: '' })
    setFormatLabelText()
    setClear(true)
  }

  const onApply = React.useCallback(() => {
    const filterValue: any = {
      min_price: price.min ? price.min : null,
      max_price: price.max ? price.max : null,
    }
    props.handleOnSubmit(filterValue)
    // TODO API CALL
    setPanelIsOpen(false)
    setFormatLabelText()
  }, [price, setPanelIsOpen, props.handleOnSubmit])

  const onChangeHandler = React.useCallback(
    event => {
      const changedPrice = {
        ...price,
        [event.target.name]: event.target.value,
      }
      setPrice(changedPrice)
    },
    [price]
  )

  const onBlurHandler = React.useCallback(
    event => {
      const clampedValue =
        event.target.name === 'min'
          ? clamp(
              Number(event.target.value),
              0,
              Number(price.max) || Number(event.target.value) + 1
            )
          : clamp(Number(event.target.value), Number(price.min), MAX_PRICE)
      const changedPrice = {
        ...price,
        [event.target.name]: clampedValue,
      }
      setPrice(changedPrice)
    },
    [price]
  )

  const [dragging, setDragging] = React.useState(false)

  React.useEffect(() => {
    if (clear) {
      setFormatLabelText()
      setClear(false)
    }
  }, [clear])

  const onSliderValueChange = changedValue => {
    setPrice({
      min: Math.floor(MAX_PRICE * changedValue.min),
      max: Math.floor(MAX_PRICE * changedValue.max),
    })
  }

  const getSliderValueByPrice = currentPrice => {
    return {
      min: currentPrice.min ? clamp(Number(currentPrice.min) / MAX_PRICE, 0, 1) : 0,
      max: currentPrice.max ? clamp(Number(currentPrice.max) / MAX_PRICE, 0, 1) : 1,
    }
  }

  return (
    <ClickOutside
      onClickOutside={() => {
        if (!dragging) {
          setPanelIsOpen(false)
        }
      }}
    >
      <PriceFilterWrapper className="PriceFilter">
        <FilterLabel
          className={ClassNames('Filter_Label', { filled: isOpen || hasValue() })}
          onClick={() => setPanelIsOpen(!isOpen)}
        >
          {labelName}
        </FilterLabel>
        {isOpen && (
          <FilterWindow className="FilterWindow">
            {!isTouchDevice() && (
              <Slider
                setDragging={setDragging}
                value={getSliderValueByPrice(price)}
                onChangeHandler={onSliderValueChange}
              />
            )}

            <form action="">
              <div className="Price_Inputs">
                <div className="Price_Input">
                  <span className="Price_InputLabel">{I18n.t('post.price_min')}</span>
                  <span className="Price_InputCurrency">¥</span>
                  <input
                    name="min"
                    value={price.min || ''}
                    type="text"
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                  />
                </div>
                <span>-</span>
                <div className="Price_Input">
                  <span className="Price_InputLabel">{I18n.t('post.price_max')}</span>
                  <span className="Price_InputCurrency">¥</span>
                  <input
                    name="max"
                    value={price.max || ''}
                    type="text"
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                  />
                </div>
              </div>
            </form>
            <FilterFooter>
              <div className={ClassNames('_clear', { disabled: !hasValue() })} onClick={onClear}>
                {I18n.t('generic.clear')}
              </div>
              <div className="_apply" onClick={onApply}>
                {I18n.t('generic.apply')}
              </div>
            </FilterFooter>
          </FilterWindow>
        )}
      </PriceFilterWrapper>
    </ClickOutside>
  )
}

const FilterLabel = styled.div`
  border: solid 1px ${constants.BORDER_COLOR};
  height: 36px;
  display: flex;
  align-items: center;
  margin: 0 6px;
  padding: 0 12px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;

  &.filled {
    background-color: var(${constants.THEME_COLOR_VARIABLE_NAME});
    border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
    color: #fff;
  }

  &:hover:not(.filled) {
    border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
    color: var(${constants.THEME_COLOR_VARIABLE_NAME});
  }
`

const FilterFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 12px 0;

  ._clear,
  ._apply {
    font-weight: bold;
    cursor: pointer;
  }

  ._clear {
    color: #aaa;

    &.disabled {
      visibility: hidden;
    }
  }

  ._apply {
    color: var(${constants.THEME_COLOR_VARIABLE_NAME});
  }
`

const PriceFilterWrapper = styled.div`
  position: relative;

  .Price_Inputs {
    position: relative;
    display: flex;
    align-items: center;

    input {
      display: block;
      width: 160px;
      padding: 24px 12px 8px 28px;
      border: solid 1px ${constants.BORDER_COLOR};
      border-radius: 4px;
      font-size: 15px;
      transition: border 0.2s ease;
      outline: none;
      background: none;
      @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
        width: 100%;
      }
    }
  }

  .Price_Input {
    position: relative;
    margin: 0 8px;
    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      width: 50%;
    }
  }

  .Price_InputLabel {
    position: absolute;
    left: 10px;
    top: 8px;
    font-size: 12px;
    line-height: 1;
  }

  .Price_InputCurrency {
    position: absolute;
    top: 25px;
    left: 12px;
    line-height: 1;
    font-size: 16px;
    height: 16px;
    line-height: 1;
  }
`

const FilterWindow = styled.div`
  position: absolute;
  left: 0;
  bottom: -8px;
  transform: translateY(100%);
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  padding: 16px;
  border-radius: 4px;
  z-index: 1100;
  @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
    position: fixed;
    top: ${constants.HEADER_HEIGHT}px;
    left: 0;
    right: 0;
    height: calc(100vh - ${constants.HEADER_HEIGHT}px);
    transform: none;
    width: auto;
  }
`

export default PriceFilter

import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import { IContact, IUser } from '../../../core/interfaces'
import { IWindow } from '../../../core/interfaces'
import { contactService } from '../../../core/services'
import * as constants from '../../../static/constants'
import { Button, InputText, InputTextArea, Panel } from '../../atoms'
import { Form } from '../../molecules'

declare var window: IWindow

interface IProps {
  user: IUser
}

const FIELDS = {
  name: 'name',
  email: 'email',
  subject: 'subject',
  body: 'body',
}

const useContact = (userId: number) => {
  const [contact, setContact] = React.useState<IContact>(null)
  const [completed, setCompleted] = React.useState(false)

  const handleSubmit = React.useCallback(
    async (initialValues, submitValues) => {
      if (!contact) {
        setContact(submitValues)
      } else {
        const params = {
          ...submitValues,
          user_id: userId,
        }
        const { flash } = await contactService.create(params)
        setCompleted(true)
        window.flashMessages.addMessage({ text: flash.message, type: flash.type })
      }
    },
    [contact]
  )
  const handleOnPrev = () => setContact(null)

  return { contact, handleOnPrev, handleSubmit, completed }
}

const Contacts: React.FC<IProps> = props => {
  const { contact, handleOnPrev, handleSubmit, completed } = useContact(props.user.id)

  return (
    <ContactsWrapper>
      {completed ? (
        <Panel>
          <p className="Contacts_Completed">{I18n.t('contact.completed')}</p>
        </Panel>
      ) : (
        <Panel title={I18n.t('contact.title')}>
          <Form fields={FIELDS} handleSubmit={handleSubmit}>
            <div style={{ display: (contact ? false : true) ? 'block' : 'none' }}>
              <InputText
                name="name"
                label={I18n.t('generic.name')}
                defaultValue=""
                required={true}
              />
              <InputText
                name="email"
                label={I18n.t('generic.email')}
                defaultValue=""
                required={true}
              />
              <InputText
                name="subject"
                label={I18n.t('contact.subject')}
                defaultValue=""
                required={true}
              />
              <InputTextArea
                name="body"
                label={I18n.t('contact.body')}
                defaultValue=""
                required={true}
              />
              <div className="Contacts_Buttons">
                <Button primary={true}>{I18n.t('generic.next')}</Button>
              </div>
            </div>

            <div style={{ display: (contact ? true : false) ? 'block' : 'none' }}>
              <p className="Contacts_Confirmation">{I18n.t('contact.confirmation')}</p>
              {contact && (
                <dl>
                  <dt>{I18n.t('generic.name')}</dt>
                  <dd>{contact.name}</dd>
                  <dt>{I18n.t('generic.email')}</dt>
                  <dd>{contact.email}</dd>
                  <dt>{I18n.t('contact.subject')}</dt>
                  <dd>{contact.subject}</dd>
                  <dt>{I18n.t('contact.body')}</dt>
                  <dd>{contact.body}</dd>
                </dl>
              )}
              <div className="Contacts_Buttons">
                <Button handleClick={handleOnPrev}>{I18n.t('generic.back')}</Button>
                <Button primary={true}>{I18n.t('generic.submit')}</Button>
              </div>
            </div>
          </Form>
        </Panel>
      )}
    </ContactsWrapper>
  )
}

const ContactsWrapper = styled.div`
  background-color: #f6f8f9;
  height: calc(100vh - ${constants.HEADER_HEIGHT}px);
  padding: 24px 12px;
  overflow: scroll;

  .InputText + .InputText {
    margin-top: 24px;
  }

  .InputText + .InputTextArea {
    margin-top: 24px;
  }

  .Contacts_Buttons {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 48px 0 0;
  }

  .Button[type='submit'] {
    display: block;
    width: 160px;
  }

  .Button[type='button'] {
    position: absolute;
    left: 0;
    top: 0;
  }

  .Contacts_Completed,
  .Contacts_Confirmation {
    text-align: center;
  }

  dl {
    display: flex;
    flex-wrap: wrap;
  }

  dt {
    width: 240px;
    margin-top: 24px;
    font-weight: bold;
  }

  dd {
    width: calc(100% - 240px);
    margin-top: 24px;
  }
`

export default Contacts

import * as React from 'react'
import styled from 'styled-components'

interface IPanelProps {
  title?: string
  body?: any
  className?: string
}

const Panel: React.FC<IPanelProps> = props => {
  return (
    <PanelWrapper className={props.className ? props.className + ' Panel' : 'Panel'}>
      {props.title && <h1 className="Panel_Title">{props.title}</h1>}
      <div className="Panel_Body">{props.children}</div>
    </PanelWrapper>
  )
}

const PanelWrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  max-width: 880px;
  margin: 0 auto;

  .Panel_Title {
    font-size: 18px;
    border-bottom: solid 1px #eaedef;
    padding: 16px 20px 8px;
  }

  .Panel_Body {
    padding: 20px;
  }
`

export default Panel

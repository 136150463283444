import * as React from 'react'
import { CardElement, injectStripe } from 'react-stripe-elements'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import { IPost, IUser } from '../../core/interfaces'
import { reservationService, settingsService, userService } from '../../core/services'
import * as constants from '../../static/constants'
import { Button, InputTextArea } from '../atoms'
import { Form } from '../molecules'

interface IProps {
  post: IPost
  stripe: any
  reservation: any
  setCurrentPage(page: string): void
  setCreatedReservation(reservation: any): void
}

const createOptions = () => {
  return {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: '16px',
        color: constants.TEXT_COLOR,
        letterSpacing: '0.5px',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: constants.DANGER_COLOR,
      },
    },
  }
}
interface IErrors {
  [key: string]: string | null
}

const FIELDS = {
  initial_message: 'initial_message',
}

const StripeCardForm: React.FC<IProps> = props => {
  const [user, setUser] = React.useState<IUser>(null)
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [errors, setErrors] = React.useState<IErrors>({})
  const [card, setCard] = React.useState(null)
  const [useNewCard, setUseNewCard] = React.useState(false)
  const [useRegisteredCard, setUseRegisteredCard] = React.useState(true)
  const [useBankTransfer, setUseBankTransfer] = React.useState(false)

  const getCard = async () => {
    const { card: currentCard } = await settingsService.getCard()
    if (currentCard === null) {
      setUseRegisteredCard(false)
      setUseNewCard(true)
    }
    setCard(currentCard)
  }

  React.useEffect(() => {
    getCard()
    getUser()
  }, [])

  const getUser = async () => {
    const { user: userData } = await userService.show()
    setUser(userData)
  }

  const handleUpdateForm = (updatedErrors, isSubmitEnabled) => {
    setErrors(updatedErrors)
  }
  const handleSubmit = async (initialValues, values) => {
    setIsProcessing(true)

    const params = {
      reservation: {
        ...props.reservation,
        post_id: props.post.id,
        initial_message: values.initial_message,
      },
    }

    let createReservationResponse
    if (useBankTransfer) {
      createReservationResponse = await reservationService.create({
        ...params,
        isBankTransfer: true,
      })
    } else if (useNewCard) {
      const createTokenResponse = await props.stripe.createToken()

      if (!createTokenResponse.token) {
        setIsProcessing(false)
        return
      }

      createReservationResponse = await reservationService.create({
        ...params,
        stripeToken: createTokenResponse.token.id,
      })
    } else if (useRegisteredCard) {
      createReservationResponse = await reservationService.create({
        ...params,
      })
    }

    if (createReservationResponse) {
      props.setCreatedReservation(createReservationResponse.reservation)
      props.setCurrentPage('complete')
    }
  }

  return (
    <CardForm>
      <Form fields={FIELDS} handleSubmit={handleSubmit} handleUpdateForm={handleUpdateForm}>
        <p className="CardForm_Label">{I18n.t('reservation.first_message_to_host')}</p>
        <InputTextArea
          required={true}
          name="initial_message"
          defaultValue=""
          error={errors.initial_message}
        />
        <p className="CardForm_Label">{I18n.t('generic.payment')}</p>
        <Radio>
          {user && user.has_company_info && (
            <label>
              <input
                type="radio"
                name="useBankTransfer"
                value=""
                checked={useBankTransfer}
                onChange={() => {
                  setUseNewCard(false)
                  setUseRegisteredCard(false)
                  setUseBankTransfer(true)
                }}
              />
              <span>{I18n.t('card.bank_transfer')}</span>
            </label>
          )}
          {card && (
            <label>
              <input
                type="radio"
                name="useRegisteredCard"
                value=""
                checked={useRegisteredCard}
                onChange={() => {
                  setUseNewCard(false)
                  setUseRegisteredCard(true)
                  setUseBankTransfer(false)
                }}
              />
              <span className="CardForm_RegisteredCard">
                <span>{I18n.t('card.registered_cards')} -</span>
                <span>{card.brand}</span>
                <span>{I18n.t('card.card_end')}: </span>
                <span>{card.last4}</span>
                <span className="exp">{I18n.t('generic.expiration_date')}: </span>
                <span>
                  {card.exp_month}/{card.exp_year}
                </span>
              </span>
            </label>
          )}
          <label>
            <input
              type="radio"
              name="useNewCard"
              value=""
              checked={useNewCard}
              onChange={() => {
                setUseNewCard(true)
                setUseRegisteredCard(false)
                setUseBankTransfer(false)
              }}
            />
            <span>{I18n.t('card.new_card')}</span>
          </label>
        </Radio>
        {useNewCard && <CardElement {...createOptions()} />}
        <Button disabled={isProcessing} primary={true}>
          {I18n.t('generic.pay')}
        </Button>
      </Form>
    </CardForm>
  )
}

const CardForm = styled.div`
  .CardForm_RegisteredCard {
    span + span {
      margin-left: 8px;
    }

    span.exp {
      margin-left: 24px;
    }

    & + .Button {
      margin-top: 16px;
    }
  }

  .CardForm_Label {
    margin-top: 12px;
    font-weight: bold;
  }

  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-radius: 4px;
    background: white;
  }

  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    transition: all 150ms ease;
  }

  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
`

const Radio = styled.div`
  margin: 24px 0 24px;

  > h3 {
    display: block;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 14px;
  }

  > label {
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: 32px;

    &:hover {
      opacity: 0.75;
    }

    input {
      opacity: 0;
      height: 0;
      width: 0;
    }

    input + span {
      font-size: 14px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }

      &::before {
        width: 18px;
        height: 18px;
        border: solid 1px ${constants.BORDER_COLOR};
        border-radius: 50%;
      }
    }

    input:checked + span {
      &::before {
        width: 18px;
        height: 18px;
        border: solid 1px var(${constants.THEME_COLOR_VARIABLE_NAME});
        border-radius: 50%;
      }

      &::after {
        left: 4px;
        width: 10px;
        height: 10px;
        background-color: var(${constants.THEME_COLOR_VARIABLE_NAME});
        border-radius: 50%;
      }
    }
  }

  > label + label {
    margin-top: 16px;
  }
`

export default injectStripe(StripeCardForm)

import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { userService } from '../../../../core/services/admin'
import { CheckBox, InputText, Select } from '../../../atoms'
import AdminLayoutNew from '../_layouts/new'

const FIELDS = {
  username: 'username',
  bio: 'bio',
  email: 'email',
  phone: 'phone',
  password: 'password',
  email_notification: 'email_notification',
  admin: 'admin',
  is_owner: 'is_owner',
  financial_institution_name: 'financial_institution_name',
  financial_branch_name: 'financial_branch_name',
  financial_account_number: 'financial_account_number',
  financial_account_type: 'financial_account_type',
  financial_account_holder_name: 'financial_account_holder_name',
}

const title = I18n.t('admin.create_new', {
  model: I18n.t('user', { scope: 'activerecord.models' }),
})

const AdminUserIndex: React.FC<{}> = props => {
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    await userService.create(values)
    location.href = '/admin/users'
  }, [])

  const [role, setRole] = React.useState<boolean>(false)

  return (
    <AdminLayoutNew
      model="user"
      title={title}
      fields={FIELDS}
      indexLink="/admin/users"
      formItems={
        <>
          <InputText required={true} name="username" defaultValue="" label="ユーザー名" />
          <InputText required={true} name="bio" defaultValue="" label="紹介文" />
          <InputText required={true} name="email" defaultValue="" label="メールアドレス" />
          <InputText required={true} name="password" defaultValue="" label="パスワード" />
          <CheckBox name="email_notification" defaultChecked={false} label="メール通知" />

          <CheckBox name="admin" defaultChecked={false} label="管理者権限" />
          <CheckBox
            name="is_owner"
            defaultChecked={false}
            label="オーナー権限"
            onChangeHandler={e => setRole(!role)}
          />
          <InputText required={false} name="phone" defaultValue="" label="電話番号" />
          <S.Bank isVisible={role}>
            <InputText
              required={false}
              name="financial_institution_name"
              label={I18n.t('settings.bank_account.name')}
              defaultValue=""
              placeholder={I18n.t('settings.bank_account.placeholder.name')}
            />

            <InputText
              required={false}
              name="financial_branch_name"
              label={I18n.t('settings.bank_account.branch_name')}
              defaultValue=""
              placeholder={I18n.t('settings.bank_account.placeholder.branch_name')}
            />

            <InputText
              required={false}
              name="financial_account_number"
              label={I18n.t('settings.bank_account.number')}
              defaultValue=""
              placeholder={I18n.t('settings.bank_account.placeholder.number')}
            />
            <BankType>
              <Select
                required={false}
                name="financial_account_type"
                label={I18n.t('settings.bank_account.type')}
                options={[
                  { value: '普通', label: '普通' },
                  { value: '当座', label: '当座' },
                ]}
                defaultValue=""
              />
            </BankType>
            <InputText
              required={false}
              name="financial_account_holder_name"
              label={I18n.t('settings.bank_account.account_name')}
              defaultValue=""
              placeholder={I18n.t('settings.bank_account.placeholder.account_name')}
            />
          </S.Bank>
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.Bank = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`

const CheckBoxes = styled.div`
  margin: 20px 0;
`

const BankType = styled.div`
  margin: 20px 0;
`

export default AdminUserIndex

import ClassNames from 'classnames'
import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'

interface IProps {
  active: string
}

const options = { scope: 'activerecord.models' }
const Nav: React.FC<IProps> = props => {
  return (
    <NavWrapper>
      <ul className="Nav_List">
        <li className={ClassNames({ active: props.active === 'post' })}>
          <a href="/admin/posts">{I18n.t('nav.admins.post')}</a>
        </li>
        <li className={ClassNames({ active: props.active === 'category' })}>
          <a href="/admin/categories">{I18n.t('nav.admins.category')}</a>
        </li>
        <li className={ClassNames({ active: props.active === 'tag' })}>
          <a href="/admin/tags">{I18n.t('nav.admins.tag')}</a>
        </li>
        <li className={ClassNames({ active: props.active === 'user' })}>
          <a href="/admin/users">{I18n.t('nav.admins.user')}</a>
        </li>
        <li className={ClassNames({ active: props.active === 'reservation' })}>
          <a href="/admin/reservations">{I18n.t('nav.admins.reservation')}</a>
        </li>
        <li className={ClassNames({ active: props.active === 'contact' })}>
          <a href="/admin/contacts">{I18n.t('nav.admins.contact')}</a>
        </li>
        {/* <li className={ClassNames({ active: props.active === 'identifications' })}>
          <a href="/admin/identifications">{I18n.t('nav.admins.identification')}</a>
        </li> */}
        <li className={ClassNames({ active: props.active === 'qualifications' })}>
          <a href="/admin/qualifications">{I18n.t('nav.admins.qualification')}</a>
        </li>
        <li className={ClassNames({ active: props.active === 'company' })}>
          <a href="/admin/company">{I18n.t('nav.admins.company')}</a>
        </li>
        <li className={ClassNames({ active: props.active === 'setting' })}>
          <a href="/admin/setting">{I18n.t('nav.admins.setting')}</a>
        </li>
        <li className={ClassNames({ active: props.active === 'download' })}>
          <a href="/admin/download">{I18n.t('nav.admins.download')}</a>
        </li>
      </ul>
    </NavWrapper>
  )
}

const NavWrapper = styled.section`
  width: 240px;
  height: 100%;
  border-bottom: solid 1px ${constants.BORDER_COLOR};
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.1);
  background-color: #fff;
  z-index: 1000;

  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    width: 100%;
    height: 40px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }

  .Nav_List {
    padding: 16px 0;

    @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      height: inherit;
      padding: 0;
    }

    li {
      @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
        display: inline-block;
        height: inherit;
      }

      > a {
        display: block;
        padding: 8px 20px;
        cursor: pointer;
        font-size: 14px;
        color: ${constants.TEXT_COLOR};

        &:hover {
          color: var(${constants.THEME_COLOR_VARIABLE_NAME});
        }

        @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
          display: flex;
          align-items: center;
          height: inherit;
          padding: 0 16px;
        }
      }

      &.active {
        border-right: solid 3px var(${constants.THEME_COLOR_VARIABLE_NAME});
        font-weight: bold;

        > a {
          color: var(${constants.THEME_COLOR_VARIABLE_NAME});
        }

        @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
          border-right: none;
          border-bottom: solid 2px var(${constants.THEME_COLOR_VARIABLE_NAME});
        }
      }

      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }

      &:hover:not(.active):not(.disabled) {
        color: var(${constants.THEME_COLOR_VARIABLE_NAME});
      }
    }
  }
`

export default Nav

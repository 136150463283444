import ClassNames from 'classnames'
import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'

interface IProps {
  onDrop: any
}

const maxSize = 20000000
const DropZoneUploader = React.memo<IProps>(props => {
  const { isDragActive, getRootProps, getInputProps, isDragReject, rejectedFiles } = useDropzone({
    accept: 'image/*',
    onDrop: props.onDrop,
    maxSize,
    minSize: 0,
  })
  const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize

  return (
    <DropZone
      {...getRootProps()}
      className={ClassNames('DropZoneUploader', 'file-drop-input', {
        active: isDragActive && !isDragReject,
        rejected: isDragReject || isFileTooLarge,
      })}
    >
      <input {...getInputProps()} />
      <p className="file-drop-input-text">
        {!isDragActive && I18n.t('dropzone.select_or_drag_and_drop')}
        {isDragActive && !isDragReject && I18n.t('dropzone.drop')}
        {isDragReject && I18n.t('dropzone.invalid_file')}
        {isFileTooLarge && I18n.t('dropzone.too_large')}
      </p>
    </DropZone>
  )
})

const DropZone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 240px;
  border: dashed 2px ${constants.BORDER_COLOR};
  border-radius: 4px;
  padding: 8px;
  color: #888;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;

  &.active {
    color: var(${constants.THEME_COLOR_VARIABLE_NAME});
    border: dashed 2px var(${constants.THEME_COLOR_VARIABLE_NAME});
  }

  &.rejected {
    color: ${constants.DANGER_COLOR};
    border: dashed 2px ${constants.DANGER_COLOR};
  }
`

export default DropZoneUploader

import ClassNames from 'classnames'
import * as React from 'react'
import { TwitterTimelineEmbed } from 'react-twitter-embed'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IJsonResponse, IPost, IWindow } from '../../../../core/interfaces'
import { COLORS } from '../../../../static/constants'
import { Button, InputText, Panel, Select } from '../../../atoms'
import { Form } from '../../../molecules'

declare let window: IWindow

interface IErrors {
  [key: string]: string | null
}

interface IProps {
  post: IPost
  isProcessing: boolean
  handleFormSubmit(initialValues: any, values: any): void
}

const FIELDS = {
  twitter_username: 'twitter_username',
}

const Twitter: React.FC<IProps> = props => {
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [errors, setErrors] = React.useState<IErrors>({})

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }

  return (
    <>
      <Form
        fields={FIELDS}
        handleSubmit={props.handleFormSubmit}
        handleUpdateForm={handleUpdateForm}
      >
        <FormItem>
          <InputText
            required={false}
            name="twitter_username"
            label={I18n.t('settings.twitter.name')}
            defaultValue={props.post.twitter_username || ''}
            error={errors.twitter_username}
            placeholder="例：jet_hair_salon　(@マークなし)"
          />
        </FormItem>
        <FormItem>
          <Button primary={true} disabled={!isSubmitEnabled || props.isProcessing}>
            {I18n.t('generic.save')}
          </Button>
        </FormItem>
      </Form>

      {props.post.twitter_username && (
        <TwitterWrapper>
          <Panel className="timeline" title="Timeline">
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName={props.post.twitter_username}
              options={{ height: 800 }}
            />
          </Panel>
        </TwitterWrapper>
      )}
    </>
  )
}
const Twitter_Explanation = styled.div`
  font-size: 11px;
`
const TwitterWrapper = styled.div`
  margin-top: 20px;
  .timeline {
    max-width: 640px;
  }
`

const FormItem = styled.div`
  & + & {
    margin-top: 16px;
  }

  .FormItem_Title {
    font-size: 15px;
    font-weight: bold;
  }
`

export default Twitter

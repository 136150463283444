import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import * as constants from '../../../static/constants'

const Required: React.FC<{}> = () => {
  return <Container>{I18n.t('generic.required')}</Container>
}

const Container = styled.span`
  float: right;
  margin-right: 20px;
  display: inline-block;
  border-radius: 3px;
  color: #fff;
  background-color: ${constants.DANGER_COLOR};
  vertical-align: middle;
  font-size: 11px;
  font-weight: normal;
  padding: 4px 6px;
  margin-left: 8px;
`

export default Required

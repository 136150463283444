import { AxiosInstance } from 'axios'
import IJsonApiSerializer from 'core/interfaces/IJsonApiSerializer'

class AdminCategoryService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public async create(category) {
    const { data } = await this.httpClient.post(`/admin/api/categories`, { category })
    const createdCategory = this.serializer.parseResourceData(data.category, data.category.data)

    return { createdCategory, flash: data.flash }
  }

  public async update(category) {
    const { data } = await this.httpClient.patch(`/admin/api/categories/${category.id}`, {
      category,
    })
    const updatedCategory = this.serializer.parseResourceData(data.category, data.category.data)

    return { updatedCategory, flash: data.flash }
  }

  public async delete(id) {
    const { data } = await this.httpClient.delete(`/admin/api/categories/${id}`)

    return { flash: data.flash }
  }
}

export default AdminCategoryService

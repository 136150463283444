import { AxiosInstance } from 'axios'
import IJsonApiSerializer from 'core/interfaces/IJsonApiSerializer'

class AdminStaffService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public async approve(id) {
    const { data } = await this.httpClient.put(`/admin/api/staffs/${id}/approve`)
    const staff = this.serializer.parseResourceData(data.staff, data.staff.data)

    return { staff, flash: data.flash }
  }

  public async decline(id) {
    const { data } = await this.httpClient.put(`/admin/api/staffs/${id}/decline`)
    const staff = this.serializer.parseResourceData(data.staff, data.staff.data)

    return { staff, flash: data.flash }
  }
}

export default AdminStaffService

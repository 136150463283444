import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'
import Spinner from './Spinner'

const Loading: React.FC<{}> = () => {
  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: calc(100vh - ${constants.HEADER_HEIGHT}px);
  display: flex;
  justify-content: center;
  align-items: center;
`

export default Loading

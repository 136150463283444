import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import { IJsonResponse, IReservation, IWindow } from '../../../../core/interfaces'
import { utilService } from '../../../../core/services'
import { reservationService } from '../../../../core/services/admin'
import * as constants from '../../../../static/constants'
import { Button } from '../../../atoms'
import Pagination from '../../../atoms/Pagination'
import AdminLayoutIndex from '../_layouts/index'

declare var window: IWindow

interface IProps {
  reservations: {
    reservations: IJsonResponse
  }
}

const Index: React.FC<IProps> = props => {
  const { data: initialReservations, pagination } = utilService.getDataFromJson(
    props.reservations.reservations
  )
  const [reservations, setReservations] = React.useState<IReservation[]>(initialReservations)

  const onChangePageHandler = React.useCallback(page => {
    location.href = `${location.pathname}?page=${page}`
  }, [])

  const handleConfirmPayment = async id => {
    if (confirm('支払い済みにしますか？')) {
      const { reservation: updatedReservation, flash } = await reservationService.request(id)
      setReservations(
        reservations.map(item =>
          item.id === updatedReservation.id ? updatedReservation : { ...item }
        )
      )
      window.flashMessages.addMessage({ text: flash.message, type: flash.type })
    }
  }

  return (
    <AdminLayoutIndex
      model="reservation"
      main={
        <EditTableWrapper>
          <h1 className="EditTable_Title">
            <>
              <span>{I18n.t('activerecord.models.reservation')}</span>
            </>
          </h1>
          <div className="EditTable_Scroller">
            <div className="EditTable_Sizer">
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>ユーザー名</th>
                    <th>施設名</th>
                    <th>予約日</th>
                    <th>ステータス</th>
                    <th>{I18n.t('generic.price')}</th>
                    <th>支払日</th>
                    <th>キャンセル日</th>
                    <th>作成日</th>
                    <th>更新日</th>
                    <th>返金額</th>
                    <th>承認日</th>
                    <th className="w_0" />
                  </tr>
                </thead>
                <tbody>
                  {reservations.map((reservation, index) => (
                    <tr key={index}>
                      <td>{reservation.id}</td>
                      <td>{reservation.user.username}</td>
                      <td>{reservation.post.name}</td>
                      <td>{reservation.start_date}</td>
                      <td>{reservation.workflow_state_name}</td>
                      <td>{reservation.price}</td>
                      <td>{reservation.paid_at}</td>
                      <td>{reservation.canceled_at}</td>
                      <td>{reservation.created_at}</td>
                      <td>{reservation.updated_at}</td>
                      <td>{reservation.refund_amount}</td>
                      <td>{reservation.authorized_at}</td>
                      <td>
                        <Button key="edit" small={true}>
                          <a href={`/admin/reservations/${reservation.id}/edit`}>
                            {I18n.t('generic.edit')}
                          </a>
                        </Button>
                        {reservation.workflow_state === 'pending' && (
                          <Button
                            key="edit"
                            small={true}
                            handleClick={() => handleConfirmPayment(reservation.id)}
                          >
                            {'支払い確認'}
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {pagination && (
            <Pagination
              onChangePageHandler={onChangePageHandler}
              currentPage={pagination.current_page}
              prevPage={pagination.prev_page}
              nextPage={pagination.next_page}
              totalPages={pagination.total_pages}
              totalCount={pagination.total_count}
            />
          )}
        </EditTableWrapper>
      }
    />
  )
}

const EditTableWrapper = styled.div`
  .Pagination {
    padding-bottom: 24px;
  }

  .EditTable_Title {
    font-size: 18px;
    padding: 16px 20px;

    .Button {
      margin-left: 16px;
    }
  }

  .EditTable_Scroller {
    overflow: scroll;
    padding-bottom: 16px;
  }

  table {
    width: 100%;
    border-bottom: solid 1px ${constants.BORDER_COLOR};

    th,
    td {
      max-width: 400px;
      padding: 4px 8px;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-right: solid 1px ${constants.BORDER_COLOR};

      &.w_0 {
        width: 10px;
      }
    }

    tr {
      border-top: solid 1px ${constants.BORDER_COLOR};
    }
  }
`

export default Index

import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../static/constants'

const AreaSelect: React.FC<{}> = props => {
  return (
    <AreaSelectWrapper>
      <h1 className="heading">エリアを選択</h1>
      <div className="panels">
        <div className="flex-item">
          <div className="panel-item">
            <a href="/posts/search?area=東京">
              <span>東京</span>
              <img className="panels__img" src="/images/top/img_tokyo.jpg" alt="" />
              <div className="shadow" />
            </a>
          </div>
        </div>
        <div className="flex-item">
          <div className="panel-item">
            <a href="/posts/search?area=大阪">
              <span>大阪</span>
              <img className="panels__img" src="/images/top/img_osaka.jpg" alt="" />
              <div className="shadow" />
            </a>
          </div>
        </div>
        <div className="flex-item">
          <div className="panel-item">
            <a href="/posts/search?area=京都">
              <span>京都</span>
              <img className="panels__img" src="/images/top/img_kyoto.jpg" alt="" />
              <div className="shadow" />
            </a>
          </div>
        </div>
        <div className="flex-item">
          <div className="panel-item">
            <a href="/posts/search?area=札幌">
              <span>札幌</span>
              <img className="panels__img" src="/images/top/img_sapporo.jpg" alt="" />
              <div className="shadow" />
            </a>
          </div>
        </div>
      </div>
    </AreaSelectWrapper>
  )
}

const AreaSelectWrapper = styled.div`
  margin: 0 auto;
  padding: 24px 16px;
  max-width: 1182px;

  .heading {
    font-size: 24px;
  }

  .panels {
    display: flex;
    margin: 24px -10px 0;

    @media screen and (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      margin: 0 -10px;
      flex-wrap: wrap;
    }
  }

  .panel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 420px;
    border-radius: 4px;
    overflow: hidden;

    @media screen and (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
      height: 360px;
    }

    @media screen and (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
      height: 200px;
      margin-top: 16px;
    }

    > a {
      display: block;

      > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      > span {
        position: absolute;
        bottom: 36px;
        left: 0;
        right: 0;
        color: #fff;
        font-weight: bold;
        z-index: 1;
        margin: auto;
        text-align: center;
        font-size: 24px;

        @media screen and (max-width: #{$breakpoint-mobile}) {
          bottom: 24px;
          font-size: 20px;
        }
      }
    }
  }

  .shadow {
    background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 3%, #000 100%);
    opacity: 0.6;
    position: absolute;
    height: 60%;
    width: 100%;
    left: 0;
    bottom: 0;
  }

  .flex-item {
    width: 100%;
    margin: 0 10px;

    &.shrink-4 {
      flex-shrink: 4;

      @media screen and (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
        flex-shrink: 1;
      }
    }

    &.shrink-5 {
      flex-shrink: 5;

      @media screen and (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
        flex-shrink: 1;
      }
    }

    &.has-row {
      display: flex;
      flex-direction: column;
      margin: -10px 10px;

      @media screen and (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
        margin: 0;
      }

      .panel-item {
        height: 200px;
        margin: 10px 0;

        @media screen and (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
          height: 170px;
        }

        @media screen and (max-width: ${constants.BREAKPOINT_TABLET_MOBILE}px) {
          width: calc(100% - 20px);
          height: 200px;
          margin: 16px 10px 0;
        }
      }
    }
  }
`

export default AreaSelect

import * as React from 'react'
import styled from 'styled-components'
import * as constants from '../../../../static/constants'
import { SettingsSidebar } from '../../../organisms'

interface IProps {
  activePath: string
  is_owner: boolean
  main: any
}

const SettingsCommonLayout: React.FC<IProps> = props => {
  return (
    <Wrapper>
      <SettingsSidebar active={props.activePath} is_owner={props.is_owner} />
      <div className="Main">{props.main}</div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  margin: 0 auto;
  height: calc(100vh - ${constants.HEADER_HEIGHT}px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    flex-direction: column;
  }

  .Main {
    flex: 1;
    padding: 16px;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background-color: #f6f8f9;

    input[type='file'] {
      display: none;
    }
  }
`

export default SettingsCommonLayout

import * as React from 'react'
import ClickOutside from 'react-click-outside'
import styled from 'styled-components'
import * as constants from '../../static/constants'
import Button from './Button'

interface IWindow {
  globalModal: GlobalModal
}
declare var window: IWindow

interface IState {
  title: string
  showModal: boolean
  body: any
  closeText?: string
  submitText: string
  handleSubmit: any
}

const initialState = {
  title: '',
  showModal: false,
  body: null,
  closeText: '',
  submitText: '',
  handleSubmit: () => null,
}
const themeColor = getComputedStyle(document.documentElement)
  .getPropertyValue(`${constants.THEME_COLOR_VARIABLE_NAME}`)
  .trim()

export default class GlobalModal extends React.PureComponent<{}, IState> {
  constructor(props) {
    super(props)

    this.state = { ...initialState }
    window.globalModal = this
  }

  public showModal = config => {
    const { title, body, closeText, submitText, handleSubmit } = config
    this.setState({
      showModal: true,
      title,
      body,
      closeText,
      submitText,
      handleSubmit,
    })
  }

  public closeModal = () => {
    this.setState({ ...initialState })
  }

  public render() {
    return (
      <>
        {this.state.showModal && (
          <Modal className="Modal">
            {/* <ClickOutside className="Modal_Inner" onClickOutside={() => this.setState({ showModal: false })}> */}
            <div className="Modal_Inner">
              <div className="Modal_Header">
                <p className="Modal_Title">{this.state.title}</p>
              </div>
              <div className="Modal_Body">{this.state.body}</div>
              <div className="Modal_Footer">
                {this.state.closeText && (
                  <Button id="Modal_Close" handleClick={this.closeModal}>
                    {this.state.closeText}
                  </Button>
                )}
                {this.state.handleSubmit && (
                  <Button
                    id="Modal_Submit"
                    textColor="#fff"
                    backgroundColor={themeColor}
                    handleClick={this.state.handleSubmit}
                  >
                    {this.state.submitText}
                  </Button>
                )}
              </div>
              <span className="Modal_Close" onClick={() => this.setState({ showModal: false })}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
              </span>
              {/* </ClickOutside> */}
            </div>
          </Modal>
        )}
      </>
    )
  }
}

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.4s;
  transform: translate3d(0, 100%, 0);
  transform: none;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 6000;

  .Modal_Inner {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 16px;
    padding: 30px;
    overflow-x: hidden;
    border-radius: 2px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
  }

  .Modal_Header {
    > p {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .Modal_Body {
    padding-top: 24px;

    p.description {
      margin-top: 24px;
    }
  }

  .Modal_Footer {
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
  }

  .Modal_Close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`

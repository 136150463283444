import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'

interface IProps {
  name: string
  options: any[]
  defaultValue: string
  label?: null | string
  error?: null | string
  required?: boolean
  requiredTextHide?: boolean
  selectRef?: any
  small?: boolean
  onChangeHandler?(event): any
  onBlurHandler?(event): any
}

const Select: React.FC<IProps> = props => {
  return (
    <SelectWrapper>
      {props.label && (
        <p className="Select_Title">
          {props.label}
          {props.required && !props.requiredTextHide && (
            <span className="Select_Required">{I18n.t('generic.required')}</span>
          )}
        </p>
      )}
      <div className={props.small ? 'Select_Main Select_Main_Small' : 'Select_Main'}>
        <select
          id={props.name}
          name={props.name}
          required={props.required ? true : false}
          defaultValue={props.defaultValue}
          ref={props.selectRef || null}
          onChange={event => {
            if (typeof props.onChangeHandler === 'function') {
              props.onChangeHandler(event)
            }
          }}
          onBlur={event => {
            if (typeof props.onBlurHandler === 'function') {
              props.onBlurHandler(event)
            }
          }}
        >
          {/* <option value="">-</option> */}
          {props.options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {props.error && <span className="Select_Error">{props.error}</span>}
      </div>
    </SelectWrapper>
  )
}

const SelectWrapper = styled.div`
  display: flex;

  @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }

  .Select_Title {
    width: 240px;
    margin-top: 8px;
    font-size: 15px;
    font-weight: bold;
    line-height: 1;

    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  .Select_Main {
    flex: 1;

    > select {
      appearance: none;
      margin: 0;
      padding: 0;
      background: none transparent;
      vertical-align: middle;
      font-size: inherit;
      color: inherit;
      outline: none;

      width: 100%;
      border: solid 1px #eaedef;
      border-radius: 4px;
      padding: 8px 12px;
    }
  }

  .Select_Main_Small {
    max-width: 150px !important;
  }

  .Select_Required {
    float: right;
    margin-right: 20px;
    display: inline-block;
    border-radius: 3px;
    color: #fff;
    background-color: ${constants.DANGER_COLOR};
    vertical-align: middle;
    font-size: 11px;
    font-weight: normal;
    padding: 4px 6px;
    margin-left: 8px;
  }

  .Select_Error {
    display: block;
    margin-top: 8px;
    color: ${constants.DANGER_COLOR};
    font-size: 14px;
  }
`

export default Select

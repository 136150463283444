import { IPost, IReservation } from 'core/interfaces'
import 'moment/locale/ja'
import * as React from 'react'
import 'react-dates/initialize'
import styled from 'styled-components'
import I18n from '../../../../core/i18n'
import * as constants from '../../../../static/constants'
import { Button, Panel } from '../../../atoms'
import { Form } from '../../../molecules'

interface IProps {
  post: IPost
  reservation: IReservation
  setReservation: any
  setCurrentPage(page: string): void
}

const TimeTables: React.FC<IProps> = props => {
  const handleOptionSubmit = (initialValues, values) => {
    props.setReservation({
      ...props.reservation,
      ...values,
    })
    props.setCurrentPage('confirm')
  }

  return (
    <SelectOptionsWrapper>
      <Panel title={I18n.t('post.time_table.title')}>
        <Form
          fields={{
            time_table_1: 'time_table_1',
            time_table_2: 'time_table_2',
            time_table_3: 'time_table_3',
          }}
          handleSubmit={handleOptionSubmit}
        >
          <div className="Select_Form_Group">
            <div className="Select_Label">{I18n.t('reservation.side.time_table_1')}</div>
            <div className="Select_Wrapper">
              <select name="time_table_1">
                <option value="">{I18n.t('generic.none')}</option>
                {props.post.time_tables.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="Select_Form_Group">
            <div className="Select_Label">{I18n.t('reservation.side.time_table_2')}</div>
            <div className="Select_Wrapper">
              <select name="time_table_2">
                <option value="">{I18n.t('generic.none')}</option>
                {props.post.time_tables.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="Select_Form_Group">
            <div className="Select_Label">{I18n.t('reservation.side.time_table_3')}</div>
            <div className="Select_Wrapper">
              <select name="time_table_3">
                <option value="">{I18n.t('generic.none')}</option>
                {props.post.time_tables.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <div className="SelectOptions_Buttons">
              <Button primary={true}>{I18n.t('generic.next')}</Button>
            </div>
          </div>
        </Form>
      </Panel>
    </SelectOptionsWrapper>
  )
}

const SelectOptionsWrapper = styled.div`
  padding: 24px 12px;

  .Select_Form_Group {
    margin-top: 16px;

    select {
      position: relative;
      appearance: none;
      margin: 0;
      padding: 0;
      background: none transparent;
      vertical-align: middle;
      font-size: inherit;
      color: inherit;
      outline: none;

      width: 100%;
      border: solid 1px #eaedef;
      border-radius: 0;
      padding: 10px 12px;
      font-size: 14px;

      &::after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background-color: #ccc;
      }
    }

    .Select_Wrapper {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        right: 16px;
        top: -6px;
        bottom: 0;
        margin: auto;
        display: block;
        width: 11px;
        height: 11px;
        border-bottom: solid 1px ${constants.TEXT_COLOR};
        border-right: solid 1px ${constants.TEXT_COLOR};
        transform: rotate(45deg);
      }
    }

    .Select_Label {
      font-size: 14px;
      margin-bottom: 8px;
    }

    .SelectOptions_Buttons {
      display: flex;
      justify-content: center;
      margin-top: 24px;

      .Button {
        width: 160px;
      }
    }
  }
`

export default TimeTables

import moment from 'moment'
import 'moment/locale/ja'
import React from 'react'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import styled from 'styled-components'
import * as constants from '../../static/constants'
import SingleDatePickerCustomStyle from '../../static/singleDatePickerCustomStyle'
import { Button } from '../atoms'
import { Form } from '../molecules'

const FIELDS = {
  date: 'date',
  area: 'area',
}

moment.locale('ja', {
  months: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
})

const SearchPanel: React.FC<{}> = props => {
  const [focused, setFocused] = React.useState(false)
  const [selectedDate, setSelectedDate] = React.useState(null)
  const handleSubmit = React.useCallback(
    (initialValues, values) => {
      let queryParams = `?area=${values.area}`

      if (selectedDate !== null) {
        queryParams += `&date=${selectedDate.format('YYYY-MM-DD')}`
      }

      location.href = `/posts/search${queryParams}`
    },
    [selectedDate]
  )

  const onDatePickerChange = React.useCallback(changedDate => {
    setSelectedDate(changedDate)
  }, [])

  return (
    <SearchPanelWrapper className="SearchPanel">
      <h1>
        美容師が自宅まで来てくれる！
        <br />
        訪問美容室のマッチングサイト
      </h1>
      <Form fields={FIELDS} handleSubmit={handleSubmit}>
        <div className="SearchPanel_FromItem">
          <label htmlFor="area">エリア</label>
          <input
            className="SearchPanel_AreaInput"
            type="text"
            name="area"
            placeholder="現在地付近"
          />
        </div>
        <div className="SearchPanel_FromItem">
          <div className="SearchPanel_DateRangeLabels">
            <label htmlFor="startDate">日付</label>
          </div>
          <SingleDatePickerCustomStyle />
          <SingleDatePicker
            id="date"
            date={selectedDate}
            onDateChange={onDatePickerChange}
            focused={focused}
            onFocusChange={changed => setFocused(changed.focused)}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel={true}
            placeholder="選択してください"
          />
        </div>
        <Button primary={true}>美容室を探す</Button>
      </Form>
    </SearchPanelWrapper>
  )
}

const themeColor =
  getComputedStyle(document.documentElement)
    .getPropertyValue(`${constants.THEME_COLOR_VARIABLE_NAME}`)
    .trim() || '#4A90E2'

const SearchPanelWrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  max-width: 460px;
  padding: 32px 24px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 4px 16px;
  position: absolute;
  top: 120px;
  left: 5%;
  bottom: 120px;
  margin: 0 auto;
  z-index: 200;
  @media (max-width: ${constants.BREAKPOINT_TABLET_LARGE}px) {
    max-width: none;
    width: 100%;
    position: unset;
  }

  h1 {
    font-size: 26px;
  }

  label {
    display: block;
    font-size: 14px;
    margin-bottom: 6px;
  }

  .SearchPanel_FromItem {
    margin-top: 24px;
  }

  .SearchPanel_AreaInput {
    width: 100%;
    border: solid 1px ${constants.BORDER_COLOR};
    border-radius: 3px;
    padding: 12px;

    &:focus {
      border: solid 1px ${themeColor};
    }
  }

  .SearchPanel_DateRangeLabels {
    display: flex;

    > label {
      width: 50%;
    }
  }

  .Button {
    width: 100%;
    height: 52px;
    font-size: 18px;
    font-weight: bold;
    margin-top: 32px;
    border-radius: 8px;
    background-color: #f45d47;
  }
`

export default SearchPanel
